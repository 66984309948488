import { combineEpics } from "redux-observable";

import { AccountEpic } from "./account.epic";
import { AuthEpic } from "./auth.epic";
import { ConfigEpic } from "./../epics/config.epic";
import { SearchPanelEpic, CarBookingEpic } from "./../../components";
import { VehicleImagesEpics } from "./vehicleImages.epic";
import { AppEpic } from "./app.epic";

export const epics = combineEpics(
  AppEpic.resetState,
  AuthEpic.login,
  AuthEpic.signup,
  AuthEpic.forgotPassword,
  AuthEpic.resetPassword,
  ConfigEpic.getSearchVehicleFilter,
  ConfigEpic.getAllCountries,
  ConfigEpic.fetchCarTypes,
  ConfigEpic.stripeKey,
  ConfigEpic.getBlogs,
  ConfigEpic.getLocationData,
  ConfigEpic.getPrivacyPageContent,
  SearchPanelEpic.searchStations,
  SearchPanelEpic.getCars,
  SearchPanelEpic.loadmoreCars,
  SearchPanelEpic.bookCar,
  SearchPanelEpic.getAvailableCars,
  CarBookingEpic.carBooking,
  AccountEpic.getBookedBookings,
  AccountEpic.getExpiredBookings,
  AccountEpic.getBookingDetail,
  AccountEpic.updateUserProfile,
  AccountEpic.cancelBooking,
  VehicleImagesEpics.uploadVehicleImages,
  VehicleImagesEpics.deleteVehicleImages
);
