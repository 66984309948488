import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { BounceLoader } from "react-spinners";
import ImageUploader from "react-images-upload";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";

import { DropDownInput, Button } from "../../components";
import {
  uploadVehicleImages,
  clearUploadVehicleState,
} from "../../store/actions/vehicleImages.action";
import * as styles from "./styles.module.scss";
import axios from "axios";
import {BASE} from "../../config/api";

export const CheckoutImages = () => {
  const [selectedType, setSelectedType] = useState();
  const [pictures, setPictures] = useState([]);
  const { isLoading, success } = useSelector((state) => state.vehicleImages);
  const { token } = useSelector((state) => state.auth);
  const [ImageUploaderKey, setImageUploaderKey] = useState(0);
  const [isShowForm, setisShowForm] = useState(true);
  const [failureMessage, setFailureMessage] = useState("");

  const Dispatch = useDispatch();
  const { id } = useParams();

  const onDrop = (picture) => setPictures([...picture]);

  const onSubmit = () => {
    let formData = new FormData();
    for (var i = 0; i < pictures.length; i++) {
      let file = pictures[i];
      formData.append("images[" + i + "]", file);
    }
    formData.append("type", selectedType.value);
    formData.append("bookingReference", id);
    formData.append("target", "checkout");
    Dispatch(uploadVehicleImages(token, formData));
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (success) {
      setImageUploaderKey(ImageUploaderKey + 1);
      setSelectedType(null);
      Dispatch(clearUploadVehicleState());
    }
  }, [success]);

  const checkIfCanShow = async () => {
    const {
      data: { status, message },
    } = await axios.post(
      `${BASE}/validate-booking-photos`,
      {
        bookingReference: id,
        target: "checkout",
      }
    );
    setFailureMessage(message);
    setisShowForm(status);
  };

  useEffect(() => {
    checkIfCanShow();
  }, []);

  return isShowForm ? (
    <Container>
      <Row>
        <Col></Col>
        <Col md="4">
          <DropDownInput
            label="Type"
            value={selectedType}
            name="country_of_residence"
            placeholder="Select Type"
            onChange={(val) => setSelectedType(val)}
            options={[
              {
                label: "Interior",
                value: "interior",
              },
              {
                label: "Exterior",
                value: "exterior",
              },
            ]}
          ></DropDownInput>
        </Col>
        <Col></Col>
      </Row>
      <Row className={styles.mrTop20}>
        <Col></Col>
        <Col xs="6">
          <ImageUploader
            key={ImageUploaderKey}
            className={styles.imageUploader}
            withIcon={false}
            withLabel={false}
            withPreview
            fileContainerStyle={{}}
            buttonStyles={{
              background: "#035372",
              borderColor: "#035372",
            }}
            buttonText="Add Image"
            onChange={onDrop}
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col sm="6">
          <Button
            disabled={!(pictures.length && selectedType) || isLoading}
            blueBG
            type="button"
            onClick={onSubmit}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Submit
            {isLoading && (
              <span style={{ marginLeft: "10px" }}>
                <BounceLoader
                  sizeUnit={"px"}
                  size={20}
                  color={"#035372"}
                  loading={isLoading}
                />
              </span>
            )}
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  ) : (
    <Container className="d-flex justify-content-center">
      <h5>{failureMessage}</h5>
    </Container>
  );
};
