import firebase from "firebase";

export const initializeFirebase = () => {
  firebase.initializeApp({
    appId: "1:910282349657:web:970dbe6fab24caba612548",
    apiKey: "AIzaSyAYMCt3XxaYKDEh34Ln5LBfDjLz5NoWvMI",
    authDomain: "rentalmoose-a4038.firebaseapp.com",
    databaseURL: "https://rentalmoose-a4038.firebaseio.com",
    projectId: "rentalmoose-a4038",
    storageBucket: "rentalmoose-a4038.appspot.com",
    messagingSenderId: "910282349657",
    measurementId: "G-MHRY996VMQ",
  });
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function() {
      navigator.serviceWorker
        .register("./service-worker.js")
        .then(
          function(registration) {
            console.log("Worker registration successful", registration.scope);
            firebase.messaging().useServiceWorker(registration);
          },
          function(err) {
            console.log("Worker registration failed", err);
          }
        )
        .catch(function(err) {
          console.log(err);
        });
    });
  } else {
    console.log("Service Worker is not supported by browser.");
  }
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    return token;
  } catch (error) {
    return error;
  }
};
