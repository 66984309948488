import React, { Fragment } from "react";

import { StepperComponent, SearchPanelComponent } from "./../../components";

export const BookingDetail = () => {
  return (
    <Fragment>
      <StepperComponent />
    </Fragment>
  );
};
