import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import ReactGA from "react-ga";

import { HorizontalBorder, FeedbackForm } from "../../components";
import { ContactCard } from "./contactUtils";
import ContactChatIcon from "./../../assets/chat-icon.svg";
import writeIcon from "./../../assets/write-icon.svg";
import { BASE, FEEDBACK_API } from "./../../config/api";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";

import {
  ACTION_INITIATED,
  ACTION_SUCCESS,
  ACTION_FAILED
} from "../../store/reducers/app.reducer";

export const Contact = () => {
  const Dispatch = useDispatch();
  const [respnonseState, setResponseState] = useState({});
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    try {
      window.scroll({
        top: 600,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(600, 0);
    }
  }, []);

  const handleSubmitContactForm = async (values, setSubmitting) => {
    Dispatch({ type: ACTION_INITIATED });
    try {
      axios
        .post(`${BASE}${FEEDBACK_API}`,  values )
        .then(res => {
          setResponseState(res);
          setSubmitting(false);
          Dispatch({
            type: ACTION_SUCCESS,
            message: "Thank you for your feedback!"
          });
        })
        .catch(error => {
          Dispatch({ type: ACTION_FAILED, message: "System is busy. Try again later" });
        });
    } catch (err) {
      Dispatch({ type: ACTION_FAILED, message: "" });
      setSubmitting(false);
    }
    // try {
    //   const response =  axios.post(`${BASE}${FEEDBACK_API}`, values);
    //   setResponseState(response);
    //   setSubmitting(false);
    // } catch (err) {
    //   setSubmitting(false);
    // }
  };

  return (
    <React.Fragment>
      <section className={styles.contactUsSection}>
        <Container>
          <Row>
            <Col className="col-12">
              <div className={styles.contactIntroSection}>
                <h2 className={styles.title}>Get immediate help</h2>
                <p>
                  Protect your rental and get discount on full excess cover.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ContactCard
                title="Chat"
                desc="The fastest way to get an answer to your question regarding renting a car"
                logo={ContactChatIcon}
              />
            </Col>
            <Col md="6">
              <ContactCard
                title="Write"
                desc="Give us feedback and ask questions if you have not found an answer in the Rentalmoose chat"
                logo={writeIcon}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <HorizontalBorder />
      <FeedbackForm
        response={respnonseState}
        handleSubmitContactForm={handleSubmitContactForm}
      />
    </React.Fragment>
  );
};

export default Contact;
