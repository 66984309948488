export const formikConfig_FeedbackForm = {
  initialValues: {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  },
  validate: values => {
    let errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.phone) {
      errors.phone = "Required";
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.message) {
      errors.message = "Required";
    }
    return errors;
  }
};
