import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import {
	setUserCurrency,
	setUserCountry,
	setLocationHeaderStatus,
	setLocationCheckIn,
	setLocationHeaderInformation,
	setLocationBlogs,
} from './../../store/actions/config.action';
import { TopBar } from './TopBar';
import { LocationPagesHeader } from './LocationPagesHeader';
import { Navigation } from './Navigation';
import styles from './styles.module.scss';
import { GET_LOCATION_NAME, BASE } from '../../config/api';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import {
	selectDropOffStation,
	selectEndDate,
	selectPickUpStation,
	selectStartDate,
} from './../SearchPanel';
import MooseIcon from '../../assets/moose-big.png';
export const LocationHeader = (props) => {
	const { pathname } = useLocation();
	let [checkinLocationFromURL, setCheckinLocation] = useState({});
	let [checkoutLocationFromURL, setCheckoutLocation] = useState({});
	const location = pathname.startsWith('/location');
	const Dispatch = useDispatch();
	const {
		currencies,
		locationHeaderInformation,
		getLocationCheckIn,
	} = useSelector((state) => state.config);
	useEffect(() => {
		const setUserData = () => {
			Dispatch(
				setUserCountry({
					code: 'ee',
					country: 'Estonia',
					flag: '',
				})
			);
		};
		setUserData();
	}, []);
	useEffect(() => {
		const fetchIp = () => {
			axios.get('https://api.ipify.org/?format=json').then((res) => {
				axios.get(`${BASE}/getIPDetails/${res.data.ip}`).then((result) => {
					// let user_currency = currencies.find((v) => v.country_code === result.data.country_code);
					Dispatch(
						setUserCurrency(
							false
								? false
								: {
										id: 'EUR',
										country_code: result.data.country_code,
										sign: '€',
										name: 'EUR',
								  }
						)
					);
					Dispatch(
						setUserCountry(
							false
								? false
								: {
										code: result.data.country_code,
										country: result.data.country_name,
										flag: '',
								  }
						)
					);
				});
			});
		};

		const locationInfo = () => {
			let name1 = pathname.substring(pathname.lastIndexOf('/') + 1);
			let name = pathname.split('/');
			console.log('>>>>>>>>>>>>>>>');
			console.log(name);

			let url = '';
			let url_2 = '';
			if (name.length == 3 || (name.length == 4 && name[3] == '')) {
				url = '/locationCountry/' + name[2];
				url_2 = '/locationBlogs/' + name[2];
			} else {
				url = pathname;
				url_2 = '/locationBlogs/' + name[3];
			}
			axios.get(`${BASE}${url}`).then((result) => {
				Dispatch(setLocationCheckIn(result.data.data.station_code[0].code));
				Dispatch(
					selectStartDate(
						moment()
							.add(1, 'days')
							.format()
					)
				);
				Dispatch(
					selectEndDate(
						moment()
							.add(2, 'days')
							.format()
					)
				);
				setCheckinLocation(result.data.data.station_code[0]);
				Dispatch(selectPickUpStation(result.data.data.station_code[0]));
				setCheckoutLocation(result.data.data.station_code[0]);
				selectDropOffStation(result.data.data.station_code[0]);
				Dispatch(
					setLocationHeaderInformation(
						false
							? false
							: {
									temp: result.data.data.current_temperature,
									current_time: result.data.data.current_time,
									complete_temperature: result.data.data.complete_temperature,
									temp2: result.data.data.temp2,
									nearest_locations: result.data.data.nearest_locations,
									currency_name: result.data.data.currency_name,
									gas_price: result.data.data.gas_price,
									driving_side: result.data.data.driving_side,
									image_1: result.data.data.image1,
									image_name: result.data.data.image_1_name,
									image_2: result.data.data.image2,
									car_rentaal_offer: result.data.data.car_rentaal_offer,
									location: result.data.data.location,
									station_code: result.data.data.station_code,
									code: result.data.data.code,
									station_pick_code: result.data.data.station_code[0].code,
									response: true,
							  }
					)
				);
			});
			axios.get(`${BASE}${url_2}`).then((result) => {
				console.log('|||||');
				console.log(result.data);
				Dispatch(
					setLocationBlogs(
						false
							? false
							: {
									blogs: result.data.data,
									response: true,
							  }
					)
				);
			});
		};

		if (location) {
			locationInfo();
			Dispatch(setLocationHeaderStatus(true));
		}

		fetchIp();
	}, []);

	let image_1 = '';
	console.log(getLocationCheckIn);
	console.log('^^^^^^^^^^^^^^^^^');
	if (locationHeaderInformation) {
		image_1 = locationHeaderInformation.image_1;
	}
	let image_name = '';
	if (locationHeaderInformation) {
		image_name = locationHeaderInformation.image_name;
	}
	return (
		<div
			className={`
      ${styles.header_container}
      ${pathname === '/location' && styles.header_result_container}
      ${pathname.startsWith('/location') && styles.header_result_container}
      `}
		>
			<TopBar {...props} />
			<LazyLoad height={200}>
				<div
					className={styles.location_header_banner}
					style={{ background: `url(${image_1}) no-repeat` }}
				>
					<div className={styles.mooseImage}>
						<img
							src={MooseIcon}
							alt='Moose'
							className={`${styles.mooseImageSet} img-fluid`}
						/>
					</div>
					<Navigation {...props} />
					{locationHeaderInformation?.station_pick_code && (
						<LocationPagesHeader info={locationHeaderInformation} />
					)}
				</div>
				<div className='Paragraph px-2 mt-2 float-right'>
					<p className='mr-1'>{image_name}</p>
				</div>
			</LazyLoad>
		</div>
	);
};
