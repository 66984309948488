import { Header } from "./header";
import { LocationHeader } from "./LocationHeader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal } from "../../store/actions/modal.action";
import {
  searchStation,
  selectPickUpStation,
  selectStartDate,
  selectEndDate,
  getCars,
  getAvailableCars
} from "./../SearchPanel/searchPanel.action";

const mapStateToProps = ({ modalState, auth, searchParams, searchResult, config }) => ({
  authState: auth,
  modalState,
  searchParams,
  searchResult,
  config
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      searchStation,
      selectPickUpStation,
      selectStartDate,
      selectEndDate,
      getCars,
      getAvailableCars
    },
    dispatch
  );

export let HeaderComponent = connect(mapStateToProps, mapDispatchToProps)(Header);
export let LocationHeaderComponent = connect(mapStateToProps, mapDispatchToProps)(LocationHeader);
