import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Formik, Form } from "formik";

import { ModalButton, formikConfig_Login } from "./AuthModalUtils";
import { CheckBox, InputText } from "../..";
import styles from "./styles.module.scss";

export const LoginModal = (props) => {
  const { loginModal } = props.modalState;
  const { showModal, hideModal, login, authState, resetMessage } = props;

  const toggle = () => {
    if (loginModal) {
      hideModal("loginModal");
      resetMessage();
    } else {
      showModal("loginModal");
    }
  };

  const handleSwitchModal = () => {
    resetMessage();
    hideModal("loginModal");
    showModal("signupModal");
  };

  const handleForgotPasswordSwitchModal = () => {
    resetMessage();
    hideModal("loginModal");
    showModal("forgotPasswordModal");
  };

  const handleSubmit = (values, { setSubmitting }) => {
    login(values, hideModal, setSubmitting);
  };
  const showErrorBorder = (error) => {
    if (error) return { border: "solid 1px red" };
    return {};
  };
  return (
    <Modal isOpen={loginModal} toggle={toggle} className="modal-dialog-centered">
      <ModalBody className={styles.modalBody_container}>
        <h1>Your Account</h1>
        <Formik
          enableReinitialize
          initialValues={formikConfig_Login.initialValues}
          validate={formikConfig_Login.validate}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <>
              <Form>
                <InputText
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={showErrorBorder(errors.email && touched.email && errors.email)}
                  label="Email"
                />
                {errors.email && touched.email && errors.email}

                <InputText
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={showErrorBorder(errors.password && touched.password && errors.password)}
                  label="Password"
                />
                {errors.password && touched.password && errors.password}

                <CheckBox
                  value={values.checked}
                  onChange={handleChange}
                  id="login_stayLoggedin_checkbox"
                  name="checkbox_checked"
                  label="Keep me signed in"
                  className={styles.checkBox}
                />
                <div className={styles.modalBtn_container}>
                  <ModalButton label="Sign in" type="submit" disabled={isSubmitting} />
                  <ModalButton onClick={handleSwitchModal} label="Register" />
                </div>
              </Form>
              {authState && authState.isError && (
                <p style={{ color: "brown" }}>invalid email or password</p>
              )}
              <span onClick={handleForgotPasswordSwitchModal} className={styles.passwordRecovery}>
                Password recovery
              </span>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
