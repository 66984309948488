import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SIGN_UP,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS
} from "./../actions/auth.action";
import {
  BASE,
  LOGIN_API,
  SIGNUP_API,
  RESET_PASSWORD_API,
  FORGOT_PASSWORD_API
} from "../../config/api";

import { askForPermissioToReceiveNotifications } from "../../push-notification";

export class AuthEpic {
  static login = action$ =>
    action$.pipe(
      ofType(LOGIN),
      switchMap(({ payload, hideModal, setSubmitting }) => {
        return new Observable(async observer => {
          try {
            const result = await axios.post(`${BASE}${LOGIN_API}`, payload);
            const { data } = result;
            if (data.data.token) {
              observer.next({
                type: LOGIN_SUCCESS,
                user: data.data,
                token: data.data.token,
                message: "User Successfull logged in"
              });
              hideModal("loginModal");
              setSubmitting(false);
            } else {
              observer.next({
                type: LOGIN_FAILED,
                user: {},
                message: "Invalid Username or Password"
              });
              setSubmitting(false);
            }
          } catch (err) {
            const errorMessage = err.response.data.error;
            observer.next({
              type: LOGIN_FAILED,
              user: {},
              message: errorMessage
            });
            setSubmitting(false);
          }
        });
      })
    );
  static signup = action$ =>
    action$.pipe(
      ofType(SIGN_UP),
      switchMap(({ payload, hideModal, setSubmitting }) => {
        return new Observable(async observer => {
          try {
            const fcm_token = await askForPermissioToReceiveNotifications();
            const result = await axios.post(`${BASE}${SIGNUP_API}`, {
              ...payload,
              fcm_token: typeof fcm_token === "string" ? fcm_token : null
            });
            const { data } = result;
            if (data.data.token) {
              observer.next({
                type: SIGN_UP_SUCCESS,
                user: data.data,
                token: data.data.token,
                message: data.message
              });
              hideModal("signupModal");
              setSubmitting(false);
            } else {
              observer.next({
                type: SIGN_UP_FAILED,
                user: {},
                message: data.message
              });
              setSubmitting(false);
            }
          } catch (err) {
            const errors = err.response.data.errors;
            const firstError = Object.keys(errors)[0];
            const errorMessage = errors[firstError];
            observer.next({
              type: SIGN_UP_FAILED,
              user: {},
              message: errorMessage ? errorMessage : "Error in Sign up"
            });
            setSubmitting(false);
          }
        });
      })
    );
  static forgotPassword = action$ =>
    action$.pipe(
      ofType(FORGOT_PASSWORD),
      switchMap(({ payload, hideModal, setSubmitting }) => {
        return new Observable(async observer => {
          try {
            const result = await axios.post(
              `${BASE}${FORGOT_PASSWORD_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: FORGOT_PASSWORD_SUCCESS,
                message: data.message
              });
              setSubmitting(false);
              hideModal("forgotPasswordModal");
            } else {
              observer.next({
                type: FORGOT_PASSWORD_FAILED,
                message: data.message
              });
              setSubmitting(false);
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: FORGOT_PASSWORD_FAILED,
              message: errorMessage ? errorMessage : "Error in Forgot Password"
            });
            setSubmitting(false);
          }
        });
      })
    );
  static resetPassword = action$ =>
    action$.pipe(
      ofType(RESET_PASSWORD),
      switchMap(({ payload, push, setSubmitting }) => {
        return new Observable(async observer => {
          try {
            const result = await axios.post(
              `${BASE}${RESET_PASSWORD_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: RESET_PASSWORD_SUCCESS,
                message: data.message
              });
              setTimeout(() => {
                push("/");
              }, 2000);
              setSubmitting(false);
            } else {
              observer.next({
                type: RESET_PASSWORD_FAILED,
                message: data.message
              });
              setSubmitting(false);
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: RESET_PASSWORD_FAILED,
              message: errorMessage ? errorMessage : "Error in Password Reset"
            });
            setSubmitting(false);
          }
        });
      })
    );
}
