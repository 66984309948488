import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";

export default ({ errorString }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="h-100 w-100">
      <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
        <h1
          style={{
            fontSize: "72px"
          }}
          className="mb-2"
        >
          Error!!!
        </h1>
        <span className="mb-2" style={{ fontSize: "24px" }}>
          Something went wrong!!
        </span>
        <a href="https://rentalmoose.com/" onClick={toggle} style={{ marginBottom: "1rem", cursor: "pointer" }}>
          Go To Home Page
        </a>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              <code>{errorString}</code>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};
