import {
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  LOGOUT,
  UPDATE_USER,
  RESET_MESSAGE
} from "./../actions/auth.action";

const INITIAL_STATE = {
  isUserLoggedIn: false,
  isError: false,
  isLoading: false,
  success: false,
  language: "English",
  message: "",
  user: {}
};

export const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        isLoading: true,
        isUserLoggedIn: false,
        isError: false,
        message: "",
        user: {}
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isError: false,
        success: action.success,
        user: action.user,
        message: action.message,
        isUserLoggedIn: true,
        token: action.token
      });
    case LOGIN_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        isUserLoggedIn: false,
        success: false,
        message: action.message
      });

    case SIGN_UP:
      return Object.assign({}, state, {
        isLoading: true,
        isUserLoggedIn: false,
        isError: false,
        message: "",
        user: {}
      });
    case SIGN_UP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isError: false,
        success: action.success,
        user: action.user,
        message: action.message,
        isUserLoggedIn: true,
        token: action.token
      });
    case SIGN_UP_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        isUserLoggedIn: false,
        success: false,
        message: action.message
      });
    case FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        message: "",
        isError: false
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isError: false
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isError: true
      };
    case RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
        message: "",
        isError: false
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isError: false
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isError: true
      };
    case LOGOUT:
      return INITIAL_STATE;
    case UPDATE_USER: {
      return {
        ...state,
        user: action.user
      };
    }
    case RESET_MESSAGE: {
      return {
        ...state,
        message: "",
        isError: false,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
