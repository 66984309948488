import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  LOGOUT,
} from "./../actions/auth.action";
import {
  GET_CARS,
  GET_CARS_FAILED,
  GET_CARS_SUCCESS,
  GET_AVAILABLE_CARS,
  GET_AVAILABLE_CARS_SUCCESS,
  GET_AVAILABLE_CARS_FAILED,
  BOOK_CAR,
  BOOK_CAR_FAILED,
  BOOK_CAR_SUCCESS,
} from "./../../components/SearchPanel/searchPanel.action";
import {
  CONFIRM_CAR_BOOKING,
  CONFIRM_CAR_BOOKING_FAILED,
  CONFIRM_CAR_BOOKING_SUCCESS,
} from "./../../components/Stepper/stepper.action";
import {
  CANCEL_BOOKING,
  CANCEL_BOOKING_FAILED,
  CANCEL_BOOKING_SUCCESS,
  GET_BOOKED_BOOKINGS,
  GET_BOOKED_BOOKINGS_FAILED,
  GET_BOOKED_BOOKINGS_SUCCESS,
  GET_EXPIRED_BOOKINGS,
  GET_EXPIRED_BOOKINGS_FAILED,
  GET_EXPIRED_BOOKINGS_SUCCESS,
  GET_BOOKING_DETAIL,
  GET_BOOKING_DETAIL_FAILED,
  GET_BOOKING_DETAIL_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
} from "./../actions/account.action";
import {
  UPLOAD_VEHICLE_IMAGES,
  UPLOAD_VEHICLE_IMAGES_FAILED,
  UPLOAD_VEHICLE_IMAGES_SUCCESS,
} from "./../actions/vehicleImages.action";

export const RESET_STATE = "RESET_STATE";
export const ACTION_INITIATED = "ACTION_INITIATED";
export const ACTION_FAILED = "ACTION_FAILED";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const SET_APP_VERSION = "SET_APP_VERSION";

const INITIAL_STATE = {
  isLoading: false,
  message: "",
  response: false,
  type: "",
  version: 0,
};

export const setAppVersion = (payload) => {
  return {
    type: SET_APP_VERSION,
    payload,
  };
};

export const AppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CARS:
    case BOOK_CAR:
    case GET_BOOKED_BOOKINGS:
    case GET_EXPIRED_BOOKINGS:
    case GET_BOOKING_DETAIL:
    case UPDATE_USER_PROFILE:
    case CANCEL_BOOKING:
    case ACTION_INITIATED:
      return {
        isLoading: true,
        message: "",
        type: "",
        response: false,
      };
    case GET_CARS_FAILED:
    case BOOK_CAR_FAILED:
    case CONFIRM_CAR_BOOKING_FAILED:
    case SIGN_UP_FAILED:
    case LOGIN_FAILED:
    case FORGOT_PASSWORD_FAILED:
    case GET_BOOKED_BOOKINGS_FAILED:
    case GET_EXPIRED_BOOKINGS_FAILED:
    case GET_BOOKING_DETAIL_FAILED:
    case UPDATE_USER_PROFILE_FAILED:
    case CANCEL_BOOKING_FAILED:
    case UPLOAD_VEHICLE_IMAGES_FAILED:
    case GET_AVAILABLE_CARS_FAILED:
    case ACTION_FAILED:
      return {
        isLoading: false,
        message: action.message,
        type: "error",
        response: true,
      };
    case BOOK_CAR_SUCCESS:
    case CONFIRM_CAR_BOOKING_SUCCESS:
    case SIGN_UP_SUCCESS:
    case LOGIN_SUCCESS:
    case GET_CARS_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case GET_BOOKED_BOOKINGS_SUCCESS:
    case GET_EXPIRED_BOOKINGS_SUCCESS:
    case GET_BOOKING_DETAIL_SUCCESS:
    case UPDATE_USER_PROFILE_SUCCESS:
    case CANCEL_BOOKING_SUCCESS:
    case UPLOAD_VEHICLE_IMAGES_SUCCESS:
    case GET_AVAILABLE_CARS_SUCCESS:
    case ACTION_SUCCESS:
      return {
        isLoading: false,
        message: action.message,
        type: "success",
        response: true,
      };
    case SET_APP_VERSION:
      return {
        ...state,
        version: action.payload,
      };
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
