import React from 'react';
import { DateRangePicker } from 'react-dates';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.scss';

import styles from './styles.module.scss';

export const DatePicker = ({
  focusedInput,
  setfocusedInput,
  searchProps,
  clearSearchText,
}) => {
  const {
    searchResult: { suppliers },
    searchParams: {
      pickUpStation: { code, country_code },
      deposit,
      selfLiabity,
      mileage,
      driverAge,
      childSeat,
      transmission,
      pick_up_type,
      winter_tyres,
      supplier_rating,
      number_of_seats,
      luggage_space,
      driver,
      electric,
      hybrid,
      bestValues,
      cheapest,
      expensive,
    },
    selectStartDate,
    selectEndDate,
    getCars,
  } = searchProps;
  const WINDOW_WIDTH = window.innerWidth;
  const { push } = useHistory();
  const { selectedCurrency } = useSelector((state) => state.config);
  const [StartDate_, setStartDate] = React.useState();
  const [EndDate_, setEndDate] = React.useState();

  const formatDateTime = (date) => {
    let _d = date?.format('l');
    let _t = moment(new Date()).format('LT');
    let dayDiff = moment(_d).diff(moment().format('l'), 'days');
    let hours = moment(date).hours();
    if (dayDiff > 0 || (dayDiff === 0 && hours < 9)) {
      _t = '11:00 AM';
    } else {
      let minutes = moment(new Date()).minutes();
      let addMinutes = minutes % 30 === 0 ? 30 : 30 - (minutes % 30);
      _t = moment(new Date())
        .add(addMinutes, 'minutes')
        .format('LT');
    }
    let formatedDate = moment(_d + ' ' + _t).format();
    return formatedDate;
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    let StartD_T = null;
    if (startDate) {
      StartD_T = formatDateTime(startDate);
      setStartDate(StartD_T);
      selectStartDate(StartD_T);
      selectEndDate(null);
    }
    if (endDate) {
      window.gtag("event", "Dates have been selected", {
        event_category: "Dates",
        event_label: "Dates have been selected"
      })
      let EndD_T = formatDateTime(endDate);
      //let _suppliers = suppliers.map((v) => v.id);
      setEndDate(EndD_T);
      selectEndDate(EndD_T);
      const values = {
        search: true,
        path_type : true,
        checkin_station_code: code,
        checkout_station_code: '',
        country_code: country_code,
        checkin_time: StartD_T,
        checkout_time: EndD_T,
        currency: selectedCurrency && selectedCurrency.id,
        country: 'PK',
        filters: {
          deposit: deposit,
          selfLiabity: selfLiabity,
          mileage: mileage,
          driverAge: driverAge,
          childSeat: childSeat,
          transmission: transmission,
          pick_up_type: pick_up_type,
          winter_tyres: winter_tyres,
          supplier_rating: supplier_rating,
          number_of_seats: number_of_seats,
          luggage_space: luggage_space,
          driver: driver,
        },
        supplier_filter: [],
        goGreenTypeFilter: { hybrid: hybrid, electric: electric },
        sortingFilters: {
          bestValues: bestValues,
          cheapest: cheapest,
          expensive: expensive,
        },
      };
      getCars(values, push, true);
      clearSearchText('');
      setfocusedInput(null);
    }
  };

  const renderCalendarInfo = () => {
    let _startDate = StartDate_ && moment(StartDate_).format('ll');
    let _endDate = EndDate_ && moment(EndDate_).format('ll');
    let _duration = moment(StartDate_).diff(EndDate_, 'days');

    return (
      _duration && (
        <div className={styles.calendarInfo_container}>
          <span>
            From <strong>{_startDate}</strong> to <strong>{_endDate} </strong>
            {_endDate && (
              <strong>
                {'— '}
                {!isNaN(_duration) ? _duration : 0} days
              </strong>
            )}
          </span>
        </div>
      )
    );
  };
  const isBlockedDay = (day) => {
    const dates = [
      new Date().setDate(new Date().getDate()),
      new Date().setDate(new Date().getDate() + 1),
      new Date().setDate(new Date().getDate() + 2),
    ];
    return dates.some((date) =>
      day.isSame(new Date(date).toDateString(), 'day')
    );
  };
  return (
    <DateRangePicker
      startDate={StartDate_ ? moment(StartDate_) : null}
      endDate={EndDate_ ? moment(EndDate_) : null}
      startDateId="search_start_date"
      endDateId="search_end_date"
      numberOfMonths={WINDOW_WIDTH <= 767 ? 1 : 2}
      keepOpenOnDateSelect
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setfocusedInput(focusedInput)}
      renderCalendarInfo={renderCalendarInfo}
      onDatesChange={handleDatesChange}
      // isDayBlocked={isBlockedDay}
    />
  );
};
