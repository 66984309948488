import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, Nav } from "reactstrap";

import {
  ChatLink,
  CurrencyChange,
  LanguageChange,
  TopbarButton
} from "../TopBar/topBarUtils";
import { NavBarComponent, NavLogo } from "./navUtils";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";

export const Navigation = ({ showModal }) => {
  let [isOpen, handleNavDropdown] = useState(false);
  let Width = window.innerWidth;
  const { pathname } = useLocation();
  const location = pathname.startsWith('/location');
  
  function NavComp() {
    if (!location) {
      return  <Nav navbar>
      <NavBarComponent handleNavDropdown={handleNavDropdown} />
    </Nav>;
    }
      return "";
    }
  const toggle = () => handleNavDropdown(!isOpen);

  const handleAuthModal = modal => {
    showModal(modal);
    handleNavDropdown(false);
  };

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "inherit";
  }

  return (
    <div className={styles.main_navbar}>
      <div className={isOpen ? "DropDown active" : "DropDown"}>
        <Navbar color="faded" light expand="md">
          {Width <= 767 && <span></span>}
          <NavLogo />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <div className={styles.MobileDropDown}>
              <NavComp/>
              <ul className={styles.contactLinkMobile}>
                <ul className={styles.LcMobile}>
                  <LanguageChange />
                  <CurrencyChange />
                </ul>
                <TopbarButton title="Login" onClick={() => handleAuthModal("loginModal")} />
                <TopbarButton title="Signup"onClick={() => handleAuthModal("signupModal")} />
              </ul>
            </div>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};
