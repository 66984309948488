import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export default props => {
  const { isOpen, toggleModal, handleDone, isLoading } = props;

  const handleToggle = () => toggleModal(prev => !prev);

  return (
    <Modal
      isOpen={isOpen}
      modalTransition={{ timeout: 200 }}
      toggle={handleToggle}
    >
      <ModalBody toggle={handleToggle}>
        Are you sure to delete picture?
      </ModalBody>
      <ModalFooter>
        <Button
          color={isLoading ? "secondary" : "primary"}
          onClick={handleDone}
          disabled={isLoading}
        >
          Yes
        </Button>
        <Button color="secondary" onClick={handleToggle}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};
