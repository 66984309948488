import React from "react";
import { Row, Col } from "reactstrap";

import { Button } from "./../../";
import styles from "./styles.module.scss";

export const CarGeneralFilters = ({
  searchParams: { seeResultOnMap, cheapest, expensive, bestValues },
  searchResult,
  handleMapToggle,
  setMoreOption,
  supplier_progress_loading,
}) => {
  const { carList, suppliers_progress } = searchResult;

  return (
    <div className={styles.carGeneralFilters_container}>
      <Row className="carGeneralFilters_option">
        <Col md="8" className={styles.carFilterBox}>
          <div className={styles.carFilterButtons_container}>
            <span
              onClick={() => {
                if (!supplier_progress_loading) {
                  setMoreOption("bestValues", true);
                  setMoreOption("cheapest", false);
                  setMoreOption("expensive", false);
                }
              }}
              className={
                bestValues && !cheapest && !expensive
                  ? styles.active_button
                  : ""
              }
            >
              Best value first
            </span>
            <span
              onClick={() => {
                if (!supplier_progress_loading) {
                  setMoreOption("expensive", true);
                  setMoreOption("bestValues", false);
                  setMoreOption("cheapest", false);
                }
              }}
              className={
                expensive && !bestValues && !cheapest
                  ? styles.active_button
                  : ""
              }
            >
              Most expensive first
            </span>
          </div>
        </Col>
        <Col md="4" className={styles.searchPanelBtn_column}>
          <Button
            color="primary"
            onClick={handleMapToggle}
            disabled={!carList.length}
            disabled={suppliers_progress !== 100}
          >
            {seeResultOnMap ? "See Result as Grid" : "See Results on Map"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
