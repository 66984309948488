import React from "react";
import Select from "react-select";

import styles from "./styles.module.scss";

const customStyles = {
  option: (base, state) => ({
    ...base,
    borderBottom: "1px dotted #eee",
    color: state.isSelected ? "#fff" : "#23282c",
    backgroundColor: state.isSelected && "#035372",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#035372",
      border: "none",
      color: "#fff",
    },
  }),
  container: (base, state) => {
    return {
      ...base,
      width: "100%",
      height: "56px;",
    };
  },
  control: (base, state) => ({
    ...base,
    height: "56px;",
  }),
  input: (base, state) => ({
    ...base,
    border: "none",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
};

const DropDownInput = (props) => {
  const { label, leftDropdown, rightDropdown, isError } = props;
  return (
    <React.Fragment>
      <div className={`${styles.inputText_container}`} {...props}>
        {label && <label>{label}</label>}
        <div
          style={isError ? { border: "1px solid red" } : {}}
          className={`${styles.dropDownInput_container} ${leftDropdown &&
            styles.leftDropdown} ${rightDropdown && styles.rightDropdown}`}
        >
          <Select styles={customStyles} {...props} />
        </div>
      </div>
    </React.Fragment>
  );
};

export { DropDownInput };
