import React from "react";
import { Row, Col } from "reactstrap";

import blueIcon from "../../../assets/blueok.svg";
import co2Icon from "../../../assets/co2.svg";
import earthIcon from "../../../assets/earthfriends.svg";
import styles from "./styles.module.scss";

export const HeaderBottom = () => {
  return (
    <div className={styles.header_bottom}>
      <Row className="no-margin">
        <Col md="2" className={styles.leftBottomLogo} />
        <Col md="8" className={styles.HBList}>
          <ul>
            <li>
              <img src={blueIcon} alt="tick icon" className={styles.tickIcon}/>
              Top value guaranteed
            </li>
            <li>
              <img src={blueIcon} alt="tick icon" className={styles.tickIcon}/>
              No hidden fees
            </li>
            <li>
              <img src={blueIcon} alt="tick icon" className={styles.tickIcon}/>
              Rentalmoose plants a tree for you!
            </li>
          </ul>
        </Col>
        <Col md="2" className={styles.rightBottomLogo}>
          <ul className={styles.rightLogo}>
            <li>
              <img className="hidden-sm" src={co2Icon} alt="tick icon" />
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};
