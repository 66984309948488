import {
  UPLOAD_VEHICLE_IMAGES,
  UPLOAD_VEHICLE_IMAGES_SUCCESS,
  UPLOAD_VEHICLE_IMAGES_FAILED,
  UPLOAD_VEHICLE_IMAGES_CLEAR,
  DELETE_VEHICLE_IMAGE,
  DELETE_VEHICLE_IMAGE_FAILED,
  DELETE_VEHICLE_IMAGE_SUCCESS
} from "./../actions/vehicleImages.action";

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  isError: false,
  message: ""
};

export const VehicleImagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_VEHICLE_IMAGES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        success: false
      };
    case UPLOAD_VEHICLE_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: "",
        success: true
      };
    case UPLOAD_VEHICLE_IMAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
        success: false
      };
    case UPLOAD_VEHICLE_IMAGES_CLEAR:
      return {
        ...state,
        isLoading: false,
        success: false,
        isError: false,
        message: ""
      };
    case DELETE_VEHICLE_IMAGE:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        success: false
      };
    case DELETE_VEHICLE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: "",
        success: true
      };
    case DELETE_VEHICLE_IMAGE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
        success: false
      };
    default:
      return state;
  }
};
