import React, { useState, useEffect } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { useSelector } from "react-redux";

import { CarCard, CardsContainer, Button } from "./../";
import dotIcon from "./../../assets/marker_dot.png";
import { Map } from "./Map";
import styles from "./styles.module.scss";

export const MapResult = ({
  searchResult: { carList, selectedCar, activeMarker },
  handleActiveMarker,
  loadmore_loading,
  handleBookCar,
  handleLoadMore,
  show_loadmore_button,
  searchParams,
  selectCar,
  seeResultOnMap,
}) => {
  const WIDTH = window.innerWidth;
  const { selectedCurrency } = useSelector((state) => state.config);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: WIDTH <= 767 ? 400 : 800,
    latitude: 59.437,
    longitude: 24.7536,
    zoom: 12,
    transitionDuration: 3000,
  });
  const [showSelectedCar, setShowSelectedCar] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const _onViewportChange = (viewport) => setViewport(viewport);

  const _goToViewport = ({ longitude, latitude }) => {
    _onViewportChange({
      longitude,
      latitude,
      zoom: viewport.zoom === 12 ? 11.9 : 12,
      transitionDuration: 2000,
    });
  };

  const handleSelectCar = (car, index) => {
    setShowSelectedCar(true);
    handleActiveMarker(index);
    selectCar(car);
    const coordinates = {
      latitude: Number(car.coordinates.lat),
      longitude: Number(car.coordinates.lng),
    };
    activeMarker !== index && _goToViewport(coordinates);
  };

  useEffect(() => {
    const car = carList[0];
    if (car) {
      handleActiveMarker(0);
      _goToViewport({
        latitude: Number(car.coordinates.lat),
        longitude: Number(car.coordinates.lng),
      });
    }
  }, []);

  useEffect(() => {
    WIDTH <= 767 ? setShowInfoWindow(true) : setShowInfoWindow(false);
  }, []);

  const renderCarMarkers = (car, index) => {
    return (
      <Marker
        icon={dotIcon}
        onMouseOver={() => setShowInfoWindow(true)}
        key={`marker-${index}`}
        draggable
        position={{
          lat: Number(car.coordinates.lat),
          lng: Number(car.coordinates.lng),
        }}
      >
        <InfoWindow>
          <div>
            <div
              className={`${styles.marker_container} ${activeMarker === index &&
                styles.active_marker}`}
              onClick={() => handleSelectCar(car, index)}
            >
              <div className={styles.marker_blackDot} />
              <span>{`${selectedCurrency.sign}${car.total_amount}`}</span>
            </div>
          </div>
        </InfoWindow>
      </Marker>
    );
  };

  return (
    <React.Fragment>
      <Map
        center={{ lat: viewport.latitude, lng: viewport.longitude }}
        zoom={viewport.zoom}
        carList={carList}
        renderCarMarkers={renderCarMarkers}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDzpD53K6pMqTOQZylscEoqDjucnt3_gF0"
        loadingElement={
          <div style={WIDTH < 767 ? { height: 380 } : { height: 600 }} >
            <p>Map is loading ...</p>
          </div>
        }
        containerElement={<div style={WIDTH < 767 ? { height: 380 } : { height: 600 }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      
        {showSelectedCar && (
          <div className={styles.mapCard}>
            <CarCard
              seeResultOnMap={seeResultOnMap}
              showCloseIcon
              onCloseClick={() => setShowSelectedCar(false)}
              item={selectedCar}
              searchParams={searchParams}
              handleBookCar={handleBookCar}
              handleSelectCar={selectCar}
              button
            />
          </div>
        )}
     
      {/* <MapGL
        {...viewport}
        mapboxApiAccessToken="pk.eyJ1IjoiYWhtZWRyYXphMTEiLCJhIjoiY2syZWx2eDhkMGEzZDNkcDZ0ZWU3NGh3aSJ9.DhDGY96CJLTT1jFM7z9Cug"
        onViewportChange={(viewport) => _onViewportChange(viewport)}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        style={{ marginBottom: 80 }}
        attributionControl={false}
      >
        {carList.map(renderCarMarkers)}
        <div className={styles.carCard_container}>
          {showSelectedCar && (
            <CarCard
              item={selectedCar}
              searchParams={searchParams}
              handleBookCar={handleBookCar}
              handleSelectCar={selectCar}
            />
          )}
        </div>
      </MapGL> 
       <CardsContainer
        carList={carList}
        handleLoadMore={handleLoadMore}
        selectCar={selectCar}
        searchParams={searchParams}
        handleBookCar={handleBookCar}
        loadmore_loading={loadmore_loading}
        show_loadmore_button={show_loadmore_button}
      /> */}
    </React.Fragment>
  );
};
