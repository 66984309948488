import React,{useEffect} from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";

import { QASlider } from "./helpSupportUtils";
import { ContactCard } from "./../Contact/contactUtils";
import ContactChatIcon from "./../../assets/chat-icon.svg";
import writeIcon from "./../../assets/write-icon.svg";
import SearchLogoMobile from "./../../assets/search_icon_mobile.svg";
import SearchLogo from "./../../assets/search_icon.svg";
import MicIcon from "./../../assets/mic-icon.svg";
import styles from "./styles.module.scss";

const HelpSupport = () => {
  const DEVICE_WIDTH = window.innerWidth;
  const PrivacyContent = useSelector((state) => state.config.privacyPageContent);

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])

  return (
    <React.Fragment>
      <section className={styles.qa_search_box_container}>
        <div className="car_search">
          <p className={styles.input_title}>Please type your question</p>
          <InputGroup>
            <img
              className="MicIcon"
              onClick={() => alert("Hello Mic")}
              src={MicIcon}
              alt="mic search"
            />
            <Input
              placeholder="Where do you begin your journey? Type location"
            />
            <InputGroupAddon
              addonType="append"
            >
              <InputGroupText color="primary">
                {DEVICE_WIDTH >= 767 ? (
                  <span>
                    <img src={SearchLogo} alt="search logo" />
                    Search
                  </span>
                ) : (
                  <img src={SearchLogoMobile} alt="search logo" />
                )}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </section>
      <QASlider />
      <section className={styles.helpsupport}>
        <Container>
          <Row>
            <Col md="12">
              <h3 className={styles.title}>Fuel, Mileage & Travel Plans</h3>
            </Col>
          </Row>
          <Row>
            <Col md="12" dangerouslySetInnerHTML={{ __html: PrivacyContent[0].content }}></Col>
          </Row>
          {/* <Row>
            <Col md="6">
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                Panels are decorative wall / ceiling panels with an ambient
                lighting. Wavy structure, wide coating choice, embedded LED
                technologies. Natural wood as base material compressed into
                waves to give character to your wall design.
              </p>
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                We can’t help you make a booking or request a quote without
                certain information. When you do either of those things, we ask
                you for the information we need so we can give you what you
                want. This is pretty standard stuff and may include your name,
                age, date and place of birth and contact details (email, address
                and phone number). It can also include information about your
                passport, identity card and driving licence – and your loyalty
                programme number and payment information.
              </p>
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                Panels are decorative wall / ceiling panels with an ambient
                lighting. Wavy structure, wide coating choice, embedded LED
                technologies. Natural wood as base material compressed into
                waves to give character to your wall design.
              </p>
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                We can’t help you make a booking or request a quote without
                certain information. When you do either of those things, we ask
                you for the information we need so we can give you what you
                want. This is pretty standard stuff and may include your name,
                age, date and place of birth and contact details (email, address
                and phone number). It can also include information about your
                passport, identity card and driving licence – and your loyalty
                programme number and payment information.
              </p>
            </Col>
            <Col md="6">
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                We can’t help you make a booking or request a quote without
                certain information. When you do either of those things, we ask
                you for the information we need so we can give you what you
                want. This is pretty standard stuff and may include your name,
                age, date and place of birth and contact details (email, address
                and phone number). It can also include information about your
                passport, identity card and driving licence – and your loyalty
                programme number and payment information.
              </p>
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                Panels are decorative wall / ceiling panels with an ambient
                lighting. Wavy structure, wide coating choice, embedded LED
                technologies. Natural wood as base material compressed into
                waves to give character to your wall design.
              </p>
              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                We can’t help you make a booking or request a quote without
                certain information. When you do either of those things, we ask
                you for the information we need so we can give you what you
                want. This is pretty standard stuff and may include your name,
                age, date and place of birth and contact details (email, address
                and phone number). It can also include information about your
                passport, identity card and driving licence – and your loyalty
                programme number and payment information.
              </p>

              <span className={styles.paraTitle}>
                <i className="zmdi zmdi-plus-circle-o"></i>
                This Privacy Notice explains how we collect
              </span>
              <p>
                Panels are decorative wall / ceiling panels with an ambient
                lighting. Wavy structure, wide coating choice, embedded LED
                technologies. Natural wood as base material compressed into
                waves to give character to your wall design.
              </p>
            </Col>
          </Row> */}
        </Container>
      </section>
      <section className={`${styles.contactUsSection} ${styles.contactUsHelp}`}>
        <Container>
          <Row>
            <Col className="col-12">
              <div className={styles.contactIntroSection}>
                <h2 className={styles.title}>Still need help?</h2>
                <p>Get in touch if your question has not been answered</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ContactCard
                title="Chat"
                desc="The fastest way to get in touch with an expert"
                logo={ContactChatIcon}
                isSupportPage={true}
              />
            </Col>
            <Col md="6">
              <ContactCard
                title="Write"
                desc="Make a Full Protection Claim or give us feedback"
                logo={writeIcon}
                isSupportPage={true}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export { HelpSupport };

export default HelpSupport;
