import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Stepper } from "./stepper";
import { setDriverDetials, confirmCarBooking } from "./stepper.action";
import { toggleExtras } from "./../SearchPanel";

import {showModal} from './../../store/actions/modal.action';
export { BookingDetailReducer } from "./stepper.reducer";
export * from "./stepper.action";
export * from "./stepper.epic";

const mapStateToProps = ({
  config,
  searchResult,
  bookingDetail,
  searchParams,
  auth
}) => ({
  config,
  searchResult,
  bookingDetail,
  searchParams,
  auth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { toggleExtras, setDriverDetials, confirmCarBooking,showModal },
    dispatch
  );

export let StepperComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Stepper);
