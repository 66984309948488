import React from "react";

import styles from "./styles.module.scss";

const ContactCard = ({ title, desc, logo, isSupportPage = false }) => {
  return (
    <div
      className={`${styles.twoChatWriteBox} ${isSupportPage &&
        styles.twoChatWriteBoxHelp}  `}
    >
      <img src={logo} alt="Chat Icon" />
      <div className={styles.ChatWriteRightSideText}>
        <h3 className={styles.title}>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export { ContactCard };
