/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { InputText } from '../';
import favIcon from "./../../assets/fav-icon.svg";
import defaultImage from "./../../assets/defaultCarImage.png";
import {
  setWorkingStepId,
  setTermsAndCondition,
  setSupplierTermsAndCondition,
  setCouponState,
  resetCouponState,
} from "./../Stepper";

import {
  BASE,
  REEDEM_COUPON_API,
} from '../../config/api';

import styles from "./styles.module.scss";
import { CheckBox } from "../Checkbox";
import { setBooknowFromOptionsClicked } from "../SearchPanel";
import moment from "moment";
const showErrorBorder = error => {
  if (error) return { border: 'solid 1px red' };
  return {};
};
const CCHeading = (props) => {
  let { carName, showCloseIcon, onCloseClick } = props;
  console.log(carName);
  if(typeof carName != 'undefined'){
    if(carName.length > 50){
      carName = "Dealer's Choice";
    }
  }
  
  return (
    <div className={`${styles.CC_Heading}`}>
      <h3 className={styles.title}>
        {carName} <span>or Similar</span>
      </h3>
      {showCloseIcon && (
        <i
          style={{ cursor: "pointer" }}
          onClick={onCloseClick}
          className="zmdi zmdi-close zmdi-hc-3x"
        ></i>
      )}
    </div>
  );
};
const CCTime = ({
  isOptionStep,
  item,
  driverDetails,
  searchParams
  }) => {
   
    let checkinDate;
    let checkoutDate;
    let checkinHours;
    let checkoutHours;
    if (searchParams.startDate && searchParams.endDate) {
      checkinDate = moment(searchParams.startDate).format("DD MMMM YYYY");
      checkoutDate = moment(searchParams.endDate).format("DD MMMM YYYY");
      checkinHours = moment(searchParams.startDate).format("hh:mm");
      checkoutHours = moment(searchParams.endDate).format("hh:mm");
    } else {
      checkinDate = "";
      checkoutDate = "";
      checkinHours = "";
      checkoutHours = "";
    }
    
    return (
      <Row className={'mt-4 ' + styles.CC_mileageInfo}>
        <Col style={{ marginTop: 5 }} className={styles.mldetailBox}>
            <div className={styles.card_info_item}>
            <i
            style={{
              marginRight: 10,
              marginTop: 5,
              color: "#c1c1c1",
            }}
            className="zmdi zmdi-time"
          ></i>
              <p className={`${styles.title} ${styles.customStyle}`}>
                Pickup:{" "}
                <br /><span className={styles.descmob}>{checkinDate}<br />{checkinHours}</span>
              </p>
            </div>
          </Col>
          <Col style={{ marginTop: 5 }} className={styles.mldetailBox}>
            <div className={styles.card_info_item}>
            <i
              style={{
                marginRight: 10,
                marginTop: 5,
                color: "#c1c1c1",
              }}
              className="zmdi zmdi-time"
            ></i>
              <p className={styles.title}>
                Drop-off:{" "}
                <br /><span className={styles.descmob}>{checkoutDate}<br />{checkoutHours}</span>
              </p>
            </div>
          </Col>
      </Row>
  
  );
};
const CCShortInfo = ({ carImage, specs, supplier }) => {
  return (
    <Row className={styles.CC_shortInfo}>
      <Col md="6" className={`${styles.sInfoImg} pr-0 col-6`}>
        <img
          src={carImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
          alt="Car"
          className="img-fluid"
        />
      </Col>
      <Col md="6" className="col-6">
        <ul className={styles.sInfoList}>
          <li>
            <span className={styles.gearboxIcon}></span>
            {specs.transmission}
          </li>
          <li>
            <span className={styles.bagIcon}></span>
            {specs.baggage} Small Bags
          </li>
          {specs.air_conditioned && (
            <li>
              <span className={styles.airIcon}></span>Air Con
            </li>
          )}
          {!specs.fuel?.startsWith("Unspecified") && (
            <li>
              <span className={styles.fuelIcon}></span>
              {specs.fuel}
            </li>
          )}
          <li>
            <div className={styles.CC_Budget}>
              <img src={supplier && supplier.logo} alt="supplier Logo" />
            </div>
          </li>
        </ul>
      </Col>
    </Row>
  );
};

const CCPriceInfo = ({ amount, total_days, supplier, per_day_amount }) => {
  return (
    <Row className={styles.CC_priceInfo}>
      <Col md="12" className="col-12">
        
        <div className={styles.CC_Price}>
          <strong>{amount}</strong>
        </div>
        <span className={styles.priceForDay}>Price for {total_days} days ( {per_day_amount} per day ) </span>
      </Col>
      <Col md="6" className="col-6">
        {/* <div className={styles.CC_Budget}>
          <img src={supplier && supplier.logo} alt="supplier Logo" />
        </div> */}
      </Col>
    </Row>
  );
};
const CCShortInfoMobile = ({ 
  item,
  supplier
}) => {

  const { selectedCurrency } = useSelector((state) => state.config);
  const { selectedCar } = useSelector((state) => state.searchResult);
  const { discountAmount,discountPrice } = useSelector(state => state.bookingDetail);
  let totalAmountAfterAddingExtras = item && item?.total_amount;
  if (discountPrice) {
    totalAmountAfterAddingExtras = discountAmount - discountPrice;
  }
  let extrasTotal = 0;
  let __ =
    selectedCar?.extras &&
    selectedCar.extras
      .filter((v) => v.selected)
      .filter((item) => item?.type != "external")
      .forEach((i) => {
        totalAmountAfterAddingExtras += i.count * Number(i.price_per_day);
        extrasTotal += i.count * Number(i.price_per_day);
      });
 

  return (
    <Row className={styles.CC_shortInfo}>
      <Col md="5"  className={`${styles.sInfoImg} pr-0 col-5`}>
        <img
          src={item?.image}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
          alt="Car"
          className="img-fluid"
        />
      </Col>
      <Col md="7" className="col-7">
        {/* <span className={styles.priceForDay}>Price for {total_days} days</span>
        <div className={styles.CC_Price}>
          <strong>{amount}</strong>
        </div>
        <p className={styles.priceForDay}>{per_day_amount} per day</p> */}
        <div className={styles.paylater_amount}>
                  {/* <p>{`Pay now ${selectedCurrency.sign}${item &&
                    item?.pay_now} and ${selectedCurrency.sign}${item &&
                    item?.pay_later} later`}</p> */}
                  <Row className={styles.CC_priceInfo}>
                  <Col md="12" className={styles.CC_priceInfo_re}>
                    <div className={styles.CC_Price}>
                      <strong>
                        {selectedCurrency.sign}
                        {totalAmountAfterAddingExtras?.toFixed(2)}
                      </strong>
                    </div>
                    <p className={styles.m_price}>
                      {selectedCurrency.sign}
                      {item && item?.per_day_amount} per day
                    </p>
                    <p className={styles.m_price}>{`Car Rental ${selectedCurrency.sign}${item?.rental_price}`}</p>
                    {extrasTotal > 0 && (
                      <p className={styles.m_price}>{`Extras ${selectedCurrency.sign}${extrasTotal}`}</p>
                    )}
                    <p className={styles.m_price}>{`Tree planting donation`}</p>
                    <p className={styles.m_price}>{`(non refundable ) ${selectedCurrency.sign}0.99`}</p>
                  </Col>
                </Row>
                 
                </div>
        <div className={styles.CC_Budget} style={{float :"right"}}>
          <img src={supplier && supplier.logo} alt="supplier Logo" />
        </div>
      </Col>
    </Row>
  );
};
const CCMileageInfo = ({
  isDetailed,
  deposit,
  max_liab,
  mileage,
  cards,
  startDate,
  endDate,
  fuel_policy,
}) => {
  return (
    <Row className={'mt-4 ' + styles.CC_mileageInfo}>
      <Col  className={styles.mldetailBox}>
        <div className={styles.card_info_item}>
          <span className={styles.mileageIcon}></span>
          <p className={`${styles.title} ${styles.customStyle}`}>
            <span>{mileage}</span> Mileage
          </p>
        </div>
      </Col>
      <Col style={{ marginTop: 5 }} className={styles.mldetailBox}>
        <div className={styles.card_info_item}>
          <span className={styles.fullIcon}></span>{" "}
          <p className={`${styles.title} ${styles.customStyle}`}>
            {fuel_policy}
          </p>
        </div>
      </Col>

      {/* {isDetailed && (
        <Col md="12">
          <div className={styles.card_info_item}>
            <span className={`${styles.icon} ${styles.bank_card}`}></span>
            <p className={styles.title}>{cards}</p>
          </div>
        </Col>
      )} */}
      {isDetailed && (
        <React.Fragment>
          <Col sm="12" style={{ marginTop: 5 }} className={styles.mldetailBox}>
            <div className={styles.card_info_item}>
              <span className={`${styles.icon} ${styles.deposit}`}></span>
              <p className={`${styles.title} ${styles.customStyle}`}>
                Deposit:{" "}
                <span className={styles.desc}>approx. ~ {deposit}</span>
              </p>
            </div>
          </Col>
          <Col sm="12" style={{ marginTop: 5 }} className={styles.mldetailBox}>
            <div className={styles.card_info_item}>
              <span className={`${styles.icon} ${styles.max_liab}`}></span>
              <p className={styles.title}>
                Max liability:{" "}
                <span className={styles.desc}>approx. ~ {max_liab}</span>
              </p>
            </div>
          </Col>
        </React.Fragment>
      )}
      {/* {startDate && endDate && (
        <React.Fragment>
          <Col style={{ marginTop: 5 }} className={styles.mldetailBox}>
            <div className={styles.card_info_item}>
              <i
                style={{ marginRight: 10, marginTop: 5, color: "#c1c1c1" }}
                className="zmdi zmdi-time"
              ></i>
              <p className={styles.title}>
                Checkin Date: <span className={styles.desc}>{startDate.split("T")[0]}</span>
              </p>
            </div>
          </Col>
          <Col style={{ marginTop: 5 }} md="12" className={styles.mldetailBox}>
            <div className={styles.card_info_item}>
              <i
                style={{ marginRight: 10, marginTop: 5, color: "#c1c1c1" }}
                className="zmdi zmdi-time"
              ></i>
              <p className={styles.title}>
                Checkout Date: <span className={styles.desc}>{endDate.split("T")[0]}</span>
              </p>
            </div>
          </Col>
        </React.Fragment>
      )} */}
    </Row>
  );
};

const CCType = () => {
  return <Row></Row>;
};

const CCInsuranceInfo = ({
  handleBookNow,
  isDetailed = false,
  isOptionStep,
  itemId,
  amount,
  currency,
  startDate,
  endDate,
  isSuppliersProgressing,
}) => {
  const { push, replace } = useHistory();
  const Dispatch = useDispatch();
  const { search } = useLocation();
  const {
    isTermsAndConditonChecked,
    isSupplierTermsAndConditonChecked,
  } = useSelector((state) => state.bookingDetail);
 
  const handleSubmit = () => {
    window.gtag("event", "Book now have been pressed", {
      event_category: "Booking",
      event_label: "Book now have been pressed"
    })
    let params = new URLSearchParams(search);
    const checkin_station_code = params.get("checkin_station_code");
    const checkin_time = params.get("checkin_time");
    const checkout_station_code = params.get("checkout_station_code");
    const checkout_time = params.get("checkout_time");
    const contract_id = params.get("contract_id");
    const contract_margin = params.get("contract_margin");
    const currency = params.get("currency");
    const deposit = params.get("deposit");
    const duration_margin = params.get("duration_margin");
    const max_liab = params.get("max_liab");
    const rule_id = params.get("rule_id");
    const rule_margin = params.get("rule_margin");
    const vehicle_id = params.get("vehicle_id");

    push({
      pathname: `/bookingDetail/${itemId}/driver`,
      search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&vehicle_id=${vehicle_id}&currency=${currency}`,
      state: { from: "options" },
    });
    Dispatch(setWorkingStepId(2));
    Dispatch(setBooknowFromOptionsClicked(true));
  };
  const resetsetCoupon = (Coupon) => {
    // eslint-disable-next-line no-unused-expressions
    Coupon != Coupon;
    Dispatch(resetCouponState({}));
  };

  const handleresetwithclick = () => {
    resetsetCoupon(Coupon);
    handleBookNow();
  }

  const updateCoupon = (couponValue) => {
    // const { selectedCar } = useSelector(state => state.searchResult);
    let total_amount = selectedCar.total_amount;
    let pay_calculate = amount;
    axios.post(`${BASE}${REEDEM_COUPON_API}`, {
      coupon_code: couponValue,
    })
      .then(function (response) {
        if (response.data.status) {
          let dispatch_data = { 'response': response, 'amount': total_amount , 'pay_calculate':pay_calculate, 'currency':currency };
          Dispatch(setCouponState(dispatch_data));
        } else {
          setCouponError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [Coupon, setCoupon] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const { couponCode , showCouponAmount, payNowdiscount,discountPrice} = useSelector(state => state.bookingDetail);
  const { selectedCar } = useSelector(state => state.searchResult);
  if(payNowdiscount){
    amount = payNowdiscount;
  }
  let couponClass = 'col-md-12 mt-2';
  let couponClass2 = 'col-md-5 pr-0 mt-2';
  return (
    <React.Fragment>
      <Row className={styles.free_Insurance_info}>
        <Col md="12">
          <Button
            color="primary"
            className="w-100 result_bookNowBtn"
            onClick={isOptionStep ? handleSubmit : handleBookNow}
            disabled={
              isOptionStep &&
              !(isTermsAndConditonChecked && isSupplierTermsAndConditonChecked)
            }
          >
            {`Book and Pay Now ${currency}${amount}`}
          </Button>
        </Col>
      </Row>
      {isOptionStep && (
        <Row> 
          {
            couponCode ?
              <>
                <Col className={Coupon && !couponCode ? couponClass2 : couponClass}>
                  <span className={styles.Carcategoty} >
                    Coupon: {couponCode} ({showCouponAmount}) has been applied. Discount amount {currency}{discountPrice} <span onClick={() => resetsetCoupon(Coupon)} className={styles.resetCoupon}>X</span>
                  </span>
                </Col>
              </> :
              <>
                <Col className={Coupon ? couponClass2 : couponClass}>
                  <span className={styles.Carcategoty} onClick={() => setCoupon(!Coupon)}>
                    Use Coupon
                  </span>
                </Col>
              </>
          }

          {Coupon && !couponCode ?
            <>
              <Col md="4" className={`pr-0 ${styles.couponText}`}>
                <InputText
                  name="coupon"
                  placeholder="Coupon"
                  type="text"
                  value={couponValue}
                  onChange={e => setCouponValue(e.target.value)}

                  style={showErrorBorder(
                    couponError
                  )}
                />
                {couponError}
              </Col>
              <Col md="3">
                <Button
                  color="primary"
                  className={`${styles.couponButton}`}

                  onClick={() => updateCoupon(couponValue)}
                >
                  Apply
                </Button>
              </Col>
            </> : null
          }
      </Row>
      )}
      {isOptionStep &&  <Row className={`${styles.CC_bottomText} mt-2`}>
        <Col md="12">
          <div class={styles.CC_cancelText}>
            <span className={styles.checkIcon}></span> Free
              cancellation up to 48h till pick-up time
          </div>
        </Col>
      </Row>}
    </React.Fragment>
  );
};

const CCBottomTextMobile = ({ isDetailed, paynow,item }) => {
  const { selectedCurrency } = useSelector((state) => state.config);
  const { selectedCar } = useSelector((state) => state.searchResult);
  const { discountAmount, discountPrice } = useSelector(state => state.bookingDetail);
  let totalAmountAfterAddingExtras = item && item?.total_amount;
  if (discountPrice) {
    totalAmountAfterAddingExtras = discountAmount - discountPrice;
  }
  let extrasTotal = 0;
  let __ =
    selectedCar?.extras &&
    selectedCar.extras
      .filter((v) => v.selected)
      .filter((item) => item?.type != "external")
      .forEach((i) => {
        totalAmountAfterAddingExtras += i.count * Number(i.price_per_day);
        extrasTotal += i.count * Number(i.price_per_day);
      });
  let totalPayLater = extrasTotal + item && item?.pay_later;
  return (
    <React.Fragment>

    <div className={`${styles.paylater_amount} mt-2`}>
      <p>
        {`You can pay the rest ${
             selectedCurrency.sign
          }${totalPayLater?.toFixed(2)} at the rental desk when picking up the car`}
      </p>
    </div>
   
    {/* <Row className={styles.CC_bottomText}>
      {isDetailed ? (
        <Col md="12">
          <div>
            <span className={styles.checkIcon}></span> Free Cancellation
          </div>
          <div>
            <span className={styles.checkIcon}></span> Pay Later
          </div>
        </Col>
      ) : (
        <Col md="12">
          <div>
            <span className={styles.checkIcon}></span> Pay now only {paynow} to
            book
          </div>
        </Col>
      )}
    </Row> */}
    </React.Fragment>

  );
};
const CCBottomText = ({ isDetailed, paynow }) => {
  return (
    <Row className={styles.CC_bottomText}>
      {isDetailed ? (
        <Col md="12">
          <div>
            <span className={styles.checkIcon}></span> Free Cancellation
          </div>
          <div>
            <span className={styles.checkIcon}></span> Pay Later
          </div>
        </Col>
      ) : (
        <Col md="12">
          <div>
            <span className={styles.checkIcon}></span> Pay now only {paynow} to
            book
          </div>
        </Col>
      )}
    </Row>
  );
};
/*===== CardCardHorizontal =====*/

const CardCardHorizontalBoxOne = ({ item }) => {
  return (
    <Col md="12" className={`${styles.CardCardHorizontalBoxOne}`}>
      <Row className={`${styles.CC_shortcarInfo_review} m-0`}>
        <Col md="12" className={`${styles.sInfoImg} pr-0`}>
          <img
            src={item && item.image ? item.image : defaultImage}
            alt="Car"
            className="img-fluid"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultImage;
            }}
          />
        </Col>
      </Row>
      <Row className={styles.DetailCarImgRating}>
        {/* <Col md="12">
          <div className={styles.CC_Budget}>
            <img src={item && item?.supplier_logo} alt="Car Logo" />
          </div>
        </Col> */}
        {/* <Col md="12">
          <div className={styles.carRating}>
            <span className={styles.carRatingNumber}>{rating}</span>
            <span className={styles.carRatingcomment}>Very Good</span>
            <span className={styles.carReviewsNumber}>
              ({review_count - 1}+ reviews)
            </span>
            {rental_terms !== "NO" && (
              <span className={styles.rentalItem}>Rental terms</span>
            )}
          </div>
        </Col> */}
      </Row>
    </Col>
  );
};

const CardCardHorizontalBoxTwo = ({ item, searchParams, selectedCurrency }) => {
  let title = ''; 
  if (Object.keys(item).length != 0) {
    if(item && item?.title.length > 50){
      title = 'Dealers Choice';
    }else{
      title = item?.title;
    }
  }
  return (
    <Col md="12" className={styles.CardCardHorizontalBoxTwo}>
      <CCHeading carName={title} />
      {/* <Row className={styles.CC_shortInfo}>
        <div className={styles.sInfoList}>
          <Col md="6">
            <span className={styles.seatIcon}></span> {item && item?.seats} Seats
          </Col>
          <Col md="6">
            <span className={styles.bagIcon}></span>
            {item && item?.baggage} Small Bags
          </Col>
          {item && item?.air_conditioned && (
            <Col md="6">
              <span className={styles.airIcon}></span>Air Con
            </Col>
          )}
          <Col md="6">
            <span className={styles.doorIcon}></span> {item && item?.doors} Doors
          </Col>
          <Col md="6">
            <span className={styles.bigIcon}></span>
            {item && item?.baggage} Big Bags
          </Col>
          <Col md="6">
            <span className={styles.gearboxIcon}></span>
            {item && item?.transmission}
          </Col>
        </div>
      </Row> */}
      <Row>
        <Col md="12">
          <CCMileageInfo
            isDetailed
            mileage={item && item?.mileage}
            startDate={searchParams && searchParams.startDate}
            endDate={searchParams && searchParams.endDate}
            deposit={`${selectedCurrency.sign}${item && item?.deposit}`}
            max_liab={`${selectedCurrency.sign}${item && item?.max_liab}`}
            fuel_policy={item && item?.fuel_policy}
          />
        </Col>
        <Col md="12">
          <CCType />
        </Col>
      </Row>
    </Col>
  );
};
const CardCardHorizontalBoxThree = ({
  isOptionStep,
  item,
  driverDetails,
  searchParams,
}) => {
  let couponClass = 'col-md-12 mt-2';
  let couponClass2 = 'col-md-5 pr-0 mt-2';

  const { push } = useHistory();
  const { search } = useLocation();
  const Dispatch = useDispatch();
  const { selectedCurrency } = useSelector((state) => state.config);
  const { selectedCar } = useSelector((state) => state.searchResult);
  const [RentalmooseTermsCheckBox, setRentalmooseTermsCheck] = useState(false);
  const [SupplierTermsCheckBox, setSupplierTermsCheck] = useState(false);
  const [RentalmooseTermsModal, setRentalmooseTermsModal] = useState(false);
  const [SupplierTermsModal, setSupplierTermsModal] = useState(false);

  const [Coupon, setCoupon] = useState(false);

  const [couponError, setCouponError] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const { discountAmount,payNowdiscount,discountPrice } = useSelector(state => state.bookingDetail);
  const { couponCode, showCouponAmount } = useSelector(state => state.bookingDetail);


  const { terms } = useSelector((state) => state.config);
  const {
    isTermsAndConditonChecked,
    isSupplierTermsAndConditonChecked,
  } = useSelector((state) => state.bookingDetail);

  let checkinDate;
  let checkoutDate;
  let pay_now_coupon_value = item?.pay_now;
  if(payNowdiscount){
    pay_now_coupon_value = payNowdiscount;
  }
  if (searchParams.startDate && searchParams.endDate) {
    checkinDate = moment(searchParams.startDate).format("DD MMMM YYYY hh:mm A");
    checkoutDate = moment(searchParams.endDate).format("DD MMMM YYYY hh:mm A");
  } else {
    checkinDate = "";
    checkoutDate = "";
  }

  let totalAmountAfterAddingExtras = item && item?.total_amount;
  if (discountPrice) {
    totalAmountAfterAddingExtras = discountAmount - discountPrice;
  }
  let extrasTotal = 0;
  let __ =
    selectedCar?.extras &&
    selectedCar.extras
      .filter((v) => v.selected)
      .filter((item) => item?.type != "external")
      .forEach((i) => {
        totalAmountAfterAddingExtras += i.count * Number(i.price_per_day);
        extrasTotal += i.count * Number(i.price_per_day);
      });
  let totalPayLater = extrasTotal + item && item?.pay_later;

  const handleSubmit = () => {
    window.gtag("event", "Book now have been pressed", {
      event_category: "Booking",
      event_label: "Book now have been pressed"
    })
    let params = new URLSearchParams(search);
    const checkin_station_code = params.get("checkin_station_code");
    const checkin_time = params.get("checkin_time");
    const checkout_station_code = params.get("checkout_station_code");
    const checkout_time = params.get("checkout_time");
    const contract_id = params.get("contract_id");
    const contract_margin = params.get("contract_margin");
    const currency = params.get("currency");
    const deposit = params.get("deposit");
    const duration_margin = params.get("duration_margin");
    const max_liab = params.get("max_liab");
    const rule_id = params.get("rule_id");
    const rule_margin = params.get("rule_margin");
    const vehicle_id = params.get("vehicle_id");

    push({
      pathname: `/bookingDetail/${item && item?.id}/driver`,
      search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&vehicle_id=${vehicle_id}&currency=${currency}`,
      state: { from: "options" },
    });
    Dispatch(setWorkingStepId(2));
    Dispatch(setBooknowFromOptionsClicked(true));
  };
  const redirect = () =>{ 
    window.location.href = "https://www.acriss.org/car-codes/";
  };
  const resetsetCoupon = (Coupon) => {
    // eslint-disable-next-line no-unused-expressions
    Coupon != Coupon;
    Dispatch(resetCouponState({}));
  };

  const updateCoupon = (couponValue) => {
    // const { selectedCar } = useSelector(state => state.searchResult);
    let total_amount = selectedCar.total_amount;
    let pay_calculate = item?.pay_now;
    axios.post(`${BASE}${REEDEM_COUPON_API}`, {
      coupon_code: couponValue,
      total_days : selectedCar.total_days
    })
      .then(function (response) {
        console.log(response);
        if (response.data.status) {
          let dispatch_data = { 'response': response, 'amount': total_amount, 'pay_calculate':pay_calculate , 'currency':selectedCurrency.sign };
          console.log(item?.pay_now+"============")
          Dispatch(setCouponState(dispatch_data));
        } else {
          setCouponError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <Col md="12" className={styles.CardCardHorizontalBoxThree}>
        {isOptionStep ? (
          <React.Fragment>
            <Row className={styles.CC_priceInfo}>
              <Col md="12">
                <div className={styles.CC_Price}>
                  <strong>
                    {selectedCurrency.sign}
                    {totalAmountAfterAddingExtras?.toFixed(2)}
                  </strong>
                </div>
                <span className={styles.priceForDay}>
                  {selectedCurrency.sign}
                  {item && item?.per_day_amount} per day
                </span>
              </Col>
            </Row>
            <Row className={styles.CC_bottomText}>
              {/* <Col md="12">
                <div>
                  <span className={styles.checkIcon}></span> Free Cancellation
                </div>
                <div>
                  <span className={styles.checkIcon}></span>
                  <a className={styles.bookNowLater} href="#">
                    Book Now, Pay Later
                  </a>
                </div>
              </Col> */}
              <Col md="12">
                <div className={styles.paylater_amount}>
                  {/* <p>{`Pay now ${selectedCurrency.sign}${item &&
                    item?.pay_now} and ${selectedCurrency.sign}${item &&
                    item?.pay_later} later`}</p> */}
                  <p>{`Car Rental ${selectedCurrency.sign}${item?.rental_price}`}</p>
                  {extrasTotal > 0 && (
                    <p>{`Extras ${selectedCurrency.sign}${extrasTotal}`}</p>
                  )}
                  <p>{`Tree planting donation`}</p>
                  <p>{`(non refundable ) ${selectedCurrency.sign}0.99`}</p>
                </div>
                {/* <span className={`${styles.saveForLater} ${is_saved && styles.saved}`}>
                  <i className="hearIcon">
                    <img src={favIcon} alt="Heart Icon" />
                  </i>
                  {is_saved ? "Saved" : "Save for later"}
                </span> */}
              </Col>
            </Row>
            {/* <Row className={styles.free_Insurance_info}>
              <Col>
                {isOptionStep && (
                  <React.Fragment>
                    <div className={styles.termsAndConditions_container}>
                      <div>
                        <p>
                          I accept{" "}
                          <span onClick={() => setRentalmooseTermsModal(true)}>
                            Rentalmoose Terms and Conditions & Privacy Policy
                          </span>
                        </p>
                        <CustomInput
                          type="checkbox"
                          id={`rentalmoose_terms ${Math.random() * 100}`}
                          checked={isTermsAndConditonChecked}
                          onChange={() =>
                            setRentalmooseTermsCheck((prev) => {
                              Dispatch(setTermsAndCondition(!prev));
                              return !prev;
                            })
                          }
                        />
                      </div>
                      <div>
                        <p>
                          I accept {item && item?.supplier_name}{" "}
                          <span onClick={() => setSupplierTermsModal(true)}>
                            Terms and Conditions & Privacy Policy
                          </span>
                        </p>
                        <CustomInput
                          type="checkbox"
                          id={`hertz_terms ${Math.random() * 100}`}
                          checked={isSupplierTermsAndConditonChecked}
                          onChange={() =>
                            setSupplierTermsCheck((prev) => {
                              Dispatch(setSupplierTermsAndCondition(!prev));
                              return !prev;
                            })
                          }
                        />
                      </div>
                    </div>
                    <TermsModal
                      modal={RentalmooseTermsModal}
                      toggle={() => setRentalmooseTermsModal((prev) => !prev)}
                      Text={terms}
                      title="Rentalmoose Terms"
                    />
                    <TermsModal
                      modal={SupplierTermsModal}
                      toggle={() => setSupplierTermsModal((prev) => !prev)}
                      title={`${item && item?.supplier_name} Terms`}
                      Text={item && item?.supplier_terms}
                    />
                  </React.Fragment>
                )}
              </Col>
              <Col md="12">
                <Button
                  style={{ marginTop: 5 }}
                  color="primary"
                  className="result_bookNowBtn_two"
                  disabled={
                    !(
                      isTermsAndConditonChecked &&
                      isSupplierTermsAndConditonChecked
                    )
                  }
                  onClick={handleSubmit}
                >
                  Book Now
                </Button>
              </Col>
            </Row> */}
            <Row className={styles.CC_mileageInfo}>
              <Col
                style={{ marginTop: 5 }}
                className={`${styles.mldetailBox} p-0`}
              >
                <div className={styles.card_info_item}>
                  <i
                    style={{
                      marginRight: 10,
                      marginTop: 5,
                      color: "#c1c1c1",
                    }}
                    className="zmdi zmdi-time"
                  ></i>
                  <p className={styles.title}>
                    Pick-up: <span className={styles.desc}>{checkinDate}</span>
                  </p>
                </div>
              </Col>
              <Col
                style={{ marginTop: 5 }}
                md="12"
                className={`${styles.mldetailBox} p-0`}
              >
                <div className={styles.card_info_item}>
                  <i
                    style={{
                      marginRight: 10,
                      marginTop: 5,
                      color: "#c1c1c1",
                    }}
                    className="zmdi zmdi-time"
                  ></i>
                  <p className={styles.title}>
                    Drop-off:{" "}
                    <span className={styles.desc}>{checkoutDate}</span>
                  </p>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Row className={styles.DriverDetailBox}>
            <Col md="12">
              <div className={styles.DriverDetailText}>
                <b>Driver Details</b>
                <span>
                  Main Driver: {driverDetails && driverDetails.first_name}
                </span>
              </div>
            </Col>
          </Row>
        )}
      </Col>
      <Col md="12">
        <Row className="m-0">
          <Col md="4" className="p-0">
            <div className={styles.CC_Budget}>
              <img src={item && item?.supplier_logo} alt="Supplier_Logo" />
            </div>
          </Col>
          <Col md="8" className="p-0">
            <Row className={styles.free_Insurance_info}>
              <Col className="p-0">
                {isOptionStep && (
                  <React.Fragment>
                    <div className={styles.termsAndConditions_container}>
                      <div>
                        <p>
                          I accept{" "}
                          <span onClick={() => setRentalmooseTermsModal(true)}>
                            Rentalmoose Terms and Conditions & Privacy Policy
                          </span>
                        </p>
                        <CustomInput
                          type="checkbox"
                          id={`rentalmoose_terms ${Math.random() * 100}`}
                          checked={isTermsAndConditonChecked}
                          onChange={() =>
                            setRentalmooseTermsCheck((prev) => {
                              Dispatch(setTermsAndCondition(!prev));
                              return !prev;
                            })
                          }
                        />
                      </div>
                      <div>
                        <p>
                          I accept {item && item?.supplier_name}{" "}
                          <span onClick={() => setSupplierTermsModal(true)}>
                            Terms and Conditions & Privacy Policy
                          </span>
                        </p>
                        <CustomInput
                          type="checkbox"
                          id={`hertz_terms ${Math.random() * 100}`}
                          checked={isSupplierTermsAndConditonChecked}
                          onChange={() =>
                            setSupplierTermsCheck((prev) => {
                              Dispatch(setSupplierTermsAndCondition(!prev));
                              return !prev;
                            })
                          }
                        />
                      </div>
                    </div>
                    <TermsModal
                      modal={RentalmooseTermsModal}
                      toggle={() => setRentalmooseTermsModal((prev) => !prev)}
                      Text={terms}
                      title="Rentalmoose Terms"
                    />
                    <TermsModal
                      modal={SupplierTermsModal}
                      toggle={() => setSupplierTermsModal((prev) => !prev)}
                      title={`${item && item?.supplier_name} Terms`}
                      Text={item && item?.supplier_terms}
                    />
                  </React.Fragment>
                )}
              </Col>
              <Col md="12" className="text-right">
                {/* <Button
                  style={{ marginTop: 5 }}
                  color="primary"
                  className="result_bookNowBtn_two"
                  disabled={
                    !(
                      isTermsAndConditonChecked &&
                      isSupplierTermsAndConditonChecked
                    )
                  }
                  onClick={handleSubmit}
                >
                  Book Now
                </Button> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md="12">
        <Row className="m-0">
          <Col md="4" className="p-0">
            <Row className={styles.CC_shortInfo}>
              <div className={styles.sInfoList}>
                <Col md="6">
                  <span className={styles.gearboxIcon}></span>
                  {item && item?.transmission}
                </Col>
                {item && item?.air_conditioned && (
                  <Col md="6">
                    <span className={styles.airIcon}></span>Air Con
                  </Col>
                )}

                {/* <Col md="6">
                  <span className={styles.doorIcon}></span> {item && item?.doors}{" "}
                  Doors
                </Col> */}

                <Col md="6">
                  <span className={styles.seatIcon}></span>{" "}
                  {item && item?.seats} Seats
                </Col>
                <Col md="6">
                  {item?.fuel && !item?.fuel.startsWith("Unspecified") && (
                    <>
                      <span className={styles.fuelIcon}></span>
                      {item && item?.fuel}
                    </>
                  )}
                </Col>

                {/* <Col md="6">
                  <span className={styles.bigIcon}></span>
                  {item && item?.baggage} Big Bags
                </Col>
                <Col md="6">
                  <span className={styles.bagIcon}></span>
                  {item && item?.baggage} Small Bags
                </Col> */}
              </div>
            </Row>
            <Row className={styles.CC_shortInfo}>
              <div className={styles.sInfoList}>
                <Col md="6">
                    {item && item?.category_code && (
                      <>
                        Car class : <span onClick={redirect} className={styles.Carcategoty}>
                          
                            {item && item?.category_code}
                          </span>
                      </>
                    )}
                </Col>
              </div>
            </Row>
          </Col>
          <Col md="8" className="p-0">
            <Row className={`${styles.free_Insurance_info} text-right `}>
              <Col className="p-0">
                {isOptionStep && (
                  <React.Fragment>
                    <div className={styles.termsAndConditions_container}>
                      {/* <div>
                        <p>
                          I accept{" "}
                          <span onClick={() => setRentalmooseTermsModal(true)}>
                            Rentalmoose Terms and Conditions & Privacy Policy
                          </span>
                        </p>
                        <CustomInput
                          type="checkbox"
                          id={`rentalmoose_terms ${Math.random() * 100}`}
                          checked={isTermsAndConditonChecked}
                          onChange={() =>
                            setRentalmooseTermsCheck((prev) => {
                              Dispatch(setTermsAndCondition(!prev));
                              return !prev;
                            })
                          }
                        />
                      </div>
                      <div>
                        <p>
                          I accept {item && item?.supplier_name}{" "}
                          <span onClick={() => setSupplierTermsModal(true)}>
                            Terms and Conditions & Privacy Policy
                          </span>
                        </p>
                        <CustomInput
                          type="checkbox"
                          id={`hertz_terms ${Math.random() * 100}`}
                          checked={isSupplierTermsAndConditonChecked}
                          onChange={() =>
                            setSupplierTermsCheck((prev) => {
                              Dispatch(setSupplierTermsAndCondition(!prev));
                              return !prev;
                            })
                          }
                        />
                      </div> */}
                    </div>
                    <TermsModal
                      modal={RentalmooseTermsModal}
                      toggle={() => setRentalmooseTermsModal((prev) => !prev)}
                      Text={terms}
                      title="Rentalmoose Terms"
                    />
                    <TermsModal
                      modal={SupplierTermsModal}
                      toggle={() => setSupplierTermsModal((prev) => !prev)}
                      title={`${item && item?.supplier_name} Terms`}
                      Text={item && item?.supplier_terms}
                    />
                  </React.Fragment>
                )}
              </Col>
              <Col md="12" className="p-0">
                {isOptionStep && (
                  <React.Fragment>
                    <Button
                      style={{ marginTop: 5 }}
                      color="primary"
                      className="result_bookNowBtn_two"
                      disabled={
                        !(
                          isTermsAndConditonChecked &&
                          isSupplierTermsAndConditonChecked
                        )
                      }
                      onClick={handleSubmit}
                    >
                      {`Book and Pay Now ${selectedCurrency.sign}${pay_now_coupon_value}`}
                    </Button>
                    <div className={styles.paylater_amount}>
                      <p>
                        {`You can pay the rest ${
                          selectedCurrency.sign
                        }${totalPayLater?.toFixed(
                          2
                        )} at the rental desk when picking up the car`}
                      </p>
                    </div>
                    <Row className={`${styles.CC_bottomText} mt-2`}>
                      <Col md="12">
                        <div>
                          <span className={styles.checkIcon}></span> Free
                          cancellation up to 48h till pick-up time
                        </div>
                        {/* <div>
                      <span className={styles.checkIcon}></span>
                      <a className={styles.bookNowLater} href="#">
                        Book Now, Pay Later
                      </a>
                    </div> */}
                      </Col>
                    </Row>
                    {
                      isOptionStep && (
                      <Row>
                        {
                          couponCode ?
                            <>
                              <Col className={Coupon && !couponCode ? couponClass2 : couponClass}>
                                <span className={styles.Carcategoty} >
                                  Coupon: {couponCode} ({showCouponAmount}) has been applied. Discount amount {selectedCurrency.sign}{discountPrice} <span onClick={() => resetsetCoupon(Coupon)} className={styles.resetCoupon}>X</span>
                                </span>
                              </Col>
                            </> :
                            <>
                              <Col className={Coupon ? couponClass2 : couponClass}>
                                <span className={styles.Carcategoty} onClick={() => setCoupon(!Coupon)}>
                                  Use Coupon
                                </span>
                              </Col>
                            </>
                        }

                        {Coupon && !couponCode ?
                          <>
                            <Col md="4" className={`pr-0 ${styles.couponText}`}>
                              <InputText
                                name="coupon"
                                placeholder="Coupon"
                                type="text"
                                value={couponValue}
                                onChange={e => setCouponValue(e.target.value)}

                                style={showErrorBorder(
                                  couponError
                                )}
                              />
                              {couponError}
                            </Col>
                            <Col md="3">
                              <Button
                                color="primary"
                                className={`${styles.couponButton}`}

                                onClick={() => updateCoupon(couponValue)}
                              >
                                Apply
                              </Button>
                            </Col>
                          </> : null
                        }
                      </Row>
                    )}
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};
const TermsModal = ({ modal, toggle, title, Text }) => {
  return (
    <Modal
      className="modal-dialog-centered modal-lg termsModal"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody style={{zIndex: 999999, position: 'relative'}}
                 dangerouslySetInnerHTML={{ __html: Text }}></ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export {
  CCHeading,
  CCShortInfo,
  CCPriceInfo,
  CCMileageInfo,
  CCType,
  CCInsuranceInfo,
  CCShortInfoMobile,
  CCTime,
  CCBottomText,
  CCBottomTextMobile,
  CardCardHorizontalBoxOne,
  CardCardHorizontalBoxThree,
  CardCardHorizontalBoxTwo,
  TermsModal,
};
