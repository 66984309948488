import React, { useEffect,Suspense, lazy } from "react";
import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ErrorBoundary from "./utils/ErrorBoundary/index";
import { HeaderComponent, Footer, LocationHeaderComponent } from "./components";
import { setLocationHeaderStatus,setLocationCheckIn } from "./store/actions/config.action";

import {
  HomePage,
  // PageNotFound,
  SearchResult,
  BookingDetailPage,
  // Contact,
  // Privacy,
  // HelpSupport,
  // BookingDetails,
  // Bookings,
  // Profile,
  // History,
  VehicleImages,
  // ResetPassword,
  CheckoutImages,
  
} from "./pages";
import { dispatch } from "rxjs/internal/observable/pairs";

// const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Locations = lazy(() => import('./pages/Locations'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Contact = lazy(() => import('./pages/Contact'));
const Privacy = lazy(() => import('./pages/Privacy'));
const HelpSupport = lazy(() => import('./pages/HelpSupport'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Bookings = lazy(() => import('./pages/Account/modules/Bookings'));
const BookingDetails = lazy(() => import('./pages/Account/modules/BookingDetails'));
const History = lazy(() => import('./pages/Account/modules/History'));
const Profile = lazy(() => import('./pages/Account/modules/Profile'));
const pathname  = window.location.pathname;
const location = pathname.includes('/location');
let count = 1;
function Header(props) {
  const isMainHeader = props.isMainHeader;
  if (isMainHeader) {
    return <LocationHeaderComponent />;
  }
  return <HeaderComponent />;
}
export default () => {
  const {locationHeaderStatus } = useSelector((state) => state.config);
  const Dispatch = useDispatch();
 
  if(location && (count == 1))
  {
    count = 2;
    Dispatch(setLocationHeaderStatus(true));
    Dispatch(setLocationCheckIn(false));
  } 
  if(!location && (count == 1))
  {
    count = 2;
    Dispatch(setLocationHeaderStatus(false));
    Dispatch(setLocationCheckIn(false));
  } 
  useEffect(() => {
    
    ReactGA.initialize({ trackingId: "GTM-TS3HNHM", debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
     {<Header isMainHeader={locationHeaderStatus} /> }
      <ErrorBoundary>
        <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={About} />
          <Route path="/results" component={SearchResult} />
          <Route
            path="/bookingDetail/:carId/:stepId"
            component={BookingDetailPage}
          />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/helpsupport" component={HelpSupport} />
          <Route path="/account/bookings" component={Bookings} />
          <Route path="/account/bookingHistory" component={History} />
          <Route path="/checkin/:id" component={VehicleImages} />
          <Route path="/checkout/:id" component={CheckoutImages} />
          <Route
            path="/account/bookingDetails/:id"
            component={BookingDetails}
          />
          <Route path="/account/profile" component={Profile} />
          <Route path="/resetPassword/:token" component={ResetPassword} />
          <Route path="/location/:id" component={Locations} />
        </Suspense>  
          <Route component={PageNotFound} />
        </Switch>
      </ErrorBoundary>
      {<Footer />}
    
  </Router>
  );
};
