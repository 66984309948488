import React from "react";

import HeaderLogo from "../../assets/head.svg";
import styles from "./styles.module.scss";

export const Overlay = ({ container }) => {
  return (
    <div className={container ? styles.overlay_Box : styles.overlay_container}>
      <img src={HeaderLogo} width="120" />
      <div className="mt-3">Loading cars, please wait!</div>
    </div>
  );
};
export default Overlay;