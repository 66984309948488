let today = new Date();

var Checkin = new Date(new Date().setDate(today.getDate() + 7));
var Checkout = new Date(new Date().setDate(today.getDate() + 9));
Checkin.setHours(12);
Checkout.setHours(12);
Checkin.setMinutes(0);
Checkout.setMinutes(0);
Checkin.setSeconds(0);
Checkout.setSeconds(0);
Checkin.setMilliseconds(0);
Checkout.setMilliseconds(0);

const data_col_1 = [
  {
    place: "Dubrovnik, Croatia",
    price: "€11.99",
    link: `https://dev.rentalmoose.com/location/croatia/dubrovnik`
  },
  {
    place: "Podgorica, Montenegro",
    link: `https://dev.rentalmoose.com/location/montenegro/podgorica`,
    price: "€14.99"
  },
  {
    place: "Rijeka, Croatia",
    link: `https://dev.rentalmoose.com/location/croatia/rijeka`,
    price: "€18.99"
  },
  {
    place: "Split, Croatia",
    link: `https://dev.rentalmoose.com/location/croatia/split`,
    price: "€18.99"
  },
  {
    place: "Tirana, Albania",
    link: `https://dev.rentalmoose.com/location/albania/tirana`,
    price: "€18.99"
  }
];

const data_col_2 = [
  {
    place: "Tallinn, Estonia",
    link: `https://dev.rentalmoose.com/location/estonia/tallinn`,
    price: "€26.99"
  },
  {
    place: "Liverpool, United Kingdom",
    link: `https://dev.rentalmoose.com/location/United Kingdom/liverpool`,
    price: "€26.99"
  },
  {
    place: "Rovaniemi, Finland",
    link: `https://dev.rentalmoose.com/location/finland/rovaniemi`,
    price: "€29.99"
  },
  {
    place: "Oslo, Norway",
    link: `https://dev.rentalmoose.com/location/norway/Oslo`,
    price: "€29.99"
  },
  {
    place: "Reykjavik, Iceland",
    link: `https://dev.rentalmoose.com/location/iceland/reykjavik`,
    price: "€33.99"
  }
];

const data_col_3 = [
  {
    place: "Auckland, New Zealand",
    link: `https://dev.rentalmoose.com/location/New Zealand/auckland`,
    price: "€13.99"
  },
  {
    place: "Santo Domingo, Dominican",
    link: `https://dev.rentalmoose.com/location/dominican/Santo Domingo`,
    price: "€13.99"
  },
  {
    place: "Cancun, Mexico",
    link: `https://dev.rentalmoose.com/location/mexico/cancun`,
    price: "€14.99"
  },
  {
    place: "Miami, United States",
    link: `https://dev.rentalmoose.com/location/United States/miami`,
    price: "€22.99"
  },
  {
    place: "Tbilisi, Georgia",
    link: `https://dev.rentalmoose.com/location/georgia/tbilisi`,
    price: "€33.99"
  }
];

const place_data = [
  {
    title: "Balkan Peninsula",
    imgSrc: "balkan.jpg",
    alt: "Balkan Peninsula",
    cardDetails: data_col_1
  },
  {
    title: "North Europe",
    imgSrc: "north-europe.jpg",
    alt: "North Europe",
    cardDetails: data_col_2
  },
  {
    title: "Global",
    imgSrc: "global.jpg",
    alt: "Global",
    cardDetails: data_col_3
  }
];

export { place_data };
