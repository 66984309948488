import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button as RButton,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Viewer from "react-viewer";
import ICalendarLink from "react-icalendar-link";
import ReactGA from "react-ga";

import ConfirmationDialog from "./modal";
import greenTick from "./../../../../assets/greentick.svg";
import downloadIcon from "./../../../../assets/download.svg";
import checkinIcon from "./../../../../assets/checkin.svg";
import checkoutIcon from "./../../../../assets/checkout.svg";
import { Button } from "./../../../../components";
import { MyAccountNavigation } from "./../../utils";
import { showModal, hideModal } from "./../../../../store/actions/modal.action";
import { cancelBooking } from "./../../../../store/actions/account.action";
import { deleteVehicleImage } from "./../../../../store/actions/vehicleImages.action";
import defaultImage from "./../../../../assets/defaultCarImage.png";
import styles from "./styles.module.scss";

const KeyPoints = ({ title, icon, desc }) => (
  <div className={styles.KeyPoints_container}>
    {icon && <img src={icon} className={styles.icon} />}
    <span className={styles.title}>{title}</span>
    <p className={styles.desc}>{desc}</p>
  </div>
);

const SectionRow = ({ title, children }) => (
  <Row className={styles.sectionRow_container}>
    <Col md="9" className="offset-md-3">
      <h2 className={styles.sectionRow_title}>{title}</h2>
      {children}
    </Col>
  </Row>
);

const Location = ({
  title,
  date,
  icon,
  location: { name, country, address, lat, lng },
}) => {
  const event = {
    title,
    description: "I have to go to pickup point",
    startTime: date,
    endTime: date,
    location: `${address}, ${country}`,
  };
  return (
    <div className={styles.locationCard_container}>
      <h1>{title}</h1>
      <div className={styles.locationCardInner_container}>
        <div className={styles.dropOffDate_container}>
          <span>Drop-off Date</span>
          <span>
            <b>
              <span className="time">{date}</span>
            </b>
          </span>
        </div>
        <div className={styles.dropOffLocation_container}>
          <span>Location</span>
          <span>
            <b>{title}</b>
          </span>
          <span>
            <b>{address} </b>
          </span>
        </div>
        <div className={styles.locationCardLinks_container}>
          <a
            href={`https://maps.google.com?q=${lat},${lng}`}
            target="_blank"
            className={styles.openMaps_link}
          >
            Open in Maps
          </a>
          <ICalendarLink filename={`${title}-reminder`} event={event}>
            Set Reminder
          </ICalendarLink>
        </div>
      </div>
    </div>
  );
};

export const BookingDetails = () => {
  const {
    selectedBooking: {
      id: selectedBooking_id,
      pickup_location,
      drop_off_location,
      pickup_date,
      drop_off_date,
      duration_in_days,
      booking_photos,
      booking_status,
      booking_reference,
      booking_extras,
      download_voucher,
      currency,
      supplier_logo,
      contract_name,
      applied_rule_name,
      payment,
      supplier: { name: supplier_name },
      vehicle: {
        title,
        image,
        seats,
        doors,
        transmission,
        air_conditioned,
        baggage,
      },
      billing: {
        booking_rate,
        booking_location_rate,
        booking_net_total,
        vehicle_net_total,
        vehicle_booking_rate,
        vehicle_location_rate,
        extras_net_total,
        extras_booking_rate,
        extras_location_rate,
      },
      driver: { first_name, last_name, age, address, city, country },
    },
    isLoading,
  } = useSelector((state) => state.UserAccount);
  const {
    modalState: { bookingCancellationModal, picDeleteConfirmationModal },
    vehicleImages,
  } = useSelector((state) => state);
  const { token } = useSelector((state) => state.auth);
  const Dispatch = useDispatch();
  const [currentPhotosTab, setPhotoTab] = useState("interior");
  const [chkoutPhotosTab, setChkoutPhotoTab] = useState("interior");
  const [currentImageIndex, setImageIndex] = useState(1);
  const [chkoutImageIndex, setChkoutImageIndex] = useState(1);
  const [imageViewerVisible, setImageViewer] = useState(false);
  const [chkoutImageVisibility, setChkoutImageViewer] = useState(false);
  const [currDltPicId, setCurrDltPicId] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const hideConfirmationModal = () =>
    Dispatch(hideModal("picDeleteConfirmationModal"));

  const showConfirmationModal = () =>
    Dispatch(showModal("picDeleteConfirmationModal"));

  const toggleConfirmationModal = () =>
    picDeleteConfirmationModal
      ? Dispatch(hideModal("picDeleteConfirmationModal"))
      : Dispatch(showModal("picDeleteConfirmationModal"));

  const handleConfirmationDone = () => {
    Dispatch(
      deleteVehicleImage(token, { id: currDltPicId }, hideConfirmationModal)
    );
  };
  let filteredImages =
    booking_photos &&
    booking_photos
      .filter(
        (val) => val.type === currentPhotosTab && val.target === "checkin"
      )
      .map((x) => {
        return {
          ...x,
          src: x.file_path,
        };
      });

  let chkoutfilteredImages =
    booking_photos &&
    booking_photos
      .filter(
        (val) => val.type === chkoutPhotosTab && val.target === "checkout"
      )
      .map((x) => {
        return {
          ...x,
          src: x.file_path,
        };
      });

  const toggleCancellationModal = () =>
    bookingCancellationModal
      ? Dispatch(hideModal("bookingCancellationModal"))
      : Dispatch(showModal("bookingCancellationModal"));

  const hideCancellationModal = () =>
    Dispatch(hideModal("bookingCancellationModal"));

  const handleCancelBooking = () => {
    Dispatch(
      cancelBooking(
        {
          booking_id: selectedBooking_id,
        },
        hideCancellationModal,
        token
      )
    );
  };
  const Width = window.innerWidth;
  return (
    <Container>
      <ConfirmationDialog
        isOpen={picDeleteConfirmationModal}
        toggleModal={toggleConfirmationModal}
        handleDone={handleConfirmationDone}
        isLoading={vehicleImages && vehicleImages.isLoading}
      />
      <Modal
        isOpen={bookingCancellationModal}
        toggle={toggleCancellationModal}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <h5>Are you sure for booking cancellation?</h5>
        </ModalBody>
        <ModalFooter>
          {
            <RButton onClick={handleCancelBooking} disabled={isLoading}>
              Yes
            </RButton>
          }
          <RButton onClick={toggleCancellationModal}>No</RButton>
        </ModalFooter>
      </Modal>
      <MyAccountNavigation />
      <Row className={styles.detail_car_card}>
        <Col md="3" className={styles.image_container}>
          <div className={`${styles.title_section} ${styles.mobile}`}>
            <span
              className={`${styles.status} ${booking_status === "Cancelled" &&
                styles.status_cancelled} no`}
            >
              {booking_status === "Booked" && (
                <img src={greenTick} alt="icon" />
              )}
              {booking_status}
            </span>
            <h1 className={`${styles.title}`}>
              {title && title.replace("OR SIMILAR", "")}{" "}
              <span className={styles.days}>{duration_in_days} dayssss</span>
            </h1>
          </div>
          <span className={styles.imgBox}>
             <img
                 src={image}
                 alt="Car"
                 className={styles.car_image}
                 onError={(e) => {
                   e.target.onerror = null;
                   e.target.src = defaultImage;
                 }}
             />
          </span>
          <img
              className={styles.supplier_logo}
              src={supplier_logo}
              style={{height: 'unset'}}
          />
        </Col>
        <Col md="9">
          <div className={`${styles.title_section} ${styles.desktop}`}>
            <span
              className={`${styles.status} ${booking_status === "Cancelled" &&
                styles.status_cancelled} no`}
            >
              {booking_status === "Booked" && (
                <img src={greenTick} alt="icon" />
              )}
              {booking_status}
            </span>
            <h1 className={`${styles.title} `}>
              {title && title.replace("OR SIMILAR", "")}{" "}
            </h1>
          </div>
          <span className={`${styles.days}`}>{duration_in_days} days</span>
          <Row className={styles.listinfoBox}>
            <Col md="5">
              <div className={styles.sInfoList}>
                <span className={styles.listName}>
                  <span className={styles.seatIcon} /> {seats} Seats
                </span>
                {air_conditioned && (
                  <span className={styles.listName}>
                    <span className={styles.airIcon} />
                    Air Con
                  </span>
                )}
                <span className={styles.listName}>
                  <span className={styles.doorIcon} /> {doors} Doors
                </span>
                <span className={styles.listName}>
                  <span className={styles.bigIcon} /> {baggage} Bags
                </span>
                <span className={styles.listName}>
                  <span className={styles.gearboxIcon} />
                  {transmission}
                </span>
              </div>
            </Col>
            <Col md="3">
              <span>Supplier:</span>
              <p>{supplier_name}</p>
            </Col>
            <Col md="4">
              <span>Booking ID:</span>{" "}
              <p>
                {booking_reference}{" "}
                <a
                  href={download_voucher}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  download
                  className={styles.get_voucher}
                >
                  <img src={downloadIcon} alt="icon" /> Get Voucher
                </a>
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col md="3" xs="6">
              <KeyPoints title="Contract" desc={contract_name} />
            </Col>
            <Col md="3" xs="6">
              <KeyPoints title="Supplier" desc={supplier_name} />
            </Col>
            <Col md="6" xs="6">
              <KeyPoints title="Applied rules" desc={applied_rule_name} />
            </Col>
          </Row> */}
        </Col>
      </Row>
      <SectionRow title="Location">
        <Row>
          <Col md="6">
            <Location
              title="Pick-Up"
              icon={checkinIcon}
              location={pickup_location}
              date={pickup_date}
            />
          </Col>
          <Col md="6">
            <Location
              title="Drop-Off"
              icon={checkoutIcon}
              location={drop_off_location}
              date={drop_off_date}
            />
          </Col>
        </Row>
      </SectionRow>
      <SectionRow title="Driver & License">
        <Row>
          <Col md="3" xs="6">
            <KeyPoints title="Driver" desc={`${first_name} ${last_name}`} />
          </Col>
          <Col md="3" xs="6">
            <KeyPoints title="Driver Age" desc={age} />
          </Col>
          <Col md="3" xs="6">
            <KeyPoints title="Location" desc={`${country}`} />
          </Col>
          <Col md="3">
            <KeyPoints title="Language" desc="English" />
          </Col>
        </Row>
      </SectionRow>
      <SectionRow title="Check-in Photos">
        <div className={styles.checkInPhotos_tabs}>
          <span
            onClick={() => setPhotoTab("interior")}
            className={currentPhotosTab === "interior" && styles.tab_clicked}
          >
            Interior
          </span>
          <span
            onClick={() => setPhotoTab("exterior")}
            className={currentPhotosTab === "exterior" && styles.tab_clicked}
          >
            Exterior
          </span>
        </div>
        <Row className={styles.images_container}>
          {filteredImages.map((val, idx) => (
            <Col md="2" key={idx}>
              <div>
                <div
                  onClick={() => {
                    setCurrDltPicId(val.id);
                    showConfirmationModal();
                  }}
                  className={styles.delete_pic}
                >
                  <p>x</p>
                </div>
                <img
                  src={val.file_path}
                  onClick={() => {
                    setImageIndex(idx);
                    setImageViewer(true);
                  }}
                  className={styles.car_photo}
                />
              </div>
            </Col>
          ))}
        </Row>
        <Viewer
          visible={imageViewerVisible}
          onClose={() => setImageViewer(false)}
          activeIndex={currentImageIndex}
          images={filteredImages}
          onMaskClick={() => setImageViewer(false)}
        />
      </SectionRow>
      <SectionRow title="Check-out Photos">
        <div className={styles.checkInPhotos_tabs}>
          <span
            onClick={() => setChkoutPhotoTab("interior")}
            className={chkoutPhotosTab === "interior" && styles.tab_clicked}
          >
            Interior
          </span>
          <span
            onClick={() => setChkoutPhotoTab("exterior")}
            className={chkoutPhotosTab === "exterior" && styles.tab_clicked}
          >
            Exterior
          </span>
        </div>
        <Row className={styles.images_container}>
          {chkoutfilteredImages.map((val, idx) => (
            <Col md="2" key={idx}>
              <div>
                <div
                  onClick={() => {
                    setCurrDltPicId(val.id);
                    showConfirmationModal();
                  }}
                  className={styles.delete_pic}
                >
                  <p>x</p>
                </div>
                <img
                  src={val.file_path}
                  onClick={() => {
                    setChkoutImageIndex(idx);
                    setChkoutImageViewer(true);
                  }}
                  className={styles.car_photo}
                />
              </div>
            </Col>
          ))}
        </Row>
        <Viewer
          visible={chkoutImageVisibility}
          onClose={() => setChkoutImageViewer(false)}
          activeIndex={chkoutImageIndex}
          images={chkoutfilteredImages}
          onMaskClick={() => setChkoutImageViewer(false)}
        />
      </SectionRow>
      <SectionRow title="Payment Info">
        <Row>
          <Col md="3" xs="6">
            <div className={styles.KeyPoints_container}>
              <span className={styles.title}>{title}</span>
              <p className={styles.desc}>
                {payment?.card_type}{" "}
                <p className={styles.desc}>{payment?.card_number}</p>
              </p>
            </div>
          </Col>
          {/* <Col md="3" xs="6">
            <KeyPoints title="Bank" desc={bank} />
          </Col> */}
          <Col md="3" xs="6">
            <KeyPoints title="Currency" desc={currency} />
          </Col>
        </Row>
      </SectionRow>
      <SectionRow title="Extras">
        <Row>
          {booking_extras &&
            booking_extras.map((val, idx) => (
              <Col md="3" key={idx}>
                <KeyPoints
                  title={`${val.description}-${val.code}`}
                  desc={`${val.total}`}
                  icon={val.icon}
                />
              </Col>
            ))}
          {/* <Col md="3">
            <KeyPoints
              title="Free cancellation waiver"
              desc="€11,00"
              icon={driverIcon}
            />
          </Col>
          <Col md="3">
            <KeyPoints
              title="Child seat 1pcs"
              desc="Included"
              icon={childseatIcon}
            />
          </Col> */}
        </Row>
      </SectionRow>
      <SectionRow title="Summary">
        <Row className={styles.summarySection}>
          {/* Headings Start */}
          {Width >= 767 ? (
            <>
              <Col md="12" xs="6">
                <Row>
                  <Col md="3">
                    <span className={styles.title}>Item</span>
                  </Col>
                  <Col md="3">
                    <span className={styles.title}>Pay on Booking</span>
                  </Col>
                  <Col md="3">
                    <span className={styles.title}>Pay on Location</span>
                  </Col>
                  <Col md="3">
                    <span className={styles.title}>Total</span>
                  </Col>
                </Row>
              </Col>
              {/* Headings End */}
              <Col md="12">
                <Row className={styles.summary_row}>
                  <Col md="3">
                    <span className={styles.title}>Vehicle</span>
                  </Col>
                  <Col md="3">
                    <p>{vehicle_booking_rate}</p>
                  </Col>
                  <Col md="3">
                    <p>{vehicle_location_rate}</p>
                  </Col>
                  <Col md="3">
                    <p>{vehicle_net_total}</p>
                  </Col>
                </Row>
              </Col>
              {/* Row === */}
              <Col md="12">
                <Row className={styles.summary_row}>
                  <Col md="3">
                    <span className={styles.title}>Supplier Extra</span>
                  </Col>
                  <Col md="3">
                    <p>{extras_booking_rate}</p>
                  </Col>
                  <Col md="3">
                    <p>{extras_location_rate}</p>
                  </Col>
                  <Col md="3">
                    <p>{extras_net_total}</p>
                  </Col>
                </Row>
              </Col>
              {/* Row === */}
              <Col md="12">
                <Row className={styles.summary_row}>
                  <Col md="3">
                    <span className={styles.title}>Rentalmoose Extras</span>
                  </Col>
                  <Col md="3">
                    <p>{booking_rate}</p>
                  </Col>
                  <Col md="3">
                    <p>{booking_location_rate}</p>
                  </Col>
                  <Col md="3">
                    <p>{booking_net_total}</p>
                  </Col>
                </Row>

                {booking_status !== "Cancelled" && (
                  <Button onClick={toggleCancellationModal} color="danger">
                    Cancel Booking
                  </Button>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col xs="12" style={{ marginBottom: 30 }}>
                <Row>
                  <Col xs="12">
                    <span className={styles.title}>Pay on Booking</span>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>Vehicle</span>
                  </Col>
                  <Col xs="6">
                    <p>{vehicle_booking_rate}</p>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>Supplier Extra</span>
                  </Col>
                  <Col xs="6">
                    <p>{vehicle_location_rate}</p>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>RentaMoose</span>
                  </Col>
                  <Col xs="6">
                    <p>{vehicle_net_total}</p>
                  </Col>
                </Row>
              </Col>

              <Col xs="12" style={{ marginBottom: 30 }}>
                <Row>
                  <Col xs="12">
                    <span className={styles.title}>Pay on Location</span>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>Vehicle</span>
                  </Col>
                  <Col xs="6">
                    <p>{extras_booking_rate}</p>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>Supplier Extra</span>
                  </Col>
                  <Col xs="6">
                    <p>{extras_location_rate}</p>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>RentaMoose</span>
                  </Col>
                  <Col xs="6">
                    <p>{extras_net_total}</p>
                  </Col>
                </Row>
              </Col>

              <Col xs="12" style={{ marginBottom: 30 }}>
                <Row>
                  <Col xs="12">
                    <span className={styles.title}>Total</span>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>Vehicle</span>
                  </Col>
                  <Col xs="6">
                    <p>{booking_rate}</p>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>Supplier Extra</span>
                  </Col>
                  <Col xs="6">
                    <p>{booking_location_rate}</p>
                  </Col>
                  <Col xs="6">
                    <span className={styles.title}>RentaMoose</span>
                  </Col>
                  <Col xs="6">
                    <p>{booking_net_total}</p>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                {booking_status !== "Cancelled" && (
                  <Button onClick={toggleCancellationModal} color="danger">
                    Cancel Booking
                  </Button>
                )}
              </Col>
            </>
          )}
        </Row>
      </SectionRow>
    </Container>
  );
};


export default BookingDetails;