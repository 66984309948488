import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag"
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import styles from "./styles.module.scss";
import { changeCurrency,getUserCountry } from "./../../../store/actions/config.action";
import { getCars } from "./../../SearchPanel/searchPanel.action";
import chatIcon from "../../../assets/support-icon.svg";
import chatIconWhite from "../../../assets/support-icon-white.svg";

export const ChatLink = () => {
  const Width = window.innerWidth;
  return (
    <li className={styles.chatButton}>
      {Width >= 767 ? (
        <>
          <i className={styles.chat_active}>
            <img src={chatIcon} alt="chat icon" />
          </i>
          Online Chat
        </>
      ) : (
        <i className={styles.chat_active}>
          <img src={chatIconWhite} alt="chat icon" />
        </i>
      )}
    </li>
  );
};

export const LanguageChange = () => {
  const [isLngSelectorOpen, toggleLngSelector] = useState(false);
  const { i18n } = useTranslation();
  let langSign, language;

  let languages = [
    {
      id: "en",
      name: "US-English",
    },
  ];

  switch (i18n.language) {
    case "en":
      langSign = "US";
      language = "English";
      break;
    case "ar":
      langSign = "AR";
      language = "Arabic";
      break;
    case "fn":
      langSign = "FN";
      language = "Finland";
      break;
    default:
      langSign = "US";
      language = "English";
      break;
  }

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <li>
      <Dropdown
        className="topDropdown"
        isOpen={isLngSelectorOpen}
        toggle={() => toggleLngSelector((prevState) => !prevState)}
      >
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={isLngSelectorOpen}
        >
          <span>
            <i className="btn-primary lang">{langSign}</i>
            <span>{language}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="topDropdown">
          {languages.map((val, idx) => (
            <DropdownItem
              key={idx}
              onClick={() => handleLanguageChange(val.id)}
            >
              {val.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </li>
  );
};

export const CurrencyChange = () => {
  const { currencies, selectedCurrency } = useSelector((state) => state.config);
  const {
    pickUpStation,
    startDate,
    endDate,
    deposit,
    selfLiabity,
    mileage,
    driverAge,
    childSeat,
    transmission,
    pick_up_type,
    winter_tyres,
    supplier_rating,
    number_of_seats,
    luggage_space,
    driver,
    electric,
    hybrid,
    bestValues,
    cheapest,
    expensive,
  } = useSelector((state) => state.searchParams);
  const { suppliers } = useSelector((state) => state.searchResult);
  const [isCurrencySelectorOpen, toggleCurrencySelector] = useState(false);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const Dispatch = useDispatch();

  const handleCurrencyChange = (currency_id) => {
    Dispatch(changeCurrency(currency_id));
    if (
      pathname.startsWith("/bookingDetail") ||
      pathname.startsWith("/results")
    ) {
      let _suppliers = suppliers.filter((v) => v.is_selected).map((v) => v.id);

      const values = {
        checkin_station_code: pickUpStation && pickUpStation.code,
        checkout_station_code: "",
        country_code: pickUpStation && pickUpStation.country_code,
        checkin_time: moment(startDate).format(),
        checkout_time: moment(endDate).format(),
        currency: currency_id,
        filters: {
          deposit: deposit,
          selfLiabity: selfLiabity,
          mileage: mileage,
          driverAge: driverAge,
          childSeat: childSeat,
          transmission: transmission,
          pick_up_type: pick_up_type,
          winter_tyres: winter_tyres,
          supplier_rating: supplier_rating,
          number_of_seats: number_of_seats,
          luggage_space: luggage_space,
          driver: driver,
        },
        supplier_filter: _suppliers,
        goGreenTypeFilter: { hybrid: hybrid, electric: electric },
        sortingFilters: {
          bestValues: bestValues,
          cheapest: cheapest,
          expensive: expensive,
        },
      };
      Dispatch(getCars(values, push, true));
    }
  };
  return (
    <li>
      <Dropdown
        isOpen={isCurrencySelectorOpen}
        toggle={() => toggleCurrencySelector((prevState) => !prevState)}
      >
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          aria-expanded={isCurrencySelectorOpen}
        >
          <span>
            <i className="btn-primary lang">
              {selectedCurrency && selectedCurrency.sign}
            </i>
            <span>{selectedCurrency && selectedCurrency.name}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {currencies &&
            currencies.map((val, idx) => (
              <DropdownItem
                key={idx}
                onClick={() => handleCurrencyChange(val.id)}
              >
                {`${val.sign} - ${val.name}`}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </li>
  );
};

export const TopbarButton = (props) => {
  return (
    <li>
      <span {...props}>
        <Button className="btn btn-outline-primary ">{props.title}</Button>
      </span>
    </li>
  );
};
export const UserCountry = () => {

  const { selectedCountry } = useSelector((state) => state.config);
  console.log(selectedCountry);
  return (
    <li>
      <span style={{marginRight: "5px"}}>
        {selectedCountry.country} 
      </span> 
      <span> 
        <ReactCountryFlag className="emojiFlag" aria-label="{selectedCountry.country}" 
        countryCode={selectedCountry.code} svg style={{width:'1.25em',height:'1.25em'}}/> 
      </span>
    </li>
  );
};
