export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const UPDATE_USER = "UPDATE_USER";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const LOGOUT = "LOGOUT";

export const login = (payload, hideModal, setSubmitting) => ({
  type: LOGIN,
  payload,
  hideModal,
  setSubmitting
});

export const signup = (payload, hideModal, setSubmitting) => ({
  type: SIGN_UP,
  payload,
  hideModal,
  setSubmitting
});

export const logout = () => ({
  type: LOGOUT
});

export const forgotPassword = (payload, hideModal, setSubmitting) => ({
  type: FORGOT_PASSWORD,
  payload,
  setSubmitting,
  hideModal
});

export const resetPassword = (payload, push, setSubmitting) => ({
  type: RESET_PASSWORD,
  payload,
  push,
  setSubmitting
});

export const resetMessage = () => ({ type: RESET_MESSAGE });
