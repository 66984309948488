import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/browser";

import { initializeFirebase } from "./push-notification";

import i18n from "./i18n";
import * as serviceWorker from "./service-worker";
import { App } from "./pages";
import { store, persistor } from "./store";
import "./scss/style.scss";

caches.delete("workbox-precache-v2-https://dev.rentalmoose.com/");
caches.delete("mapbox-tiles");
const cacheWhitelist = ["mapbox-tiles", "workbox-precache-v2-https://dev.rentalmoose.com/"];
caches.keys().then((cacheNames) => {
  return Promise.all(
    cacheNames.map((cacheName) => {
      if (cacheWhitelist.indexOf(cacheName) === -1) {
        return caches.delete(cacheName);
      }
    })
  );
});
Sentry.init({
  dsn:
    "https://542ca489883d48b58ee355e01442af93@o410836.ingest.sentry.io/5285240",
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
initializeFirebase();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
