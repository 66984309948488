import React from "react";
import { Row, Col } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/core";

import styles from "./styles.module.scss";

const override = css`
  position: relative;
  display: block;
  margin: 0px auto;
  border-color: red;
  width: 0px;
  padding: 13px;
`;

const ListItem = ({ location, _handleBookNow, _closeSearchList }) => {
  const { name, item_1, item_2, item_3, icon, counter } = location;
  const handleBookNow = () => {
    _handleBookNow(location);
    _closeSearchList();
  };

  const renderRowDesc = (item_1, item_2, item_3) => {
    let rowDesc = `${item_1 ? item_1 + ", " : ""}${item_2 ? item_2 + ", " : ""}${item_3 ? item_3 : ""}`;
    return rowDesc.replace(/,\s*$/, "")
  };

  return (
    <div onClick={counter < 100 && handleBookNow} className={styles.searchResultRow}>
      <Row className="no-margin w-100">
        <Col md="10" sm="10" className="col-10 p-0 mt-2" style={{ display: "block" }}>
          <Row className="m-0">
            <Col md="12" sm="12" style={{ display: "block" }}>
              <div style={{ textAlign: "left" }} className={styles.SearchPlaces}>
                {counter > 100 ? <span><strong style={{color:"grey"}}>{name} </strong></span>:<strong className={styles.placename}>{name} </strong>}<span><small>({counter})</small></span>
              
                <br></br>
                <span>{counter < 100 && renderRowDesc(item_1, item_2, item_3)} </span>
                {/* <span style={{fontSize:"12px", float:"right"}}> From 20.64 EUR/day</span> */}
                <div style={{ display: "flex", justifyContent: "space-between" }}>{counter > 100 && <><span style={{fontSize:"12px"}}>Too many cars, pick lowel locality level! </span></>} </div>
              </div>

                {/* <span><div>{counter > 100 &&<div> From 20.64 EUR/day</div>}</div></span> */}
            
            </Col>
          </Row>
        </Col>
        <Col md="2" sm="2" className={`${styles.RightCityIcon} col-2 no-padding`}>
          <img styles={styles.list_icon} alt="icon" src={icon} />
        </Col>
      </Row>
    </div>
  );
};

export const List = ({ data, handleBookNow, closeSearchList, isLoading }) => {
  return (
    <Scrollbars style={{ width: 560, height: 500 }} className={styles.searchResultbox}>
      {data &&
        data.map((item) => (
          <ListItem
            location={item}
            _handleBookNow={handleBookNow}
            _closeSearchList={closeSearchList}
            key={item.code}
          />
        ))}
      {isLoading && (
        <div className={styles.searchBarLoader_container}>
          <PropagateLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={"#035372"}
            loading={isLoading}
          />
        </div>
      )}
    </Scrollbars>
  );
};
