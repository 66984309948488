import React from "react";
import { Card, CardImg, CardBody } from "reactstrap";

import styles from "./styles.module.scss";

const CardHeader = ({ src, title, alt }) => (
  <div className={styles.placesTitleImage}>
    <CardImg top src={src} alt={alt} />
    <h2 className={styles.cardHeader_title}>{title}</h2>
  </div>
);

const CardDetail = ({ data }) => {
  let host = window.location.host;
  return (
    <CardBody className={styles.placeCardcardbody}>
      {data &&
        data.map((val, idx) => {
          return (
            <a
              href={
                host.startsWith("dev.")
                  ? val.link
                  : host.startsWith("localhost")
                  ? val.link
                  : val.link.replace("dev.", "")
              }
              className={styles.cardDesc_row}
              key={idx}
            >
              <strong>{val.place}</strong>
              {/*<p>from {val.price}</p>*/}
            </a>
          );
        })}
    </CardBody>
  );
};

export const PlaceCard = ({ title, imgSrc, alt, cardDetails }) => (
  <Card className={styles.placeCard_container} style={{ border: "none" }}>
    <CardHeader title={title} src={imgSrc} alt={alt} />
    <CardDetail data={cardDetails} />
  </Card>
);

export const HorizontalRow = () => <div className={styles.horizontal_row} />;
