import React from "react";
import { Container, Row, Col } from "reactstrap";

import { PlaceCard, HorizontalRow } from "./placeCardUtils";
import BalkanPic from './../../assets/balkan.jpg';
import NorthEuropePic from './../../assets/north-europe.jpg';
import GlobalPic from './../../assets/pic-global.jpg';
import styles from "./styles.module.scss";

export const PlaceCardComponent = ({ place_data }) => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 767) {
    place_data.splice(2, 3);

  }

  let ImageBackground = (img) => {
    if (img.startsWith("balkan")) {
      return BalkanPic;
    } else if (img.startsWith("north")) {
      return NorthEuropePic;
    } else {
      return GlobalPic;
    }
  }

  return (
    <section className={styles.placeCardRow}>
      <Container>
        <Row>
          <Col md="12">
            <div className={styles.popularPlacesSection_head}>
              <h1 className={styles.title}>Popular places</h1>
              <span>
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.popularPlacesBlogCards_container}>
          <Row className={styles.width_100}>
            {place_data &&
              place_data.map((val, idx) => (
                <Col md="4" xs="6" key={idx}>
                  <PlaceCard
                    imgSrc={ImageBackground(val.imgSrc)}
                    title={val.title}
                    alt={val.alt}
                    cardDetails={val.cardDetails}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </Container>
      <HorizontalRow />
    </section>
  );
};
