import { BookingDetail } from "./bookingDetail";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const mapStateToProps = ({ searchResult }) => ({ searchResult });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export let BookingDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingDetail);
