import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { List } from "./List";
import { SearchInput } from "./searchBarUtils";
import { DatePicker } from "./../../DateRangePicker";
import MooseIcon from "../../../assets/moose-big.png";
import styles from "./styles.module.scss";
import _ from "lodash";

export const SearchBar = (props) => {
  const {
    searchStation,
    selectPickUpStation,
    searchParams: { stations, isLoading },
    searchResult: { selectedCar },
  } = props;
  const [searchText, setSearchText] = useState("");
  const [isListOpen, setListVisibility] = useState(true);
  const [focusedInput, setfocusedInput] = useState();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleBookNow = (code) => {
    setfocusedInput("startDate");
    selectPickUpStation(code);
  };

  const closeSearchList = () => setListVisibility(false);

  const handleSearchText = (evt) => {
    setListVisibility(true);
    setSearchText(evt.target.value);
    if (evt.target.value && evt.target.value?.length > 2) {
      searchStation(evt.target.value, "");
    }
  };

  const renderHeaderTitle = () => {
    if (pathname.startsWith("/bookingDetail")) {
      return `${selectedCar && selectedCar.title} for ${selectedCar &&
        selectedCar.total_days} days`;
    } else if (pathname.startsWith("/contact")) {
      return "Contact us";
    } else if (pathname.startsWith("/account")) {
      return "My Account";
    } else if (pathname.startsWith("/resetPassword")) {
      return "Reset Password";
    } else if (pathname.startsWith("/helpsupport")) {
      return "Help & Support";
    } else if (pathname.startsWith("/vehicleImages")) {
      return "Checkin & Checkout Booking Pictures";
    } else {
      return "Ask the moose for rentals";
    }
  };

  const result = [];
  const map = new Map();
  for (const item of stations) {
    if (!map.has(item.code)) {
      map.set(item.code, true); // set any value to Map
      result.push(item);
    }
  }

  return (
    <div className={styles.bannerTile}>
      <div className={styles.mooseImage}>
        <img src={MooseIcon} alt="Moose" className={`${styles.mooseImageSet} img-fluid`} />
      </div>
      {pathname.startsWith("/bookingDetail") ? (
        _.isEmpty(selectedCar) ? (
          <h1 className={styles.title}>...</h1>
        ) : (
          <h1 className={styles.title}>{renderHeaderTitle()}</h1>
        )
      ) : (
        <h1 className={styles.title}>{renderHeaderTitle()}</h1>
      )}
      {!(
        pathname.startsWith("/bookingDetail") ||
        pathname.startsWith("/contact") ||
        pathname.startsWith("/account") ||
        pathname.startsWith("/helpsupport") ||
        pathname.startsWith("/resetPassword") ||
        pathname.startsWith("/vehicleImages")
      ) && (
        <div className="car_search">
          <SearchInput
            {...props}
            searchText={searchText}
            CountryName= {''}
            handleSearchText={handleSearchText}
          />
          <div className={styles.CarSearchList}>
            {searchText && isListOpen && (
              <List
                data={result}
                isLoading={isLoading}
                handleBookNow={handleBookNow}
                closeSearchList={closeSearchList}
              />
            )}
            {focusedInput && (
              <DatePicker
                focusedInput={focusedInput}
                setfocusedInput={setfocusedInput}
                searchProps={props}
                clearSearchText={setSearchText}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
