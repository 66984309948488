import React from "react";
import { Button } from "reactstrap";

import styles from "./styles.module.scss";

const ModalButton = (props) => {
  return (
    <Button className={styles.modalBtn} {...props}>
      {props.label}
    </Button>
  );
};

const formikConfig_Login = {
  initialValues: {
    email: "",
    password: "",
    checkbox_checked: false,
  },
  validate: (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    }
    return errors;
  },
};

const formikConfig_ForgotPassword = {
  initialValues: {
    email: "",
  },
  validate: (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  },
};

const formikConfig_Signup = {
  initialValues: {
    name: "",
    username: "",
    last_name: "",
    contact: "",
    email: "",
    password: "",
    password_confirmation: "",
    country_of_residence: "",
    license_number: "",
    license_expiry_date: "",
    license_issue_date: "",
    license_issue_country: "",
    birth_date: "",
  },
  validate: (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Password length should be 6 ";
    }
    if (!values.last_name) {
      errors.last_name = "Required";
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "Required";
    } else if (values.password !== values.password_confirmation) {
      errors.password_confirmation = "Password no match";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.contact) {
      errors.contact = "Required";
    }
    if (!values.country_of_residence) {
      errors.country_of_residence = "Required";
    }
    // if (!values.license_issue_country) {
    //   errors.license_issue_country = "Required";
    // }
    // if (!values.license_number) {
    //   errors.license_number = "Required";
    // }
    // if (!values.license_expiry_date) {
    //   errors.license_expiry_date = "Required";
    // }
    // if (!values.license_issue_date) {
    //   errors.license_issue_date = "Required";
    // }
    if (new Date(values.license_expiry_date) <= new Date(values.license_issue_date)) {
      errors.license_expiry_date = "Expiry date should after issue date";
    }
    if (!values.phonecode) {
      errors.phonecode = "Required";
    }
    if (new Date().getFullYear() - new Date(values.birth_date).getFullYear() < 20) {
      errors.birth_date = "Your age should not be less than 20";
    }
    if (!values.birth_date) {
      errors.birth_date = "Required";
    }

    return errors;
  },
};

export { ModalButton, formikConfig_Login, formikConfig_Signup, formikConfig_ForgotPassword };
