import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { setUserCurrency,setUserCountry } from "./../../store/actions/config.action";
import { HeaderBottom } from "./HeaderBottom";
import { TopBar } from "./TopBar";
import { Navigation } from "./Navigation";
import { SearchBar } from "./SearchBar";
import { PartnerLogos } from "./../index";
import styles from "./styles.module.scss";
import {BASE} from "../../config/api";
import LazyLoad from 'react-lazyload';
import MooseIcon from "../../assets/moose-big.png";
export const Header = (props) => {
  const { pathname } = useLocation();
  const location = pathname.startsWith('/location');
  const Dispatch = useDispatch();
  const { currencies } = useSelector((state) => state.config);
  useEffect(() => {
    const setUserData = () => {
      Dispatch(
        setUserCountry(
          {
            code: "ee",
            country: "Estonia",
            flag:""
          }
        )
      );
    };
    setUserData();
  }, []);
  useEffect(() => {
  
  }, [pathname]);
  useEffect(() => {
    const fetchIp = () => {
      axios.get("https://api.ipify.org/?format=json").then((res) => {
        axios.get(`${BASE}/getIPDetails/${res.data.ip}`).then((result) => {
          // let user_currency = currencies.find((v) => v.country_code === result.data.country_code);
          Dispatch(
            setUserCurrency(
              false
                ? false
                : {
                    id: "EUR",
                    country_code: result.data.country_code,
                    sign: "€",
                    name: "EUR",
                  }
            )
          );
          Dispatch(
            setUserCountry(
              false
              ? false
              :{
                    code: result.data.country_code,
                    country: result.data.country_name,
                    flag:""
                }
            )
          );
        });
      });
    };
  
    fetchIp();
  }, []);
  

  return (
      <div
      className={`
        ${styles.header_container}
        ${pathname === "/results" && styles.header_result_container}
        ${(pathname.startsWith("/bookingDetail") ||
          pathname.startsWith("/resetPassword") ||
          pathname.startsWith("/account") ||
          pathname === "/helpsupport" ||
          pathname === "/contact") &&
          styles.header_result_container}
        `}
    >
      <TopBar {...props} />
      <LazyLoad height={200}>
      <div className={styles.header_banner}>
        <Navigation {...props} />
        <SearchBar {...props} />
        {(pathname === "/" ||
          !(
            pathname.startsWith("/bookingDetail") ||
            pathname.startsWith("/resetPassword") ||
            pathname === "/contact" ||
            pathname === "/results" ||
            pathname === "/helpsupport" ||
            pathname.startsWith("/account")
          )) && <HeaderBottom />}
      </div>
      </LazyLoad>
      {(pathname === "/" ||
        !(
          pathname.startsWith("/bookingDetail") ||
          pathname.startsWith("/resetPassword") ||
          pathname === "/contact" ||
          pathname === "/helpsupport" ||
          pathname === "/results" ||
          pathname.startsWith("/account") ||
          pathname.startsWith("/vehicleImages")
        )) && <PartnerLogos />}
    </div>
    );
};
