import React, { useEffect, Fragment, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { Button} from "reactstrap";
import { useDispatch } from "react-redux";
import { NavLink as Link, useHistory } from "react-router-dom";
import {
  PlaceCardComponent,
  BlogCardComponent,
  MobileAppSection,
  BlogCardSlider,
} from "./../../components";
import { setLocationHeaderStatus } from "../../store/actions/config.action";
import { place_data } from "./homeUtils";

// const BlogCardComponent = lazy(() => import('./../../components/BlogCard'));
// const BlogCardSlider = lazy(() => import('./../../components/BlogCard'));

export const Home = ({
  resetSearchParams,
  resetSearchPanel,
  resetBookingDetails,
  setFromUrlParams,
  config: { category_1, category_2, category_3 },
}) => {
  const { t } = useTranslation();
  let Width = window.innerWidth;
  const Dispatch = useDispatch();
  const history = useHistory();
  const handleClick = name => () => {
    Dispatch(setLocationHeaderStatus(true));
    //let name = text.toLowerCase();
    history.push('/location/'+ name);

  }

  useEffect(() => {
    ReactGA.initialize({ trackingId: "GTM-TS3HNHM" });
    ReactGA.pageview(window.location.pathname + window.location.search);
    setFromUrlParams(true);
    resetSearchParams();
    resetSearchPanel();
    resetBookingDetails();
  }, []);
  return (
    <React.Fragment>
      <PlaceCardComponent place_data={place_data} />
      <MobileAppSection />
    {Width <= 600?(
      // <Suspense fallback={<div>Loading...</div>}>
        <Fragment>

        <BlogCardSlider
        blogs_data={category_1}
        title={"Travelguides"}
        link="https://blog.rentalmoose.com/category/travel-guides"
        showMoose
        />

        <BlogCardSlider
        blogs_data={category_2}
        title="Car renting tips"
        link="https://blog.rentalmoose.com/category/pro-tips"
        />
        <BlogCardSlider
        blogs_data={category_3}
        title="About the Rentalmoose"
        link="https://blog.rentalmoose.com/category/rentalmoose-news/"
        />
        </Fragment>
      // </Suspense>
    ): (

      <Fragment>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
        <BlogCardComponent
        blogs_data={category_1}
        title={"Travel guides"}
        link="https://blog.rentalmoose.com/category/travel-guides"
        showMoose
        />
      {/* </Suspense>   */}
      {/* <Suspense fallback={<div>Loading...</div>}> */}
        <BlogCardComponent
        blogs_data={category_2}
        title="Car renting tips"
        link="https://blog.rentalmoose.com/category/pro-tips"
        />

        {/* </Suspense> */}
        {/* <Suspense fallback={<div>Loading...</div>}>   */}
        <BlogCardComponent
        blogs_data={category_3}
        title="About the Rentalmoose"
        link="https://blog.rentalmoose.com/category/rentalmoose-news/"
        />
        {/* </Suspense> */}
        <div className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5f68cd64ae88d40001e6b166" data-style-height="40" data-style-width="100%" data-theme="light">
        <a href="https://uk.trustpilot.com/review/rentalmoose.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <div>
       
        </div>
      </Fragment>
    )}
    </React.Fragment>
  );
};
