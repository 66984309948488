import React, { useRef } from "react";
import { Row, Col, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import { showModal } from "../../store/actions/modal.action";
import mailIcon from "./../../assets/mailsmall-icon.svg";
import supportIcon from "./../../assets/supportwhite-icon.svg";
import fabIcon from "./../../assets/fb.svg";
import instaIcon from "./../../assets/inst.svg";
import styles from "./styles.module.scss";
import { setLocationHeaderStatus } from "./../../store/actions/config.action";
const ChatSupportBtn = () => (
  <div className={styles.chatSupportBtn_container}>
    <img src={supportIcon} alt="chat support" />
    <span>Chat Support</span>
  </div>
);

const FooterSocialContainer = () => (
  <div className={styles.footer_social_container}>
    <div className={styles.footer_socialIcon_container}>
      <a target="_blank" href="https://www.facebook.com/rentalmoose/ ">
        <img src={fabIcon} alt="Facebook link" className={styles.socialIcon}/>
      </a>
      <a target="_blank" href="https://www.instagram.com/rentalmoose/">
        <img src={instaIcon} alt="Instagram link" className={styles.socialIcon}/>
      </a>
    </div>
  </div>
);

export const FooterBanner = ({ handleEmailSubscribe }) => {
  const inputRef = useRef();
  return (
    <Col className={styles.footer_banner_container}>
      <Container className={styles.footer_banner}>
        <Row className="w-100 no-margin">
          <Col md="6" className="col-12">
            <div className={styles.footer_heading}>
              <h1>Best offers and travel guides to your inbox</h1>
              <p>Subscribe for Rentalmoose updates</p>
            </div>
          </Col>
          <Col md="6" className="col-12 no-padding">
            <div className={styles.footer_email_subs}>
              <input ref={inputRef} type="email" placeholder="your e-mail" onKeyPress={e => {
                if (e.charCode == 13) {
                  e.preventDefault();
                  handleEmailSubscribe(inputRef.current.value);
                  inputRef.current.value = "";
                }
              }} />
              <img
                className={styles.socialIcon}
                src={mailIcon}
                alt="mail icon"
                onClick={() => { handleEmailSubscribe(inputRef.current.value); inputRef.current.value = ""; }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export const FooterLinkContainer = () => {
  const isAuthenticated = useSelector(state => state.auth.isUserLoggedIn);
  const Dispatch = useDispatch();
  const { push } = useHistory();

  const handleMyBooking = () => {
    if (isAuthenticated) {
      push("/account/bookings");
    } else {
      Dispatch(showModal("loginModal"));
    }
  };
  const handleClick = (e) => {
    Dispatch(setLocationHeaderStatus(false));
  };
  return (
    <div className={styles.footer_link_container}>
      <Container>
        <Row>
          <Col md="3">
            <ul>
              <li>
                <a href="https://blog.rentalmoose.com/terms-and-conditions/">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="https://blog.rentalmoose.com/privacy-policy/">
                  Privacy Notice
                </a>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <ul>
              <li>
                <a href="https://blog.rentalmoose.com">Blog</a>
              </li>
              <li>
                <a onClick={handleMyBooking} href="javasscript:void(0);">
                  View Bookings
                </a>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <ul>
              <li>
                <a href="https://blog.rentalmoose.com/about/">About Us</a>
              </li>
              <li>
                <NavLink to="/contact" onClick={handleClick}>Contact Us</NavLink>
              </li>
              <li>
                <a href="https://blog.rentalmoose.com/partnership-inquiries/">
                  Supply Partner Enquiry
                </a>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <FooterSocialContainer />
          </Col>
        </Row>
        <Row>
          <Col md="4" className={styles.footer_copyRight_container}>
            <span>Copyright {new Date().getFullYear()} Rentalmoose</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
