import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SearchPanel } from "./searchPanel";
import {
  getCars,
  getAvailableCars,
  selectEndDate,
  setActiveMarker,
  selectStartDate,
  selectDriverSeniority,
  selectPickUpStation,
  toggleSeeResultOnMap,
  selectDropOffStation,
  toggleSameDropOffStation,
  toggleSupplierFilter,
  handleSupplierLoad,
  sortResults,
  carTypeFilter,
  setMoreOption,
  toggleSearchMoreOptions,
  setBooknowClicked
} from "./searchPanel.action";
export { SearchPanelReducer, SearchParamsReducer } from "./searchPanel.reducer";
export { SearchPanelEpic } from "./searchPanel.epic";
export * from "./searchPanel.action";

const mapStateToProps = ({ modalState, searchParams, searchResult, config }) => ({
  modalState,
  searchParams,
  searchResult,
  config
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCars,
      getAvailableCars,
      selectEndDate,
      selectStartDate,
      selectDriverSeniority,
      setActiveMarker,
      selectPickUpStation,
      toggleSeeResultOnMap,
      selectDropOffStation,
      toggleSameDropOffStation,
      toggleSearchMoreOptions,
      handleSupplierLoad,
      toggleSupplierFilter,
      sortResults,
      setMoreOption,
      carTypeFilter,
      setBooknowClicked
    },
    dispatch
  );

export let SearchPanelComponent = connect(mapStateToProps, mapDispatchToProps)(SearchPanel);
