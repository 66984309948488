import {
  SET_DRIVER_DETAILS,
  CONFIRM_CAR_BOOKING,
  SET_COUPON_VALUE,
  RESET_COUPON_VALUE,
  CONFIRM_CAR_BOOKING_SUCCESS,
  CONFIRM_CAR_BOOKING_FAILED,
  SET_WORKING_STEP_ID,
  RESET_BOOKING_DETAILS,
  SET_TERMS_AND_CONDITION,
  SET_SUPPLIER_TERMS_AND_CONDITION,
} from "./stepper.action";

const BOOKING_DETAIL_STATE = {
  driverDetails: {
    first_name: "",
    surname: "",
    phone_number: "",
    email: "",
    email_checked: false,
  },
  paymentDetails: {},
  workingStepId: 1,
  isLoading: false,
  isError: false,
  message: "",
  success: false,
  bookingVoucher: {},
  isTermsAndConditonChecked: false,
  isSupplierTermsAndConditonChecked: false,
  discountAmount:0,
  couponCode:'',
  
};

export const BookingDetailReducer = (state = BOOKING_DETAIL_STATE, action) => {
  switch (action.type) {
    case SET_DRIVER_DETAILS:
      return { ...state, driverDetails: action.payload };
      case SET_COUPON_VALUE:
        let data = action.payload.response.data.data;
        let type = data.type;
        let currency = action.payload.currency;
        let showCouponAmount, discountPrice ;
        let int_total = action.payload.amount;
        let total = action.payload.pay_calculate;
        if(type ==="fixed"){
          if(data.discount > (total / 2))
          {
            total = total / 2;
            discountPrice = total
            showCouponAmount = (currency + '' + total.toFixed(2));
          } else {
            discountPrice = data.discount;
            total = total - data.discount;
            showCouponAmount = (currency + '' + data.discount);
          }
          
        }else{
          if(data.discount > 50)
          {
            let val = (total / 100) * 50;
            discountPrice = val;
            total = total - val;
            showCouponAmount = ("50 %");
          } else {
            let val = (total / 100) * data.discount;
            discountPrice = val;
            total = total - val;
            showCouponAmount = (data.discount+" %");
          }
          
        }
        return {
          ...state,
          discountAmount:int_total,
          payNowdiscount:total.toFixed(2),
          couponCode:data.coupon_code,
          discountPrice:discountPrice.toFixed(2),
          showCouponAmount:showCouponAmount
        };
      case RESET_COUPON_VALUE:
        let total1 = action.payload.pay_calculate;
        return {
          ...state,
          discountAmount:0,
          discountPrice:0,
          payNowdiscount:total1,
          couponCode:''
        };
    case CONFIRM_CAR_BOOKING:
      return {
        ...state,
        paymentDetails: action.payload,
        isLoading: false,
        isError: false,
        message: "",
        success: false,
      };
    case CONFIRM_CAR_BOOKING_SUCCESS:
      return {
        ...state,
        bookingVoucher: action.payload,
        workingStepId: 1,
        isLoading: false,
        isError: false,
        message: action.message,
        success: true,
      };
    case CONFIRM_CAR_BOOKING_FAILED:
      return {
        ...state,
        message: action.message,
        isLoading: false,
        isError: true,
        success: false,
      };
    case SET_WORKING_STEP_ID:
      return {
        ...state,
        workingStepId: action.payload,
      };
    case SET_TERMS_AND_CONDITION:
      return {
        ...state,
        isTermsAndConditonChecked: action.payload,
      };
    case SET_SUPPLIER_TERMS_AND_CONDITION:
      return {
        ...state,
        isSupplierTermsAndConditonChecked: action.payload,
      };
    case RESET_BOOKING_DETAILS:
      return {
        driverDetails: {
          first_name: "",
          surname: "",
          phone_number: "",
          email: "",
          email_checked: false,
        },
        paymentDetails: {},
        workingStepId: 1,
        isLoading: false,
        isError: false,
        message: "",
        success: false,
        bookingVoucher: {},
      };
    default:
      return state;
  }
};
