import React from "react";
import { Input } from "reactstrap";

import CartoonIcon from "../../../assets/head.svg";
import styles from "./styles.module.scss";

export const BannerIcon = () => {
  return (
    <div className={styles.BannerAvator}>
      <CartoonIcon />
    </div>
  );
};

export const SearchInput = ({
  searchText,
  countryName,
  handleSearchText,
}) => {
  return (
    <Input
      style={{ padding: 30 }}
      placeholder="Where do you begin your journey? Type location"
      value={searchText}
      onChange={(e) => handleSearchText(e)}
    />
  );
};
