export const SET_DRIVER_DETAILS = "SET_DRIVER_DETAILS";

export const CONFIRM_CAR_BOOKING = "CONFIRM_CAR_BOOKING";
export const CONFIRM_CAR_BOOKING_SUCCESS = "CONFIRM_CAR_BOOKING_SUCCESS";
export const CONFIRM_CAR_BOOKING_FAILED = "CONFIRM_CAR_BOOKING_FAILED";

export const RESET_BOOKING_DETAILS = "RESET_BOOKING_DETAILS";
export const SET_WORKING_STEP_ID = "SET_WORKING_STEP_ID";
export const SET_TERMS_AND_CONDITION = "SET_TERMS_AND_CONDITION";
export const SET_SUPPLIER_TERMS_AND_CONDITION = "SET_SUPPLIER_TERMS_AND_CONDITION";
export const SET_COUPON_VALUE = 'SET_COUPON_VALUE';
export const RESET_COUPON_VALUE = 'RESET_COUPON_VALUE';

export const setDriverDetials = (payload) => ({
  type: SET_DRIVER_DETAILS,
  payload,
});

export const confirmCarBooking = (payload, carId, push, isUserLoggedIn, token, confirmCardPayment, paymentClientSecret, paymentMethod, setErrorMessage, setBusy) => ({
  type: CONFIRM_CAR_BOOKING,
  payload,
  carId,
  push,
  isUserLoggedIn,
  token,
  confirmCardPayment,
  paymentClientSecret,
  paymentMethod,
  setErrorMessage,
  setBusy
});

export const setCouponState = payload => ({
  type: SET_COUPON_VALUE,
  payload,
});
export const resetCouponState = payload => ({
  type: RESET_COUPON_VALUE,
  payload,
});

export const setWorkingStepId = (payload) => ({
  type: SET_WORKING_STEP_ID,
  payload,
});

export const setTermsAndCondition = (payload) => ({
  type: SET_TERMS_AND_CONDITION,
  payload,
});
export const setSupplierTermsAndCondition = (payload) => ({
  type: SET_SUPPLIER_TERMS_AND_CONDITION,
  payload,
});

export const resetBookingDetails = () => ({
  type: RESET_BOOKING_DETAILS,
});
