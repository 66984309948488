import {
  GET_BOOKED_BOOKINGS,
  GET_BOOKED_BOOKINGS_FAILED,
  GET_BOOKED_BOOKINGS_SUCCESS,
  GET_EXPIRED_BOOKINGS,
  GET_EXPIRED_BOOKINGS_FAILED,
  GET_EXPIRED_BOOKINGS_SUCCESS,
  GET_BOOKING_DETAIL,
  GET_BOOKING_DETAIL_FAILED,
  GET_BOOKING_DETAIL_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  CLEAR_PROFILE_DATA,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILED,
  DELETE_BOOKING_PHOTOS_BY_ID
} from "./../actions/account.action";

const INITIAL_STATE = {
  bookedBookings: [],
  expiredBookings: [],
  selectedBooking: {},
  isLoading: false,
  success: false,
  isError: false,
  message: ""
};

export const AccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BOOKED_BOOKINGS:
      return {
        ...state,
        bookedBookings: [],
        isLoading: true,
        isError: false,
        message: ""
      };
    case GET_BOOKED_BOOKINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookedBookings: action.payload,
        message: action.message
      };
    case GET_BOOKED_BOOKINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case GET_EXPIRED_BOOKINGS:
      return {
        ...state,
        bookedBookings: [],
        isLoading: true,
        isError: false,
        message: ""
      };
    case GET_EXPIRED_BOOKINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expiredBookings: action.payload,
        message: action.message
      };
    case GET_EXPIRED_BOOKINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case GET_BOOKING_DETAIL:
      return { ...state, isLoading: true, isError: false, message: "" };
    case GET_BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedBooking: action.payload,
        message: action.message
      };
    case GET_BOOKING_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        success: false
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        success: true,
        success: true,
        message: action.message
      };
    case UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        isError: true,
        message: action.message
      };
    case CANCEL_BOOKING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedBooking: {
          ...state.selectedBooking,
          booking_status: action.payload.booking_status
        },
        bookedBookings: state.bookedBookings.map(val => {
          if (val.id === action.payload.id) {
            return {
              ...val,
              booking_status: action.payload.booking_status
            };
          }
          return val;
        }),
        message: action.message
      };
    case CANCEL_BOOKING_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case DELETE_BOOKING_PHOTOS_BY_ID:
      return {
        ...state,
        selectedBooking: {
          ...state.selectedBooking,
          booking_photos: state.selectedBooking.booking_photos.filter(
            pic => pic.id !== action.id
          )
        }
      };
    case CLEAR_PROFILE_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
