import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import Swal from 'sweetalert2';

import {
  UPLOAD_VEHICLE_IMAGES,
  UPLOAD_VEHICLE_IMAGES_FAILED,
  UPLOAD_VEHICLE_IMAGES_SUCCESS,
  DELETE_VEHICLE_IMAGE,
  DELETE_VEHICLE_IMAGE_FAILED,
  DELETE_VEHICLE_IMAGE_SUCCESS
} from "./../actions/vehicleImages.action";

import { DELETE_BOOKING_PHOTOS_BY_ID } from "./../actions/account.action";

import {
  BASE,
  UPLOAD_VEHICLE_IMAGES_API,
  DELETE_VEHICLE_IMAGES_API
} from "../../config/api";

export class VehicleImagesEpics {
  static uploadVehicleImages = action$ =>
    action$.pipe(
      ofType(UPLOAD_VEHICLE_IMAGES),
      switchMap(({ payload, token }) => {
        return new Observable(async observer => {
          try {
            const result = await axios.post(
              `${BASE}${UPLOAD_VEHICLE_IMAGES_API}`,
              payload,
              {
                headers: { Authorization: `Bearer ${token}` }
              }
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: UPLOAD_VEHICLE_IMAGES_SUCCESS,
                payload: data.data,
                message: data.msg
              });
              Swal.fire(
                '',
                'Thank you, your pictures are submitted. You can see your pictures under your account',
                'success'
              )
            } else {
              observer.next({
                type: UPLOAD_VEHICLE_IMAGES_FAILED,
                message: data.msg
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: UPLOAD_VEHICLE_IMAGES_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in uploading vehicle images."
            });
          }
        });
      })
    );
  static deleteVehicleImages = action$ =>
    action$.pipe(
      ofType(DELETE_VEHICLE_IMAGE),
      switchMap(({ payload, token, hideModal }) => {
        return new Observable(async observer => {
          try {
            const result = await axios.post(
              `${BASE}${DELETE_VEHICLE_IMAGES_API}`,
              payload,
              {
                headers: { Authorization: `Bearer ${token}` }
              }
            );
            const { data } = result;
            if (data.status) {
              observer.next({
                type: DELETE_VEHICLE_IMAGE_SUCCESS,
                message: data.msg
              });
              observer.next({
                type: DELETE_BOOKING_PHOTOS_BY_ID,
                id: data.id
              });
              hideModal();
            } else {
              observer.next({
                type: DELETE_VEHICLE_IMAGE_FAILED,
                message: data.msg
              });
              hideModal();
            }
          } catch (err) {
            observer.next({
              type: DELETE_VEHICLE_IMAGE_FAILED,
              message: "Error in deleting vehicle images."
            });
            hideModal();
          }
        });
      })
    );
}
