import { Locations } from "./location";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  resetSearchParams,
  resetSearchPanel,
  setFromUrlParams
} from "./../../components/SearchPanel";
import { resetBookingDetails } from "./../../components/Stepper";

const mapStateToProps = ({ config }) => ({ config });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { resetSearchParams, resetSearchPanel, resetBookingDetails, setFromUrlParams },
    dispatch
  );

const LocationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Locations);

export default LocationPage
