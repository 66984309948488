import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Collapse } from "reactstrap";

import logoOne from "./../../assets/logoOne.jpg";
import logoTwo from "./../../assets/logoTwo.jpg";
import logoThree from "./../../assets/logoThree.jpg";
import logoFour from "./../../assets/logoFour.jpg";
import logoFive from "./../../assets/logoFive.jpg";
import logoSix from "./../../assets/logoSix.jpg";
import { Button } from "./../../components";
import styles from "./styles.module.scss";

const Privacy = () => {
  const partnersLogo = [
    { url: logoOne },
    { url: logoTwo },
    { url: logoThree },
    { url: logoFour },
    { url: logoFive },
    { url: logoSix },
  ];
  const [sideBarCollapse, toggleAffiliatesCollapse] = useState(false);
  const [affilatesCollapse, toggleResultCollapse] = useState(false);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <section className={styles.privacypolicy}>
      <Container>
        <Row>
          <Col md="9">
            <h3 className={styles.title}>Privacy Notice</h3>
            <p>
              This Privacy Notice explains how we collect, use and protect any
              information about you. It also tells you how to get in touch if
              you have any questions, which we will be more than happy to
              answer. If you’re interested in what we do with cookies and
              similar technologies, please check out our Cookie Statement.
            </p>
            <p>
              We may change this Notice from time to time; so please check back
              here for any updates. We offer a range of services related to
              online car rental, including products and services such as
              insurance, through our own websites, mobile apps, emails and text
              messages (‘platforms’). We also do this through our business
              partners’ websites, social media and other platforms. This Notice
              applies to all the personal data we collect while we’re doing
              this, or when you contact us by email, live chat, phone or post.
            </p>
            <p>
              Our business partners should also visit our Privacy and
              <a
                href="javascript:void(0);"
                className="text-bold"
                color="primary"
              >
                &nbsp;Cookie Statement&nbsp;
              </a>
              for Business Partners to understand how personal data is processed
              as part of our business relationship.
            </p>
            <h3 className={styles.title}>Personal data you give us</h3>
            <p>
              We can’t help you make a booking or request a quote without
              certain information. When you do either of those things, we ask
              you for the information we need so we can give you what you want.
              This is pretty standard stuff and may include your name, age, date
              and place of birth and contact details (email, address and phone
              number). It can also include information about your passport,
              identity card and driving licence – and your loyalty programme
              number and payment information.
            </p>
            <p>
              In addition, we collect information from your computer when you
              use one of our platforms, even if you don’t make a booking. This
              may include your IP address, which browser you use, and your
              language settings. There are also situations where we receive
              information about you from third parties, or automatically collect
              other information. This is a basic overview of the information we
              collect.
            </p>
            <div className={styles.privacyLogo}>
              <Row>
                {partnersLogo.map((v, i) => (
                  <Col md="4" className="col-6">
                    <div className={styles.logoBox}>
                      <img src={v.url} alt="Logo" />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <h3 className={styles.title}>Privacy Notice</h3>
            <p>
              This Privacy Notice explains how we collect, use and protect any
              information about you. It also tells you how to get in touch if
              you have any questions, which we will be more than happy to
              answer.
            </p>

            <Row className={styles.privacyLink}>
              <Col md="4">
                <ul>
                  <li>
                    <a href="javasscript:void(0);">Offer Subscription</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Price Match Guarantee</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Notice</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Cookie Statement</a>
                  </li>
                </ul>
              </Col>
              <Col md="4">
                <ul>
                  <li>
                    <a href="javasscript:void(0);">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Careers</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Affiliate Programme</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Supply Partner Enquiry</a>
                  </li>
                </ul>
              </Col>
              <Col md="4">
                <ul>
                  <li>
                    <a href="javasscript:void(0);">About Us</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Contact Us</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">Help</a>
                  </li>
                  <li>
                    <a href="javasscript:void(0);">View Booking</a>
                  </li>
                </ul>
              </Col>
            </Row>
            <h3 className={styles.title}>Privacy Notice</h3>
            <p>
              This Privacy Notice explains how we collect, use and protect any
              information about you. It also tells you how to get in touch if
              you have any questions, which we will be more than happy to
              answer.
            </p>
            <p className={styles.grayBg}>
              Batumi is located in a convenient natural Black Sea bay and today
              it is not only the most important seaport of Georgia, but also the
              tourist capital of the country.
            </p>
            <h3 className={styles.title}>Personal data you give us</h3>
            <ul className={styles.bulletList}>
              <li>
                <p>
                  We can’t help you make a booking or request a quote without
                  certain information. When you do either of those things, we
                  ask you for the information we need so we can give you what
                  you want. This is pretty standard stuff and may include your
                  name, age, date and place of birth and contact details (email,
                  address and phone number). It can also include information
                  about your passport, identity card and driving licence – and
                  your loyalty programme number and payment information.
                </p>
              </li>
              <li>
                <p>
                  In addition, we collect information from your computer when
                  you use one of our platforms, even if you don’t make a
                  booking. This may include your IP address, which browser you
                  use, and your language settings. There are also situations
                  where we receive information about you from third parties, or
                  automatically collect other information. This is a basic
                  overview of the information we collect. Would you like to know
                  more?
                </p>
              </li>
            </ul>

            <Row>
              <Col md="12" className={styles.bookingButtons_container}>
                <Button blueBG>See Full Details</Button>
                <Button
                  style={{
                    background: "#cfcfcf",
                    borderColor: "transparent",
                    color: "#fff",
                    marginLeft: "15px",
                  }}
                >
                  Download PDF
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { Privacy };
export default Privacy;