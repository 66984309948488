import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { PaymentDetails } from "./paymentUtils";
import { askForPermissioToReceiveNotifications } from "../../../../push-notification";
import { setWorkingStepId } from "../../stepper.action";
export const PaymentStep = ({
  searchParams,
  auth,
  bookingDetail,
  searchResult,
  config,
  confirmCarBooking
}) => {
  const { search, state } = useLocation();
  const { push } = useHistory();
  const Dispatch = useDispatch();

  useEffect(() => {
    try {
      window.scroll({
        top: 600,
        left: 0,
        behavior: "smooth"
      });
    } catch (error) {
      window.scrollTo(600, 0);
    }
  });

  useEffect(() => {
    let params = new URLSearchParams(search);
    const checkin_station_code = params.get("checkin_station_code");
    const checkin_time = params.get("checkin_time");
    const checkout_station_code = params.get("checkout_station_code");
    const checkout_time = params.get("checkout_time");
    const contract_id = params.get("contract_id");
    const contract_margin = params.get("contract_margin");
    const currency = params.get("currency");
    const deposit = params.get("deposit");
    const duration_margin = params.get("duration_margin");
    const max_liab = params.get("max_liab");
    const rule_id = params.get("rule_id");
    const rule_margin = params.get("rule_margin");
    const vehicle_id = params.get("vehicle_id");
    if (state === undefined) {
      push({
        pathname: `/bookingDetail/${vehicle_id}/options`,
        search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&currency=${currency}&vehicle_id=${vehicle_id}`
      });
      Dispatch(setWorkingStepId(1));
    }
  }, []);

  let total_pay_now =
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.pay_now;
    let total_pay_later =  searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.pay_later;
    const { discountAmount } = useSelector(state => state.bookingDetail);
    if(discountAmount){
      total_pay_later = discountAmount-total_pay_now;
    }

  searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.extras
      .filter(
        v =>
          v.selected &&
          v.type === "internal" &&
          (v.remaining ? v.remaining >= 1 : true)
      )
      .map(v => {
        total_pay_now += v.count * Number(v.price_per_day);
      });

  let values = {
    checkin_station_code:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.checkin_station,
    checkout_station_code:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.checkout_station,
    country_code: config && config.selectedCurrency.country_code,
    checkin_time: searchParams && searchParams.startDate,
    checkout_time: searchParams && searchParams.endDate,
    extras:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.extras
        .filter(v => v.selected && (v.remaining ? v.remaining >= 1 : true))
        .map(x => {
          return {
            code: x.code,
            qty: x.count,
            rate: x.price_per_day,
            type: x.type
          };
        }),
    driver: {
      first_name: bookingDetail && bookingDetail.driverDetails.first_name,
      email: bookingDetail && bookingDetail.driverDetails.email,
      last_name: bookingDetail && bookingDetail.driverDetails.last_name,
      phone_number: bookingDetail && bookingDetail.driverDetails.phone_number,
      phonecode: bookingDetail && bookingDetail.driverDetails.phonecode,
      country: config && config.selectedCurrency.country_code, //previosuly it was this
      email_check: bookingDetail && bookingDetail.driverDetails.email_check,
      license_number:
        bookingDetail && bookingDetail.driverDetails.driver_license_number,
      age: bookingDetail && bookingDetail.driverDetails.driver_age,
      flight_number: bookingDetail && bookingDetail.driverDetails.flight_number
    },
    vehicle_id:
      searchResult && searchResult.selectedCar && searchResult.selectedCar.id,
    pay_now: total_pay_now,
    pay_later:total_pay_later,
    contract_id:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.contract_id,
    contract_margin:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.ontract_margin,
    rule_margin:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.rule_margin,
    duration_margin:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.duration_margin,
    rule_id:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.rule_id,
    total_amount:
      searchResult &&
      searchResult.selectedCar &&
      searchResult.selectedCar.total_amount,
    currency:
      config && config.selectedCurrency && config && config.selectedCurrency.id
  };

  let extras_Total = 0;
  let internal_Extras = 0;
  let external_Extras = 0;
  let total_pay_now_calculated = 0;
  let later_charges = 0;
  let car_charges = 0;

  searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.extras &&
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.extras
      .filter(v => v.selected && v.type === "internal")
      .map(v => {
        internal_Extras += v.count * Number(v.price_per_day);
      });

  searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.extras &&
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.extras
      .filter(v => v.selected && v.type === "external")
      .map(v => {
        external_Extras += v.count * Number(v.price_per_day);
      });

  total_pay_now_calculated =
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.pay_now + internal_Extras;
  extras_Total = internal_Extras + external_Extras;
  later_charges =
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.pay_later + external_Extras;
  car_charges =
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.pay_now + searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.pay_later;

  let price_breakdown_data = {
    pay_now:
      total_pay_now_calculated !== null && total_pay_now_calculated.toFixed(2),
    pay_later: later_charges !== null && later_charges.toFixed(2),
    total_amount: car_charges !== null && car_charges.toFixed(2),
    extras_total: extras_Total !== null && extras_Total.toFixed(2),
    service_charges: 0.99
  };

  return (
    <PaymentDetails
      values={values}
      auth={auth}
      vehicle_id={
        searchResult && searchResult.selectedCar && searchResult.selectedCar.id
      }
      handleConfirmBooking={confirmCarBooking}
      price_breakdown_data={price_breakdown_data}
    />
  );
};
