import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import HeaderLogo from "../../../../assets/head.svg";
import styless from "../../../Overlay/styles.module.scss";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { HorizontalBorder, Button, InputText, Overlay } from "../../../";
import { CustomStepRow } from "../stepsUtils";
import { PriceBreakDownContainer } from "../stepsUtils";
import styles from "./styles.module.scss";
import { askForPermissioToReceiveNotifications } from "../../../../push-notification";
import securePaymentIcon from "./../../../../assets/secure-payment-powered-by-stripe.jpg";
import {BASE} from "../../../../config/api";
import { useSelector } from "react-redux";

const PaymentDetails = ({
  values,
  auth: { isUserLoggedIn, token },
  vehicle_id,
  handleConfirmBooking,
  price_breakdown_data,
}) => {
  const { push } = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState({});
  const [paymentIntentData, setPaymentIntentData] = useState({});
  const [isBusy, setBusy] = useState(false);

  const [fcmToken, setFcmToken] = useState("");

  const getFcmToken = async () => {
    const token = await askForPermissioToReceiveNotifications();
    setFcmToken(token);
  };

  const { payNowdiscount } = useSelector(state => state.bookingDetail);
  let pay_now = values.pay_now;
  if(payNowdiscount)
  {
    pay_now = payNowdiscount;
  }
  const getPaymentIntent = async () => {
    const { data } = await axios.post(
      `${BASE}/payment-intent`,
      {
        amount: pay_now,
        currency: values.currency,
      }
    );
    setPaymentIntentData(data);
    return data;
  };

  useEffect(() => {
    getFcmToken();
    
  }, []);
  const { search } = useLocation();

  const handleSubmit = async () => {
    window.gtag("event", "Pay now have been pressed", {
      event_category: "Booking",
      event_label: "Pay now have been pressed"
    })
    setBusy(true);
    var div = document.createElement("div");
    div.className = styless.overlay_container;
    div.id = "loader";
    var img = document.createElement('img');
    img.src = HeaderLogo;
    img.width ="120";
    div.appendChild(img);
    document.getElementById("root").appendChild(div);
    let intent_data = await getPaymentIntent();
    
    let params = new URLSearchParams(search);
    let confirmCardPayment = stripe.confirmCardPayment;
    let cardNumberElement = elements.getElement(CardNumberElement);
    const paymentClientSecret = intent_data.client_secret;
    const paymentMethod = {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: `${values.driver.first_name} ${values.driver.last_name}`,
        },
      },
    };

    const updatedPayload = {
      ...values,
      payment_intent_id: paymentIntentData?.id,
      checkin_time: params
        .get("checkin_time")
        .split(" ")
        .join("+"),
      checkout_time: params
        .get("checkout_time")
        .split(" ")
        .join("+"),
      driver: { ...values.driver, fcm_token: fcmToken },
    };

    handleConfirmBooking(
      updatedPayload,
      vehicle_id,
      push,
      isUserLoggedIn,
      token,
      confirmCardPayment,
      paymentClientSecret,
      paymentMethod,
      setErrorMessage,
      setBusy
    );
  };
  return (
    <React.Fragment>
    
        <Container className={styles.driverPaymentSection}>
          <CustomStepRow
            heading="Payment details"
            hasRequiredFields
            isDriverPaymentStep
          >
            <Col md="8">
              <Row>
                <Col md="6">
                  <InputText
                    disabled
                    id="cardHolderName"
                    placeholder="Name"
                    name="name"
                    type="text"
                    label="Name on Card"
                    // value={`${values.driver.first_name} ${values.driver.last_name}`}
                  />
                </Col>
                <Col md="6">
                  <label className={styles.cardNumberLabel}>
                    Card number
                    <CardNumberElement
                      id="CardNumberElement"
                      className={`${styles.paymentInput} ${
                        errorMessage.code === "incomplete_number"
                          ? styles.paymentInputRedBorder
                          : ""
                      }`}
                      options={{
         
                        style: {
                          base: {
                            fontSize: "16px",
                            padding: "10px",
                            height: "56px",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                         
                        },
                      }}
                    />
                  </label>
                </Col>
                <Col md="6">
                  <label className={styles.paymentLabel}>
                    Expiry date*
                    <CardExpiryElement
                
                      id="card-expiry"
                      className={`${styles.paymentInput} ${
                        errorMessage.code === "incomplete_expiry"
                          ? styles.paymentInputRedBorder
                          : ""
                      }`}
                      options={{
                       
                        style: {
                          base: {
                            fontSize: "16px",
                            padding: "10px",
                            height: "56px",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                         
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                         
                        },
                      }}
                    />
                  </label>
                </Col>
                <Col md="6">
                  <label className={styles.paymentLabel}>
                    Security Code
                    <CardCvcElement
                 
                      id="card-csv"
                      className={`${styles.paymentInput} ${
                        errorMessage.code === "incomplete_cvc"
                          ? styles.paymentInputRedBorder
                          : ""
                      }`}
                      options={{
                        
                        style: {
                          base: {
                            fontSize: "16px",
                            padding: "10px",
                            height: "56px",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                        
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        
                        },
                      }}
                    />
                  </label>
                </Col>
                <Col md="6">
                  {errorMessage && (
                    <p
                      style={{
                        color: "red",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </Col>
              </Row>
            </Col>
          </CustomStepRow>
        </Container>
        <PriceBreakDownContainer {...price_breakdown_data} />
        <HorizontalBorder />
        <Container>
          <Row className={styles.booknowBtn_row}>
            <div className={`${styles.booknowBtn_container} col-md-12`}>
              <Button blueBG onClick={handleSubmit} >
                Book Now with No Card Fees
              </Button>

              <img src={securePaymentIcon} width="300px" />
              <p>
                Rentalmoose will never store your card details. <br />
                Payment gateway by STRIPE.
              </p>
            </div>
          </Row>
        </Container>
    
    </React.Fragment>
  );
};
export { PaymentDetails};
