import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Formik, Form } from "formik";

import { formikConfig_FeedbackForm } from "./feedbackFormUtils";
import { InputText, DropDownInput } from "../../components";
import tickIcon from "./../../assets/blueok-small.svg";
import styles from "./styles.module.scss";

const FeedbackForm = ({ handleSubmitContactForm, response }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    handleSubmitContactForm(values, setSubmitting)
  };

  return (
    <section>
      <Container className={styles.feedbackFormContainer} r>
        <Row>
          <Col md="12">
            <h3 className={styles.title}>Send Feedback</h3>
          </Col>
        </Row>
        <Formik
          enableReinitialize
          initialValues={formikConfig_FeedbackForm.initialValues}
          validate={formikConfig_FeedbackForm.validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting
          }) => (
              <Form>
                <div className={styles.feedbackForm}>
                  <Row>
                    <Col md="6">
                      <InputText
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name && errors.name}
                    </Col>
                    <Col md="6">
                      <InputText
                        label="E-mail"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && errors.email}
                    </Col>
                    <Col md="6">
                      <InputText
                        type="number"
                        label="Phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone && errors.phone}
                    </Col>
                    <Col md="6">
                      <InputText
                        type="text"
                        label="Subject"
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.subject && touched.subject && errors.subject}
                    </Col>
                    <Col md="12">
                      <InputText
                        type="textarea"
                        placeholder="Tell us what we can help you with"
                        rows="7"
                        label="Message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.message && touched.message && errors.message}
                    </Col>
                    <Col md="12">
                      <Button
                        color="primary"
                        type="submit"
                        className={`${styles.feedbackFormBtn} w-100`}
                        disabled={isSubmitting}
                      >
                        Submit
                    </Button>
                      {response && response.success && <p className={styles.msgSentMessage}>
                        <img src={tickIcon} alt="Tik" /> Thank you, we'll get in
                        touch shortly!
                    </p>}
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
        </Formik>
      </Container>
    </section>
  );
};

export { FeedbackForm };
