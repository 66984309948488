import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import _ from "lodash";
import {
  GET_CARS,
  GET_CARS_FAILED,
  GET_CARS_SUCCESS,
  GET_AVAILABLE_CARS,
  GET_AVAILABLE_CARS_SUCCESS,
  GET_AVAILABLE_CARS_FAILED,
  LOAD_MORE_CARS,
  LOAD_MORE_CARS_SUCCESS,
  LOAD_MORE_CARS_FAILED,
  SEARCH_STATION,
  SEARCH_STATION_FAILED,
  SEARCH_STATION_SUCCESS,
  BOOK_CAR,
  BOOK_CAR_FAILED,
  BOOK_CAR_SUCCESS,
  SET_BOOK_NOW_CLICKED,
  GET_AVAILABLE_CARS_COMPLETED,
  GET_AVAILABLE_CARS_RESULT_FAILED,
  setSuppliersProgress,
  SET_BOOK_NOW_CLICKED_FROM_OPTIONS,
  SET_PROCEED_TO_PAYMENT_CLICKED,
} from "./searchPanel.action";
import { FETCH_CAR_TYPES_SUCCESS } from "./../../store/actions/config.action";
import {
  BASE,
  SEARCH_STATIONS,
  SEARCH_CARS,
  GET_CAR_DETAILS,
  GET_AVAILABLE_CAR_DETAILS,
} from "../../config/api";
let getVehicleInterval;
let stopCount = 0;
let cancelToken;

export class SearchPanelEpic {
  static getCars = (action$) =>
    action$.pipe(
      ofType(GET_CARS),
      switchMap(({ payload, push }) => {
        return new Observable(async (observer) => {
          try {
            observer.next({
              type: GET_AVAILABLE_CARS_COMPLETED,
              payload: 0,
            });
            console.log(payload)
            const { data } = await axios.post(`${BASE}${SEARCH_CARS}`, payload);
            if (data.data.length) {
              const result = data.data.map((val) => {
                return {
                  ...val,
                  extras: val.extras.map((v) => {
                    return {
                      ...v,
                      selected: false,
                    };
                  }),
                };
              });

              observer.next({
                type: GET_CARS_SUCCESS,
                cars: result,
                suppliers: data.suppliers,
                supplier_ids: data.supplier_ids,
                type_ids: data.type_ids,
                supplier_respond: data.supplier_respond,
                vehicle_ids: data.vehicle_ids,
                message: data.message,
              });

              observer.next({
                type: FETCH_CAR_TYPES_SUCCESS,
                payload: data.car_types,
                message: "car type mapped",
              });
              if(payload.path_type) {
                push({
                  pathname: "/results",
                  search: `?checkin_station_code=${payload.checkin_station_code}&checkin_time=${payload.checkin_time}&checkout_time=${payload.checkout_time}&checkout_station_code=${payload.checkout_station_code}&country_code=${payload.country_code}`,
                  state: { from: "homePage" },
                });
              }
              if (
                payload.is_filters_applied &&
                payload.is_filters_applied === true
              ) {
                observer.next({
                  type: GET_AVAILABLE_CARS_COMPLETED,
                  payload: 100,
                });
                return;
              }
              let loaderCount = 10;
              getVehicleInterval = setInterval(() => {
                if (loaderCount !== 80) {
                  observer.next({
                    type: GET_AVAILABLE_CARS_COMPLETED,
                    payload: (loaderCount += 5),
                  });
                }
                observer.next({
                  type: GET_AVAILABLE_CARS,
                  payload,
                });
              }, 5000);
            } else {
              observer.next({
                type: GET_CARS_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_CARS_FAILED,
              message: errorMessage ? errorMessage : "Error in Search Cars",
            });
          }
        });
      })
    );

  static getAvailableCars = (action$, state$) =>
    action$.pipe(
      ofType(GET_AVAILABLE_CARS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          stopCount++;
          try {
            const searchResults = state$.value.searchResult;
            const { data } = await axios.post(
              `${BASE}${GET_AVAILABLE_CAR_DETAILS}`,
              {
                ...payload,
                count: searchResults.carList.length,
              }
            );
            const availaibleVehcileIds = data.data.map((i) => i.id);
            const carListIds = searchResults.carList.map((i) => i.id);

            if (stopCount === 4) {
              stopCount = 0;
              clearInterval(getVehicleInterval);
              observer.next({
                type: GET_AVAILABLE_CARS_COMPLETED,
                payload: 100,
              });
            }
            if (_.isEqual(carListIds, availaibleVehcileIds)) {
              stopCount = 0;

              clearInterval(getVehicleInterval);
              observer.next({
                type: GET_AVAILABLE_CARS_COMPLETED,
                payload: 100,
              });
            }
            if (searchResults.booknow_clicked) {
              stopCount = 0;

              clearInterval(getVehicleInterval);
            }
            if (data.data.length) {
              const result = data.data.map((val) => {
                return {
                  ...val,
                  extras: val.extras.map((v) => {
                    return {
                      ...v,
                      selected: false,
                    };
                  }),
                };
              });

              observer.next({
                type: GET_AVAILABLE_CARS_SUCCESS,
                cars: result.sort(),
                suppliers: data.suppliers,
                supplier_ids: data.supplier_ids,
                type_ids: data.type_ids,
                supplier_respond: data.supplier_respond,
                vehicle_ids: data.vehicle_ids,
              });
              if (searchResults.booknow_clicked) {
                clearInterval(getVehicleInterval);
              }
            } else {
              clearInterval(getVehicleInterval);
              observer.next({
                type: GET_AVAILABLE_CARS_RESULT_FAILED,
              });
              observer.next({
                type: GET_AVAILABLE_CARS_COMPLETED,
                payload: 100,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_AVAILABLE_CARS_FAILED,
              message: errorMessage ? errorMessage : "Error in Search Cars",
            });
            observer.next({
              type: GET_AVAILABLE_CARS_COMPLETED,
              payload: 100,
            });
            clearInterval(getVehicleInterval);
          }
        });
      })
    );

  static loadmoreCars = (action$) =>
    action$.pipe(
      ofType(LOAD_MORE_CARS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axios.post(`${BASE}${SEARCH_CARS}`, payload);
            if (data.data.length) {
              const result = data.data.map((val) => {
                return {
                  ...val,
                  extras: val.extras.map((v) => {
                    return {
                      ...v,
                      selected: false,
                    };
                  }),
                };
              });

              observer.next({
                type: LOAD_MORE_CARS_SUCCESS,
                cars: result,
                suppliers: data.suppliers,
                message: "Loading Cars Success",
              });
            } else {
              observer.next({
                type: LOAD_MORE_CARS_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: LOAD_MORE_CARS_FAILED,
              message: errorMessage ? errorMessage : "Error in loading Cars",
            });
          }
        });
      })
    );
  static bookCar = (action$) =>
    action$.pipe(
      ofType(BOOK_CAR),
      switchMap(({ payload, push }) => {
        return new Observable(async (observer) => {
          try {
            observer.next({
              type: SET_BOOK_NOW_CLICKED,
              payload: true,
            });
            observer.next({
              type: SET_BOOK_NOW_CLICKED_FROM_OPTIONS,
              payload: false,
            });
            observer.next({
              type: SET_PROCEED_TO_PAYMENT_CLICKED,
              payload: false,
            });
            const { data } = await axios.post(
              `${BASE}${GET_CAR_DETAILS}`,
              payload
            );
            if (data.data.length) {
              let car = data.data[0];
              let extras = car.extras.map((extra) => {
                if (extra.mandatory && extra.mandatory > 0) {
                  return {
                    ...extra,
                    selected: true,
                    count: 1,
                  };
                }
                return {
                  ...extra,
                  selected: false,
                  count: 1,
                };
              });
              observer.next({
                type: BOOK_CAR_SUCCESS,
                car: data.data[0],
                extras: extras,
              });
              const {
                checkin_station_code,
                checkin_time,
                checkout_station_code,
                checkout_time,
                contract_id,
                contract_margin,
                currency,
                deposit,
                duration_margin,
                max_liab,
                rule_id,
                rule_margin,
                vehicle_id,
                country_code,
              } = payload;
              push({
                pathname: `/bookingDetail/${payload.vehicle_id}/options`,
                search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&currency=${currency}&country_code=${country_code}&vehicle_id=${vehicle_id}`,
                state: { fromSearchResult: true },
              });
            } else {
              observer.next({
                type: BOOK_CAR_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: BOOK_CAR_FAILED,
              message: errorMessage,
            });
          }
        });
      })
    );
  static searchStations = (action$) =>
    action$.pipe(
      ofType(SEARCH_STATION),
      switchMap(({ text , countryName}) => {
        return new Observable(async (observer) => {
          //Check if there are any previous pending requests
          if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.");
          }
          //Save the cancel token for the current request
          cancelToken = axios.CancelToken.source();
          try {
            const { data } = await axios.post(
              `${BASE}${SEARCH_STATIONS}`,
              {
                search: text,
                location: countryName
              },
              { cancelToken: cancelToken.token }
            );
            if (data.data) {
              observer.next({
                type: SEARCH_STATION_SUCCESS,
                stations: data.data,
                message: data.message,
              });
            } else {
              observer.next({
                type: SEARCH_STATION_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            const errorMessage = err?.response?.data?.message;
            observer.next({
              type: SEARCH_STATION_FAILED,
              message: errorMessage ? errorMessage : "Error in Searh Stations",
            });
          }
        });
      })
    );

  
}
