import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";

import { getExpiredBookings } from "../../../../store/actions/account.action";
import { BookingListItem, MyAccountNavigation } from "./../../utils";

export const History = () => {
  const Dispatch = useDispatch();
  const { push } = useHistory();
  const { user, token } = useSelector((state) => state.auth);
  const { expiredBookings, isLoading, isError, message } = useSelector(
    (state) => state.UserAccount
  );

  useEffect(() => {
    if (!token) {
      push("/");
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    Dispatch(getExpiredBookings(user.id, token));
  }, []);

  return (
    <React.Fragment>
      <MyAccountNavigation />
      <Container>
        {expiredBookings &&
          expiredBookings.map((val, idx) => (
            <BookingListItem item={val} key={idx} />
          ))}
        {!isLoading && expiredBookings.length < 1 && <p>{message}</p>}
      </Container>
    </React.Fragment>
  );
};

export default History;