import React from "react";
import { Input, Tooltip } from "reactstrap";
import styles from "./styles.module.scss";

const InputText = (props) => {
  const { showTooltip = false } = props;

  return (
    <div className={styles.inputText_container}>
      {!showTooltip && <label>{props.label}</label>}
      {showTooltip && (
        <div>
          <p>
            <label>{props.label}</label>{" "}
            <span className="zmdi zmdi-help-outline" id="tool_tip"></span>
          </p>
          <Tooltip
            placement="right"
            isOpen={props.isToolTipOpen}
            target="tool_tip"
            toggle={props.toggleToolTip}
          >
            {props.tooltipText}
          </Tooltip>
        </div>
      )}

      <div className={styles.inputBox}>
        <Input {...props} />
      </div>
    </div>
  );
};

export { InputText };
