import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ConfirmationStep, DriverStep, OptionStep, PaymentStep } from "./Steps";
import { StepperHeader } from "./Header";
import styles from "./styles.module.scss";

const CompArray = [
  {
    options: OptionStep,
    driver: DriverStep,
    payment: PaymentStep,
    confirmation: ConfirmationStep,
  },
];

export const Stepper = (props) => {
  const {
    bookingDetail: {
      workingStepId,
      isTermsAndConditonChecked,
      isSupplierTermsAndConditonChecked,
    },
    searchResult: { carList },
  } = useSelector((state) => state);
  const {
    booknow_clicked_from_options,
    proceed_to_payment_clicked,
  } = useSelector((state) => state.searchResult);
  const { stepId, carId } = useParams();

  const Step = CompArray[0][stepId];

  return (
    <section className={styles.searchOption_container}>
      <StepperHeader
        carList={carList}
        proceed_to_payment_clicked={proceed_to_payment_clicked}
        booknow_clicked_from_options={booknow_clicked_from_options}
        isPoliciesChecked={
          isTermsAndConditonChecked && isSupplierTermsAndConditonChecked
        }
        activeStepId={stepId}
        carId={carId}
        workingStepId={workingStepId}
        {...props}
      />
      <Step {...props} />
    </section>
  );
};
