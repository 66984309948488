import React, { useState } from "react";
import Slider from "react-slick";
import icon from "./../../assets/bubbles-icon.svg";

import styles from "./styles.module.scss";

const QASiderItem = ({ id, title, handleOnClick, active }) => (
  <div
    className={`${styles.qaSlider_item} ${id === active && styles.active_qaSlider_item}`}
    onClick={handleOnClick}
  >
    <img src={icon} alt="icon" />
    <p>{title}</p>
  </div>
);

export const QASlider = () => {
  const [active, setActive] = useState(1);
  let arr = [
    {
      id: 1,
      title: "Driver Requirments & Responsibilities"
    },
    {
      id: 2,
      title: "Fuel Mileage & Travel Plans"
    },
    {
      id: 3,
      title: "Insurance, Cover & Protection"
    },
    {
      id: 4,
      title: "Optional Extras"
    },
    {
      id: 5,
      title: "Payment Confirmation"
    },
    {
      id: 6,
      title: "Sign up & Sign in"
    }
  ];
  return (
    <Slider
      focusOnSelect={true}
      slidesToShow={5}
      variableWidth
      swipeToSlide={true}
      slidesToScroll={1}
      centerMode
      className={styles.qa_slider_container}
    >
      {arr.map((val, key) => (
        <QASiderItem
          key={key}
          {...val}
          active={active}
          handleOnClick={() => setActive(val.id)}
        />
      ))}
    </Slider>
  );
};
