import React from "react";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Button } from "./../../components";
import styles from "./styles.module.scss";

export const PageNotFound = () => {
  const { push } = useHistory();

  return (
    <Container className={styles.pageNotFound_container}>
      <h1>404</h1>
      <p>Oops! Something is wrong.</p>
      <Button style={{ width: 300 }} onClick={() => push("/")}>
        Go back in initial page, is better.
      </Button>
    </Container>
  );
};
