import React from "react";
import { NavItem } from "reactstrap";
import {useDispatch, useSelector } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import HeaderLogo from "../../../assets/main_logo_white.svg";
import styles from "./styles.module.scss";
import { setLocationHeaderStatus } from "./../../../store/actions/config.action";
const NavElement = ({ route, title, handleNavDropdown }) => (
  <NavItem onClick={() => handleNavDropdown(false)}>
    <Link to={route} className={styles.navlink}>
      {title}
    </Link>
  </NavItem>
);


const NavBarComponent = ({ handleNavDropdown }) => {
  const isAuthenticated = useSelector(state => state.auth.isUserLoggedIn);
  return (
    <>
      <a
        target="_blank"
        href="https://blog.rentalmoose.com/"
        className={styles.navlinkAnchor}
      >
        Blog
      </a>
      <a
        target="_blank"
        href="https://blog.rentalmoose.com/about/"
        className={styles.navlinkAnchor}
      >
        About
      </a>
      <div />
    </>
  );
};


const NavLogo = () => {
  const Dispatch = useDispatch();
  const handleClick = (e) => {
    Dispatch(setLocationHeaderStatus(false));
  };
  return (
    <Link to="/" className="navbar-brand">
      <img src={HeaderLogo} onClick={handleClick} alt="rentalmoose" className={styles.headerLogoSet}/>
    
    </Link>
  );
};

export { NavBarComponent, NavLogo };
