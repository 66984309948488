import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  CONFIRM_CAR_BOOKING,
  CONFIRM_CAR_BOOKING_SUCCESS,
  CONFIRM_CAR_BOOKING_FAILED,
  SET_WORKING_STEP_ID,
} from "./stepper.action";
import { BASE, BOOKING_CAR, FAIL_BOOKING, SUCCESS_BOOKING } from "../../config/api";
import { askForPermissioToReceiveNotifications } from "../../push-notification";

export class CarBookingEpic {
  static carBooking = (action$) =>
    action$.pipe(
      ofType(CONFIRM_CAR_BOOKING),
      switchMap(
        ({
          payload,
          push,
          isUserLoggedIn,
          token,
          confirmCardPayment,
          paymentClientSecret,
          paymentMethod,
          setErrorMessage,
          setBusy,
        }) => {
          return new Observable(async (observer) => {
            try {
              const { data } = await axios.post(
                `${BASE}${BOOKING_CAR}`,
                payload,
                isUserLoggedIn && {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              if (data.success) {
                const { paymentIntent, error } = await confirmCardPayment(
                  paymentClientSecret,
                  paymentMethod
                );
                if (error) {
                  setErrorMessage(error);
                  document.getElementById("loader").remove();
                  setBusy(false);
                  await axios.post(
                    `${BASE}${FAIL_BOOKING}${data?.data?.id}`,
                    {},
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  return;
                } else if (paymentIntent.status === "succeeded") {
                  await axios.post(
                    `${BASE}${SUCCESS_BOOKING}${data?.data?.id}`,
                    {},
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  let {
                    booking_reference,
                    download_voucher,
                    driver_details,
                    dropoff,
                    pickup,
                    id,
                  } = data.data;
                  observer.next({
                    type: CONFIRM_CAR_BOOKING_SUCCESS,
                    payload: {
                      booking_reference,
                      download_voucher,
                      driver_details,
                      id,
                      pickup: {
                        date: pickup.date,
                        location: {
                          title: pickup.city,
                          address: pickup.address,
                          coords: { lat: pickup.lat, long: pickup.lng },
                        },
                      },
                      dropoff: {
                        date: dropoff.date,
                        location: {
                          title: dropoff.city,
                          address: dropoff.address,
                          coords: { lat: dropoff.lat, long: dropoff.lng },
                        },
                      },
                    },
                    message: data.message,
                  });
                  document.getElementById("loader").remove();
                  push(`/bookingDetail/${payload.vehicle_id}/confirmation`);
                  observer.next({
                    type: SET_WORKING_STEP_ID,
                    payload: 4,
                  });
                }
              } else {
                observer.next({
                  type: CONFIRM_CAR_BOOKING_FAILED,
                  message: data.message,
                });
                document.getElementById("loader").remove();
                setBusy(false);
              }
            } catch (err) {
              const errorMessage = err.response
                ? err.response.data.message
                : "Error in booking";
              observer.next({
                type: CONFIRM_CAR_BOOKING_FAILED,
                message: errorMessage,
              });
              document.getElementById("loader").remove();
              setBusy(false);
            }
          });
        }
      )
    );
}
