export const GET_CARS = "GET_CARS";
export const GET_CARS_SUCCESS = "GET_CARS_SUCCESS";
export const GET_CARS_FAILED = "GET_CARS_FAILED";

export const GET_AVAILABLE_CARS = "GET_AVAILABLE_CARS";
export const GET_AVAILABLE_CARS_SUCCESS = "GET_AVAILABLE_CARS_SUCCESS";
export const GET_AVAILABLE_CARS_FAILED = "GET_AVAILABLE_CARS_FAILED";
export const GET_AVAILABLE_CARS_RESULT_FAILED =
  "GET_AVAILABLE_CARS_RESULT_FAILED";
export const GET_AVAILABLE_CARS_COMPLETED = "GET_AVAILABLE_CARS_COMPLETED";

export const LOAD_MORE_CARS = "LOAD_MORE_CARS";
export const LOAD_MORE_CARS_SUCCESS = "LOAD_MORE_CARS_SUCCESS";
export const LOAD_MORE_CARS_FAILED = "LOAD_MORE_CARS_FAILED";

export const SEARCH_STATION = "SEARCH_STATION";
export const SEARCH_STATION_SUCCESS = "SEARCH_STATION_SUCCESS";
export const SEARCH_STATION_FAILED = "SEARCH_STATION_FAILED";

export const SELECT_PICKUP_STATION = "SELECT_PICKUP_STATION";
export const SELECT_DROPOFF_STATION = "SELECT_DROPOFF_STATION";
export const SELECT_START_DATE = "SELECT_START_DATE";
export const SELECT_END_DATE = "SELECT_END_DATE";
export const SELECT_DRIVER_SENIORITY = "SELECT_DRIVER_SENIORITY";

export const SELECT_CAR = "SELECT_CAR";

export const BOOK_CAR = "BOOK_CAR";
export const BOOK_CAR_SUCCESS = "BOOK_CAR_SUCCESS";
export const BOOK_CAR_FAILED = "BOOK_CAR_FAILED";

export const SET_ACTIVE_MARKER = "SET_ACTIVE_MARKER";
export const TOGGLE_SEE_RESULT_ON_MAP = "TOGGLE_SEE_RESULT_ON_MAP";
export const TOGGLE_SAME_DROP_OFF_STATION = "TOGGLE_SAME_DROP_OFF_STATION";

export const TOGGLE_EXTRAS = "TOGGLE_EXTRAS";
export const INCREMENT_EXTRAS_COUNT = "INCREMENT_EXTRAS_COUNT";
export const DECREMENT_EXTRAS_COUNT = "DECREMENT_EXTRAS_COUNT";
export const TOGGLE_SUPPLIER_FILTER = "TOGGLE_SUPPLIER_FILTER";
export const HANDLE_SUPPLIER_LOAD = "HANDLE_SUPPLIER_LOAD";
export const SORT_RESULTS = "SORT_RESULTS";
export const CAR_TYPE_FILTER = "CAR_TYPE_FILTER";
export const SET_MORE_OPTION = "SET_MORE_OPTION";

export const RESET_SEARCH_PARAMS = "RESET_SEARCH_PARAMS";
export const RESET_SEARCH_PANEL = "RESET_SEARCH_PANEL";
export const SEARCH_PANEL_MORE_OPTIONS = "SEARCH_PANEL_MORE_OPTIONS";

export const SET_BOOK_NOW_CLICKED = "SET_BOOK_NOW_CLICKED";
export const SET_BOOK_NOW_CLICKED_FROM_OPTIONS =
  "SET_BOOK_NOW_CLICKED_FROM_OPTIONS";
export const SET_PROCEED_TO_PAYMENT_CLICKED = "SET_PROCEED_TO_PAYMENT_CLICKED";

export const SET_FROM_URL_PARAMS = "SET_FROM_URL_PARAMS";

export const setMoreOption = (key, value) => ({
  type: SET_MORE_OPTION,
  key,
  value,
});

export const searchStation = (text,countryName) => ({
  type: SEARCH_STATION,
  text,
  countryName,
});

export const selectPickUpStation = (station) => ({
  type: SELECT_PICKUP_STATION,
  station,
});

export const selectDropOffStation = (station) => ({
  type: SELECT_DROPOFF_STATION,
  station,
});

export const selectStartDate = (startDate) => ({
  type: SELECT_START_DATE,
  startDate,
});

export const selectEndDate = (endDate) => ({
  type: SELECT_END_DATE,
  endDate,
});

export const selectDriverSeniority = (driverSeniority) => ({
  type: SELECT_DRIVER_SENIORITY,
  driverSeniority,
});

export const getCars = (payload, push, fromSearchBar = false) => ({
  type: GET_CARS,
  payload,
  push,
  fromSearchBar,
});

export const getAvailableCars = (payload, fromSearchBar = false) => ({
  type: GET_AVAILABLE_CARS,
  payload,
  fromSearchBar,
});

export const loadmoreCars = (payload) => ({
  type: LOAD_MORE_CARS,
  payload,
});

export const selectCar = (payload) => ({
  type: SELECT_CAR,
  payload,
});

export const bookCar = (payload, push) => ({
  type: BOOK_CAR,
  payload,
  push,
});

export const toggleSeeResultOnMap = () => ({
  type: TOGGLE_SEE_RESULT_ON_MAP,
});

export const setActiveMarker = (payload) => ({
  type: SET_ACTIVE_MARKER,
  payload,
});

export const toggleSameDropOffStation = () => ({
  type: TOGGLE_SAME_DROP_OFF_STATION,
});

export const resetSearchParams = () => ({
  type: RESET_SEARCH_PARAMS,
});

export const resetSearchPanel = () => ({
  type: RESET_SEARCH_PANEL,
});

export const toggleExtras = (payload) => ({
  type: TOGGLE_EXTRAS,
  payload,
});

export const incrementExtrasCount = (payload) => ({
  type: INCREMENT_EXTRAS_COUNT,
  payload,
});

export const decrementExtrasCount = (payload) => ({
  type: DECREMENT_EXTRAS_COUNT,
  payload,
});

export const toggleSupplierFilter = (payload) => ({
  type: TOGGLE_SUPPLIER_FILTER,
  payload,
});

export const handleSupplierLoad = () => ({
  type: HANDLE_SUPPLIER_LOAD,
});

export const sortResults = (payload) => ({
  type: SORT_RESULTS,
  payload,
});

export const carTypeFilter = (payload) => ({
  type: CAR_TYPE_FILTER,
  payload,
});

export const toggleSearchMoreOptions = () => ({
  type: SEARCH_PANEL_MORE_OPTIONS,
});

export const setBooknowClicked = (payload) => ({
  type: SET_BOOK_NOW_CLICKED,
  payload,
});
export const setBooknowFromOptionsClicked = (payload) => ({
  type: SET_BOOK_NOW_CLICKED_FROM_OPTIONS,
  payload,
});
export const setProceedToPaymentClicked = (payload) => ({
  type: SET_PROCEED_TO_PAYMENT_CLICKED,
  payload,
});
export const setFromUrlParams = (payload) => ({
  type: SET_FROM_URL_PARAMS,
  payload,
});
