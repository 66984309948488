import React from "react";
import axios from "axios";
import { Col, Row, Container } from "reactstrap";
import ICalendarLink from "react-icalendar-link";
import { useSelector } from "react-redux";
import { HorizontalBorder } from "./../../../";
import { CustomStepRow } from "./../stepsUtils";
import greenTick from "./../../../../assets/greentick.svg";
import blueTick from "./../../../../assets/blueok.svg";
import cvvIcon from "./../../../../assets/download.svg";
import styles from "./styles.module.scss";
import { BASE } from '../../../../config/api';
const bookingLocation = {
  pickUp: {
    date: "Sun, 27 Jun 2019",
    time: "10:00 AM",
    location: {
      title: "Honolulu, Hawaii, USA",
      address: "155a, Orchard Road, Christchurch Airport, Christchurch",
      coords: {
        lat: "",
        long: "",
      },
    },
  },
  dropOff: {
    date: "Wed, 30 Jun 2019",
    time: "10:00 AM",
    location: {
      title: "Honolulu, Hawaii, USA",
      address: "155a, Orchard Road, Christchurch Airport, Christchurch",
      coords: {
        lat: "",
        long: "",
      },
    },
  },
};
const mapCoupon = (couponCode,id,email) => {
 axios.post(
    `${BASE}/coupon/map`,
    {
      booking_id: id,
      coupon_code:couponCode,
      email:email
    }
  );
}
const DownloadVoucherContainer = ({
  voucher: {
    booking_reference,
    download_voucher,
    driver_details: { email },
    id,
  },
}) => {
  const { couponCode } = useSelector(state => state.bookingDetail);
  if(couponCode.length > 0)
  {
    mapCoupon(couponCode,id,email)
  }
  return (
    <Container>
      <div className={styles.downloadVoucher_container}>
        <div className={styles.heading_container}>
          <img src={greenTick} alt="green tick" />
          <h1>Your Booking is Confirmed</h1>
        </div>
        <div className={`${styles.bookingRef_container}`}>
          <span>
            Your booking reference is: <span className={styles.bold_span}>{booking_reference}</span>
          </span>
          <span>
            We have sent e-mail to: <span className={styles.bold_span}>{email}</span>
          </span>
          <a href={download_voucher} download className={styles.downloadPDF}>
            <span>
              Download PDF voucher <img src={cvvIcon} alt="icon" />
            </span>
          </a>
        </div>
      </div>
    </Container>
  );
};

const LocationCard = ({
  cardTitle,
  date,
  time,
  location: {
    title,
    address,
    coords: { lat, long },
  },
}) => {
  const event = {
    title: cardTitle,
    description: `I have to go to ${cardTitle} point`,
    startTime: date,
    endTime: date,
    location: `${address}`,
  };
  return (
    <div className={styles.locationCard_container}>
      <h1>{cardTitle}</h1>
      <div className={styles.locationCardInner_container}>
        <div className={styles.dropOffDate_container}>
          <span>Drop-off Date</span>
          <span>
            <b>
              {date} <span className="time">{time}</span>
            </b>
          </span>
        </div>
        <div className={styles.dropOffLocation_container}>
          <span>Location</span>
          <span>
            <b>{title}</b>
          </span>
          <span>
            <b>{address} </b>
          </span>
        </div>
        <div className={styles.locationCardLinks_container}>
          <a
            href={`https://maps.google.com?q=${lat},${long}`}
            target="_blank"
            className={styles.openMaps_link}
          >
            Open in Maps
          </a>
          <ICalendarLink filename={`${cardTitle}-reminder`} event={event}>
            Set Reminder
          </ICalendarLink>
        </div>
      </div>
    </div>
  );
};

const OrderedExtrasItem = ({ description, price_per_day }) => {
  return (
    <div className={styles.orderedExtrasItem_container}>
      <img src={blueTick} alt="tick check" />
      <div className={styles.itemDetail_container}>
        <b>{description}</b>
        <p>{price_per_day} € per rental</p>
      </div>
    </div>
  );
};

const LocationCardsContainer = ({ bookingLocation, checkoutStationDetails }) => {
  const dropOffLocation = {
    date: bookingLocation.dropoff.date,
    time: bookingLocation.dropoff.time,
    location: {
      title: checkoutStationDetails.city,
      address: checkoutStationDetails.address,
      coords: { lat: checkoutStationDetails.lat, long: checkoutStationDetails.lng },
    },
  };
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <Row>
          <Col md="6">
            <LocationCard cardTitle="Pick-Up" {...bookingLocation.pickup} />
          </Col>
          <Col md="6">
            <LocationCard cardTitle="Drop-Off" {...dropOffLocation} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const OrderedExtras = ({ item }) => (
  <React.Fragment>
    <HorizontalBorder />
    <Container>
      <CustomStepRow heading="Ordered extras">
        <Col md="8">
          <Row>
            {item.extras.map((val, idx) => {
              if (val.selected && (val.remaining ? val.remaining > 0 : true)) {
                return (
                  <Col md="6" key={idx}>
                    <OrderedExtrasItem {...val} />
                  </Col>
                );
              }
            })}
          </Row>
        </Col>
      </CustomStepRow>
    </Container>
  </React.Fragment>
);

export {
  DownloadVoucherContainer,
  LocationCard,
  OrderedExtrasItem,
  bookingLocation,
  LocationCardsContainer,
  OrderedExtras,
};
