import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Button } from "reactstrap";
import { useDispatch,useSelector } from 'react-redux';

import { setFromUrlParams } from './../../SearchPanel/searchPanel.action';
import styles from "./styles.module.scss";

const StepperMenu = [
  { title: "Options", stepId: 1 },
  { title: "Driver", stepId: 2 },
  { title: "Confirmation", stepId: 3 },
];

const HeadItem = ({
  title,
  stepId,
  activeStepId,
  isSearchResultItem,
  isPoliciesChecked,
  booknow_clicked_from_options,
  proceed_to_payment_clicked,
}) => {
  const {
    searchResult:{carList}
  } = useSelector((state) => state);
  const isActive =
    StepperMenu.find((i) => i.stepId === stepId).title.toLocaleLowerCase() === activeStepId;
  const Screen_Width = window.innerWidth;
  const Dispatch = useDispatch();
  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const checkin_station_code = params.get("checkin_station_code");
  const checkin_time = params.get("checkin_time");
  const checkout_station_code = params.get("checkout_station_code");
  const checkout_time = params.get("checkout_time");
  const currency = params.get("currency");
  const vehicle_id = params.get("vehicle_id");

  return (
    <div
      className={styles.stepperHeader_container}
      onClick={() => {
        const step = StepperMenu.find((i) => i.stepId === stepId).title.toLocaleLowerCase();
        if (isSearchResultItem) {
          if (carList.length) {
            Dispatch(setFromUrlParams(false))
            push("/results");
          } else {
            push("/");
          }
        } else if (activeStepId !== "confirmation") {
          if (step !== "confirmation") {
            if (step === "driver" && isPoliciesChecked && booknow_clicked_from_options) {
              push({
                pathname: `/bookingDetail/${vehicle_id}/${step}`,
                search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&currency=${currency}&vehicle_id=${vehicle_id}`,
                state: { from: "step" },
              });
            } else if (step === "payment" && proceed_to_payment_clicked) {
              push({
                pathname: `/bookingDetail/${vehicle_id}/${step}`,
                search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&currency=${currency}&vehicle_id=${vehicle_id}`,
                state: { from: "step" },
              });
            } else if (
              (activeStepId === "driver" || activeStepId === "payment") &&
              step === "options"
            ) {
              push({
                pathname: `/bookingDetail/${vehicle_id}/${step}`,
                search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&currency=${currency}&vehicle_id=${vehicle_id}`,
                state: { from: "step" },
              });
            }
          }
        }
      }}
    >
      <div
        className="d-flex"
        className={`${styles.headItem_container}  ${isActive &&
          !isSearchResultItem &&
          styles.headItem_active}`}
      >
        <div className={styles.headItem_index}>{isSearchResultItem ? stepId : stepId + 1}</div>
        {Screen_Width <= 768 ? isActive ? <span>{title}</span> : <></> : <span>{title}</span>}
      </div>
    </div>
  );
};

export const StepperHeader = ({
  carId,
  activeStepId,
  workingStepId,
  isPoliciesChecked,
  booknow_clicked_from_options,
  proceed_to_payment_clicked,
  carList
}) => {

  const Width = window.innerWidth;
  const { push } = useHistory();

  return (
    <Container className={styles.optionButtons}>
      <Row className="w-100 m-0">
        {Width <= 767 ? (
          <HeadItem
            proceed_to_payment_clicked={proceed_to_payment_clicked}
            booknow_clicked_from_options={booknow_clicked_from_options}
            isPoliciesChecked={isPoliciesChecked}
            key={0}
            isSearchResultItem
            carList={carList}
            title=""
            carId={0}
            stepId={1}
            activeStepId={activeStepId}
          />
        ) : (
            <HeadItem
              proceed_to_payment_clicked={proceed_to_payment_clicked}
              booknow_clicked_from_options={booknow_clicked_from_options}
              isPoliciesChecked={isPoliciesChecked}
              key={0}
              isSearchResultItem
              title="Search Result"
              carId={0}
              stepId={1}
              activeStepId={activeStepId}
            />
          )}

        {StepperMenu.map(({ title, stepId }, idx) => (
          <HeadItem
            proceed_to_payment_clicked={proceed_to_payment_clicked}
            booknow_clicked_from_options={booknow_clicked_from_options}
            isPoliciesChecked={isPoliciesChecked}
            key={idx}
            title={title}
            carId={carId}
            stepId={stepId}
            activeStepId={activeStepId}
          />
        ))}
      </Row>
    </Container>
  );
};
