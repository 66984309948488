import React, { useState } from "react";
import { Modal, ModalBody, Row, Col, Tooltip } from "reactstrap";
import { Formik, Form } from "formik";

import { ModalButton, formikConfig_Signup } from "./AuthModalUtils";
import { InputText, DropDownInput } from "../..";
import MooseIcon from "../../../assets/moose-big.png";
import styles from "./styles.module.scss";

export const SignupModal = (props) => {
  const { signupModal } = props.modalState;
  const [selectedCountry, setSelectedCountry] = useState();
  const [
    selectedLicenseIssueCountry,
    setselectedLicenseIssueCountry,
  ] = useState();
  const { showModal, hideModal, signup, countries, resetMessage } = props;

  const toggle = () => {
    if (signupModal) {
      hideModal("signupModal");
      resetMessage();
    } else {
      showModal("signupModal");
    }
  };

  const handleSwitchModal = () => {
    resetMessage();
    hideModal("signupModal");
    showModal("loginModal");
  };

  const handleSeletCountryChange = (value, setFieldValue) => {
    setSelectedCountry(value);
    setFieldValue("country_of_residence", value.value);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = {
      ...values,
      phonecode: values.phonecode.split("+")[1],
      newsletter_subscribed: 1,
    };
    signup(formData, hideModal, setSubmitting);
  };

  const showErrorBorder = (error) => {
    if (error) return { border: "solid 1px red" };
    return {};
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Modal
      isOpen={signupModal}
      toggle={toggle}
      className="modal-dialog-centered modal-lg"
    >
      <ModalBody className={styles.modalBody_container}>
        <h1>Register</h1>
        <div className={styles.formMoose}>
          <img src={MooseIcon} alt="Moose" className="img-fluid" />
        </div>
        <Formik
          enableReinitialize
          initialValues={formikConfig_Signup.initialValues}
          validate={formikConfig_Signup.validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Row>
                  <Col md="6" className={styles.lableTip}>
                    <InputText
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="First Name"
                      style={showErrorBorder(
                        errors.name && touched.name && errors.name
                      )}
                    />
                    {errors.name && touched.name && errors.name}
                  </Col>
                  <Col md="6">
                    <InputText
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Last Name*"
                      style={showErrorBorder(
                        errors.last_name &&
                        touched.last_name &&
                        errors.last_name
                      )}
                    />
                    {errors.last_name && touched.last_name && errors.last_name}
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <InputText
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Email"
                      style={showErrorBorder(
                        errors.email && touched.email && errors.email
                      )}
                    />
                    {errors.email && touched.email && errors.email}
                  </Col>
                  <Col md="4">
                    <InputText
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Password"
                      style={showErrorBorder(
                        errors.password && touched.password && errors.password
                      )}
                    />
                    {errors.password && touched.password && errors.password}
                  </Col>
                  <Col md="4">
                    <InputText
                      type="password"
                      name="password_confirmation"
                      value={values.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Confirm Password"
                      style={showErrorBorder(
                        errors.password_confirmation &&
                        touched.password_confirmation &&
                        errors.password_confirmation
                      )}
                    />
                    {errors.password_confirmation &&
                      touched.password_confirmation &&
                      errors.password_confirmation}
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <DropDownInput
                      label="Gender"
                      value={values.title && values.title.value}
                      name="title"
                      // placeholder={values.title}
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue("title", val.value);
                      }}
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                    />
                    {errors.title}
                  </Col>
                  <Col md="6">
                    <InputText
                      name="birth_date"
                      label="Date Of Birth"
                      max="9999-12-31"
                      type="date"
                      value={values.birth_date}
                      onChange={(val) => {
                        setFieldValue("birth_date", val.target.value);
                      }}
                      style={showErrorBorder(
                        errors.birth_date &&
                        touched.birth_date &&
                        errors.birth_date
                      )}
                      onBlur={handleBlur}
                    />
                    {touched.birth_date && errors.birth_date}
                  </Col>
                </Row>

                <Row>
                  <Col md="3">
                    <DropDownInput
                      label="Country Code"
                      value={values.phonecode && values.phonecode.value}
                      name="phonecode"
                      onBlur={handleBlur}
                      isError={
                        touched["react-select-3-input"] && !values.phonecode
                      }
                      onChange={(val) => {
                        setFieldValue("phonecode", val.value);
                      }}
                      options={
                        countries &&
                        countries.map((val) => ({
                          label: (
                            <span>
                              <img
                                src={val.flag}
                                width="50"
                                className={styles.flagicon}
                                alt="country-logo"
                              />
                              +{val.phonecode}
                            </span>
                          ),
                          value: `+${val.phonecode}`,
                        }))
                      }
                    />
                    {touched["react-select-3-input"] ? errors.phonecode : ""}
                  </Col>
                  <Col md="3">
                    <InputText
                      type="text"
                      name="contact"
                      value={values.contact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Contact No"
                      style={showErrorBorder(
                        errors.contact && touched.contact && errors.contact
                      )}
                    />
                    {errors.contact && touched.contact && errors.contact}
                  </Col>
                  <Col md="6">
                    <DropDownInput
                      label="Country of residence"
                      value={selectedCountry}
                      name="country_of_residence"
                      placeholder="Select Country"
                      onBlur={handleBlur}
                      onChange={(val) => {
                        handleSeletCountryChange(val, setFieldValue);
                      }}
                      isError={
                        touched["react-select-4-input"] && !selectedCountry
                      }
                      options={countries.map((val) => ({
                        label: val.name,
                        value: val.code,
                      }))}
                    />
                    {touched["react-select-4-input"]
                      ? errors.country_of_residence
                      : ""}
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <InputText
                      type="text"
                      value={values.license_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="license_number"
                      label="Driving licence number"
                    // style={showErrorBorder(
                    //   errors.license_number &&
                    //     touched.license_number &&
                    //     errors.license_number
                    // )}
                    />
                    {/* {errors.license_number &&
                      touched.license_number &&
                      errors.license_number} */}
                  </Col>
                  <Col md="6">
                    <InputText
                      type="date"
                      value={values.license_expiry_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      max="9999-12-31"
                      name="license_expiry_date"
                      label="Driving licence expiry date"
                    // style={showErrorBorder(
                    //   errors.license_expiry_date &&
                    //     touched.license_expiry_date &&
                    //     errors.license_expiry_date
                    // )}
                    />
                    {/* {touched.license_expiry_date && errors.license_expiry_date} */}
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <InputText
                      type="date"
                      value={values.license_issue_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      max="9999-12-31"
                      name="license_issue_date"
                      label="Driving licence issue date"
                    // style={showErrorBorder(
                    //   errors.license_issue_date &&
                    //     touched.license_issue_date &&
                    //     errors.license_issue_date
                    // )}
                    />
                    {/* {touched.license_issue_date && errors.license_issue_date} */}
                  </Col>

                  <Col md="6">
                    <DropDownInput
                      label="Driving licence issue country"
                      value={selectedLicenseIssueCountry}
                      name="license_issue_country"
                      placeholder="Select Country"
                      onBlur={handleBlur}
                      // isError={
                      //   touched["react-select-5-input"] &&
                      //   !selectedLicenseIssueCountry
                      // }
                      onChange={(val) => {
                        setselectedLicenseIssueCountry(val);
                        setFieldValue("license_issue_country", val.value);
                      }}
                      options={countries.map((val) => ({
                        label: val.name,
                        value: val.code,
                      }))}
                    />
                    {/* {touched["react-select-5-input"]
                      ? errors.license_issue_country
                      : ""} */}
                  </Col>
                </Row>

                <div className={styles.modalBtn_container}>
                  <ModalButton
                    type="sumbit"
                    label="Register"
                    disabled={isSubmitting}
                  />
                </div>
                <span
                  className={styles.passwordRecovery}
                  onClick={handleSwitchModal}
                >
                  Already have account?
                </span>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
