import React, { useEffect } from "react";

import { Container } from "reactstrap";
import ReactGA from "react-ga";

export const About = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Container>
      <h1>About Us</h1>
    </Container>
  );
};
