import React from "react";
import { Row, Col, Container } from "reactstrap";
import { useSelector } from "react-redux";

import blueIcon from "./../../../assets/blueok.svg";
import img from "./../../../assets/reentalmoose2.png";
import { HorizontalBorder } from "./../../";
import styles from "./styles.module.scss";

const CustomStepRow = ({
  heading,
  hasRequiredFields,
  isDriverPaymentStep,
  icon,
  children,
}) => (
  <Row className={styles.customStepStep_row2}>
    <Col md="4">
      <span
        className={`${styles.customStepRow_heading} ${isDriverPaymentStep &&
          styles.driverPaymentSectionHeading}`}
      >
        {heading}
      </span>
      {icon && <img src={icon} width="80" height="98" />}
      {hasRequiredFields && (
        <span className={styles.req}>* Required Fields</span>
      )}
    </Col>
    {children}
  </Row>
);

const PriceBreakDownItem = ({ title, price }) => (
  <div className={styles.priceBreakDownItem_container}>
    <b>{title}</b>
    <span>{price}</span>
  </div>
);

const PriceBreakDownContainer = ({
  pay_now,
  pay_later,
  total_amount,
  extras_total,
  service_charges,
}) => {
  const { selectedCurrency } = useSelector((state) => state.config);
  const { discountAmount,payNowdiscount,discountPrice } = useSelector(state => state.bookingDetail);
  let discounted_amount = pay_now;

  if(payNowdiscount) {
    discounted_amount = parseFloat(payNowdiscount).toFixed(2);
  }

  if(discountAmount){
    total_amount = parseFloat(discountAmount).toFixed(2);
    pay_later = (discountAmount-pay_now).toFixed(2);
  }

  if(discountPrice) {
    total_amount = discountAmount - discountPrice;
    total_amount = parseFloat(total_amount).toFixed(2);
  }
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading="Price breakdown">
          <Col md="8">
            <Row>
              <Col md="3" xs="6">
                <PriceBreakDownItem
                  title="Payable today"
                  price={`${selectedCurrency.sign}${discounted_amount ? discounted_amount : 0}`}
                />
              </Col>
              <Col md="3" xs="6">
                <PriceBreakDownItem
                  title="Payable at Desk"
                  price={`${selectedCurrency.sign}${pay_later ? pay_later : 0}`}
                />
              </Col>
              <Col md="3" xs="6">
                <PriceBreakDownItem
                  title="Extras Charges"
                  price={`${selectedCurrency.sign}${
                    extras_total ? extras_total : 0
                  }`}
                />
              </Col>
              <Col md="3" xs="6">
                <PriceBreakDownItem
                  title="Car Charges"
                  price={`${selectedCurrency.sign}${
                    total_amount ? total_amount : 0
                  }`}
                />
              </Col>
              <Col md="6" xs="6">
                <PriceBreakDownItem
                  title="Tree planting donation (non refundable)"
                  price={`${selectedCurrency.sign}${
                    service_charges ? service_charges : 0
                  }`}
                />
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};

const chunkArray = (array, size) => {
  if (array.length <= size) {
    return [array];
  }
  return [array.slice(0, size), ...chunkArray(array.slice(size), size)];
};
const IncludedPriceRow = ({ heading, inclusives }) => {
  let inclusivesList = [];
  if (inclusives && inclusives.length) {
    inclusivesList = chunkArray(inclusives, 5);
  }
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="4">
            <ul className={styles.optionStep_list}>
              {inclusivesList.length &&
                inclusivesList[0]?.map((i) => (
                  <li>
                    <img src={blueIcon} alt="tick icon" />
                    {i}
                  </li>
                ))}
            </ul>
          </Col>
          <Col md="4">
            <ul className={styles.optionStep_list}>
            <img className={styles.img} src={img}></img>
            </ul>
          </Col>
         
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};

export { CustomStepRow, PriceBreakDownContainer, IncludedPriceRow };
