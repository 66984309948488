import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Formik, Form } from "formik";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";

import { ModalButton, formikConfig_ForgotPassword } from "./AuthModalUtils";
import { InputText, ErrorViewer, Button } from "../..";
import styles from "./styles.module.scss";

const override = css`
  margin-left: 5px;
`;

export const ForgotPasswordModal = props => {
  const { forgotPasswordModal } = props.modalState;
  const {
    showModal,
    hideModal,
    forgotPassword,
    resetMessage,
    authState: { isError, message, isLoading }
  } = props;

  const toggle = () => {
    if (forgotPasswordModal) {
      hideModal("forgotPasswordModal");
      resetMessage();
    } else {
      showModal("forgotPasswordModal");
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    forgotPassword(values, hideModal, setSubmitting);
  };
  return (
    <Modal
      isOpen={forgotPasswordModal}
      toggle={toggle}
      className="modal-dialog-centered"
    >
      <ModalBody className={styles.modalBody_container}>
        <h1>Forgot Password</h1>
        <Formik
          enableReinitialize
          initialValues={formikConfig_ForgotPassword.initialValues}
          validate={formikConfig_ForgotPassword.validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting
          }) => (
            <Form>
              <InputText
                type="email"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email"
              />
              <ErrorViewer name="email" touched={touched} errors={errors} />
              <div className={styles.modalBtn_container}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ display: "flex" }}
                  blueBG
                >
                  Send Email
                  {isLoading && (
                    <BounceLoader
                      css={override}
                      sizeUnit={"px"}
                      size={20}
                      color={"#035372"}
                      loading={isLoading}
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {isError && isError && <p style={{ color: "brown" }}>{message}</p>}
      </ModalBody>
    </Modal>
  );
};
