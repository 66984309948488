import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Modal,
  ModalBody,
  Collapse,
  Tooltip,
} from "reactstrap";

import blueIcon from "./../../../../assets/blueok.svg";
import tabAbout from "./../../../../assets/tab_about.jpg";
import paymentIcons from "./../../../../assets/stripe.png";
import sampleicon from "./../../../../assets/aircon-icon.svg";
import { Button, HorizontalBorder, CheckBox } from "./../../../";
import { CarDetailedCard, CarCard,CarDetailedCardForMobile } from "./../../../";
import { setWorkingStepId } from "../index";
import { CustomStepRow } from "../stepsUtils";
import {
  incrementExtrasCount,
  decrementExtrasCount,
  setBooknowFromOptionsClicked,
} from "./../../../SearchPanel/searchPanel.action";
import styles from "./styles.module.scss";

const CustomCheckBox = (props) => {
  const { sub_desc } = props;
  return (
    <span>
      <CheckBox {..._.omit(props, ["sub_desc"])} />
      <span>{sub_desc}</span>
    </span>
  );
};

const CarDetailCardRow = ({ item, searchParams }) => {
  const Width = window.innerWidth;
  return (
    <React.Fragment>
      {Width <= 767 ? (
        <Container>
          <CarDetailedCardForMobile item={item} isOptionStep searchParams={searchParams} />
        </Container>
      ) : (
        <CarDetailedCard
          isOptionStep
          item={item}
          searchParams={searchParams}
          className={styles.optionStep_row1}
        />
      )}
    </React.Fragment>
  );
};

const SupplierLocationRow = ({ supplierDetails }) => {
  const [isModalOpen, setModalVisibilty] = React.useState(false);
  const ScheduleItem = ({ day, end_time, open_time, status }) => (
    <div
      style={
        status === "OPEN"
          ? { background: "#daf1df" }
          : { background: "#fee2e2" }
      }
      className={styles.scheduleItem}
    >
      <Row>
        <Col md="3">{day}</Col>
        <Col md="3">{open_time}</Col>
        <Col md="3">{end_time}</Col>
        <Col md="3">{status}</Col>
      </Row>
    </div>
  );

  const ScheduleModal = ({ schedule = [], isOpen, toggle }) => (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalBody>
        <h4>Station Schedule</h4>
        <hr />
        {schedule &&
          schedule.map((item, idx) => <ScheduleItem key={idx} {...item} />)}
        <Button className="float-right" onClick={toggle}>
          Close
        </Button>
      </ModalBody>
    </Modal>
  );

  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading="Supplier Location">
          <Col md="4">
            <div>
              <h6 className={styles.title}>
                {supplierDetails &&
                  supplierDetails.station_details &&
                  supplierDetails.station_details.name}
              </h6>
              <p className={styles.description}>
                {supplierDetails &&
                  supplierDetails.station_details &&
                  supplierDetails.station_details.address}
              </p>
              <a
                href={`https://maps.google.com?q=${supplierDetails &&
                  supplierDetails.station_details &&
                  supplierDetails.station_details.lat},${supplierDetails &&
                  supplierDetails.station_details &&
                  supplierDetails.station_details.lng}`}
                target="blank"
              >
                <Button stepName="option" color="primary">
                  Open Maps
                </Button>
              </a>
            </div>
          </Col>
          <Col md="4">
            <div className={styles.scheduleBtn}>
              <h6 className={styles.title}>Opening hours</h6>
              <p className={styles.description}>
                To see supplier schedule details, click on check schedule button
              </p>
              <Button
                style={{ marginTop: 15 }}
                onClick={() => setModalVisibilty((prev) => !prev)}
                color="primary"
              >
                Check Schedule
              </Button>
            </div>
          </Col>
        </CustomStepRow>
      </Container>
      <ScheduleModal
        schedule={supplierDetails && supplierDetails.station_schedule}
        isOpen={isModalOpen}
        toggle={() => setModalVisibilty((prev) => !prev)}
      />
    </React.Fragment>
  );
};

const ExtrasRow = ({ val, currency, handleExtrasToggle }) => {
  const Dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Row className={styles.extras_row}>
      <Col md="6" sm="12" xs="12" className={styles.checkBox_container}>
        <img src={val.icon} />
        <CustomCheckBox
          className={styles.CustomCheckBox}
          label={val.description}
          checked={val.selected}
          disabled={val.mandatory === 1}
          onChange={() => handleExtrasToggle(val.code)}
          id={val.code}
        />
        <div key={`tooltip_${val.code}`}>
          <p style={{ marginLeft: 10 }}>
            <span
              className="zmdi zmdi-help-outline"
              id={`tooltip-${val.code}`}
            ></span>
          </p>
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target={`tooltip-${val.code}`}
            toggle={toggle}
          >
            {val.description}
          </Tooltip>
        </div>
      </Col>
      <Col md="2" sm="6" xs="6" style={{ padding: 0 }}>
        {val.selected && (
          <div className={styles.counter_container}>
            <button
              className={styles.button}
              onClick={() => Dispatch(decrementExtrasCount(val.code))}
              disabled={val.count == 1}
            >
              -
            </button>
            <div className={styles.count}>{val.count}</div>
            <button
              className={styles.button}
              onClick={() => Dispatch(incrementExtrasCount(val.code))}
              disabled={val.remaining && val.count == val.remaining}
            >
              +
            </button>
          </div>
        )}
      </Col>
      <Col md="4" sm="6" xs="6" className={styles.total_container}>
        {val.type !== "external" && (
          <>
            <p>per rental</p>
            <p className={styles.total}>
              {currency.sign}
              {val.type == "external" ? 0 : val.price_per_day}
            </p>
          </>
        )}
      </Col>
    </Row>
  );
};

const AvailableExtraRow = ({
  heading,
  extras,
  handleExtrasToggle,
  selectedCurrency,
  id,
}) => {
  const { push } = useHistory();
  const Dispatch = useDispatch();
  let totalExtra = 0;
  extras &&
    extras
      .filter((v) => v.selected && (v.remaining ? v.remaining > 0 : true))
      .filter((v) => v.type != "external")
      .map((i) => {
        totalExtra += i.count * Number(i.price_per_day);
      });

  const {
    isTermsAndConditonChecked,
    isSupplierTermsAndConditonChecked,
  } = useSelector((state) => state.bookingDetail);

  const handleSubmit = () => {
    push(`/bookingDetail/${id}/driver`);
    Dispatch(setWorkingStepId(2));
    Dispatch(setBooknowFromOptionsClicked(true));
  };

  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            {extras &&
              extras
                .filter((v) => {
                  if (v.remaining) {
                    if (v.remaining > 0) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                })
                .map((val, idx) => (
                  <ExtrasRow
                    val={val}
                    key={idx}
                    currency={selectedCurrency}
                    handleExtrasToggle={handleExtrasToggle}
                  />
                ))}
            <Row>
              <Col md="12">
                <p className={styles.extraText}>
                  Because companies can sometimes run out of extras, you may not
                  get the ones you reserve. We also can't guarantee that the
                  prices listed here are the prices you'll pay at the counter.
                  Is there anything you need? Where possible, we'll pass your
                  requests on to the car hire company.
                </p>
              </Col>
              {/* <Col md="4" className={styles.extras_total_container}>
                <span className={styles.extras_span_heading}>
                  Subtotal for all extras:
                </span>
                <p>{`${selectedCurrency.sign}${totalExtra.toFixed(2)}`}</p> */}
                {/* <Button
                  onClick={handleSubmit}
                  disabled={
                    !totalExtra || !isTermsAndConditonChecked || !isSupplierTermsAndConditonChecked
                  }
                  blueBG
                >
                  Book with Extras
                </Button> */}
              {/* </Col> */}
              {/* <Col>
              </Col> */}
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};

const DriverAndLicenceRequirementsRow = ({ heading, driver_age }) => {
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            <Row>
              <Col md="6">
                <span className={styles.spanHeading}>
                  When you pick the car up, you'll need:
                </span>
                <ul className={styles.optionStep_list}>
                  <li>
                    <img src={blueIcon} alt="tick icon" />
                    Passport or national ID card
                  </li>
                  <li>
                    <img src={blueIcon} alt="tick icon" />
                    Driving licence{" "}
                    <p style={{ marginTop: 2, marginLeft: 5 }}>
                      {" "}
                      (Held for min. 1 year)
                    </p>
                  </li>
                  <li>
                    <img src={blueIcon} alt="tick icon" />
                    Credit card
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <span className={styles.spanHeading}>Driver age</span>
                <p>
                Young driver fees can be added at the rental desk if the driver is less than 25 years old. Please check the supplier terms and conditions for more details.
                </p>
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};

const SecurityDepositRow = ({ heading, currencySign, securityDeposit }) => {
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            <Row>
              <Col md="12">
                <p>
                  {`At pick-up, the main driver will leave a refundable security deposit of approx. ~${currencySign}${securityDeposit} on their credit card. Debit can be accepted, but Credit cards are mostly preferred. Please check suppliers Terms and Conditions for more details.`}
                </p>
                <span className={styles.spanHeading}>Accepted Cards</span>
                <img src={paymentIcons} width="230px" alt="Payments" />
                <p className={styles.paymentNote}>
                  Please note:<br></br>
                  This company will not accept prepaid, recharge or virtual
                  credit cards - or any card on which the relevant characters
                  are not raised (embossed).
                </p>
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};
const FuelPolicyRow = ({ heading }) => {
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            <Row>
              <Col md="12">
                <p>
                  When you pick your car up, the fuel tank will be full or
                  partly full. You will leave a deposit to cover the cost of the
                  fuel: the counter staff will block this money on your credit
                  card. Just before you return your car, please replace the fuel
                  you’ve used.
                </p>
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};
const CleaningPolicy = ({ heading }) => {
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            <Row>
              <Col md="12">
                <p>
                  The car has been cleaned and disinfected before each client. 
                  When you return a car, it is expected to be clean. That means no litter, 
                  garbage, food stains inside or heavy dirt outside, or a cleaning fee will 
                  be added. The fee would be deducted from your deposit. 
                  Please check each suppliers' terms and conditions for more details.
                </p>
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};
const DriverAgePolicy = ({ heading }) => {
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            <Row>
              <Col md="12">
                <p>
                  When you pick your car up, the fuel tank will be full or
                  partly full. You will leave a deposit to cover the cost of the
                  fuel: the counter staff will block this money on your credit
                  card. Just before you return your car, please replace the fuel
                  you’ve used.
                </p>
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};
const MileageUnlimitedRow = ({ heading }) => {
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container>
        <CustomStepRow heading={heading}>
          <Col md="8">
            <Row>
              <Col md="12">
                <p>
                  Suppliers might have fees for cross-border and boarding a
                  ferry. Please check suppliers conditions when you pick-up the
                  car.
                </p>
              </Col>
            </Row>
          </Col>
        </CustomStepRow>
      </Container>
    </React.Fragment>
  );
};

const UseFullInfoRowAccordion_Item = ({ title, children }) => {
  const [isOpen, setActive] = useState(false);
  return (
    <div>
      <label onClick={() => setActive((prev) => !prev)}>{title}</label>
      <Collapse isOpen={isOpen}>
        <div className={styles.CustomTabPanel}>{children}</div>
      </Collapse>
    </div>
  );
};

const UserFullInfoAccordion = ({ heading }) => {
  return (
    <Container>
      <CustomStepRow heading={heading}></CustomStepRow>
      <UseFullInfoRowAccordion_Item title="General">
        <Row>
          <Col md="6">
            <div className={styles.tabText}>
              <p>
                <strong>
                  Ateena on linn, mis on külastamist väärt! See on iidse
                  kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                </strong>
                Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib kindel
                olla, et Ateenast tagasi tulles tunned end puhanuna, sest linna
                lihtne elustiil lihtsalt ei lase stressil ligi tikkuda.
              </p>
              <p>
                Ja kui Sa veel Kreeka väikesaartele satud… Külastamiseks on
                sobivaim ajavahemik aprillist oktoobrini. Siiski on väga hea
                võimalus veeta oma talvepuhkus Ateenas turistide vähesuse tõttu.
              </p>
              <a href="javasscript:void(0);" className={styles.tabReadMore}>
                <i className="zmdi zmdi-sign-in"></i> Read More
              </a>
            </div>
          </Col>
          <Col md="6">
            <div className={styles.tabimage}>
              <img src={tabAbout} alt="About" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </UseFullInfoRowAccordion_Item>
      <UseFullInfoRowAccordion_Item title="Visa">
        <Row>
          <Col md="6">
            <div className={styles.tabText}>
              <p>
                <strong>
                  Ateena on linn, mis on külastamist väärt! See on iidse
                  kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                </strong>
                Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib kindel
                olla, et Ateenast tagasi tulles tunned end puhanuna, sest linna
                lihtne elustiil lihtsalt ei lase stressil ligi tikkuda.
              </p>
              <p>
                Ja kui Sa veel Kreeka väikesaartele satud… Külastamiseks on
                sobivaim ajavahemik aprillist oktoobrini. Siiski on väga hea
                võimalus veeta oma talvepuhkus Ateenas turistide vähesuse tõttu.
              </p>
              <a href="javasscript:void(0);" className={styles.tabReadMore}>
                <i className="zmdi zmdi-sign-in"></i> Read More
              </a>
            </div>
          </Col>
          <Col md="6">
            <div className={styles.tabimage}>
              <img src={tabAbout} alt="About" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </UseFullInfoRowAccordion_Item>
      <UseFullInfoRowAccordion_Item title="Climate">
        <Row>
          <Col md="6">
            <div className={styles.tabText}>
              <p>
                <strong>
                  Ateena on linn, mis on külastamist väärt! See on iidse
                  kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                </strong>
                Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib kindel
                olla, et Ateenast tagasi tulles tunned end puhanuna, sest linna
                lihtne elustiil lihtsalt ei lase stressil ligi tikkuda.
              </p>
              <p>
                Ja kui Sa veel Kreeka väikesaartele satud… Külastamiseks on
                sobivaim ajavahemik aprillist oktoobrini. Siiski on väga hea
                võimalus veeta oma talvepuhkus Ateenas turistide vähesuse tõttu.
              </p>
              <a href="javasscript:void(0);" className={styles.tabReadMore}>
                <i className="zmdi zmdi-sign-in"></i> Read More
              </a>
            </div>
          </Col>
          <Col md="6">
            <div className={styles.tabimage}>
              <img src={tabAbout} alt="About" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </UseFullInfoRowAccordion_Item>
      <UseFullInfoRowAccordion_Item title="Prices">
        <Row>
          <Col md="6">
            <div className={styles.tabText}>
              <p>
                <strong>
                  Ateena on linn, mis on külastamist väärt! See on iidse
                  kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                </strong>
                Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib kindel
                olla, et Ateenast tagasi tulles tunned end puhanuna, sest linna
                lihtne elustiil lihtsalt ei lase stressil ligi tikkuda.
              </p>
              <p>
                Ja kui Sa veel Kreeka väikesaartele satud… Külastamiseks on
                sobivaim ajavahemik aprillist oktoobrini. Siiski on väga hea
                võimalus veeta oma talvepuhkus Ateenas turistide vähesuse tõttu.
              </p>
              <a href="javasscript:void(0);" className={styles.tabReadMore}>
                <i className="zmdi zmdi-sign-in"></i> Read More
              </a>
            </div>
          </Col>
          <Col md="6">
            <div className={styles.tabimage}>
              <img src={tabAbout} alt="About" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </UseFullInfoRowAccordion_Item>
      <UseFullInfoRowAccordion_Item title="Advices">
        <Row>
          <Col md="6">
            <div className={styles.tabText}>
              <p>
                <strong>
                  Ateena on linn, mis on külastamist väärt! See on iidse
                  kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                </strong>
                Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib kindel
                olla, et Ateenast tagasi tulles tunned end puhanuna, sest linna
                lihtne elustiil lihtsalt ei lase stressil ligi tikkuda.
              </p>
              <p>
                Ja kui Sa veel Kreeka väikesaartele satud… Külastamiseks on
                sobivaim ajavahemik aprillist oktoobrini. Siiski on väga hea
                võimalus veeta oma talvepuhkus Ateenas turistide vähesuse tõttu.
              </p>
              <a href="javasscript:void(0);" className={styles.tabReadMore}>
                <i className="zmdi zmdi-sign-in"></i> Read More
              </a>
            </div>
          </Col>
          <Col md="6">
            <div className={styles.tabimage}>
              <img src={tabAbout} alt="About" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </UseFullInfoRowAccordion_Item>
    </Container>
  );
};

const UseFullInfoRow = ({ heading }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <HorizontalBorder />
      <Container className={styles.customTabContainer}>
        <CustomStepRow heading={heading}></CustomStepRow>
        <div className={styles.customTabOptions}>
          <Nav tabs>
            <NavItem className={styles.customNavLink}>
              <NavLink
                className={`${classnames({ active: activeTab === "1" })} ${
                  styles.customNavLink
                }`}
                onClick={() => toggle("1")}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem className={styles.customNavLink}>
              <NavLink
                className={`${classnames({ active: activeTab === "2" })} ${
                  styles.customNavLink
                }`}
                onClick={() => toggle("2")}
              >
                Visas
              </NavLink>
            </NavItem>
            <NavItem className={styles.customNavLink}>
              <NavLink
                className={`${classnames({ active: activeTab === "3" })} ${
                  styles.customNavLink
                }`}
                onClick={() => toggle("3")}
              >
                Climate
              </NavLink>
            </NavItem>
            <NavItem className={styles.customNavLink}>
              <NavLink
                className={`${classnames({ active: activeTab === "4" })} ${
                  styles.customNavLink
                }`}
                onClick={() => toggle("4")}
              >
                Prices
              </NavLink>
            </NavItem>
            <NavItem className={styles.customNavLink}>
              <NavLink
                className={`${classnames({ active: activeTab === "5" })} ${
                  styles.customNavLink
                }`}
                onClick={() => toggle("5")}
              >
                Advices
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className={styles.CustomTabPanel}>
              <Row>
                <Col md="6">
                  <div className={styles.tabText}>
                    <p>
                      <strong>
                        Ateena on linn, mis on külastamist väärt! See on iidse
                        kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                      </strong>
                      Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                      omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib
                      kindel olla, et Ateenast tagasi tulles tunned end
                      puhanuna, sest linna lihtne elustiil lihtsalt ei lase
                      stressil ligi tikkuda.
                    </p>
                    <p>
                      Ja kui Sa veel Kreeka väikesaartele satud… Külastamiseks
                      on sobivaim ajavahemik aprillist oktoobrini. Siiski on
                      väga hea võimalus veeta oma talvepuhkus Ateenas turistide
                      vähesuse tõttu.
                    </p>
                    <a
                      href="javasscript:void(0);"
                      className={styles.tabReadMore}
                    >
                      <i className="zmdi zmdi-sign-in"></i> Read More
                    </a>
                  </div>
                </Col>
                <Col md="6">
                  <div className={styles.tabimage}>
                    <img src={tabAbout} alt="About" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className={styles.CustomTabPanel}>
              <Row>
                <Col md="6">
                  <div className={styles.tabText}>
                    <span>
                      <br></br>
                      Ateena on linn, mis on külastamist väärt! See on iidse
                      kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                    </span>
                    <br></br>
                    <p>
                      Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                      omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib
                      kindel olla, et Ateenast tagasi tulles tunned end
                      puhanuna, sest linna lihtne elustiil lihtsalt ei lase
                      stressil ligi tikkuda. Ja kui Sa veel Kreeka väikesaartele
                      satud… Külastamiseks on sobivaim ajavahemik aprillist
                      oktoobrini. Siiski on väga hea võimalus veeta oma
                      talvepuhkus Ateenas turistide vähesuse tõttu.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className={styles.tabimage}>
                    <img src={tabAbout} alt="About" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className={styles.CustomTabPanel}>
              <Row>
                <Col md="6">
                  <div className={styles.tabText}>
                    <span>
                      <br></br>
                      <br></br>
                      Ateena on linn, mis on külastamist väärt! See on iidse
                      kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                    </span>
                    <p>
                      <br></br>
                      <br></br>
                      Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                      omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib
                      kindel olla, et Ateenast tagasi tulles tunned end
                      puhanuna, sest linna lihtne elustiil lihtsalt ei lase
                      stressil ligi tikkuda. Ja kui Sa veel Kreeka väikesaartele
                      satud… Külastamiseks on sobivaim ajavahemik aprillist
                      oktoobrini. Siiski on väga hea võimalus veeta oma
                      talvepuhkus Ateenas turistide vähesuse tõttu.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className={styles.tabimage}>
                    <img src={tabAbout} alt="About" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="4">
            <div className={styles.CustomTabPanel}>
              <Row>
                <Col md="6">
                  <div className={styles.tabText}>
                    <span>
                      <br></br>
                      Ateena on linn, mis on külastamist väärt! See on iidse
                      kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                    </span>
                    <p>
                      <br></br>
                      Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                      omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib
                      kindel olla, et Ateenast tagasi tulles tunned end
                      puhanuna, sest linna lihtne elustiil lihtsalt ei lase
                      stressil ligi tikkuda. Ja kui Sa veel Kreeka väikesaartele
                      satud… Külastamiseks on sobivaim ajavahemik aprillist
                      oktoobrini. Siiski on väga hea võimalus veeta oma
                      talvepuhkus Ateenas turistide vähesuse tõttu.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className={styles.tabimage}>
                    <img src={tabAbout} alt="About" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="5">
            <div className={styles.CustomTabPanel}>
              <Row>
                <Col md="6">
                  <div className={styles.tabText}>
                    <span>
                      <br></br>
                      Ateena on linn, mis on külastamist väärt! See on iidse
                      kultuuri sünnikoht, mis tänapäeval aina edasi areneb.
                    </span>
                    <p>
                      <br></br>
                      Kesklinnast leiab ühe suurima jalakäijateala, mis liidab
                      omavahel mitmeid maailmakuulsaid vaatamisväärsusi. Võib
                      kindel olla, et Ateenast tagasi tulles tunned end
                      puhanuna, sest linna lihtne elustiil lihtsalt ei lase
                      stressil ligi tikkuda. Ja kui Sa veel Kreeka väikesaartele
                      satud… Külastamiseks on sobivaim ajavahemik aprillist
                      oktoobrini. Siiski on väga hea võimalus veeta oma
                      talvepuhkus Ateenas turistide vähesuse tõttu.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className={styles.tabimage}>
                    <img src={tabAbout} alt="About" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </Container>
    </React.Fragment>
  );
};

export {
  CarDetailCardRow,
  SupplierLocationRow,
  AvailableExtraRow,
  SecurityDepositRow,
  DriverAndLicenceRequirementsRow,
  FuelPolicyRow,
  MileageUnlimitedRow,
  UseFullInfoRow,
  UserFullInfoAccordion,
  CleaningPolicy,
  DriverAgePolicy,
};
