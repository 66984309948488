import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga";
import loadable from '@loadable/component';
import { Base64 } from 'js-base64';

import {
  getBlogs,
  getAllCountries,
  stripeKey,
  getSearchVehicleFilter,
  getPrivacyPageContent,
  // setUserCurrency,
} from "../../store/actions/config.action";
import Routes from "../../routes";
// import { Overlay, Toaster } from "../../components";
// import { askForPermissioToReceiveNotifications } from "../../push-notification";
import { setAppVersion } from "../../store/reducers/app.reducer";
import { version as p_version } from "../../../package.json";
import styles from "./styles.module.scss";
const AuthModalComponent = loadable(() => import('../../components/AuthModal'));
const Toasters = loadable(() => import('../../components/Toaster'));
const Overlay = loadable(() => import('../../components/Overlay'));


const stripePromise = loadStripe(
  "pk_live_Si945l9XPDx18kdtxtYZMl7r007q5hhi7w"
  // "pk_test_ie5Z9jgCVR0kQxqDdLsc5onz00z5z0IlrX"
);
// caches.keys().then(async function(names) {
//   console.log("mm"+names);
//   await Promise.all(names.map(name => caches.delete(name)));
// });
export const App = () => {
  const Dispatch = useDispatch();

  const {
    app: { response, message, type, isLoading, version },
  } = useSelector((state) => state);
  useEffect(() => {
    ReactGA.initialize({ trackingId: "G-0V44TJ092C" });
    Dispatch(stripeKey());
    Dispatch(getAllCountries());
    Dispatch(getSearchVehicleFilter());
    Dispatch(getBlogs());
    Dispatch(getPrivacyPageContent());
    if (version != p_version) {
      Dispatch(setAppVersion(p_version));
    }
  }, []);
  // const key = useSelector((state) => state.config.key);
  // console.log(key);
  // const stripePromise = loadStripe(Base64.decode(key));
  const Loader = () => {
    if (window.location.pathname.startsWith("/location"))
      return null
    else
      return isLoading && <Overlay />

  }
  return (
    <Elements stripe={stripePromise}>
      <div className={styles.Home}>
        <AuthModalComponent />
        <Routes />
        {Loader()}
        {response && message && <Toasters message={message} type={type} />}
      </div>
    </Elements>
  );
};
