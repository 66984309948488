import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";

import {
  DriverAndLicenceRequirementsRow,
  SupplierLocationRow,
  MileageUnlimitedRow,
  SecurityDepositRow,
  AvailableExtraRow,
  CarDetailCardRow,
  UseFullInfoRow,
  FuelPolicyRow,
  CleaningPolicy,
  DriverAgePolicy,
  UserFullInfoAccordion,
} from "./optionsUtils";
import { IncludedPriceRow } from "./../stepsUtils";
import { GET_LOCATION_NAME, BASE } from "./../../../../config/api";
import styles from "./styles.module.scss";

import {
  bookCar,
  selectDropOffStation,
  selectEndDate,
  selectPickUpStation,
  selectStartDate,
} from "../../../SearchPanel";
import { HorizontalBorder } from "../../../HorizontalRow";

export const OptionStep = ({
  searchResult,
  config,
  toggleExtras,
  searchParams,
}) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const { search, state } = useLocation();
  const Dispatch = useDispatch();
  const history = useHistory();

  let { selectedCurrency } = config;
  const WINDOW_WIDTH = window.innerWidth;
  useEffect(() => {
    try {
      window.scroll({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(600, 0);
    }
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          history.goBack();
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  const getLocationName = (params) => {
    try {
      axios
        .get(`${BASE}${GET_LOCATION_NAME}${params.get("checkin_station_code")}`)
        .then((res) => {
          let data = res.data.map((val) => {
            return {
              name: val.name,
              code: val.code,
            };
          });
          Dispatch(selectPickUpStation(data[0]));
        });

      axios
        .get(
          `${BASE}${GET_LOCATION_NAME}${params.get("checkout_station_code")}`
        )
        .then((res) => {
          let data = res.data.map((val) => {
            return {
              name: val.name,
              code: val.code,
            };
          });
          Dispatch(selectDropOffStation(data[0]));
        });
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    let params = new URLSearchParams(search);
    const checkin_station_code = params.get("checkin_station_code");
    const checkin_time = params.get("checkin_time");
    const checkout_station_code = params.get("checkout_station_code");
    const checkout_time = params.get("checkout_time");
    const contract_id = params.get("contract_id");
    const contract_margin = params.get("contract_margin");
    const currency = params.get("currency");
    const deposit = params.get("deposit");
    const duration_margin = params.get("duration_margin");
    const max_liab = params.get("max_liab");
    const rule_id = params.get("rule_id");
    const rule_margin = params.get("rule_margin");
    const vehicle_id = params.get("vehicle_id");
    let values = {
      checkin_station_code,
      checkout_station_code,
      checkin_time: checkin_time.split(" ").join("+"),
      checkout_time: checkout_time.split(" ").join("+"),
      vehicle_id: parseInt(vehicle_id),
      currency,
    };
    getLocationName();
    Dispatch(
      selectStartDate(
        params
          .get("checkin_time")
          .split(" ")
          .join("+")
      )
    );
    Dispatch(
      selectEndDate(
        params
          .get("checkout_time")
          .split(" ")
          .join("+")
      )
    );
    if (!state?.fromSearchResult) {
      Dispatch(bookCar(values, history.push));
    }
  }, []);

  const supplierDetails = {
    station_details:
      searchResult.selectedCar && searchResult.selectedCar.station_details,
    station_schedule:
      searchResult.selectedCar && searchResult.selectedCar.station_schedule,
  };

  return (
    <div className={styles.optionStep_container}>
      <CarDetailCardRow
        item={searchResult.selectedCar}
        searchParams={searchParams}
      />
      <IncludedPriceRow
        inclusives={
          searchResult.selectedCar && searchResult.selectedCar.inclusives
        }
        heading="Included in the price"
      />
      <SupplierLocationRow supplierDetails={supplierDetails} />
      <AvailableExtraRow
        heading="Protection & Extras"
        extras={searchResult.selectedCar && searchResult.selectedCar.extras}
        id={searchResult.selectedCar && searchResult.selectedCar.id}
        selectedCurrency={selectedCurrency}
        handleExtrasToggle={toggleExtras}
      />
      <DriverAndLicenceRequirementsRow
        driver_age={
          searchResult.selectedCar && searchResult.selectedCar.driver_age
        }
        heading="Driver & Licence Requirements"
      />
      <SecurityDepositRow
        heading={`Security Deposit`}
        currencySign={selectedCurrency.sign}
        securityDeposit={
          searchResult.selectedCar && searchResult.selectedCar.deposit
        }
      />
      <FuelPolicyRow heading="Fuel Policy" />
      <CleaningPolicy heading="Cleaning policy" />
      <MileageUnlimitedRow
        mileage={searchResult.selectedCar && searchResult.selectedCar.mileage}
        heading={`Mileage and cross-border`}
      />
      <HorizontalBorder />
      <CarDetailCardRow
        item={searchResult.selectedCar}
        searchParams={searchParams}
      />
    </div>
  );
};
