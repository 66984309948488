import React, { useState } from "react";
import { Progress } from "reactstrap";
import Slider from "react-slick";

import { CarType } from "./carTypeUtils";
import styles from "./styles.module.scss";

export const CarTypeFilter = ({
  searchResult: { suppliers_progress, sortByCarType, supplier_respond },
  carTypeFilter,
  config: { carTypes },
  supplier_progress_loading,
}) => {
  let [nav, setNav] = useState();
  let [isSwiping, setSwipe] = useState(false);
  const Width = window.innerWidth;

  const handleCarTypeClick = (value) => {
    if (!isSwiping && !supplier_progress_loading) {
      carTypeFilter(value.title);
    }
  };

  let limit = 0;
  let total = 0;
  if(supplier_respond == undefined)
  {
    supplier_respond = [];
  }
  if (Object.keys(supplier_respond).length > 0) {
    limit = supplier_respond.limit;
    total = supplier_respond.total;
  }
//   const ProgressElements = [10,20,30,40,50,60,70,80]
  return (
    <div className={styles.carTypePanel_container}>
      <Slider
        onSwipe={() => setSwipe(true)}
        afterChange={() => setSwipe(false)}
        asNavFor={nav}
        ref={(slider) => setNav(slider)}
        className="carTypePanel_slider"
        slidesToShow={Width > 767 ? 7 : 2}
        variableWidth={Width > 767 ? true : true}
        swipeToSlide={true}
        infinite={true}
        center={true}
      >
        {carTypes &&
          carTypes.map((value, i) => (
            <CarType
              {...value}
              key={i}
              idx={i}
              onClick={() => handleCarTypeClick(value)}
              active={sortByCarType}
            />
          ))}
      </Slider>
      <div className={styles.progressBar_container}>
        {suppliers_progress !== 100 && <p>Loading ...</p>}
        {/* value={supplier_respond === undefined ? 0 : (Object.keys(supplier_respond).length === 2
              ? isNaN((supplier_respond.limit / supplier_respond.total) * 75) ? 0 : (supplier_respond.limit / supplier_respond.total) * 75
              : 0)} */}
        <Progress
          barClassName={styles.progress}
          style={{ height: "7px" }}
          value={suppliers_progress===0 ? 25 : suppliers_progress === 50 ? 50 : suppliers_progress === 15 ? 75: suppliers_progress===20 ? 95:suppliers_progress===100?100:100}
          max={100}
        />

        {supplier_respond && (
          <p>
            {limit} of {total} supplier
            responded
          </p>
        )}
      </div>
    </div>
  );
};
