import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import { FooterBanner, FooterLinkContainer } from "./footerUtils";
import { BASE, SUBSCRIBE } from "./../../config/api";
import {
  ACTION_INITIATED,
  ACTION_SUCCESS,
  ACTION_FAILED
} from "../../store/reducers/app.reducer";
import styles from "./styles.module.scss";

export const Footer = () => {
  const Dispatch = useDispatch();
  const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const handleEmailSubscribe = email => {
    if (validateEmail(email)) {
      Dispatch({ type: ACTION_INITIATED });
      try {
        axios
          .post(`${BASE}${SUBSCRIBE}`, { email })
          .then(res => {
            Dispatch({
              type: ACTION_SUCCESS,
              message: "Thanks For Subscription"
            });
          })
          .catch(error => {
            Dispatch({ type: ACTION_FAILED, message: "Email cannot sent" });
          });
      } catch (err) {
        Dispatch({ type: ACTION_FAILED, message: "" });
      }
    } else {
      Dispatch({ type: ACTION_FAILED, message: "Enter a valid email address" });
    }
  };
  return (
    <section className={styles.footer_container}>
      <FooterBanner handleEmailSubscribe={handleEmailSubscribe} />
      <FooterLinkContainer />
    </section>
  );
};
