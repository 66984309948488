import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import ReactGA from "react-ga";

import { InputText, DropDownInput, ErrorViewer, Button } from "../../../../components";
import { updateUserProfile, clearProfileData } from "./../../../../store/actions/account.action";
import { MyAccountNavigation } from "./../../utils";
import { formikConfig_profile } from "./profileUtils";
import blueTick from "./../../../../assets/blueok-small.svg";
import greenTick from "./../../../../assets/greentick.svg";
import styles from "./styles.module.scss";

export const CustomProfileRow = ({ heading = "Account", children }) => (
  <section className={styles.customProfileRow}>
    <Row className={styles.customProfileRow_heading_container}>
      <Col md="12">
        <span className={`${styles.customProfileRow_heading}`}>{heading}</span>
      </Col>
    </Row>
    {children}
  </section>
);

export const Profile = () => {
  const Dispatch = useDispatch();
  const { push } = useHistory();
  const [selectedResidenceCountry, setResidenceCountry] = useState({});
  const [selectedCountryCode, setCountryCode] = useState({});
  const [selectedLicenceCountry, setLicenceCountry] = useState({});
  const [alternativeNumber, setAltNumVisibility] = useState(false);
  const [alternativeEmail, setAltEmailVisibility] = useState(false);

  const { user, isUserLoggedIn, token } = useSelector((state) => state.auth);
  const { success, v } = useSelector((state) => state.UserAccount);
  const Countries = useSelector((state) => state.config.allCountries);

  useEffect(() => {
    if (!token) {
      push("/");
    }
    if (user) {
      let res_country = Countries.find((v) => v.code === user.country_of_residence);
      let phone_code = Countries.find((v) => v.code === user.countryPhoneCode);
      let licence_country = Countries.find((v) => v.code === user.license_issue_country);
      res_country &&
        setResidenceCountry({
          value: res_country.code,
          label: res_country.name,
        });
      phone_code &&
        setCountryCode({
          value: phone_code.code,
          label: phone_code.name,
        });
      licence_country &&
        setLicenceCountry({
          value: licence_country.code,
          label: licence_country.name,
        });

      if (user.alt_email) {
        setAltEmailVisibility(true);
      }
      if (user.alt_phone_number) {
        setAltNumVisibility(true);
      }
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    return () => {
      Dispatch(clearProfileData());
    };
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = {
      first_name: values.first_name,
      last_name: values.last_name,
      contact: values.phoneNumber,
      license_number: values.licenceNumber,
      license_expiry_date: values.licenceExpireDate,
      license_issue_date: values.licenceIssueDate,
      license_issue_country: values.licenceIssuedCountry.value,
      country_of_residence: values.countryOfResidence.value,
      phonecode: values.countryPhoneCode.value,
      alt_email: values.alternativeEmail,
      birth_date: values.dateOfBirth,
      alt_phone_number: values.alternativePhoneNumber,
      ...(values.newPassword && { password: values.newPassword }),
    };
    Dispatch(updateUserProfile(formData, token, setSubmitting));
  };

  let initialFormValues = {
    first_name: user && user.first_name,
    last_name: user && user.last_name,
    dateOfBirth: user && user.birth_date,
    phoneNumber: user && user.contact,
    alternativePhoneNumber: user && user.alt_phone_number,
    countryOfResidence: selectedResidenceCountry,
    countryPhoneCode: {
      label: user.phonecode ? `+${user.phonecode}` : null,
      value: user.phonecode,
    },
    licenceIssuedCountry: selectedLicenceCountry,
    email: user && user.email,
    alternativeEmail: user && user.alt_email,
    newPassword: "",
    repeatPassword: "",
    licenceNumber: user && user.license_number,
    licenceIssueDate: user && user.license_issue_date,
    licenceExpireDate: user && user.license_expiry_date,
  };
  return (
    <React.Fragment>
      <Container>
        <MyAccountNavigation />
        <Formik
          enableReinitialize
          initialValues={isUserLoggedIn ? initialFormValues : formikConfig_profile.initialValues}
          validate={formikConfig_profile.validate}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
            <Form>
              <CustomProfileRow heading="Account Details">
                <Row className={styles.customProfileRow_body}>
                  <Col md="8">
                    <Row>
                      <Col md="6">
                        <InputText
                          type="text"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="First Name"
                        />
                        <ErrorViewer name="first_name" touched={touched} errors={errors} />
                      </Col>
                      <Col md="6">
                        <InputText
                          type="text"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Last Name"
                        />
                        <ErrorViewer name="last_name" touched={touched} errors={errors} />
                      </Col>
                      <Col md="6">
                        <InputText
                          type="date"
                          name="dateOfBirth"
                          placeholder="dd/mm/yyyy"
                          value={values.dateOfBirth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Date of birth"
                        />
                        <ErrorViewer name="dateOfBirth" touched={touched} errors={errors} />
                      </Col>
                      <Col md="6">
                        <DropDownInput
                          label="Country of residence"
                          name="countryOfResidence"
                          placeholder="Select Country"
                          value={values.countryOfResidence}
                          onBlur={handleBlur}
                          onChange={(val) => setFieldValue("countryOfResidence", val)}
                          options={
                            Countries &&
                            Countries.map((val) => ({
                              label: val.name,
                              value: val.code,
                            }))
                          }
                        />
                        <ErrorViewer name="countryOfResidence" touched={touched} errors={errors} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CustomProfileRow>

              <CustomProfileRow heading="E-mail & Phone">
                <Row className={styles.customProfileRow_body}>
                  <Col md="6">
                    <Row>
                      <Col className="col-5">
                        <DropDownInput
                          label="Country"
                          value={values.countryPhoneCode}
                          name="countryPhoneCode"
                          onBlur={handleBlur}
                          onChange={(val) => setFieldValue("countryPhoneCode", val)}
                          options={
                            Countries &&
                            Countries.map((val) => ({
                              label: (
                                <span>
                                  <img
                                    src={val.flag}
                                    width="50"
                                    className={styles.flagicon}
                                    alt="country-logo"
                                  />
                                  +{val.phonecode}
                                </span>
                              ),
                              value: val.phonecode,
                            }))
                          }
                        />
                        <ErrorViewer name="countryPhoneCode" touched={touched} errors={errors} />
                      </Col>

                      <Col className="col-7">
                        <InputText
                          type="number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Number"
                        />
                        <ErrorViewer name="phoneNumber" touched={touched} errors={errors} />
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6" className="justify-content-end d-flex flex-column">
                    <Row>
                      <Col className="col-6" className={styles.add_alternative_container}>
                        <p
                          onClick={() => {
                            setAltNumVisibility((prev) => !prev);
                            setFieldValue("alternativePhoneNumber", "");
                          }}
                        >
                          {`${
                            alternativeNumber || values.alternativePhoneNumber ? "Remove" : "Add"
                          } alternative`}
                        </p>
                      </Col>
                      <Col className="col-5" className={styles.verified_container}>
                        <span>
                          <img src={greenTick} alt="icon" />
                          <p>Verified</p>
                        </span>
                      </Col>
                    </Row>
                  </Col>

                  {/* Alternatvie Phone Number Field  Starts*/}
                  {(alternativeNumber || values.alternativePhoneNumber) && (
                    <React.Fragment>
                      <Col md="6" className="animated fadeIn">
                        <InputText
                          type="number"
                          name="alternativePhoneNumber"
                          value={values.alternativePhoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Alternative Number"
                        />
                      </Col>
                      <Col md="6"></Col>
                    </React.Fragment>
                  )}
                  {/* Alternatvie Phone Number Field  Ends*/}

                  <Col md="6">
                    <InputText
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="E-mail"
                    />
                    <ErrorViewer name="email" touched={touched} errors={errors} />
                  </Col>
                  <Col md="6" className="d-flex justify-content-end flex-column">
                    <Row>
                      <Col className={`${styles.add_alternative_container} col-6`}>
                        <p
                          onClick={() => {
                            setAltEmailVisibility((prev) => !prev);
                            setFieldValue("alternativeEmail", "");
                          }}
                        >
                          {`${
                            alternativeEmail || values.alternativeEmail ? "Remove" : "Add"
                          } alternative`}
                        </p>
                      </Col>
                      <Col className={`${styles.verified_container} col-6`}>
                        <span>
                          <img src={greenTick} alt="icon" />
                          <p>Verified</p>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  {/* Alternatvie Phone Number Field  Starts*/}
                  {(alternativeEmail || values.alternativeEmail) && (
                    <React.Fragment>
                      <Col md="6" className="animated fadeIn">
                        <InputText
                          type="email"
                          name="alternativeEmail"
                          value={values.alternativeEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Alternative Email"
                        />
                      </Col>
                    </React.Fragment>
                  )}
                  {/* Alternatvie Phone Number Field  Ends*/}
                </Row>
              </CustomProfileRow>

              <CustomProfileRow heading="Password">
                <Row className={styles.customProfileRow_body}>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <InputText
                          type="password"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Type new password"
                        />
                        <ErrorViewer name="newPassword" touched={touched} errors={errors} />
                      </Col>
                      <Col md="6">
                        <InputText
                          type="password"
                          name="repeatPassword"
                          value={values.repeatPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Repeat password"
                        />
                        <ErrorViewer name="repeatPassword" touched={touched} errors={errors} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CustomProfileRow>

              <CustomProfileRow heading="Driving Licence">
                <Row className={styles.customProfileRow_body}>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <InputText
                          type="text"
                          name="licenceNumber"
                          value={values.licenceNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Number"
                        />
                        <ErrorViewer name="licenceNumber" touched={touched} errors={errors} />
                      </Col>
                      <Col md="6">
                        <DropDownInput
                          label="Where issued"
                          name="licenceIssuedCountry"
                          placeholder="Select Country"
                          value={values.licenceIssuedCountry}
                          onBlur={handleBlur}
                          onChange={(val) => setFieldValue("licenceIssuedCountry", val)}
                          options={
                            Countries &&
                            Countries.map((val) => ({
                              label: val.name,
                              value: val.code,
                            }))
                          }
                        />
                        <ErrorViewer
                          name="licenceIssuedCountry"
                          touched={touched}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6">
                        <InputText
                          type="date"
                          name="licenceIssueDate"
                          value={values.licenceIssueDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Date issued"
                        />
                        <ErrorViewer name="licenceIssueDate" touched={touched} errors={errors} />
                      </Col>
                      <Col md="6">
                        <InputText
                          type="date"
                          name="licenceExpireDate"
                          value={values.licenceExpireDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Date expire"
                        />
                        <ErrorViewer name="licenceExpireDate" touched={touched} errors={errors} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CustomProfileRow>
              <div className={styles.saveButton_container}>
                <Button blueBG type="submit" disabled={isSubmitting}>
                  Save
                </Button>
                {success && (
                  <div className={styles.message_container}>
                    <img src={blueTick} />
                    <span>Thank you, data has been saved</span>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};


export default Profile;