import { _isUserLoggedIn } from "./index";
export const formikConfig_driver = {
  initialValues: {
    first_name: "",
    last_name: "",
    phone_number: "",
    phonecode: "",
    driver_license_number: "",
    driving_experience: "",
    email: "",
    driver_age: "",
    email_checked: false,
    flight_number: "",
  },
  validate: (values) => {

    let errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.first_name) {
      errors.first_name = "Required";
    }
    if (!values.last_name) {
      errors.last_name = "Required";
    }
    if (!values.driving_experience) {
      errors.driving_experience = "Required";
    }
    if (!values.driving_experience) {
      errors.driving_experience = "Required";
    }
    if (values.driving_experience > values.driver_age) {
      errors.driving_experience = "Experience cannot be greater than age";
    }
    if (values.driver_age < 20) {
      errors.driver_age = "Age should not be less than 20";
    }
    if (!values.phone_number) {
      errors.phone_number = "Required";
    }
    if (!values.phonecode) {
      errors.phonecode = "Required";
    }
    if (!_isUserLoggedIn) {
      if (!values.email_confirmation) {
        errors.email_confirmation = "Required";
      } else if (values.email !== values.email_confirmation) {
        errors.email_confirmation = "Email no matched";
      }
    }
    return errors;
  },
};
