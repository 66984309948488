import React from "react";
import { Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";

import {
  ChatLink,
  UserCountry,
  CurrencyChange,
  LanguageChange,
  TopbarButton
} from "./topBarUtils";
import { logout } from "../../../store/actions/auth.action";
import styles from "./styles.module.scss";
import { useHistory, useLocation, NavLink as Link } from "react-router-dom";

export const TopBar = ({ showModal, authState }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const Dispatch = useDispatch();
  const { isUserLoggedIn, user } = authState;

  const handleClick = () => {
    if (pathname.startsWith("/account")) {
      push("/");
    }
    Dispatch(logout());
  };

  return (
    <div className={styles.header_top}>
      <Row>
        <Col md="2">
          <p>Worldwide Car Rental</p>
        </Col>
        <Col md="10" className={styles.text_right}>
          <ul className={styles.header_link}>
            <UserCountry />
            <LanguageChange />
            <CurrencyChange />
            {isUserLoggedIn ? (
              <>
                <Link to={"/account/bookings"}><span className={styles.loggedInUserName}>My Account</span></Link>
                <TopbarButton title="Logout" onClick={handleClick} />
              </>
            ) : (
              <>
                <TopbarButton
                  title="Login"
                  onClick={() => showModal("loginModal")}
                />
                <TopbarButton
                  title="Sign up"
                  onClick={() => showModal("signupModal")}
                />
              </>
            )}
          </ul>
        </Col>
      </Row>
    </div>
  );
};
