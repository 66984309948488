// GET BOOKED BOOKINGS
export const GET_BOOKED_BOOKINGS = "GET_BOOKED_BOOKINGS";
export const GET_BOOKED_BOOKINGS_SUCCESS = "GET_BOOKED_BOOKINGS_SUCCESS";
export const GET_BOOKED_BOOKINGS_FAILED = "GET_BOOKED_BOOKINGS_FAILED";
export const DELETE_BOOKING_PHOTOS_BY_ID = "DELETE_BOOKING_PHOTOS_BY_ID";

export const getBookedBookings = (userId, token) => ({
  type: GET_BOOKED_BOOKINGS,
  userId,
  token
});

// GET EXPIRED BOOKINGS
export const GET_EXPIRED_BOOKINGS = "GET_EXPIRED_BOOKINGS";
export const GET_EXPIRED_BOOKINGS_SUCCESS = "GET_EXPIRED_BOOKINGS_SUCCESS";
export const GET_EXPIRED_BOOKINGS_FAILED = "GET_EXPIRED_BOOKINGS_FAILED";

export const getExpiredBookings = (userId, token) => ({
  type: GET_EXPIRED_BOOKINGS,
  userId,
  token
});

// BOOKINGS DETAILS
export const GET_BOOKING_DETAIL = "GET_BOOKING_DETAIL";
export const GET_BOOKING_DETAIL_SUCCESS = "GET_BOOKING_DETAIL_SUCCESS";
export const GET_BOOKING_DETAIL_FAILED = "GET_BOOKING_DETAIL_FAILED";

export const getBookingDetail = (id, token, push) => ({
  type: GET_BOOKING_DETAIL,
  id,
  token,
  push
});

export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_FAILED = "CANCEL_BOOKING_FAILED";

export const cancelBooking = (payload, hideModal, token) => ({
  type: CANCEL_BOOKING,
  payload,
  hideModal,
  token
});

export const deleteBookingPhotosById = id => ({
  type: DELETE_BOOKING_PHOTOS_BY_ID,
  id
});

// PROFILE UPDATE
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";

export const CLEAR_PROFILE_DATA = "CLEAR_PROFILE_DATA";

export const updateUserProfile = (payload, token, setSubmitting) => ({
  type: UPDATE_USER_PROFILE,
  setSubmitting,
  payload,
  token
});

export const clearProfileData = () => ({
  type: CLEAR_PROFILE_DATA
});
