import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { rootInitialState, rootReducer } from "./reducers/index";
import { epics } from "./epics/index";

const migrations = {
  0: (state) => {
    console.log("state", state);
    // migration clear out device state
    return {
      ...state,
      device: undefined,
    };
  },
  1: (state) => {
    // migration to keep only device state
    return {
      device: state.device,
    };
  },
};

const persistConfig = {
  key: "root",
  storage,
  version: 3,
  blacklist: ["app", "modalState"],
  migrate: createMigrate(migrations, { debug: false }),
};

const logger = createLogger({
  collapsed: true,
  predicate: (getState: Function, action: Object) => {
    if (action.type && action.type.startsWith("@@redux-form")) {
      return false;
    } else {
      return true;
    }
  },
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddlewares = createEpicMiddleware();

const store = createStore(
  persistedReducer,
  rootInitialState,
  composeWithDevTools(applyMiddleware(epicMiddlewares, logger))
);

const persistor = persistStore(store);

epicMiddlewares.run(epics);

export { store, persistor };
