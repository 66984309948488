export const UPLOAD_VEHICLE_IMAGES = "UPLOAD_VEHICLE_IMAGES";
export const UPLOAD_VEHICLE_IMAGES_SUCCESS = "UPLOAD_VEHICLE_IMAGES_SUCCESS";
export const UPLOAD_VEHICLE_IMAGES_FAILED = "UPLOAD_VEHICLE_IMAGES_FAILED";
export const UPLOAD_VEHICLE_IMAGES_CLEAR = "UPLOAD_VEHICLE_IMAGES_CLEAR";

export const DELETE_VEHICLE_IMAGE = "DELETE_VEHICLE_IMAGE";
export const DELETE_VEHICLE_IMAGE_SUCCESS = "DELETE_VEHICLE_IMAGE_SUCCESS";
export const DELETE_VEHICLE_IMAGE_FAILED = "DELETE_VEHICLE_IMAGE_FAILED";

export const uploadVehicleImages = (token, payload) => ({
  type: UPLOAD_VEHICLE_IMAGES,
  payload,
  token
});

export const clearUploadVehicleState = () => ({
  type: UPLOAD_VEHICLE_IMAGES_CLEAR
});

export const deleteVehicleImage = (token, payload, hideModal) => ({
  type: DELETE_VEHICLE_IMAGE,
  payload,
  token,
  hideModal
});
