import React, { useState } from "react";
import { Col, Row, Container, Button, Tooltip } from "reactstrap";
import { PropagateLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { css } from "@emotion/core";

import { CarCard } from "../";
import styles from "./styles.module.scss";

const override = css`
  display: block;
  margin: 50px auto;
  border-color: red;
`;

export const CardsContainer = ({
  carList,
  selectCar,
  loadmore_loading,
  show_loadmore_button,
  handleBookCar,
  searchParams,
  handleLoadMore,
}) => {
  const { suppliers_progress } = useSelector((state) => state.searchResult);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  let showLoadMoreButton = false;
  if (suppliers_progress === 100) {
    showLoadMoreButton = true;
    if (loadmore_loading) {
      showLoadMoreButton = false;
    }
    if (!show_loadmore_button) {
      showLoadMoreButton = false;
    }
  }

  const toggle = () => {
    if (suppliers_progress === 100) {
      setTooltipOpen(false);
    } else {
      setTooltipOpen(!tooltipOpen);
    }
  };

  let noCarsFound = suppliers_progress == 100 && carList.length < 1;

  return (
    <React.Fragment>
      <Container>
        <Row style={{ marginTop: -30 }}>
          {carList.map((v, i) => (
            <Col md="4" sm="6" key={i} className={`${styles.carColumn} col-12`}>
              <CarCard
                searchParams={searchParams}
                item={v}
                handleBookCar={handleBookCar}
              />
            </Col>
          ))}
        </Row>
        <div className={styles.loadmore_container}>
          <PropagateLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={"#035372"}
            loading={loadmore_loading}
          />
          {noCarsFound ? (
            <p>No cars found</p>
          ) : (
            showLoadMoreButton && (
              <div>
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Please wait while fetch all the cars ...
                </Tooltip>
                <Button
                  className={`loadmore_btn ${carList.length<9 ? "d-none" : ""}`}
                  onClick={handleLoadMore}
                  color="primary"
                  disabled={!carList.length || suppliers_progress !== 100}
                >
                  <p
                    id="TooltipExample"
                    style={
                      suppliers_progress === 100
                        ? { marginTop: 10, color: "#000" }
                        : { marginTop: 10, color: "#fff" }
                    }
                  >
                    Load more
                  </p>
                </Button>
              </div>
            )
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};
