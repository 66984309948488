import React, { useState, useCallback, Redirect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { GET_LOCATION_NAME, BASE } from '../../config/api';
import { NavLink as Link, useHistory, useLocation } from 'react-router-dom';
import {
	setLocationHeaderInformation,
	setLocationBlogs,
} from './../../store/actions/config.action';
import moment from 'moment';
import {
	selectDropOffStation,
	selectEndDate,
	selectPickUpStation,
	selectStartDate,
} from './../SearchPanel';
import {
	PlaceCardComponent,
	BlogLocationComponent,
	MobileAppSection,
	BlogCardSlider,
} from './../../components';
import { getLocationData } from '../../store/actions/config.action';
import { getCars, getAvailableCars } from '../SearchPanel/searchPanel.action';
import './styles.css';

const Price = () => {
	const { locationHeaderInformation, selectedCurrency } = useSelector(
		(state) => state.config
	);
	return (
		<Container>
			<Row className='py-3'>
				<div className='text-center col-lg-3 col-md-6 col-sm-12 py-3 inline'>
					<span className='dot'> </span>{' '}
					<p>
						&nbsp; Local Currency: {locationHeaderInformation.currency_name}
					</p>
				</div>
				<div className='text-center col-lg-3 col-md-6 col-sm-12 py-3 inline'>
					<span className='dot'> </span>{' '}
					<p>&nbsp; Gas Price: € {locationHeaderInformation.gas_price}</p>
				</div>
				<div className='text-center col-lg-3 col-md-6 col-sm-12 py-3 inline'>
					<span className='dot'> </span>{' '}
					<p>
						&nbsp; Traffic Direction: {locationHeaderInformation.driving_side}
					</p>
				</div>
				<div className='text-center col-lg-3 col-md-6 col-sm-12 py-3 inline'>
					<span className='dot'> </span>{' '}
					<p>
						&nbsp; Rental Offer: € {locationHeaderInformation.car_rentaal_offer}
						/day
					</p>
				</div>
			</Row>
		</Container>
	);
};

const Temp = () => {
	const { locationHeaderInformation } = useSelector((state) => state.config);
	function color(temp) {
		if (temp >= -39.0 && temp < -38.0) {
			return 'rgb(0,0,255)';
		}
		if (temp >= -38.0 && temp < -37.0) {
			return 'rgb(2,2,255)';
		}
		if (temp >= -37.0 && temp < -36.0) {
			return 'rgb(10,10,255)';
		}
		if (temp >= -36.0 && temp < -35.0) {
			return 'rgb(15,15,255)';
		}
		if (temp >= -35.0 && temp < -34.0) {
			return 'rgb(20,20,255)';
		}
		if (temp >= -34.0 && temp < -33.0) {
			return 'rgb(25,25,255)';
		}
		if (temp >= -33.0 && temp < -32.0) {
			return 'rgb(30,30,255)';
		}
		if (temp >= -32.0 && temp < -31.0) {
			return 'rgb(36,36,255)';
		}
		if (temp >= -31.0 && temp < -30.0) {
			return 'rgb(42,42,255)';
		}
		if (temp >= -30.0 && temp < -29.0) {
			return 'rgb(48,48,255)';
		}
		if (temp >= -29.0 && temp < -28.0) {
			return 'rgb(54,54,255)';
		}
		if (temp >= -28.0 && temp < -27.0) {
			return 'rgb(63,63,255)';
		}
		if (temp >= -27.0 && temp < -26.0) {
			return 'rgb(66,66,255)';
		}
		if (temp >= -26.0 && temp < -25.0) {
			return 'rgb(75,75,255)';
		}
		if (temp >= -25.0 && temp < -24.0) {
			return 'rgb(79,79,255)';
		}
		if (temp >= -24.0 && temp < -23.0) {
			return 'rgb(85,85,255)';
		}
		if (temp >= -23.0 && temp < -22.0) {
			return 'rgb(92,92,255)';
		}
		if (temp >= -22.0 && temp < -21.0) {
			return 'rgb(98,98,255)';
		}
		if (temp >= -21.0 && temp < -20.0) {
			return 'rgb(105,105,255)';
		}
		if (temp >= -20.0 && temp < -19.0) {
			return 'rgb(112,112,255)';
		}
		if (temp >= -19.0 && temp < -18.0) {
			return 'rgb(117,117,255)';
		}

		if (temp >= -18.0 && temp < -17.0) {
			return 'rgb(122,122,255)';
		}

		if (temp >= -17.0 && temp < -16.0) {
			return 'rgb(129,129,255)';
		}

		if (temp >= -16.0 && temp < -15.0) {
			return 'rgb(136,136,255)';
		}
		if (temp >= -15.0 && temp < -14.0) {
			return 'rgb(141,141,255)';
		}
		if (temp >= -14.0 && temp < -13.0) {
			return 'rgb(144,144,255)';
		}

		if (temp >= -13.0 && temp < -12.0) {
			return 'rgb(151,151,255)';
		}

		if (temp >= -12.0 && temp < -11.0) {
			return 'rgb(160,160,255)';
		}
		if (temp >= -11.0 && temp < -10.0) {
			return 'rgb(166,166,255)';
		}
		if (temp >= -10.0 && temp < -9.0) {
			return 'rgb(169,169,255)';
		}
		if (temp >= -9.0 && temp < -8.0) {
			return 'rgb(178,178,255)';
		}
		if (temp >= -8.0 && temp < -7.0) {
			return 'rgb(180,180,255)';
		}
		if (temp >= -7.0 && temp < -6.0) {
			return 'rgb(189,189,255)';
		}
		if (temp >= -6.0 && temp < -5.0) {
			return 'rgb(197,197,255)';
		}
		if (temp >= -5.0 && temp < -4.0) {
			return 'rgb(199,199,255)';
		}
		if (temp >= -4.0 && temp < -3.0) {
			return 'rgb(205,205,255)';
		}
		if (temp >= -3.0 && temp < -2.0) {
			return 'rgb(211,211,255)';
		}
		if (temp >= -2.0 && temp < -1.0) {
			return 'rgb(218,218,255)';
		}
		if (temp >= -1.0 && temp < 0) {
			return 'rgb(226,226,255)';
		}

		if (temp === 0) {
			return 'rgb(228,228,255)';
		}
		if (temp <= 1.0 && temp > 0) {
			return 'rgb(231,231,255)';
		}
		if (temp <= 2.0 && temp > 1.0) {
			return 'rgb(237,237,255)';
		}
		if (temp <= 3.0 && temp > 2.0) {
			return 'rgb(242,242,255)';
		}
		if (temp <= 4.0 && temp > 3.0) {
			return 'rgb(247,247,255)';
		}
		if (temp <= 5.0 && temp > 4.0) {
			return 'rgb(253,253,237)';
		}
		if (temp <= 6.0 && temp > 5.0) {
			return 'rgb(255,245,241)';
		}
		if (temp <= 7.0 && temp > 6.0) {
			return 'rgb(255,233,224)';
		}
		if (temp <= 8.0 && temp > 7.0) {
			return 'rgb(255,222,209)';
		}
		if (temp <= 9.0 && temp > 8.0) {
			return 'rgb(255,217,202)';
		}
		if (temp <= 10.0 && temp > 9.0) {
			return 'rgb(255,206,187)';
		}
		if (temp <= 11.0 && temp > 10.0) {
			return 'rgb(255,196,173)';
		}
		if (temp <= 12.0 && temp > 11.0) {
			return 'rgb(255,186,160)';
		}
		if (temp <= 13.0 && temp > 12.0) {
			return 'rgb(255,171,139)';
		}
		if (temp <= 14.0 && temp > 13.0) {
			return 'rgb(255,163,128)';
		}
		if (temp <= 15.0 && temp > 14.0) {
			return 'rgb(255,154,115)';
		}
		if (temp <= 16.0 && temp > 15.0) {
			return 'rgb(255,143,100)';
		}
		if (temp <= 17.0 && temp > 16.0) {
			return 'rgb(255,137,92)';
		}
		if (temp <= 18.0 && temp > 17.0) {
			return 'rgb(255,129,81)';
		}
		if (temp <= 19.0 && temp > 18.0) {
			return 'rgb(255,118,66)';
		}
		if (temp <= 20.0 && temp > 19.0) {
			return 'rgb(255,100,41)';
		}
		if (temp <= 21.0 && temp > 20.0) {
			return 'rgb(255,92,31)';
		}
		if (temp <= 22.0 && temp > 21.0) {
			return 'rgb(255,81,15)';
		}
		if (temp <= 23.0 && temp > 22.0) {
			return 'rgb(255,72,2)';
		}
		if (temp <= 24.0 && temp > 23.0) {
			return 'rgb(255,62,0)';
		}
		if (temp <= 25.0 && temp > 24.0) {
			return 'rgb(255,55,0)';
		}
		if (temp <= 26.0 && temp > 25.0) {
			return 'rgb(239,38,0)';
		}
		if (temp <= 27.0 && temp > 26.0) {
			return 'rgb(232,32,0)';
		}
		if (temp <= 28.0 && temp > 27.0) {
			return 'rgb(219,22,0)';
		}
		if (temp <= 29.0 && temp > 28.0) {
			return 'rgb(210,15,0)';
		}
		if (temp <= 30.0 && temp > 29.0) {
			return 'rgb(192,1,0)';
		}
		if (temp <= 31.0 && temp > 30.0) {
			return 'rgb(189,0,0)';
		}
		if (temp <= 32.0 && temp > 31.0) {
			return 'rgb(170,0,0)';
		}
		if (temp <= 33.0 && temp > 32.0) {
			return 'rgb(157,0,0)';
		}
		if (temp <= 34.0 && temp > 33.0) {
			return 'rgb(144,0,0)';
		}
		if (temp <= 35.0 && temp > 34.0) {
			return 'rgb(131,0,0)';
		}
		if (temp <= 36.0 && temp > 35.0) {
			return 'rgb(118,0,0)';
		}
		if (temp <= 37.0 && temp > 36.0) {
			return 'rgb(105,0,0)';
		}
		if (temp <= 38.0 && temp > 37.0) {
			return 'rgb(92,0,0)';
		}
		if (temp <= 39.0 && temp > 38.0) {
			return 'rgb(79,0,0)';
		}
		if (temp <= 40.0 && temp > 39.0) {
			return 'rgb(66,0,0)';
		}
	}

	function precp(temp) {
		let value = 0;
		if (temp > 0 && temp <= 15) {
			value = 15;
		}
		if (temp > 15 && temp <= 30) {
			value = 30;
		}
		if (temp > 30 && temp <= 45) {
			value = 45;
		}
		if (temp > 45 && temp <= 60) {
			value = 60;
		}
		if (temp > 60 && temp <= 75) {
			value = 75;
		}
		if (temp > 75 && temp <= 90) {
			value = 90;
		}
		if (temp > 90 && temp <= 105) {
			value = 105;
		}
		if (temp > 105 && temp <= 120) {
			value = 120;
		}
		if (temp > 120 && temp <= 135) {
			value = 135;
		}
		if (temp > 135 && temp <= 150) {
			value = 150;
		}
		if (temp > 150 && temp <= 165) {
			value = 165;
		}
		if (temp > 165 && temp <= 180) {
			value = 180;
		}
		if (temp > 180 && temp <= 195) {
			value = 195;
		}
		if (temp > 195 && temp <= 210) {
			value = 210;
		}
		if (temp > 210 && temp <= 225) {
			value = 225;
		}
		if (temp > 225 && temp <= 240) {
			value = 240;
		}
		if (temp > 240 && temp <= 255) {
			value = 255;
		}
		if (temp > 255 && temp <= 270) {
			value = 270;
		}
		if (temp > 270 && temp <= 285) {
			value = 285;
		}
		if (temp > 285 && temp <= 300) {
			value = 300;
		}

		if (value !== 0) {
			return 'precipitation precipitation-' + value;
		} else {
			return 'precipitation precipitation-' + value;
		}
	}
	let tmp_type_name = '';
	if (locationHeaderInformation.temp2.type) {
		if (locationHeaderInformation.temp2.type.includes('Precipitation')) {
			tmp_type_name = 'Precipitation (mm)';
		} else {
			tmp_type_name = locationHeaderInformation.temp2.type;
		}
	}
	return (
		<Container className='mb-5'>
			<Row className='around'>
				<div className='text-center col-lg-12 col-md-6 col-sm-12 py-3'>
					<h2>
						Average tempratures around the year in{' '}
						{locationHeaderInformation.location}
					</h2>
				</div>
				<div className='around1 mb-5 content text-center col-lg-11 col-md-6 col-sm-12 py-3 px-5 scroll'>
					<table className='table'>
						<thead>
							<tr>
								<th></th>
								<th className='best-travel-month'>Jan</th>
								<th className='best-travel-month'>Feb</th>
								<th className='best-travel-month'>Mar</th>
								<th className='best-travel-month'>Apr</th>
								<th className='best-travel-month'>May</th>
								<th className='best-travel-month'>Jun</th>
								<th>Jul</th>
								<th>Aug</th>
								<th className='best-travel-month'>Sep</th>
								<th className='best-travel-month'>Oct</th>
								<th className='best-travel-month'>Nov</th>
								<th className='best-travel-month'>Dec</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Temperature (°C)</th>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.jan ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.jan ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.jan ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.feb ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.feb ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.feb ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.mar ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.mar ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.mar ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.apr ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.apr ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.apr ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.may ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.may ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.may ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.jun ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.jun ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.jun ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.jul ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.jul ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.jul ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.aug ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.aug ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.aug ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.sep ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.sep ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.sep ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.oct ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.oct ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.oct ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.nov ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.nov ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.nov ?? '-'}
								</td>
								<td
									className={color(
										locationHeaderInformation.complete_temperature.dec ?? 'null'
									)}
									style={{
										backgroundColor: color(
											locationHeaderInformation.complete_temperature.dec ??
												'null'
										),
									}}
								>
									{locationHeaderInformation.complete_temperature.dec ?? '-'}
								</td>
							</tr>
							{/* <tr>
								<th>{tmp_type_name}</th>
								<td
									className={precp(
										locationHeaderInformation.temp2.jan ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.jan ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.feb ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.feb ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.mar ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.mar ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.apr ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.apr ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.may ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.may ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.jun ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.jun ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.jul ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.jul ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.aug ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.aug ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.sep ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.sep ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.oct ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.oct ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.nov ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.nov ?? '-'}
								</td>
								<td
									className={precp(
										locationHeaderInformation.temp2.dec ?? 'null'
									)}
								>
									{locationHeaderInformation.temp2.dec ?? '-'}
								</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			</Row>
		</Container>
	);
};
const Blogs = () => {
	const { locationBlogs } = useSelector((state) => state.config);
	let blogsData = [];
	if (locationBlogs !== undefined) {
		blogsData = locationBlogs.blogs;
	}

	let blogs = blogsData.map((val) => {
		return {
			title: val.title,
			imgSrc: val.image,
			readmore_link: val.link,
			description: val.description,
			alt: 'thumbnail',
		};
	});
	return (
		<BlogLocationComponent
			blogs_data={blogs}
			title={'Rentalmoose’s travelguides for the area'}
			link='https://blog.rentalmoose.com/category/travel-guides'
			showMoose='false'
		/>
	);
};
const Things = () => {
	const { locationHeaderInformation } = useSelector((state) => state.config);
	return (
		<Container>
			<div className='text-center col-lg-12 col-md-6 col-sm-12 py-3'>
				<h2>Things to do in {locationHeaderInformation.location}</h2>
			</div>
			<div>
				<h4>
					<p>Are You A Fan Of National Parks?</p>
				</h4>
				<p>
					National Park lovers should definitely take a look at this blog post,
					where we list out the 7 best National Park road trips you need to add
					to your bucket list next year!
				</p>
			</div>
			<div className='mt-1'>
				<h3>
					<p>Lake Mead National Recreation Area</p>
				</h3>
				<p>
					Lake Mead is an artificial reservoir that was America’s first-ever
					National Recreation Area. It is only about 25 miles out of the center
					of Las Vegas, and is definitely worth the slight detour!
				</p>

				<p>
					This National Park is packed with fun attractions to spend the day
					(and night!). You can hike around the massive slot canyons, swim in
					the clear waters, or get a different perspective during a boat tour of
					the lake. After dusk falls, the night skies are ideal for stargazing.
				</p>

				<p>When in Lake Mead, you can’t skip the iconic Hoover Dam!</p>

				<p>
					Consider spending the night at one of the local campgrounds. Keep in
					mind that there is no reservation system, spots are subject to a
					first-come, first-serve availability.
				</p>

				<p>
					You can learn more about Lake Mead and its local attractions on the
					park’s official website.
				</p>

				<p>
					An entrance pass to Lake Mead costs $25 per vehicle, it is valid from
					7 days from the date of purchase.
				</p>
			</div>
		</Container>
	);
};
const LocationImage = () => {
	const { locationHeaderInformation } = useSelector((state) => state.config);
	console.log('||||||');
	console.log(locationHeaderInformation.image_2);
	return (
		<>
			<div
				className='location_header_banner img-fluid'
				style={{
					background: `url(${locationHeaderInformation.image_2}) no-repeat`,
				}}
			></div>
			<div className='Paragraph px-2 mt-2 float-right'>
				<p className='mr-1'>{locationHeaderInformation.image_name}</p>
			</div>
		</>
	);
};
const NearestLocation = () => {
	const { locationHeaderInformation } = useSelector((state) => state.config);

	const history = useHistory();
	const handleClick = (name) => () => {
		//let name = text.toLowerCase();
		history.push('/location/' + name);

		locationInfo(name);
	};
	const { pathname } = useLocation();
	let [checkinLocationFromURL, setCheckinLocation] = useState({});
	let [checkoutLocationFromURL, setCheckoutLocation] = useState({});
	const Dispatch = useDispatch();
	const locationInfo = (url) => {
		console.log(url);
		axios.get(`${BASE}/location/${url}`).then((result) => {
			console.log('****************');
			console.log(result.data.data.station_code[0].code);
			Dispatch(
				setLocationHeaderInformation(
					false
						? false
						: {
								temp: result.data.data.current_temperature,
								current_time: result.data.data.current_time,
								complete_temperature: result.data.data.complete_temperature,
								nearest_locations: result.data.data.nearest_locations,
								currency_name: result.data.data.currency_name,
								temp2: result.data.data.temp2,
								gas_price: result.data.data.gas_price,
								driving_side: result.data.data.driving_side,
								image_1: result.data.data.image1,
								image_name: result.data.data.image_1_name,
								image_2: result.data.data.image2,
								car_rentaal_offer: result.data.data.car_rentaal_offer,
								location: result.data.data.location,
								station_code: result.data.data.station_code,
								station_pick_code: result.data.data.station_code[0].code,
								code: result.data.data.code,
								response: true,
						  }
				)
			);
			Dispatch(
				selectStartDate(
					moment()
						.add(1, 'days')
						.format()
				)
			);
			Dispatch(
				selectEndDate(
					moment()
						.add(2, 'days')
						.format()
				)
			);
			setCheckinLocation(result.data.data.station_code[0]);
			Dispatch(selectPickUpStation(result.data.data.station_code[0]));
			setCheckoutLocation(result.data.data.station_code[0]);
			selectDropOffStation(result.data.data.station_code[0]);
			window.scrollTo(0, 0);
		});
		if (locationHeaderInformation.station_pick_code) {
			document.querySelector('.button_container_custom').click();
		}
		axios.get(`${BASE}${`${BASE}/location/${url}`}`).then((result) => {
			// console.log("|||||")
			// console.log(result.data)
			Dispatch(
				setLocationBlogs(
					false
						? false
						: {
								blogs: result.data.data,
								response: true,
						  }
				)
			);
		});
	};

	return (
		<Container className='mb-5'>
			<div className='text-center col-lg-12 col-md-6 col-sm-12 py-3 mt-5'>
				<h2>
					Other locations to visit near {locationHeaderInformation.location}
				</h2>
			</div>
			<div className='container container-sm container-md container-lg'>
				<Row className='sec6row'>
					{locationHeaderInformation.nearest_locations.map(function(
						name,
						index
					) {
						return (
							<div className='col p-1' id='FlexCol' key={index}>
								<Button
									onClick={handleClick(name.location_href)}
									className='navbar-brand buttonCol'
								>
									{name.location_name}
								</Button>
							</div>
						);
					})}
				</Row>
			</div>
		</Container>
	);
};
export { Price, Temp, Blogs, Things, LocationImage, NearestLocation };
