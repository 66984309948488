import React from "react";
import { Container, Row, Col } from "reactstrap";

import tickIcon from "./../../assets/tick-icon.svg";
import screen1 from "./../../assets/ss-1@2x.jpg";
import screen2 from "./../../assets/ss-2@2x.jpg";
import googlePlayBtn from "./../../assets/g-playBtn.png";
import appleStoreBtn from "./../../assets/a-storeBtn.png";
import styles from "./styles.module.scss";
import LazyLoad from 'react-lazyload';
// import Button from "reactstrap/lib/Button";

const AppDetailCard = ({ title, detail, isBtnVisible = false }) => {
  return (
    <div className={styles.appDetailCard_container}>
      <LazyLoad height={200}>
        <img src={tickIcon} className={styles.appDetailCardTick_img} alt="tick icon" />
      </LazyLoad>
      <div>
        <h2>{title}</h2>
        <p>{detail}</p>
        {false && ( // if you need download button place (isBtnVisible) in condition
          <div className={styles.appDownloadBtn_container}>
            <LazyLoad height={200}>
            <img src={googlePlayBtn} style={{ marginRight: 20 }} alt="Google play store" />
            <img src={appleStoreBtn} alt="Apple app store" />
            </LazyLoad>
          </div>
        )}
      </div>
    </div>
  );
};

export const MobileAppSection = () => {
  return (
    <React.Fragment>
      <section className={styles.appSection}>
        <Container className={styles.mainContainer}>
          <div className={styles.header_container}>
            <h1>Get your car from Rentalmoose!</h1>
            <p>We are making it straight and simple. <br></br> No hidden fees, only verified car rental brands.</p>
          </div>
          <Row className={`${styles.appDetailColumns_container} d-none d-md-flex`}>
            <Col md="6" className="col-12">
              <div className={styles.detail_container}>
                <LazyLoad height={200}>
                  <img src={screen1} className={styles.mobScreen} alt="Mobile screen 1" />
                </LazyLoad>  
                <div className={styles.mobScreenDetail_container}>
                  <AppDetailCard
                    title="Protect your planet"
                    detail="You can make a difference! When booking a car from Rentalmoose, we plant a tree for every booking."
                  />
                  <AppDetailCard
                    title="Make your next trip unforgettable"
                    detail="Travel like a local - use Rentalmoose travel guides and save a ton of time researching where to go and what to see."
                    isBtnVisible={true}
                  />
                </div>
              </div>
            </Col>
            <Col md="6" className="col-12">
              <div className={styles.detail_container}>
                <div className={styles.mobScreenDetail_container}>
                  <AppDetailCard
                    title="Rentalmoose is protecting you"
                    detail="Using Rentalmoose webapp, we remind you to upload pictures of the car condition when picking it up and dropping it off to avoid false claims and additional fees"
                  />
                  <AppDetailCard
                    title="Cleanliness is priority"
                    detail="Rentalmoose partners sanitize key areas throughout the entire vehicle between every booking period and follow strict rules that cars are clean."
                  />
                </div>
                <LazyLoad height={200}>
                  <img src={screen2} className={styles.mobScreen} alt="Mobile screen 2" />
                </LazyLoad>
              </div>
            </Col>
          </Row>
          <Row className={`${styles.appDetailColumns_container_Mobile} d-md-none`}>
            <Col md="6" className="col-12">
              <div className={styles.detail_container}>
                <div className={styles.mobScreenDetail_container}>
                  <AppDetailCard
                    title="Rentalmoose is protecting you"
                    detail="Using Rentalmoose webapp, we remind you to upload pictures of the car condition when picking it up and dropping it off to avoid false claims and additional fees"
                  />
                  <AppDetailCard
                    title="Cleanliness is priority"
                    detail="Rentalmoose partners sanitize key areas throughout the entire vehicle between every booking period and follow strict rules that cars are clean."
                  />
                </div>
              </div>
            </Col>
            <Row className={styles.appImageSection}>
              <Col className="col-6 p-0">
              <LazyLoad height={200}>
                <img
                  src={screen1}
                  className={`${styles.mobScreen}  ${styles.leftAppScreen} img-fluid`}
                  alt="Mobile screen 1"
                />
               </LazyLoad> 
              </Col>
              <Col className="col-6 p-0">
              <LazyLoad height={200}>
                <img
                  src={screen2}
                  className={`${styles.mobScreen} ${styles.rightAppScreen} img-fluid`}
                  alt="Mobile screen 2"
                />
               </LazyLoad> 
              </Col>
            </Row>
            <Col md="6" className="col-12">
              <div className={styles.detail_container}>
                <div className={styles.mobScreenDetail_container}>
                  <AppDetailCard
                    title="Protect your planet"
                    detail="You can make a difference! When booking a car from Rentalmoose, we plant a tree for every booking."
                  />
                  <AppDetailCard
                    title="Cleanliness is priority"
                    detail="Rentalmoose partners sanitize key areas throughout the entire vehicle between every booking period and follow strict rules that cars are clean."
                    isBtnVisible={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
