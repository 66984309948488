import {
  GET_ALL_COUNTRIES,
  GET_ALL_COUNTRIES_FAILED,
  GET_ALL_COUNTRIES_SUCCESS,
  FETCH_CAR_TYPES,
  FETCH_CAR_TYPES_FAILED,
  FETCH_CAR_TYPES_SUCCESS,
  GET_SEARCH_VEHICLE_FILTER,
  GET_SEARCH_VEHICLE_FILTER_FAILED,
  GET_SEARCH_VEHICLE_FILTER_SUCCESS,
  STRIPE_KEY,
  STRIPE_KEY_SUCCESS,
  STRIPE_KEY_FAILED,
  GET_BLOGS,
  GET_BLOGS_FAILED,
  GET_BLOGS_SUCCESS,
  GET_PRIVACY_PAGE_CONTENT,
  GET_PRIVACY_PAGE_CONTENT_FAILED,
  GET_PRIVACY_PAGE_CONTENT_SUCCESS,
  CHANGE_CURRENCY,
  SET_USER_CURRENCY,
  SET_USER_COUNTRY,
  SET_LOCATION_INFORMATION,
  SET_LOCATION_BLOGS,
  SET_LOCATION_DATA,
  SET_LOCATION_HEADER,
  SET_LOCATION_CHECK_IN
} from "./../actions/config.action";

const CONFIG_STATE = {
  allCountries: [],
  carTypes: [],
  moreOptions: {},
  isLoading: false,
  isError: false,
  terms: "",
  terms: [],
  blogs: [],
  locationHeaderInformation:{
      temp: '',
      current_time:'',
      complete_temperature:'',
      temp2:'',
      nearest_locations:[],
      currency_name: '',
      gas_price :'',
      driving_side : '',
      image_1:'',
      image_name:'',
      image_2:'',
      car_rentaal_offer:'',
      location:'',
      station_code: '',
      code:'',
      station_pick_code: ''
  },
  SET_LOCATION_BLOGS:{
  },
  key:"",
  category_1: [],
  category_2: [],
  category_3: [],
  message: "",
  selectedCountry:{
    code:"EUR",
    country:"Estonia"
  },
  selectedCurrency: {
    id: "EUR",
    country_code: "EUR",
    sign: "€",
    name: "EUR",
  },
  currencies: [
    {
      id: "EUR",
      country_code: "EUR",
      sign: "€",
      name: "EUR",
    },
    {
      id: "USD",
      country_code: "US",
      sign: "$",
      name: "USD",
    },
    {
      id: "CAD",
      country_code: "CA",
      sign: "$",
      name: "CAD",
    },
    {
      id: "AUD",
      country_code: "AU",
      sign: "$",
      name: "AUD",
    },
    {
      id: "GBP",
      country_code: "IM",
      sign: "£",
      name: "GBP",
    },
    {
      id: "JPY",
      country_code: "JP",
      sign: "¥",
      name: "JPY",
    },
    {
      id: "HKD",
      country_code: "HK",
      sign: "$",
      name: "HKD",
    },
  ],
};

export const ConfigReducer = (state = CONFIG_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRIES:
      return { ...state, isLoading: true, isError: false, message: "" };
    case GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allCountries: action.payload,
        message: action.message,
      };
    case GET_ALL_COUNTRIES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
      case STRIPE_KEY:
        return { ...state, isLoading: true, isError: false, message: "" };
      case STRIPE_KEY_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          key: action.payload,
          message: action.message,
        };
      case STRIPE_KEY_FAILED:
        return {
          ...state,
          isLoading: false,
          isError: true,
          message: action.message,
        };
    case GET_SEARCH_VEHICLE_FILTER:
      return { ...state, isLoading: true, isError: false, message: "" };
    case GET_SEARCH_VEHICLE_FILTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        moreOptions: action.payload,
        message: action.message,
      };
    case GET_SEARCH_VEHICLE_FILTER_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case FETCH_CAR_TYPES:
      return { ...state, isLoading: true, isError: false, message: "" };
    case FETCH_CAR_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        carTypes: action.payload,
        message: action.message,
      };
    case FETCH_CAR_TYPES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    
    case GET_BLOGS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        meesage: "",
      };
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        category_1: action.category_1.map((val) => {
          return {
            title: val.title,
            imgSrc: val.image,
            readmore_link: val.link,
            description: val.description,
            alt: "thumbnail",
          };
        }),
        category_2: action.category_2.map((val) => {
          return {
            title: val.title,
            imgSrc: val.image,
            readmore_link: val.link,
            description: val.description,
            alt: "thumbnail",
          };
        }),
        category_3: action.category_3.map((val) => {
          return {
            title: val.title,
            imgSrc: val.image,
            readmore_link: val.link,
            description: val.description,
            alt: "thumbnail",
          };
        }),
        message: action.message,
      };
    case GET_BLOGS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_PRIVACY_PAGE_CONTENT:
      return {
        ...state,
        isLoading: true,
        terms: "",
        isError: false,
        meesage: "",
      };
    case GET_PRIVACY_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        terms: action.payload,
        message: action.message,
      };
    case GET_PRIVACY_PAGE_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case CHANGE_CURRENCY:
      return {
        ...state,
        selectedCurrency: state.currencies.find((v) => v.id === action.payload),
      };
    case SET_USER_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      };
    case SET_USER_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      }; 
      case SET_LOCATION_INFORMATION:
      return {
        ...state,
        locationHeaderInformation: action.payload,
      };
      case SET_LOCATION_BLOGS:
        return {
          ...state,
        locationBlogs: action.payload,
        };
      case SET_LOCATION_HEADER:
        return {
          ...state,
          locationHeaderStatus: action.payload,
        };
        case SET_LOCATION_CHECK_IN:
        return {
          ...state,
          getLocationCheckIn: action.payload,
        };
      case SET_LOCATION_DATA:
        return {
          ...state,
          locationHeaderInformation: action.payload,
        };  
    default:
      return state;
  }
};
