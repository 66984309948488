import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  GET_BOOKED_BOOKINGS,
  GET_BOOKED_BOOKINGS_FAILED,
  GET_BOOKED_BOOKINGS_SUCCESS,
  GET_BOOKING_DETAIL,
  GET_BOOKING_DETAIL_FAILED,
  GET_BOOKING_DETAIL_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  GET_EXPIRED_BOOKINGS,
  GET_EXPIRED_BOOKINGS_FAILED,
  GET_EXPIRED_BOOKINGS_SUCCESS,
  CANCEL_BOOKING,
  CANCEL_BOOKING_FAILED,
  CANCEL_BOOKING_SUCCESS,
} from "./../actions/account.action";
import { UPDATE_USER } from "../../store/actions/auth.action";
import {
  BASE,
  GET_BOOKED_BOOKINGS_API,
  GET_BOOKING_DETAIL_API,
  UPDATE_USER_PROFILE_API,
  GET_EXPIRED_BOOKINGS_API,
  CANCEL_BOOKING_API,
} from "../../config/api";

export class AccountEpic {
  static getBookedBookings = (action$) =>
    action$.pipe(
      ofType(GET_BOOKED_BOOKINGS),
      switchMap(({ token }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.get(
              `${BASE}${GET_BOOKED_BOOKINGS_API}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const { data } = result;
            if (data.data) {
              observer.next({
                type: GET_BOOKED_BOOKINGS_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
            } else {
              observer.next({
                type: GET_BOOKED_BOOKINGS_FAILED,
                message: data.msg,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_BOOKED_BOOKINGS_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in User Booking Fetching",
            });
          }
        });
      })
    );
  static getExpiredBookings = (action$) =>
    action$.pipe(
      ofType(GET_EXPIRED_BOOKINGS),
      switchMap(({ token }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.get(
              `${BASE}${GET_EXPIRED_BOOKINGS_API}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const { data } = result;
            if (data.data) {
              observer.next({
                type: GET_EXPIRED_BOOKINGS_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
            } else {
              observer.next({
                type: GET_EXPIRED_BOOKINGS_FAILED,
                message: data.msg,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_EXPIRED_BOOKINGS_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in User Expired Booking Fetching",
            });
          }
        });
      })
    );
  static getBookingDetail = (action$) =>
    action$.pipe(
      ofType(GET_BOOKING_DETAIL),
      switchMap(({ id, token, push }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.get(
              `${BASE}${GET_BOOKING_DETAIL_API}${id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_BOOKING_DETAIL_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
              push(`/account/bookingDetails/${id}`);
            } else {
              observer.next({
                type: GET_BOOKING_DETAIL_FAILED,
                message: data.msg,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_BOOKING_DETAIL_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in Fetching Booking Detail ",
            });
          }
        });
      })
    );
  static cancelBooking = (action$) =>
    action$.pipe(
      ofType(CANCEL_BOOKING),
      switchMap(({ payload, hideModal, token }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.post(
              `${BASE}${CANCEL_BOOKING_API}`,
              payload,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: CANCEL_BOOKING_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
              hideModal();
            } else {
              observer.next({
                type: CANCEL_BOOKING_FAILED,
                message: data.msg,
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: CANCEL_BOOKING_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in Fetching Booking Detail ",
            });
          }
        });
      })
    );
  static updateUserProfile = (action$) =>
    action$.pipe(
      ofType(UPDATE_USER_PROFILE),
      switchMap(({ payload, token, setSubmitting }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.post(
              `${BASE}${UPDATE_USER_PROFILE_API}`,
              payload,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: UPDATE_USER_PROFILE_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
              observer.next({
                type: UPDATE_USER,
                user: data.data,
              });
              setSubmitting(false);
            } else {
              observer.next({
                type: UPDATE_USER_PROFILE_FAILED,
                message: data.msg,
              });
              setSubmitting(false);
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: UPDATE_USER_PROFILE_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in updating user profile",
            });
            setSubmitting(false);
          }
        });
      })
    );
}
