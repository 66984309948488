export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_ALL_COUNTRIES_SUCCESS = "GET_ALL_COUNTRIES_SUCCESS";
export const GET_ALL_COUNTRIES_FAILED = "GET_ALL_COUNTRIES_FAILED";

export const FETCH_CAR_TYPES = "FETCH_CAR_TYPES";
export const FETCH_CAR_TYPES_SUCCESS = "FETCH_CAR_TYPES_SUCCESS";
export const FETCH_CAR_TYPES_FAILED = "FETCH_CAR_TYPES_FAILED";

export const GET_SEARCH_VEHICLE_FILTER = "GET_SEARCH_VEHICLE_FILTER";
export const GET_SEARCH_VEHICLE_FILTER_SUCCESS = "GET_SEARCH_VEHICLE_FILTER_SUCCESS";
export const GET_SEARCH_VEHICLE_FILTER_FAILED = "GET_SEARCH_VEHICLE_FILTER_FAILED";

export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAILED = "GET_BLOGS_FAILED";

export const GET_PRIVACY_PAGE_CONTENT = "GET_PRIVACY_PAGE_CONTENT";
export const GET_PRIVACY_PAGE_CONTENT_SUCCESS = "GET_PRIVACY_PAGE_CONTENT_SUCCESS";
export const GET_PRIVACY_PAGE_CONTENT_FAILED = "GET_PRIVACY_PAGE_CONTENT_FAILED";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const SET_USER_CURRENCY = "SET_USER_CURRENCY";
export const SET_USER_COUNTRY = "SET_USER_COUNTRY";

export const STRIPE_KEY = "STRIPE_KEY";
export const STRIPE_KEY_SUCCESS = "STRIPE_KEY_SUCCESS";
export const STRIPE_KEY_FAILED = "STRIPE_KEY_FAILED";

export const SET_LOCATION_INFORMATION = "LOCATION_INFORMATION";
export const SET_LOCATION_DATA = "SET_LOCATION_DATA";
export const GET_LOCATION_DATA = "GET_LOCATION_DATA";
export const GET_LOCATION_DATA_SUCCESS = "GET_LOCATION_DATA_SUCCESS";
export const GET_LOCATION_DATA_FAILED = "GET_LOCATION_DATA_FAILED";

export const SET_LOCATION_HEADER = "SET_LOCATION_HEADER";
export const SET_LOCATION_BLOGS = "SET_LOCATION_BLOGS";
export const SET_LOCATION_CHECK_IN = 'SET_LOCATION_CHECK_IN';
export const getAllCountries = () => ({
  type: GET_ALL_COUNTRIES
});

export const fetchCarTypes = () => ({
  type: FETCH_CAR_TYPES
});

export const getSearchVehicleFilter = () => ({
  type: GET_SEARCH_VEHICLE_FILTER
});

export const getBlogs = () => ({
  type: GET_BLOGS
});

export const stripeKey = () => ({
  type: STRIPE_KEY
});

export const getPrivacyPageContent = () => ({
  type: GET_PRIVACY_PAGE_CONTENT
});

export const changeCurrency = payload => ({
  type: CHANGE_CURRENCY,
  payload
});

export const setUserCurrency = payload => ({
  type: SET_USER_CURRENCY,
  payload
});
export const setUserCountry = payload => ({
  type: SET_USER_COUNTRY,
  payload
});
export const getUserCountry = () => ({
  type: SET_USER_COUNTRY,
});
export const setLocationHeaderInformation  = payload => ({
  type: SET_LOCATION_INFORMATION,
  payload
});

export const setLocationBlogs = payload => ({
  type: SET_LOCATION_BLOGS,
  payload
});

export const setLocationHeaderStatus  = payload => ({
  type: SET_LOCATION_HEADER,
  payload
});

export const setLocationCheckIn  = payload => ({
  type: SET_LOCATION_CHECK_IN,
  payload
});
export const getLocationData  = payload => ({
  type: SET_LOCATION_DATA,
  payload
});
