import React from "react";
import { Container } from "reactstrap";
import Slider from "react-slick";

import styles from "./styles.module.scss";

const partnerLogo = [
  {
    name: "",
    img_src: "../assets/partnerLogos/thrifty.svg",
    address: "",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/europcar.svg",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/surprice.svg",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/national.svg",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/hertz.svg",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/enterprise.svg",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/alamo.svg",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/ace.svg",
    address: "/",
  },
  {
    img_src: "../assets/partnerLogos/ok.png",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/sicily.png",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/carwiz.png",
    address: "/",
  },
  {
    name: "",
    img_src: "../assets/partnerLogos/goldcar.png",
    address: "/",
  }
];

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const PartnerLogos = () => {
  return (
    <Container>
      <div className={styles.partnerLogo_container}>
        <Slider
           {...settings}
          // slidesToShow={1}
          // swipeToSlide={true}
          // focusOnSelect={true}
           className="partnerLogoSlider"
          // infinite
          // lazy={true}
          // variableWidth
          // centerPadding={"10px"}
          // centerMode
        >
          {partnerLogo &&
            partnerLogo.map((v, i) => (
              <div>
                <img width="72" key={i} className={styles.partnerLogo_img} src={v.img_src} />
              {/* <img width="72" key={i} className={v.name  == "ok" ? styles.okbycar : styles.partnerLogo_img} src={v.img_src} /> */}
              </div>
            ))}
        </Slider>
      </div>
    </Container>
  );
};
