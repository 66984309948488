import React, { useEffect} from "react";
import ReactGA from "react-ga";
import { LocationPageInfo, Overlay } from "../../components"
import { SearchResult } from "./../SearchResult";
import { useSelector } from "react-redux";
import { loadNamespaces } from "i18next";

export const Locations = ({
  resetSearchParams,
  resetSearchPanel,
  resetBookingDetails,
  setFromUrlParams }) => {
  const {isLoading } = useSelector((state) => state.searchResult);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setFromUrlParams(true);
    resetSearchParams();
    resetSearchPanel();
    resetBookingDetails();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);
  const { locationHeaderInformation, getLocationCheckIn } = useSelector((state) => state.config);
  return (
    <>
      <LocationPageInfo />
      <div style={{ position: "relative" }}>
        {
          locationHeaderInformation.station_pick_code === '' || isLoading ? (
            <Overlay container  />
          ) :null
        }
      { getLocationCheckIn ?  (
        <SearchResult location className="mt-5" />
        ) : null
      }
      </div>
    </>
  );
};
