import React from "react";

import styles from "./styles.module.scss";

export const Toaster = ({ message, type }) => {
  return (
    <div
      className={`${styles.toaster_container} ${
        type === "success"
          ? `${styles.success} animated fadeInDown fadeOut delay-2s`
          : `${styles.error} animated tada fadeOut delay-2s`
      } `}
    >
      <p>{message}</p>
    </div>
  );
};

export default Toaster;
