import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AuthModal } from "./auth";
import { showModal, hideModal } from "../../store/actions/modal.action";
import {
  login,
  signup,
  forgotPassword,
  resetMessage
} from "./../../store/actions/auth.action";

const mapStateToProps = ({ modalState, auth, config }) => ({
  authState: auth,
  countries: config.allCountries,
  modalState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      login,
      forgotPassword,
      resetMessage,
      signup
    },
    dispatch
  );
export let AuthModalComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthModal);

export default AuthModalComponent;
