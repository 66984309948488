import { ResultPanel } from "./resultPanel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  selectCar,
  setActiveMarker,
  loadmoreCars,
  bookCar,
  toggleSeeResultOnMap,
} from "./../SearchPanel";

const mapStateToProps = ({ modalState, searchResult, searchParams, config }) => ({
  modalState,
  searchResult,
  searchParams,
  config,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectCar,
      loadmoreCars,
      setActiveMarker,
      bookCar,
      toggleSeeResultOnMap,
    },
    dispatch
  );

export let ResultPanelComponent = connect(mapStateToProps, mapDispatchToProps)(ResultPanel);
