import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { PaymentDetails } from "./../Payment/paymentUtils";
import { useDispatch,useSelector } from "react-redux";
import { Formik, Form } from "formik";
import LoadingOverlay from "react-loading-overlay";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { InputText, CheckBox, Button, DropDownInput } from "../../../";
import { CustomStepRow } from "../stepsUtils";
import { formikConfig_driver } from "./driverUtils";
import { setWorkingStepId } from "./../index";
import styles from "./styles.module.scss";
import { setProceedToPaymentClicked } from "../../../SearchPanel";

let _isUserLoggedIn;
let data = null;
export const DriverStep = ({
  auth: { user, isUserLoggedIn },
  searchResult,
  bookingDetail: { driverDetails },
  setDriverDetials,
  searchParams,
  isBusy,
  auth,
  bookingDetail,
  config,
  confirmCarBooking,
  config: { allCountries: Countries, selectedCurrency },
}) => {
  const { push } = useHistory();
  const { search, state } = useLocation();
  const elements = useElements();
  const Dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    try {
      window.scroll({
        top: 600,
        left: 0,
        behavior: "smooth",
      });
    } catch (_) {
      window.scrollTo(600, 0);
    }
    document.getElementById('payment').style.pointerEvents = "none";
    document.getElementById('payment').style.opacity = "0.4";
    if(document.getElementById('react-select-2-input') != null){
      document.getElementById('react-select-2-input').type = 'number';
    }

  }, []);

  _isUserLoggedIn = isUserLoggedIn;

  const driverInitialValues = {
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    phone_number: user.contact || "",
    email: user.email || "",
    driver_license_number: user.license_number || "",
    driver_age: user.driver_age || "",
    phonecode: {
      label: `+${user.phonecode}` || "",
      value: `+${user.phonecode}` || "",
    },
    driving_experience: user.driver_experience || "",
  };
  const enableDriverSection = () =>{
    document.getElementById('payment').style.pointerEvents = "none";
    document.getElementById('driverDetails').style.pointerEvents = "auto";
    document.getElementById('payment').style.opacity = "0.4";
    document.getElementById('driverDetails').style.opacity = "unset";

  };
  const enablePaymentSection = () =>{
    if(data != null){
      document.getElementById('payment').style.pointerEvents = "auto";
      document.getElementById('driverDetails').style.pointerEvents = "none";
      document.getElementById('driverDetails').style.opacity = "0.4";
      document.getElementById('payment').style.opacity = "unset";
    }
   
  };
  const handleSubmitdriver = async (values, { setSubmitting }) => {
    const formData = {
      ...values,
      phonecode: values.phonecode.value?.split("+")[1],
    };
    if (values.email_checked) {
      setDriverDetials(formData);
    } else {
      values.email_checked = false;
      setDriverDetials(formData);
    }
    document.getElementById('payment').style.pointerEvents = "auto";
    document.getElementById('driverDetails').style.pointerEvents = "none";
    document.getElementById('driverDetails').style.opacity = "0.4";
    document.getElementById('payment').style.opacity = "unset";
    // elements.getElement(CardNumberElement).clear();
    // elements.getElement(CardExpiryElement).clear();
    // elements.getElement(CardCvcElement).clear();
    document.getElementById("cardHolderName").value = values.first_name+' '+values.last_name;

    let params = new URLSearchParams(search);
    const id =
      searchResult && searchResult.selectedCar && searchResult.selectedCar.id;
    const checkin_station_code = params.get("checkin_station_code");
    const checkin_time = params.get("checkin_time");
    const checkout_station_code = params.get("checkout_station_code");
    const checkout_time = params.get("checkout_time");
    const contract_id = params.get("contract_id");
    const contract_margin = params.get("contract_margin");
    const currency = params.get("currency");
    const deposit = params.get("deposit");
    const duration_margin = params.get("duration_margin");
    const max_liab = params.get("max_liab");
    const rule_id = params.get("rule_id");
    const rule_margin = params.get("rule_margin");
    const vehicle_id = params.get("vehicle_id");
    if (state === undefined) {
      push({
        pathname: `/bookingDetail/${vehicle_id}/driver`,
        search: `?checkin_station_code=${checkin_station_code}&checkin_time=${checkin_time}&checkout_station_code=${checkout_station_code}&checkout_time=${checkout_time}&currency=${currency}&vehicle_id=${vehicle_id}`
      });
      Dispatch(setWorkingStepId(1));
    }
  };

  const showErrorBorder = (error) => {
    if (error) return { border: "solid 1px red" };
    return {};
  };
  const { discountAmount,payNowdiscount,discountPrice } = useSelector(state => state.bookingDetail);

  // let total_pay_now =
  // searchResult &&
  // searchResult.selectedCar &&
  // searchResult.selectedCar.pay_now;
  // let total_pay_later = 0; 
  // if(payNowdiscount) {
  //   total_pay_now = discountAmount;
  // } else {
  //   total_pay_now = searchResult &&
  //     searchResult.selectedCar &&
  //     searchResult.selectedCar.pay_later;
  // }
  // if(discountAmount){
  //   total_pay_later = discountAmount-total_pay_now;
  // }else{
  //   total_pay_now = searchResult &&
  //     searchResult.selectedCar &&
  //     searchResult.selectedCar.pay_later;
  // }  
  // let total_amount = 0; 
  // if(discountAmount){
  //   total_amount = discountAmount;
  // }else{
  //   total_amount = searchResult &&
  //   searchResult.selectedCar &&
  //   searchResult.selectedCar.total_amount;
  // }  

  let total_pay_now =
  searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.pay_now;
  let total_pay_later = searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.pay_later; 
  
  if(payNowdiscount)
  {
    total_pay_now = payNowdiscount;
  }

  let total_amount = searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.total_amount;

  if(discountPrice){
    total_amount = discountAmount - discountPrice;
  }

  // let total_amount = searchResult &&
  // searchResult.selectedCar &&
  // searchResult.selectedCar.total_amount;

  // if(discountPrice){
  //   total_amount = discountAmount - discountPrice;
  // }
searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.extras
    .filter(
      v =>
        v.selected &&
        v.type === "internal" &&
        (v.remaining ? v.remaining >= 1 : true)
    )
    .map(v => {
      total_pay_now += v.count * Number(v.price_per_day);
    });

let values = {
  checkin_station_code:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.checkin_station,
  checkout_station_code:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.checkout_station,
  country_code: config && config.selectedCurrency.country_code,
  checkin_time: searchParams && searchParams.startDate,
  checkout_time: searchParams && searchParams.endDate,
  extras:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.extras
      .filter(v => v.selected && (v.remaining ? v.remaining >= 1 : true))
      .map(x => {
        return {
          code: x.code,
          qty: x.count,
          rate: x.price_per_day,
          type: x.type
        };
      }),
  driver: {
    first_name: bookingDetail && bookingDetail.driverDetails.first_name,
    email: bookingDetail && bookingDetail.driverDetails.email,
    last_name: bookingDetail && bookingDetail.driverDetails.last_name,
    phone_number: bookingDetail && bookingDetail.driverDetails.phone_number,
    phonecode: bookingDetail && bookingDetail.driverDetails.phonecode,
    country: config && config.selectedCurrency.country_code, //previosuly it was this
    email_check: bookingDetail && bookingDetail.driverDetails.email_check,
    license_number:
      bookingDetail && bookingDetail.driverDetails.driver_license_number,
    age: bookingDetail && bookingDetail.driverDetails.driver_age,
    flight_number: bookingDetail && bookingDetail.driverDetails.flight_number
  },
  vehicle_id:
    searchResult && searchResult.selectedCar && searchResult.selectedCar.id,
  pay_now: total_pay_now,
  pay_later: total_pay_later,

  contract_id:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.contract_id,
  contract_margin:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.ontract_margin,
  rule_margin:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.rule_margin,
  duration_margin:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.duration_margin,
  rule_id:
    searchResult &&
    searchResult.selectedCar &&
    searchResult.selectedCar.rule_id,
  total_amount:total_amount,
  currency:
    config && config.selectedCurrency && config && config.selectedCurrency.id
};

let extras_Total = 0;
let internal_Extras = 0;
let external_Extras = 0;
let total_pay_now_calculated = 0;
let later_charges = 0;
let car_charges = 0;

searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.extras &&
  searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.extras
    .filter(v => v.selected && v.type === "internal")
    .map(v => {
      internal_Extras += v.count * Number(v.price_per_day);
    });

searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.extras &&
  searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.extras
    .filter(v => v.selected && v.type === "external")
    .map(v => {
      external_Extras += v.count * Number(v.price_per_day);
    });

total_pay_now_calculated =
  searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.pay_now + internal_Extras;
extras_Total = internal_Extras + external_Extras;
later_charges =
  searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.pay_later + external_Extras;
  if(discountAmount){
    later_charges = discountAmount-total_pay_now_calculated;
  }
car_charges =
  searchResult &&
  searchResult.selectedCar &&
  searchResult.selectedCar.pay_now + searchResult.selectedCar.pay_later;

let price_breakdown_data = {
  pay_now:
    total_pay_now_calculated !== null && total_pay_now_calculated.toFixed(2),
  pay_later: later_charges !== null && later_charges.toFixed(2),
  total_amount: car_charges !== null && car_charges.toFixed(2),
  extras_total: extras_Total !== null && extras_Total.toFixed(2),
  service_charges: 0.99
};
const handleSubmit = () =>  {
  window.gtag("event", "Proceed to payment", {
    event_category: "Booking",
    event_label: "Proceed to payment"
  })
}
  return (
     
    <Container className={styles.driverPaymentSection}>
    <LoadingOverlay
        onClick={() => {}}
        id="overlay"
        active={isBusy}
        spinner
        text="Please wait while we are Booking your vehicle..."
      >
        <Container onClick={enableDriverSection}>
        <Container id="driverDetails">
      <CustomStepRow 
        heading="Driver details"
        isDriverPaymentStep
        hasRequiredFields
      >
        <Formik
          enableReinitialize
          initialValues={
            isUserLoggedIn || driverDetails.first_name !== ""
              ? driverDetails.first_name === ""
                ? {
                    ...driverDetails,
                    ...driverInitialValues,
                    phonecode: driverInitialValues.phonecode,
                  }
                : {
                    ...driverInitialValues,
                    ...driverDetails,
                    phonecode: {
                      label: !driverDetails.phonecode
                        ? ""
                        : `+${driverDetails.phonecode}`,
                      value: !driverDetails.phonecode
                        ? ""
                        : `+${driverDetails.phonecode}`,
                    },
                  }
              : formikConfig_driver.initialValues
          }
          validate={formikConfig_driver.validate}
          onSubmit={handleSubmitdriver}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Col md="8">
                <Form>
                  <Row>
                    <Col md="6" className={styles.lableTip}>
                      <InputText
                        name="first_name"
                        label="First Name*"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        style={showErrorBorder(
                          errors.first_name &&
                            touched.first_name &&
                            errors.first_name
                        )}
                      />
                      {errors.first_name &&
                        touched.first_name &&
                        errors.first_name}
                    </Col>
                    <Col md="6">
                      <InputText
                        name="last_name"
                        label="Last Name*"
                        type="text"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={showErrorBorder(
                          errors.last_name &&
                            touched.last_name &&
                            errors.last_name
                        )}
                      />
                      {errors.last_name &&
                        touched.last_name &&
                        errors.last_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <InputText
                        name="email"
                        label="E-mail*"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={showErrorBorder(
                          errors.email && touched.email && errors.email
                        )}
                      />
                      {errors.email && touched.email && errors.email}
                    </Col>
                    <Col md="6">
                      <InputText
                        disabled={isUserLoggedIn}
                        name="email_confirmation"
                        label="Re-enter E-mail*"
                        type="email"
                        value={values.email_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={showErrorBorder(
                          errors.email_confirmation &&
                            touched.email_confirmation &&
                            errors.email_confirmation
                        )}
                      />
                      {errors.email_confirmation &&
                        touched.email_confirmation &&
                        errors.email_confirmation}
                    </Col>
                    <Col md="6">
                      <InputText
                        name="driver_age"
                        label="Driver Age*"
                        type="number"
                        value={values.driver_age}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={showErrorBorder(
                          errors.driver_age &&
                            touched.driver_age &&
                            errors.driver_age
                        )}
                      />
                      {errors.driver_age &&
                        touched.driver_age &&
                        errors.driver_age}
                    </Col>
                    <Col md="6">
                      <InputText
                        name="driving_experience"
                        label="Years of driving experience*"
                        type="number"
                        value={values.driving_experience}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={showErrorBorder(
                          errors.driving_experience &&
                            touched.driving_experience &&
                            errors.driving_experience
                        )}
                        i
                      />
                      {errors.driving_experience &&
                        touched.driving_experience &&
                        errors.driving_experience}
                    </Col>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          <InputText
                            name="flight_number"
                            label="Flight Number"
                            type="text"
                            value={values.flight_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col md="6">
                          <InputText
                            name="driver_license_number"
                            label="Driver license number"
                            type="text"
                            value={values.driver_license_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={showErrorBorder(
                              errors.driver_license_number &&
                                touched.driver_license_number &&
                                errors.driver_license_number
                            )}
                          />
                        </Col>

                        <Col md="6">
                          <DropDownInput
                            isError={
                              errors.phonecode &&
                              touched.phonecode &&
                              errors.phonecode
                            }
                            label="Country Code*"
                            value={values.phonecode}
                            name="phonecode"
                            onBlur={handleBlur}
                            inputProps={{ inputMode: 'numeric' }}
                            onChange={(val) => setFieldValue("phonecode", val)}
                            options={
                              Countries &&
                              Countries.map((val) => ({
                                label: (
                                  <span>
                                    <img
                                      src={val.flag}
                                      width="50"
                                      className={styles.flagicon}
                                      alt="country-logo"
                                    />
                                    +{val.phonecode}
                                  </span>
                                ),
                                value: `+${val.phonecode}`,
                              }))
                            }
                          />
                          {errors.phonecode &&
                            touched["react-select-2-input"] &&
                            errors.phonecode}
                        </Col>
                        <Col md="6">
                          <InputText
                            style={showErrorBorder(
                              errors.phone_number &&
                                touched.phone_number &&
                                errors.phone_number
                            )}
                            name="phone_number"
                            label="Phone number*"
                            type="number"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className={styles.driverPaymentdescription}>
                        <strong>Ready for some money-saving ideas?</strong>
                        <p>
                          We can send you discounts and other car rental offers,
                          saving you even more money in the future.
                        </p>
                        <p>
                          Yes, please. Contact me by: <br></br>
                        </p>
                        <CheckBox
                          label="Email"
                          name="email_checked"
                          value={values.email_checked}
                          onChange={handleChange}
                          id="driver_step_email_checkbox"
                        />
                        <p className={styles.privacyNote}>
                          Our Privacy Notice tells you how to unsubscribe. It
                          also explains how we use and protect your personal
                          information.
                        </p>
                      </div>
                      <div class={styles.DriverButton}>
                        <Button type="submit" blueBG onClick={handleSubmit}>
                          Procced to Payment
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            );
          }}
        </Formik>
      </CustomStepRow>
      </Container>
      </Container>
      <Container  onClick={enablePaymentSection}>
        <div id="payment">
          <PaymentDetails
          values={values}
          auth={auth}
          vehicle_id={
            searchResult && searchResult.selectedCar && searchResult.selectedCar.id
          }
          handleConfirmBooking={confirmCarBooking}
          price_breakdown_data={price_breakdown_data}
        />
      </div>
      </Container>
      </LoadingOverlay>
    </Container>
   
  );
};

export { _isUserLoggedIn };
