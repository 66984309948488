import React from "react";
import { LoginModal } from "./Modals/LoginModal";
import { SignupModal } from "./Modals/SignupModal";
import { ForgotPasswordModal } from "./Modals/ForgotPasswordModal";

export const AuthModal = props => {
  return (
    <>
      <LoginModal {...props} />
      <SignupModal {...props} />
      <ForgotPasswordModal {...props} />
    </>
  );
};
