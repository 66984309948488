import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import ReactGA from "react-ga";

import { resetPassword, resetMessage } from "./../../store/actions/auth.action";
import { InputText, ErrorViewer, Button } from "./../../components";

const validate = (values) => {
  let errors = {};
  if (values.new_password && values.new_password.length <= 5) {
    errors.new_password = "Password should have atleast 6 Charactors";
  }
  if (values.new_password && !values.repeat_password) {
    errors.repeat_password = "Required";
  } else if (values.repeat_password !== values.new_password) {
    errors.repeat_password = "Password not matched";
  }
  return errors;
};

export const ResetPassword = () => {
  const Dispatch = useDispatch();
  const { push } = useHistory();
  const { token } = useParams();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    Dispatch(resetMessage());
  }, []);

  const { message } = useSelector((state) => state.auth);

  const handleSubmit = (values, { setSubmitting }) => {
    Dispatch(
      resetPassword(
        {
          password: values.new_password,
          cpassword: values.repeat_password,
          reset_password_token: token,
        },
        push,
        setSubmitting
      )
    );
  };
  return (
    <Container style={{ width: 400, marginTop: 60, marginBottom: 60 }}>
      <Formik
        initialValues={{ new_password: "", repeat_password: "" }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <InputText
              type="password"
              name="new_password"
              value={values.new_password}
              onChange={handleChange}
              onBlur={handleBlur}
              label="New Password"
            />
            <ErrorViewer
              name="new_password"
              touched={touched}
              errors={errors}
            />
            <InputText
              type="password"
              name="repeat_password"
              value={values.repeat_password}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Repeat Password"
            />
            <ErrorViewer
              name="repeat_password"
              touched={touched}
              errors={errors}
            />
            <Button
              blueBG
              type="submit"
              disabled={isSubmitting}
              style={{ marginTop: 10 }}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
      {message && <p style={{ marginTop: 10 }}>{message}</p>}
    </Container>
  );
};

export default ResetPassword;
