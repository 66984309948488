import { ofType } from "redux-observable";
import { mapTo, delay } from "rxjs/operators";

import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  LOGOUT
} from "./../actions/auth.action";
import {
  GET_CARS,
  GET_CARS_FAILED,
  GET_CARS_SUCCESS,
  BOOK_CAR,
  BOOK_CAR_FAILED,
  BOOK_CAR_SUCCESS
} from "./../../components/SearchPanel/searchPanel.action";

import {
  CONFIRM_CAR_BOOKING,
  CONFIRM_CAR_BOOKING_FAILED,
  CONFIRM_CAR_BOOKING_SUCCESS
} from "./../../components/Stepper/stepper.action";
import {
  CANCEL_BOOKING_FAILED,
  CANCEL_BOOKING_SUCCESS,
  GET_BOOKED_BOOKINGS_FAILED,
  GET_BOOKED_BOOKINGS_SUCCESS,
  GET_EXPIRED_BOOKINGS_FAILED,
  GET_EXPIRED_BOOKINGS_SUCCESS,
  GET_BOOKING_DETAIL_FAILED,
  GET_BOOKING_DETAIL_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS
} from "./../actions/account.action";

import { RESET_STATE } from "./../reducers/app.reducer";

export class AppEpic {
  static resetState = action$ =>
    action$.pipe(
      ofType(
        FORGOT_PASSWORD_FAILED,
        FORGOT_PASSWORD_SUCCESS,
        LOGIN_FAILED,
        LOGIN_SUCCESS,
        SIGN_UP_FAILED,
        SIGN_UP_SUCCESS,
        GET_CARS_FAILED,
        GET_CARS_SUCCESS,
        BOOK_CAR_FAILED,
        BOOK_CAR_SUCCESS,
        CONFIRM_CAR_BOOKING_FAILED,
        CONFIRM_CAR_BOOKING_SUCCESS,
        CANCEL_BOOKING_FAILED,
        CANCEL_BOOKING_SUCCESS,
        GET_BOOKED_BOOKINGS_FAILED,
        GET_BOOKED_BOOKINGS_SUCCESS,
        GET_EXPIRED_BOOKINGS_FAILED,
        GET_EXPIRED_BOOKINGS_SUCCESS,
        GET_BOOKING_DETAIL_FAILED,
        GET_BOOKING_DETAIL_SUCCESS,
        UPDATE_USER_PROFILE_FAILED,
        UPDATE_USER_PROFILE_SUCCESS
      ),
      mapTo({ type: RESET_STATE }),
      delay(3000)
    );
}
