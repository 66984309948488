import {
  GET_CARS,
  GET_CARS_SUCCESS,
  GET_CARS_FAILED,
  GET_AVAILABLE_CARS,
  GET_AVAILABLE_CARS_SUCCESS,
  GET_AVAILABLE_CARS_FAILED,
  GET_AVAILABLE_CARS_RESULT_FAILED,
  GET_AVAILABLE_CARS_COMPLETED,
  LOAD_MORE_CARS,
  LOAD_MORE_CARS_SUCCESS,
  LOAD_MORE_CARS_FAILED,
  SEARCH_STATION,
  SEARCH_STATION_FAILED,
  SEARCH_STATION_SUCCESS,
  SELECT_PICKUP_STATION,
  SELECT_DROPOFF_STATION,
  TOGGLE_SEE_RESULT_ON_MAP,
  TOGGLE_SAME_DROP_OFF_STATION,
  SET_ACTIVE_MARKER,
  SELECT_START_DATE,
  RESET_SEARCH_PARAMS,
  RESET_SEARCH_PANEL,
  SELECT_END_DATE,
  TOGGLE_EXTRAS,
  TOGGLE_SUPPLIER_FILTER,
  SORT_RESULTS,
  CAR_TYPE_FILTER,
  SELECT_CAR,
  BOOK_CAR,
  BOOK_CAR_SUCCESS,
  BOOK_CAR_FAILED,
  HANDLE_SUPPLIER_LOAD,
  SET_MORE_OPTION,
  SEARCH_PANEL_MORE_OPTIONS,
  INCREMENT_EXTRAS_COUNT,
  DECREMENT_EXTRAS_COUNT,
  SET_BOOK_NOW_CLICKED,
  SET_BOOK_NOW_CLICKED_FROM_OPTIONS,
  SET_PROCEED_TO_PAYMENT_CLICKED,
  SELECT_DRIVER_SENIORITY,
  SET_FROM_URL_PARAMS,
} from "./searchPanel.action";

const RESULT_PANEL_INITIAL_STATE = {
  carList: [],
  activeMarker: 0,
  selectedCar: null,
  suppliers_progress: 0,
  suppliers: [],
  supplier_ids: [],
  vehicle_ids: [],
  type_ids: [],
  suppliersLoaded: false,
  sortByCarType: "",
  sortByResult: "",
  isLoading: false,
  loadmore_loading: false,
  show_loadmore_button: true,
  booknow_clicked: false,
  booknow_clicked_from_options: false,
  proceed_to_payment_clicked: false,
  isFromURLParams: true,
  isError: false,
  message: "",
};

const SEARCH_PARAMS_INITIAL_STATE = {
  stations: [],
  pickUpStation: "",
  dropOffStation: "",
  isSameDropOffStation: true,
  seeResultOnMap: false,
  startDate: null,
  endDate: null,
  driverSeniority: 26,
  isMoreFilterOpen: false,
  //
  bestValues: true,
  cheapest: false,
  expensive: false,
  electric: false,
  hybrid: false,
  deposit: "All",
  selfLiabity: "All",
  mileage: "All",
  bankCards: "All",
  driverAge: "30-70 (default)",
  childSeat: "No",
  transmission: "All",
  pick_up_type: 0,
  winter_tyres: "All",
  supplier_rating: "All",
  number_of_seats: "All",
  luggage_space: "All",
  driver: "1",
  isLoading: false,
  isError: false,
  message: "",
};

export const SearchPanelReducer = (
  state = RESULT_PANEL_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_CARS:
      return {
        ...state,
        show_loadmore_button: true,
        carList: [],
        sortByResult: "",
        supplier_respond: {},
        isLoading: true,
        isFromURLParams: action.fromSearchBar,
        isError: false,
        message: "",
      };

    case GET_CARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        carList: action.cars,
        suppliersLoaded: false,
        suppliers_progress:50,
        supplier_respond: action.supplier_respond,
        supplier_ids: action.supplier_ids,
        type_ids: action.type_ids,
        vehicle_ids: action.vehicle_ids,
        isFromURLParams: true,
        suppliers: action.suppliers,
      };
    case GET_CARS_FAILED:
      return {
        ...state,
      suppliers_progress:100,
        isLoading: false,
        isError: true,
        suppliersLoaded: false,
        isFromURLParams: true,
        message: action.message,
        suppliers: action.suppliers,
      };
    case GET_AVAILABLE_CARS_COMPLETED:
      return {
        ...state,
        ...(action?.lastFetchRound && { carList: [] }),
        suppliers_progress: action.payload,
      };
    case GET_AVAILABLE_CARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        carList: action.cars,
        suppliersLoaded: false,
        supplier_respond: action.supplier_respond,
        supplier_ids: action.supplier_ids,
        type_ids: action.type_ids,
        vehicle_ids: action.vehicle_ids,
        isFromURLParams: true,
        suppliers: action.suppliers,
      };
    case GET_AVAILABLE_CARS_RESULT_FAILED:
      return {
        ...state,
        carList: [],
      };
    case GET_AVAILABLE_CARS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        suppliersLoaded: false,
        isFromURLParams: true,
        message: action.message,
      };
    case LOAD_MORE_CARS: {
      return {
        ...state,
        show_loadmore_button: false,
        loadmore_loading: true,
        isError: false,
        message: "",
      };
    }
    case LOAD_MORE_CARS_SUCCESS:
      return {
        ...state,
        show_loadmore_button: action.cars.length >= 9,
        carList: state.carList.concat(action.cars),
        loadmore_loading: false,
        suppliers: action.suppliers,
      };
    case LOAD_MORE_CARS_FAILED:
      return {
        ...state,
        show_loadmore_button: false,
        loadmore_loading: false,
        isError: true,
        message: action.message,
      };
    case SELECT_CAR:
      return { ...state, selectedCar: action.payload };
    case BOOK_CAR:
      return {
        ...state,
        isError: false,
        selectedCar: {},
        message: "",
      };
    case BOOK_CAR_SUCCESS:
      return {
        ...state,
        selectedCar: {
          ...action.car,
          extras: action.extras,
        },
        isError: false,
        message: action.message,
      };
    case BOOK_CAR_FAILED:
      return {
        ...state,
        isError: true,
        booknow_clicked: false,
        message: action.message,
      };
    case TOGGLE_EXTRAS:
      return {
        ...state,
        selectedCar: {
          ...state.selectedCar,
          extras: state.selectedCar.extras.map((extra) => {
            if (action.payload === extra.code) {
              return {
                ...extra,
                selected: !extra.selected,
              };
            }
            return extra;
          }),
        },
      };
    case INCREMENT_EXTRAS_COUNT:
      return {
        ...state,
        selectedCar: {
          ...state.selectedCar,
          extras: state.selectedCar.extras.map((extra) => {
            if (action.payload === extra.code) {
              return {
                ...extra,
                count: (extra.count += 1),
              };
            }
            return extra;
          }),
        },
      };
    case DECREMENT_EXTRAS_COUNT:
      return {
        ...state,
        selectedCar: {
          ...state.selectedCar,
          extras: state.selectedCar.extras.map((extra) => {
            if (action.payload === extra.code) {
              return {
                ...extra,
                count: (extra.count -= 1),
              };
            }
            return extra;
          }),
        },
      };
    case TOGGLE_SUPPLIER_FILTER:
      const suppliers = state.suppliers.map((sup) => {
        if (action.payload === sup.name) {
          return {
            ...sup,
            is_selected: !sup.is_selected,
          };
        }
        return sup;
      });
      return {
        ...state,
        suppliers,
        suppliersLoaded: true,
      };
    case HANDLE_SUPPLIER_LOAD:
      return {
        ...state,
        suppliersLoaded: true,
      };
    case SORT_RESULTS:
      return {
        ...state,
        sortByResult: action.payload,
      };
    case CAR_TYPE_FILTER:
      return {
        ...state,
        sortByCarType:
          state.sortByCarType === action.payload ? "" : action.payload,
      };
    case SET_ACTIVE_MARKER:
      return {
        ...state,
        activeMarker: action.payload,
      };
    case RESET_SEARCH_PANEL:
      return {
        ...state,
        suppliers: [],
        sortByCarType: "",
        sortByResult: "",
        message: "",
      };
    case SET_BOOK_NOW_CLICKED:
      return {
        ...state,
        booknow_clicked: action.payload,
      };
    case SET_BOOK_NOW_CLICKED_FROM_OPTIONS:
      return {
        ...state,
        booknow_clicked_from_options: action.payload,
      };
    case SET_PROCEED_TO_PAYMENT_CLICKED:
      return {
        ...state,
        proceed_to_payment_clicked: action.payload,
      };
    case SET_FROM_URL_PARAMS:
      return {
        ...state,
        isFromURLParams: action.payload,
      };
    default:
      return state;
  }
};

export const SearchParamsReducer = (
  state = SEARCH_PARAMS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SEARCH_STATION:
      return {
        ...state,
        isLoading: true,
        isError: false,
        stations: [],
        message: "",
      };
    case SEARCH_STATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        stations: action.stations,
        message: action.message,
      };
    case SEARCH_STATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case SELECT_PICKUP_STATION:
      console.log('}}}')
      console.log( action.station)
      return {
        ...state,
        pickUpStation: action.station,
      };
    case SELECT_DROPOFF_STATION:
      return {
        ...state,
        dropOffStation: action.station,
      };
    case SELECT_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case SELECT_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case SELECT_DRIVER_SENIORITY:
      return {
        ...state,
        driverSeniority: action.driverSeniority,
      };
    case TOGGLE_SEE_RESULT_ON_MAP:
      return {
        ...state,
        seeResultOnMap: !state.seeResultOnMap,
      };
    case TOGGLE_SAME_DROP_OFF_STATION:
      return {
        ...state,
        isSameDropOffStation: !state.isSameDropOffStation,
      };
    case SET_MORE_OPTION:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SEARCH_PANEL_MORE_OPTIONS:
      return {
        ...state,
        isMoreFilterOpen: !state.isMoreFilterOpen,
      };
    case RESET_SEARCH_PARAMS:
      return SEARCH_PARAMS_INITIAL_STATE;
    default:
      return state;
  }
};
