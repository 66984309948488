import React from "react";

import styles from "./styles.module.scss";

const CarType = ({ title, img_src, starting_price, active, onClick }) => {
  return (
    <div
      className={`${styles.carTypeBox_container} ${active === title && styles.carTypeBox_active}`}
      onClick={onClick}
    >
      <span className={styles.carType_title}>{title}</span>
      <img src={img_src} alt={title} className={styles.carType_img} />
      <div className={styles.price_container}>
        {/* <span className={styles.from_style}>From</span> */}
        <span className={styles.startingPrice_style}>{starting_price}</span>
      </div>
    </div>
  );
};

export { CarType };
