const formikConfig_profile = {
  initialValues: {
    first_name: "",
    last_name: "",
    dateOfBirth: "",
    countryOfResidence: {},
    countryPhoneCode: {},
    phoneNumber: "",
    alternativePhoneNumber: "",
    email: "",
    alternativeEmail: "",
    newPassword: "",
    repeatPassword: "",
    licenceNumber: "",
    licenceIssuedCountry: {},
    licenceIssueDate: "",
    licenceExpireDate: "",
  },
  validate: (values) => {
    let errors = {};
    if (!values.first_name) {
      errors.first_name = "Required";
    }
    if (!values.last_name) {
      errors.last_name = "Required";
    }
    if (!values.countryOfResidence) {
      errors.countryOfResidence = "Required";
    }
    if (!values.countryPhoneCode) {
      errors.countryPhoneCode = "Required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    if (!values.licenceNumber) {
      errors.licenceNumber = "Required";
    }
    if (!values.licenceIssuedCountry) {
      errors.licenceIssuedCountry = "Required";
    }
    if (!values.licenceIssueDate) {
      errors.licenceIssueDate = "Required";
    }
    if (!values.licenceExpireDate) {
      errors.licenceExpireDate = "Required";
    }
    if (values.newPassword && values.newPassword.length <= 5) {
      errors.newPassword = "Password should have atleast 6 Charactors";
    }
    if (values.newPassword && !values.repeatPassword) {
      errors.repeatPassword = "Required";
    } else if (values.repeatPassword !== values.newPassword) {
      errors.repeatPassword = "Password not matched";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  },
};

export { formikConfig_profile };
