import React from "react";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import { BASE, SEARCH_STATIONS } from "./../../config/api";
import { CarTypeFilter } from "./CardTypeFilter";
import { CarGeneralFilters } from "./CarFilter";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
import { DropDownInput } from "../DropDownInput";

const customStyles = {
  option: (base, state) => ({
    ...base,
    borderBottom: "1px dotted #eee",
    color: state.isSelected ? "#fff" : "#23282c",
    backgroundColor: state.isSelected && "#035372",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#035372",
      border: "none",
      color: "#fff",
    },
  }),
};

let cancelToken;
const loadOptions = async (inputValue, callback) => {
  if (inputValue.length > 2) {
    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    try {
      let stations = await axios.post(
        `${BASE}${SEARCH_STATIONS}`,
        {
          search: inputValue,
        },
        { cancelToken: cancelToken.token }
      );
      callback(
        stations.data &&
          stations.data.data.map((v, i) => {
            return {
              label: v.name,
              value: v.code,
              ...v,
            };
          })
      );
    } catch (err) {}
  }
};

const SearchDropDown = (props) => {
  return (
    <Col md="12">
      <div className={styles.searchDropDown_container}>
        <label>{props.title}</label>
        <AsyncSelect
          {...props}
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          styles={customStyles}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
        />
      </div>
    </Col>
  );
};

const DriverAgeDropDown = ({ options, age, setDriverSeniority }) => {
  return (
    <Col md="2">
      <div className={`${styles.searchDropDown_container}`}>
        <DropDownInput
          label="Driver Age"
          className="driveDropDown_container"
          value={
            age
              ? options.find((item) => item.value == age)
              : options.find((item) => item.value == 26)
          }
          name="driver_seniority"
          onChange={(item) => {
            setDriverSeniority(item.value);
          }}
          options={options}
        />
        {age && age != 26 && (
          <p className="text text-warning">
            Young driver fee may apply, for age less than 26
          </p>
        )}
      </div>
    </Col>
  );
};

const DateTimePicker = ({ title, date, setDate,minDate }) => {
  const { startDate, endDate } = useSelector((state) => state.searchParams);

  return (
    <Col md="3" className={styles.searchPanel_column}>
      <label>{title}</label>
      <div className={styles.dataTime_container}>
        <DatePicker
          selected={date}
          showPopperArrow={false}
          dateFormat="iii, P"
          className={styles.datePicker_container}
          minDate={minDate}
          onChange={(date) => {
            setDate(date);
          }}
        />
        <DatePicker
          selected={date}
          showTimeSelect
          showTimeSelectOnly
          timeCaption="Time"
          className={styles.timePicker_container}
          dateFormat="h:mm aa"
          onKeyDown={(e) => {
            e.preventDefault();
            return false;
          }}
          onChange={(time) => {
            setDate(time);
          }}
        />
      </div>
    </Col>
  );
};

export {
  SearchDropDown,
  DateTimePicker,
  CarGeneralFilters,
  CarTypeFilter,
  DriverAgeDropDown,
};
