import React, { useState } from "react";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/core";
import { Tooltip } from "reactstrap";
import moment from "moment";

import { CardsContainer, MapResult } from "../";
import styles from "./styles.module.scss";
import { Button } from "./../";
import { useSelector } from "react-redux";

const override = css`
  display: block;
  margin: 20px 70px 0px 0px;
  border-color: red;
`;

export const ResultPanel = ({
  searchParams,
  setActiveMarker,
  searchResult,
  loadmoreCars,
  bookCar,
  selectCar,
  config: { selectedCurrency },
  toggleSeeResultOnMap,
}) => {
  const {
    carList,
    suppliers,
    isLoading,
    loadmore_loading,
    show_loadmore_button,
    sortByCarType,
    isError,
    supplier_ids,
    type_ids,
    vehicle_ids,
    suppliers_progress,
  } = searchResult;
  const {
    seeResultOnMap,
    startDate,
    endDate,
    pickUpStation,
    dropOffStation,
    deposit,
    selfLiabity,
    mileage,
    driverAge,
    childSeat,
    transmission,
    pick_up_type,
    winter_tyres,
    supplier_rating,
    number_of_seats,
    luggage_space,
    driver,
    electric,
    hybrid,
    bestValues,
    cheapest,
    expensive,
  } = searchParams;

  const handleLoadMore = () => {
    let _suppliers = suppliers.filter((v) => v.is_selected).map((v) => v.id);
    let load_more_vehicle_ids = [];
    carList.map((i) => load_more_vehicle_ids.push(i.id));
    let values = {
      loadmore: true,
      checkin_station_code:
        pickUpStation && pickUpStation.code && pickUpStation.code,
      checkout_station_code:
        dropOffStation && dropOffStation.code
          ? dropOffStation.code
          : pickUpStation.code,
      country_code: pickUpStation.country_code,
      checkin_time: moment(startDate).format(),
      checkout_time: moment(endDate).format(),
      supplier_filter: _suppliers,
      goGreenTypeFilter: { hybrid: hybrid, electric: electric },
      sortingFilters: {
        bestValues: bestValues,
        cheapest: cheapest,
        expensive: expensive,
      },
      type_filter: sortByCarType,
      currency: selectedCurrency && selectedCurrency.id,
      filters: {
        deposit: deposit,
        selfLiabity: selfLiabity,
        mileage: mileage,
        driverAge: driverAge,
        childSeat: childSeat,
        transmission: transmission,
        pick_up_type: pick_up_type,
        winter_tyres: winter_tyres,
        supplier_rating: supplier_rating,
        number_of_seats: number_of_seats,
        luggage_space: luggage_space,
        driver: driver,
      },
      count: carList && carList.length,
      supplier_ids,
      type_ids,
      vehicle_ids: load_more_vehicle_ids,
    };
    loadmoreCars(values);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  let showLoadMoreButton = false;
  if (suppliers_progress === 100) {
    showLoadMoreButton = true;
    if (loadmore_loading) {
      showLoadMoreButton = false;
    }
    if (!show_loadmore_button) {
      showLoadMoreButton = false;
    }
  }

  const toggle = () => {
    if (suppliers_progress === 100) {
      setTooltipOpen(false);
    } else {
      setTooltipOpen(!tooltipOpen);
    }
  };

  return (
    <section className={styles.mapSection}>
      {seeResultOnMap && (
        <span className={styles.mapClose}>
          <Button onClick={toggleSeeResultOnMap}>
            <i className="zmdi zmdi-close"></i>
          </Button>
        </span>
      )}
      {seeResultOnMap && (
        <span className={styles.loadMoreButton}>
          <PropagateLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={"#ffffff"}
            loading={loadmore_loading}
          />
          {showLoadMoreButton && (
            <div>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen}
                target="TooltipExample"
                toggle={toggle}
              >
                Please wait while fetch all the cars ...
              </Tooltip>
              <Button
                className="loadmore_btn"
                onClick={handleLoadMore}
                color="primary"
                disabled={!carList.length || suppliers_progress !== 100}
              >
                <span
                  id="TooltipExample"
                  style={
                    suppliers_progress === 100
                      ? { marginTop: 10, color: "#000" }
                      : { marginTop: 10, color: "#fff" }
                  }
                >
                  Load more vehicles
                </span>
              </Button>
            </div>
          )}
        </span>
      )}

      {/* <HashLoader css={override} sizeUnit={"px"} size={60} color={"#035372"} loading={isLoading} /> */}
      {seeResultOnMap ? (
        <MapResult
          seeResultOnMap={seeResultOnMap}
          selectCar={selectCar}
          searchResult={searchResult}
          searchParams={searchParams}
          handleBookCar={bookCar}
          handleLoadMore={handleLoadMore}
          loadmore_loading={loadmore_loading}
          show_loadmore_button={show_loadmore_button}
          handleActiveMarker={setActiveMarker}
          isSuppliersProgressing={suppliers_progress !== 100}
        />
      ) : (
        <CardsContainer
          carList={carList}
          handleLoadMore={handleLoadMore}
          searchParams={searchParams}
          selectCar={selectCar}
          handleBookCar={bookCar}
          loadmore_loading={loadmore_loading}
          show_loadmore_button={show_loadmore_button}
          isSuppliersProgressing={suppliers_progress !== 100}
        />
      )}
    </section>
  );
};
