import React, { useState } from "react";
import { Row, Container, CustomInput } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  CCBottomText,
  CCBottomTextMobile,
  CCHeading,
  CCInsuranceInfo,
  CCShortInfoMobile,
  CCTime,
  CCMileageInfo,
  CCPriceInfo,
  CCShortInfo,
  CardCardHorizontalBoxOne,
  CardCardHorizontalBoxTwo,
  CardCardHorizontalBoxThree,
  TermsModal,
} from "./cardCardUtils";
import {
  setTermsAndCondition,
  setSupplierTermsAndCondition,
  resetCouponState
} from "./../Stepper";
import styles from "./styles.module.scss";

const TermsAndCondition = ({ item }) => {
  const [RentalmooseTermsModal, setRentalmooseTermsModal] = useState(false);
  const [SupplierTermsModal, setSupplierTermsModal] = useState(false);
  const [RentalmooseTermsCheckBox, setRentalmooseTermsCheck] = useState(false);
  const [SupplierTermsCheckBox, setSupplierTermsCheck] = useState(false);

  const Dispatch = useDispatch();
  const { terms } = useSelector((state) => state.config);
  const {
    isTermsAndConditonChecked,
    isSupplierTermsAndConditonChecked,
  } = useSelector((state) => state.bookingDetail);

  return (
    <React.Fragment>
      <div className={styles.termsAndConditions_container}>
        <div>
          
          <CustomInput
            type="checkbox"
            id={`rentalmoose_terms ${Math.random() * 100}`}
            checked={isTermsAndConditonChecked}
            onChange={() =>
              setRentalmooseTermsCheck((prev) => {
                Dispatch(setTermsAndCondition(!prev));
                return !prev;
              })
            }
          />
          <p>
            I accept{" "}
            <span onClick={() => setRentalmooseTermsModal(true)}>
              Rentalmoose Terms and Conditions & Privacy Policy
            </span>
          </p>
        </div>
        <div>
         
          <CustomInput
            type="checkbox"
            id={`hertz_terms ${Math.random() * 100}`}
            checked={isSupplierTermsAndConditonChecked}
            onChange={() =>
              setSupplierTermsCheck((prev) => {
                Dispatch(setSupplierTermsAndCondition(!prev));
                return !prev;
              })
            }
          />
           <p>
            I accept {item && item.supplier_name}{" "}
            <span onClick={() => setSupplierTermsModal(true)}>
              Terms and Conditions & Privacy Policy
            </span>
          </p>
        </div>
      </div>
      
      <TermsModal
        modal={RentalmooseTermsModal}
        toggle={() => setRentalmooseTermsModal((prev) => !prev)}
        Text={terms}
        title="Rentalmoose Terms"
      />
      <TermsModal
        modal={SupplierTermsModal}
        toggle={() => setSupplierTermsModal((prev) => !prev)}
        title={`${item && item.supplier_name} Terms`}
        Text={item && item.supplier_terms}
      />
    </React.Fragment>
  );
};

export const CarCard = ({
  seeResultOnMap,
  item,
  handleBookCar,
  searchParams,
  isOptionStep = false,
  isConfirmationBooking = false,
  showCloseIcon = false,
  onCloseClick,
  isSuppliersProgressing,
}) => {
  const { push } = useHistory();
  const Dispatch = useDispatch();
  const { selectedCurrency } = useSelector((state) => state.config);
  const { suppliers_progress } = useSelector((state) => state.searchResult);
  let { pickUpStation, startDate, endDate, isMoreFilterOpen } = searchParams;
  let isDetailed = true;
  const specs = {
    seats: item.seats,
    doors: item.doors,
    baggage: item.baggage,
    air_conditioned: item.air_conditioned,
    transmission: item.transmission,
    fuel: item.fuel,
  };

  const _handleBookNow = () => {
    let values = {
      checkin_station_code: item && item.station_code,
      checkout_station_code: item && item.checkout_stations,
      country_code: pickUpStation.country_code,
      checkin_time: startDate,
      checkout_time: endDate,
      vehicle_id: item.id,
      contract_id: item.contract_id && item.contract_id,
      rule_id: item.rule_id && item.rule_id,
      contract_margin: item.contract_margin && item.contract_margin,
      rule_margin: item.rule_margin && item.rule_margin,
      duration_margin: item.duration_margin && item.duration_margin,
      currency: selectedCurrency && selectedCurrency.id,
      deposit: item.deposit && item.deposit,
      max_liab: item.max_liab && item.max_liab,
      selectedCar: item,
    };
    if (suppliers_progress == 100) {
      handleBookCar(values, push);
    }
  };

  const handleOnClick = () => {
    if (!isOptionStep && !seeResultOnMap) {
      window.gtag("event", "Car have been selected", {
        event_category: "Select car",
        event_label: "Car have been selected"
      })
      Dispatch(resetCouponState({}));
      _handleBookNow();
    }
  };

  let isSupplierProgress_loading = suppliers_progress !== 100;

  return (
    <div
      onClick={handleOnClick}
      style={
        !isOptionStep && !seeResultOnMap
          ? isSupplierProgress_loading
            ? { cursor: "wait" }
            : { cursor: "pointer" }
          : {}
      }
      className={`${isConfirmationBooking && styles.carCardBox_Confirmation}  ${
        styles.carCardBox
      } ${!isOptionStep &&
        !seeResultOnMap &&
        styles.hoverCard} ${isSupplierProgress_loading &&
        styles.disabledCard} animated fadeIn`}
    >
      <div>
        <CCHeading
          showCloseIcon={showCloseIcon}
          onCloseClick={onCloseClick}
          carName={item.title}
        />
        <CCShortInfo carImage={item.image} specs={specs}  
        supplier={item?.supplier_details || {logo: item.supplier_logo}} />
        
        
      </div>
      <div className={styles.card_bottom}>
        <CCPriceInfo
          amount={`${selectedCurrency && selectedCurrency.sign}${
            item.total_amount
          }`}
          total_days={item.total_days}
          supplier={item?.supplier_details || {logo: item.supplier_logo}}
          per_day_amount={`${selectedCurrency && selectedCurrency.sign}${
            item.per_day_amount
          }`}
        />
        {/* {isMoreFilterOpen && ( */}
          <CCMileageInfo
            mileage={item.mileage}
            cards={item.cards ? item.cards : ""}
            isDetailed={isDetailed}
            deposit={`${selectedCurrency &&
              selectedCurrency.sign}${item.deposit && item.deposit}`}
            max_liab={`${selectedCurrency &&
              selectedCurrency.sign}${item.max_liab && item.max_liab}`}
            fuel_policy={item && item.fuel_policy}
          />
        {/* )} */}
        <CCInsuranceInfo
          handleBookNow={_handleBookNow}
          isOptionStep={isOptionStep}
          itemId={item.id}
          startDate={startDate}
          endDate={endDate}
          amount={item.pay_now}
          isDetailed={isDetailed}
          currency={selectedCurrency.sign}
          isSuppliersProgressing={isSuppliersProgressing}
        />
        {isOptionStep && <TermsAndCondition item={item} />}
        <CCBottomText
          isDetailed={isDetailed}
          paynow={`${selectedCurrency && selectedCurrency.sign}${item.pay_now}`}
        />
      </div>
    </div>
  );
};

export const CarDetailedCard = ({
  isOptionStep = false,
  item,
  driverDetails,
  searchParams,
}) => {
  const { selectedCurrency } = useSelector((state) => state.config);

  return (
    <div className={`${styles.carCardBox} ${styles.carCardBox_Row} w-100`}>
      <Container>
        <Row className={`${styles.optionStep_row1} m-0`}>
          <CardCardHorizontalBoxOne item={item} />
          <CardCardHorizontalBoxTwo
            selectedCurrency={selectedCurrency}
            searchParams={searchParams}
            item={item}
          />
          <CardCardHorizontalBoxThree
            item={item}
            searchParams={searchParams}
            isOptionStep={isOptionStep}
            driverDetails={driverDetails}
          />
        </Row>
      </Container>
    </div>
  );
};
export const CarDetailedCardForMobile = ({
  seeResultOnMap,
  item,
  handleBookCar,
  searchParams,
  isOptionStep = false,
  isConfirmationBooking = false,
  showCloseIcon = false,
  onCloseClick,
  isSuppliersProgressing,
}) => {
  const { push } = useHistory();
  const { selectedCurrency } = useSelector((state) => state.config);
  const { suppliers_progress } = useSelector((state) => state.searchResult);
  let { pickUpStation, startDate, endDate, isMoreFilterOpen } = searchParams;
  let isDetailed = true;
  const specs = {
    seats: item.seats,
    doors: item.doors,
    baggage: item.baggage,
    air_conditioned: item.air_conditioned,
    transmission: item.transmission,
    fuel: item.fuel,
  };
  const _handleBookNow = () => {
    let values = {
      checkin_station_code: item && item.station_code,
      checkout_station_code: item && item.checkout_stations,
      country_code: pickUpStation.country_code,
      checkin_time: startDate,
      checkout_time: endDate,
      vehicle_id: item.id,
      contract_id: item.contract_id && item.contract_id,
      rule_id: item.rule_id && item.rule_id,
      contract_margin: item.contract_margin && item.contract_margin,
      rule_margin: item.rule_margin && item.rule_margin,
      duration_margin: item.duration_margin && item.duration_margin,
      currency: selectedCurrency && selectedCurrency.id,
      deposit: item.deposit && item.deposit,
      max_liab: item.max_liab && item.max_liab,
      selectedCar: item,
    };
    if (suppliers_progress == 100) {
      handleBookCar(values, push);
    }
  };

  const handleOnClick = () => {
    if (!isOptionStep && !seeResultOnMap) {
      window.gtag("event", "Car have been selected", {
        event_category: "Select car",
        event_label: "Car have been selected"
      })
      _handleBookNow();
    }
  };

  let isSupplierProgress_loading = suppliers_progress !== 100;

  return (
    <div
      onClick={handleOnClick}
      style={
        !isOptionStep && !seeResultOnMap
          ? isSupplierProgress_loading
            ? { cursor: "wait" }
            : { cursor: "pointer" }
          : {}
      }
      className={`${isConfirmationBooking && styles.carCardBox_Confirmation}  ${
        styles.carCardBox
      } ${!isOptionStep &&
        !seeResultOnMap &&
        styles.hoverCard} ${isSupplierProgress_loading &&
        styles.disabledCard} animated fadeIn`}
    >
      <div>
        <CCHeading
          showCloseIcon={showCloseIcon}
          onCloseClick={onCloseClick}
          carName={item.title}
        />
        <CCShortInfoMobile carImage={item.image} item={item}
            searchParams={searchParams}
            isOptionStep={isOptionStep}  
            supplier={item?.supplier_details || {logo: item.supplier_logo}}
        />
        <CCMileageInfo
            mileage={item.mileage}
            cards={item.cards ? item.cards : ""}
            isDetailed={isDetailed}
            deposit={`${selectedCurrency &&
                selectedCurrency.sign}${item.deposit && item.deposit}`}
            max_liab={`${selectedCurrency &&
                selectedCurrency.sign}${item.max_liab && item.max_liab}`}
            fuel_policy={item && item.fuel_policy}
        />
    
      </div>
      <CCTime item={item}
            searchParams={searchParams}
            isOptionStep={isOptionStep}  
            supplier={item?.supplier_details || {logo: item.supplier_logo}}
      />
    
       {isOptionStep && <TermsAndCondition item={item} />}

      <div className={styles.card_bottom}>
        <CCBottomTextMobile
          isDetailed={isDetailed}
          paynow={`${selectedCurrency && selectedCurrency.sign}${item.pay_now}`}
          item={item}
        />
        <CCInsuranceInfo
          handleBookNow={_handleBookNow}
          isOptionStep={isOptionStep}
          itemId={item.id}
          startDate={startDate}
          endDate={endDate}
          amount={item.pay_now}
          isDetailed={isDetailed}
          currency={selectedCurrency.sign}
          isSuppliersProgressing={isSuppliersProgressing}
        />
      </div>
    </div>
  );
};
