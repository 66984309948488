import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import axios from "axios";
import _ from "lodash";
import moment from "moment";

import {
  SearchDropDown,
  DateTimePicker,
  CarGeneralFilters,
  CarTypeFilter,
  DriverAgeDropDown,
} from "./searchPanelUtils";
import { CheckBox, Button, DropDownInput } from "./../";
import { GET_LOCATION_NAME, BASE } from "./../../config/api";
import styles from "./styles.module.scss";
import { setLocationHeaderStatus } from "../../store/actions/config.action";

export const SearchPanel = ({
  searchResult,
  getCars,
  setMoreOption,
  searchParams,
  selectEndDate,
  filter = false,
  selectStartDate,
  selectDriverSeniority,
  selectPickUpStation,
  toggleSeeResultOnMap,
  selectDropOffStation,
  toggleSameDropOffStation,
  toggleSearchMoreOptions,
  handleSupplierLoad,
  toggleSupplierFilter,
  sortResults,
  carTypeFilter,
  responsive = false,
  config,
  setBooknowClicked,
}) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue);
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x);
        resolve(x);
      });
    return [value, setter];
  }
  const {
    startDate,
    endDate,
    driverSeniority,
    pickUpStation,
    dropOffStation,
    isSameDropOffStation,
    deposit,
    selfLiabity,
    mileage,
    driverAge,
    childSeat,
    transmission,
    pick_up_type,
    winter_tyres,
    supplier_rating,
    number_of_seats,
    luggage_space,
    driver,
    electric,
    hybrid,
    bestValues,
    cheapest,
    expensive,
    isMoreFilterOpen,
  } = searchParams;
  const {
    suppliers,
    booknow_clicked,
    sortByCarType,
    isFromURLParams,
    carList,
    supplier_ids,
    type_ids,
    suppliers_progress,
  } = searchResult;
  let { moreOptions, selectedCurrency } = config;
  const [locationKeys, setLocationKeys] = useState([]);
  let [allSuppliers, setSuppliers] = useState(suppliers || []);
  let [isSwiping, setSwipe] = useState(false);
  let [checkinLocationFromURL, setCheckinLocation] = useState({});
  let [checkoutLocationFromURL, setCheckoutLocation] = useState({});
  let supplier_progress_loading = suppliers_progress !== 100;

  const history = useHistory();
  const { push } = history;
  const { search, state, pathname } = useLocation();
  const [selectedSuppliers, setSelectedSuppliers] = useAsyncState([]);
  const initialFiltersState = {
    childSeat: "No",
    deposit: "All",
    driver: "1",
    driverAge: driverSeniority,
    luggage_space: "All",
    mileage: "All",
    number_of_seats: "All",
    pick_up_type: 0,
    selfLiabity: "All",
    supplier_rating: "All",
    transmission: "All",
    winter_tyres: "All",
  };
  const driverAgeOptions = [
    { label: "20", value: 20 },
    { label: "21", value: 21 },
    { label: "22", value: 22 },
    { label: "23", value: 23 },
    { label: "24", value: 24 },
    { label: "25", value: 25 },
    { label: "26 or Older", value: 26 },
  ];

  const [selectedFilters, setSelectedFilters] = useState(initialFiltersState);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          history.goBack();
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  const handleFilteredSearch = (
    filterDetails,
    supplierFilter = selectedSuppliers
  ) => {
    const values = {
      is_filters_applied: true,
      checkin_station_code: pickUpStation.code,
      checkout_station_code: isSameDropOffStation
        ? pickUpStation.code
        : dropOffStation.code,
      country_code: pickUpStation.country_code,
      checkin_time: moment(startDate).format(),
      checkout_time: moment(endDate).format(),
      driver_seniority: driverSeniority,
      supplier_filter: supplierFilter,
      goGreenTypeFilter: { hybrid: hybrid, electric: electric },
      sortingFilters: {
        bestValues: bestValues,
        cheapest: cheapest,
        expensive: expensive,
      },
      type_filter: sortByCarType,
      currency: selectedCurrency && selectedCurrency.id,
      filters: filterDetails,
      supplier_ids,
      vehicle_ids: [],
      type_ids,
    };
    getCars(values, push);
  };
  const handleFilter = (type) => {
    if (type === "search") {
      handleFilteredSearch(selectedFilters);
    } else {
      setSelectedSuppliers([]).then((val) => {
        setSelectedFilters(initialFiltersState);
        handleFilteredSearch(initialFiltersState, val);
      });
    }
  };

  const handleSupplierToggle = (id) => {
    selectedSuppliers.includes(id)
      ? setSelectedSuppliers(selectedSuppliers.filter((v) => v !== id))
      : setSelectedSuppliers([...selectedSuppliers, id]);
  };
  const isMounted = React.useRef(false);

  useEffect(() => {
    setSuppliers(suppliers);
  }, [suppliers]);

  useEffect(() => {
    setSelectedSuppliers([]).then((val) => {
      setSelectedFilters(initialFiltersState);
    });
  }, [endDate]);
  const {locationHeaderInformation,getLocationCheckIn } = useSelector((state) => state.config);
  
  useEffect(() => {
    if (!booknow_clicked && isMounted.current) {
      const values = {
        path_type : true,
        is_filters_applied: true,
        checkin_station_code: pickUpStation.code,
        checkout_station_code: isSameDropOffStation
          ? pickUpStation.code
          : dropOffStation.code,
        country_code: pickUpStation.country_code,
        checkin_time: moment(startDate).format(),
        checkout_time: moment(endDate).format(),
        supplier_filter: selectedSuppliers,
        goGreenTypeFilter: { hybrid: hybrid, electric: electric },
        sortingFilters: {
          bestValues: bestValues,
          cheapest: cheapest,
          expensive: expensive,
        },
        type_filter: sortByCarType,
        currency: selectedCurrency && selectedCurrency.id,
        filters: {
          deposit: deposit,
          selfLiabity: selfLiabity,
          mileage: mileage,
          driverAge: driverAge,
          childSeat: childSeat,
          transmission: transmission,
          pick_up_type: pick_up_type,
          winter_tyres: winter_tyres,
          supplier_rating: supplier_rating,
          number_of_seats: number_of_seats,
          luggage_space: luggage_space,
          driver: driver,
        },
        supplier_ids,
        vehicle_ids: [],
        type_ids,
      };
      getCars(values, push);
    }
  }, [hybrid, electric, bestValues, expensive, cheapest, sortByCarType]);

  const getLocationName = () => {
    axios
      .get(`${BASE}${GET_LOCATION_NAME}${params.get("checkin_station_code")}`)
      .then((res) => {
        let data = res.data.map((val) => {
          return {
            name: val.name,
            code: val.code,
          };
        });
        setCheckinLocation(data[0]);
        selectPickUpStation(data[0]);
      });
    axios
      .get(`${BASE}${GET_LOCATION_NAME}${params.get("checkout_station_code")}`)
      .then((res) => {
        let data = res.data.map((val) => {
          return {
            name: val.name,
            code: val.code,
          };
        });
        setCheckoutLocation(data[0]);
        selectDropOffStation(data[0]);
      });
  };
  let path_type = true
  const location = pathname.startsWith('/location');

  if(location)
  {
     path_type = false;
  } 
 const Dispatch=useDispatch();
  useEffect(() => {
    
    if(location && getLocationCheckIn) {
      console.log("sdfsdfdfsdf")
      console.log(locationHeaderInformation.station_pick_code)
      const values = {
        path_type : path_type,
        checkin_station_code: getLocationCheckIn,
        checkout_station_code: getLocationCheckIn,
        country_code: params.get("country_code"),
        checkin_time: moment().add(1, 'days').format(),
        checkout_time: moment().add(2, 'days').format(),
        currency: selectedCurrency && selectedCurrency.id,
        filters: {
          deposit: deposit,
          selfLiabity: selfLiabity,
          mileage: mileage,
          driverAge: driverAge,
          childSeat: childSeat,
          transmission: transmission,
          pick_up_type: pick_up_type,
          winter_tyres: winter_tyres,
          supplier_rating: supplier_rating,
          number_of_seats: number_of_seats,
          luggage_space: luggage_space,
          driver: driver,
        },
        supplier_filter: selectedSuppliers,
        goGreenTypeFilter: { hybrid: hybrid, electric: electric },
        sortingFilters: {
          bestValues: bestValues,
          cheapest: cheapest,
          expensive: expensive,
        },
        search: true,
      };
      getCars(values, push, true);
      isMounted.current = true;
    }
    else if (state == undefined || (state && state.from !== "homePage")) {
      setBooknowClicked(false);
      let params = new URLSearchParams(search);
      if (params.get("checkin_station_code") && isFromURLParams) {
        getLocationName();
        selectStartDate(params.get("checkin_time").split(" ")[0]);
        selectEndDate(params.get("checkout_time").split(" ")[0]);

        const values = {
          path_type : path_type,
          checkin_station_code: params.get("checkin_station_code"),
          checkout_station_code: isSameDropOffStation
            ? params.get("checkin_station_code")
            : params.get("checkout_station_code"),
          country_code: params.get("country_code"),
          checkin_time: params.get("checkin_time").split(" ")[0],
          checkout_time: params.get("checkout_time").split(" ")[0],
          currency: selectedCurrency && selectedCurrency.id,
          filters: {
            deposit: deposit,
            selfLiabity: selfLiabity,
            mileage: mileage,
            driverAge: driverAge,
            childSeat: childSeat,
            transmission: transmission,
            pick_up_type: pick_up_type,
            winter_tyres: winter_tyres,
            supplier_rating: supplier_rating,
            number_of_seats: number_of_seats,
            luggage_space: luggage_space,
            driver: driver,
          },
          supplier_filter: selectedSuppliers,
          goGreenTypeFilter: { hybrid: hybrid, electric: electric },
          sortingFilters: {
            bestValues: bestValues,
            cheapest: cheapest,
            expensive: expensive,
          },
          search: true,
        };
        getCars(values, push, true);
      }
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    setBooknowClicked(false);
    isMounted.current = true;
  }, []);

  const handlePickupChange = (val) => {
    selectPickUpStation({
      code: val.value,
      country_code: val.country_code,
      name: val.label,
    });
  };

  const handleDropOffChange = (val) => {
    selectDropOffStation({
      code: val.value,
      name: val.name,
    });
  };
  
  const handleSearchCar = () => {

    const values = {
      path_type : path_type,
      search: true,
      checkin_station_code: pickUpStation.code,
      checkout_station_code: isSameDropOffStation
        ? pickUpStation.code
        : dropOffStation.code,
      country_code: pickUpStation.country_code,
      checkin_time: moment(startDate).format(),
      checkout_time: moment(endDate).format(),
      driver_seniority: driverSeniority,
      supplier_filter: selectedSuppliers,
      goGreenTypeFilter: { hybrid: hybrid, electric: electric },
      sortingFilters: {
        bestValues: bestValues,
        cheapest: cheapest,
        expensive: expensive,
      },
      type_filter: sortByCarType,
      currency: selectedCurrency && selectedCurrency.id,
      filters: selectedFilters,
      supplier_ids,
      vehicle_ids: [],
      type_ids,
    };
    getCars(values, push);
    // history.push({
    //   pathname: "/results",
    //   search: `?checkin_station_code=${window.location.checkin_station_code}&checkin_time=${window.location.checkin_time}&checkout_time=${window.location.checkout_time}&checkout_station_code=${window.location.checkout_station_code}&country_code=${window.location.country_code}`,
    //   state: { from: "location" },
    // });
    //Dispatch(setLocationHeaderStatus(false));
    try {
      window.scroll({
        top: 340,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(340, 0);
    }
  };

  const onDropDownChange = (key, value) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
  };
  let params = new URLSearchParams(search);
  const Width = window.innerWidth;
  return (
    <section className={`${styles.searchPanel_container}`}>
      <div className={styles.locationSection}>
        <div className={styles.locationBoxShadow}>
          <Container>
            <Row>
              <Col md="4">
                <Row>
                  <SearchDropDown
                    title="Location"
                    onChange={handlePickupChange}
                    value={{
                      label: pickUpStation.name,
                      value: pickUpStation.code,
                    }}
                  />
                  <Col md="12" style={{ padding: 0 }}>
                    {!isSameDropOffStation && (
                      <SearchDropDown
                        title="Drop-Off Location"
                        onChange={handleDropOffChange}
                        value={
                          dropOffStation?.name
                            ? {
                                label: dropOffStation.name,
                                value: dropOffStation.code,
                              }
                            : {
                                label: pickUpStation.name,
                                value: pickUpStation.code,
                              }
                        }
                      />
                    )}
                    <Col md="12" className={`${styles.checkBoxIndex}`}>
                      <CheckBox
                        label="Same Drop-Off Location"
                        checked={isSameDropOffStation}
                        id="sameDropOff_checkbox"
                        onChange={() => toggleSameDropOffStation()}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
              <DateTimePicker
                title="Pick-up Date"
                date={startDate && new Date(startDate)}
                setDate={(date) => selectStartDate(moment(date).format())}
                minDate = {new Date()}
              />
              <DateTimePicker
                title="Drop-off Date"
                date={endDate && new Date(endDate)}
                setDate={(date) => selectEndDate(moment(date).format())}
                minDate = {new Date(startDate)}

              />
              <DriverAgeDropDown
                options={driverAgeOptions}
                age={driverSeniority}
                setDriverSeniority={selectDriverSeniority}
              />
            </Row>
            <Row>
              <Col md="4"></Col>
              <Col md="6" className={!isSameDropOffStation ? `${styles.row_margin_open}`: `${styles.row_margin}`}>
                <div className={`${styles.goGreen_container} advancedCheckbox`}>
                  <p>
                    Go<span>Green</span>
                  </p>
                  <div className={styles.checkbox_container}>
                    <CheckBox
                      label="Electric"
                      checked={electric}
                      color="#fff"
                      disabled={supplier_progress_loading}
                      id="electric_checkbox"
                      onChange={() => {
                        setMoreOption("electric", !electric);
                      }}
                    />
                    <CheckBox
                      label="Hybrid"
                      checked={hybrid}
                      disabled={supplier_progress_loading}
                      id="hybrid_checkbox"
                      onChange={() => {
                        setMoreOption("hybrid", !hybrid);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md="2" className={!isSameDropOffStation ? `${styles.row_margin_open}`: `${styles.row_margin}` }>
                <div className={styles.searchPanel_btn}>
                  <Button
                    color="primary"
                    onClick={handleSearchCar}
                    disabled={supplier_progress_loading}
                  >
                    <span className="zmdi zmdi-search"></span> Search
                  </Button>
                </div>
                <label
                  className={`${styles.moreFilter_btn} ${
                    isMoreFilterOpen ? styles.active : ""
                  }`}
                  onClick={() => toggleSearchMoreOptions()}
                >
                  Advanced Search
                </label>
              </Col>
            </Row>
            {isMoreFilterOpen && (
              <React.Fragment>
                <Slider
                  onSwipe={() => setSwipe(true)}
                  afterChange={() => setSwipe(false)}
                  infinite={false}
                  className="carTypePanel_slider"
                  slidesToShow={Width > 767 ? 6 : 1}
                  variableWidth={Width > 767 ? false : true}
                  swipeToSlide={true}
                >
                  {allSuppliers &&
                    allSuppliers.map((v, i) => (
                      <div className={styles.supplier_slider_item} key={i}>
                        <CheckBox
                          disabled={
                            !v.is_responded || supplier_progress_loading
                          }
                          key={i}
                          id={`${v.name}_${i}`}
                          label={<img src={v.logo} alt="partner logo" />}
                          checked={selectedSuppliers.includes(v.id)}
                          onChange={() =>
                            isSwiping ? null : handleSupplierToggle(v.id)
                          }
                        />
                      </div>
                    ))}
                </Slider>
                <Row
                  className={`${styles.advance_search_dropdown} animated fadeIn"`}
                >
                  <Col md="2">
                    <DropDownInput
                      label="Deposit"
                      name="deposit"
                      value={
                        moreOptions &&
                        moreOptions.deposit.find(
                          (v) => v.value === selectedFilters.deposit
                        )
                      }
                      options={moreOptions.deposit}
                      onChange={(val) => onDropDownChange("deposit", val.value)}
                    />
                  </Col>
                  <Col md="2">
                    <DropDownInput
                      label="Self liability"
                      name="self_liabity"
                      value={
                        moreOptions &&
                        moreOptions.self_liability.find(
                          (v) => v.value === selectedFilters.selfLiabity
                        )
                      }
                      options={moreOptions.self_liability}
                      onChange={(val) =>
                        onDropDownChange("selfLiabity", val.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <DropDownInput
                      label="Allowed mileage"
                      name="mileage"
                      value={
                        moreOptions &&
                        moreOptions.mileage.find(
                          (v) => v.value === selectedFilters.mileage
                        )
                      }
                      options={moreOptions.mileage}
                      onChange={(val) => onDropDownChange("mileage", val.value)}
                    />
                  </Col>

                  <Col md="2">
                    <DropDownInput
                      label="Transmission"
                      name="transmission"
                      value={
                        moreOptions &&
                        moreOptions.transmission.find(
                          (v) => v.value === selectedFilters.transmission
                        )
                      }
                      options={moreOptions.transmission}
                      onChange={(val) =>
                        onDropDownChange("transmission", val.value)
                      }
                    />
                  </Col>

                  <Col md="2">
                    <div className={styles.searchPanel_btn}>
                      <label>&nbsp;</label>
                      <Button
                        color="primary"
                        onClick={() => handleFilter("search")}
                        disabled={supplier_progress_loading}
                      >
                        <span className="zmdi zmdi-filter-list"></span> Apply
                      </Button>
                    </div>
                  </Col>
                  <Col md="2">
                    <div className={styles.searchPanel_btn}>
                      <label>&nbsp;</label>
                      <Button
                        color="primary"
                        onClick={() => {
                          handleFilter("reset");
                        }}
                      >
                        <span className="zmdi zmdi-repeat"></span> Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Container>
        </div>
      </div>
      {filter && (
        <div
          className={`${styles.filter_container} ${!responsive && "container"}`}
        >
          
          <CarTypeFilter
            carTypeFilter={carTypeFilter}
            searchParams={searchParams}
            getCars={getCars}
            searchResult={searchResult}
            handleSupplierLoad={handleSupplierLoad}
            config={config}
            supplier_progress_loading={supplier_progress_loading}
          />
          <CarGeneralFilters
            setMoreOption={setMoreOption}
            searchParams={searchParams}
            searchResult={searchResult}
            getCars={getCars}
            toggleSupplierFilter={toggleSupplierFilter}
            sortResults={sortResults}
            handleMapToggle={toggleSeeResultOnMap}
            supplier_progress_loading={supplier_progress_loading}
          />
          {/* {console.log("CALCCCC",config,"-----",sortResults,"sde-----",searchResult)} */}
        </div>
      )}
    </section>
  );
};
