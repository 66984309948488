import React, { useState } from "react";
import {Container,Row} from "reactstrap";
  import "./styles.css";
export const LocationPagesHeader = ({info}) => {
    return (
           <Container className="mt-4 mb-3">
               <Row className="px-5 py-3">
                    <div className="col-lg-8 col-sm-12 text-center location_title">
                        <span className="mb-4">
                            <b> LOCATION</b>
                        </span>
                    </div>
                    <div className="col-lg-8 col-sm-12 text-center location_title_1">
                        <span className="location_sub_title mt-2">
                            &nbsp;{info.location}
                        </span> 
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1 mt-2 mb-5 local text-center">
                        <div className="local_temp w-100">
                        <span className="divspan">
                        <b>{info.temp}</b><span className="tempSize">&#8451;</span></span> <br/>
                        <span className="mb-4 smallspan">
                            <b>LOCAL TEMP</b></span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1 mt-2 mb-5 time text-center">
                        <div className="time_now w-100">
                            <span className="divspan">
                            <b>{info.current_time}</b></span> <br/>
                            <span className="mb-4 smallspan px-3">
                                <b>TIME NOW</b></span>
                        </div>
                    </div>
                </Row>
           </Container>
    );

}