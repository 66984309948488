import React from "react";
import { Card, CardImg, CardBody } from "reactstrap";

import styles from "./styles.module.scss";
// import LazyLoad from 'react-lazyload';

const CardDetal = ({ title, description, readmore_link }) => {
  return (
    <CardBody className={styles.cardBodyDetail_container}>
      <h2>{title}</h2>
      <span dangerouslySetInnerHTML={{ __html: description }}></span>
      <a href={readmore_link} target="_blank" className={styles.readmorelink}>
        Read more <span className="zmdi zmdi-arrow-right"></span>
      </a>
    </CardBody>
  );
};
const LocationCardDetal = ({ title, description, readmore_link }) => {
  return (
    <CardBody className={styles.cardBodyDetail_container}>
      <h2>{title}</h2>
      <span dangerouslySetInnerHTML={{ __html: description }}></span>
      <a href={readmore_link} target="_blank" className={styles.readmorelink}>
        Read more <span className="zmdi zmdi-arrow-right"></span>
      </a>
    </CardBody>
  );
};
const BlogCard = ({ title, imgSrc, alt, description, readmore_link }) => {
  return (
    <Card className={styles.placeCard_container} style={{ border: "none" }}>
      <div className={styles.image_container}>
        {/* <LazyLoad height={200}> */}
          <CardImg top src={imgSrc} alt={alt} />
        {/* </LazyLoad> */}
      </div>
      <CardDetal
        title={title}
        description={description}
        readmore_link={readmore_link}
      />
    </Card>
  );
};

const BlogLocationCard = ({ title, imgSrc, alt, description, readmore_link }) => {
  return (
    <div className="col-6">
    <Card className={styles.location_placeCard_container} style={{ border: "none" }}>
      <div className={styles.image_container}>
        {/* <LazyLoad height={200}> */}
          <CardImg top src={imgSrc} alt={alt} />
        {/* </LazyLoad> */}
      </div>
      <LocationCardDetal
        title={title}
        description={description}
        readmore_link={readmore_link}
      />
    </Card>
    </div>
  );
};

const BlogCardSliderItem = ({ imgSrc, alt }) => {
  return (
    <div className={styles.blogCardSlider_item}>
      {/* <LazyLoad height={200}> */}
        <img src={imgSrc} alt={alt} />
      {/* </LazyLoad> */}
    </div>
  );
};

export { BlogCard, BlogCardSliderItem, BlogLocationCard };
