import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";
import downloadIcon from "./../../assets/download.svg";
import greenTick from "./../../assets/greentick.svg";
import defaultImage from "../../assets/defaultCarImage.png";

const ItemKeys = ({ title, value, locationClass='' }) => (
  <div className={styles.itemKeys + ' ' + locationClass}>
    <p className={styles.itemKeys_title}>{title}</p>
    <p className={styles.itemKeys_value}>{value}</p>
  </div>
);

export const MyAccountNavigation = () => {
  const { pathname } = useLocation();
  return (
    <section>
      <Container>
        <div className={styles.headerLinks_container}>
          <Link
            to="/account/bookings"
            className={pathname.startsWith("/account/bookings") ? styles.is_active : ""}
          >
            My Bookings
          </Link>
          <Link
            to="/account/bookingHistory"
            className={pathname.startsWith("/account/bookingHistory") ? styles.is_active : ""}
          >
            History
          </Link>
          <Link
            to="/account/profile"
            className={pathname.startsWith("/account/profile") ? styles.is_active : ""}
          >
            Profile
          </Link>
        </div>
      </Container>
    </section>
  );
};

export const BookingListItem = ({
  item: {
    vehicle: { title, image },
    download_voucher,
    booking_reference,
    booking_status,
    duration_in_days,
    pickup_date,
    pickup_location: { address },
  },
  handleItemClick,
}) => {
  const Width = window.innerWidth;
  return (
    <Row className={styles.bookingListItem} onClick={handleItemClick}>
      {Width >= 767 ? (
        <>
          <Col md="2" className="text-center">
            <span className={styles.imgBox}>
             <img
                 src={image}
                 alt="Car"
                 className={styles.car_image}
                 onError={(e) => {
                   e.target.onerror = null;
                   e.target.src = defaultImage;
                 }}
             />
            </span>
          </Col>
          <Col md="8" className="p-0">
            <h3 className={styles.bookingListItem_title}>
              {title && title.replace("OR SIMILAR", "")}
              <span>or similar</span>
            </h3>
            <div className={styles.itemKeys_container}>
              <ItemKeys title="Booking Id" value={booking_reference} />
              <ItemKeys title="Pick-Up" value={pickup_date} />
              <ItemKeys title="Days" value={duration_in_days} />
              <ItemKeys title="Location" value={address} locationClass={styles.account_description}/>
            </div>
          </Col>
          <Col md="2" className={`${styles.bookinglistBtn} text-right`}>
            <a
              href={download_voucher}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              download
              className={styles.get_voucher}
            >
              <img src={downloadIcon} alt="icon" /> Get Voucher
            </a>
            <span
              className={`${styles.status} ${
                booking_status !== "Booked" ? styles.status_cancelled : ""
              } no`}
            >
              {booking_status === "Booked" && <img src={greenTick} alt="icon" />}
              {booking_status}
            </span>
          </Col>
        </>
      ) : (
        <>
          <Col md="12">
            <h3 className={styles.bookingListItem_title}>
              {title && title.replace("OR SIMILAR", "")}
              <span>or similar</span>
            </h3>
            <Row>
              <Col xs="6" className="text-center">
                <span className={styles.imgBox}>
                  <img className={styles.car_image} src={image} />
                </span>
                <div md="2" className={`${styles.bookinglistBtn} text-left`}>
                  <span
                    className={`${styles.status} ${
                      booking_status !== "Booked" ? styles.status_cancelled : ""
                    } no`}
                  >
                    {booking_status === "Booked" && <img src={greenTick} alt="icon" />}
                    {booking_status}
                  </span>
                  <a
                    href={download_voucher}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    download
                    className={styles.get_voucher}
                  >
                    <img src={downloadIcon} alt="icon" /> Get Voucher
                  </a>
                </div>
              </Col>
              <Col xs="6" className="pr-0">
                <div className={styles.itemKeys_container}>
                  <ItemKeys title="Booking Id" value={booking_reference} />
                  <ItemKeys title="Pick-Up" value={pickup_date} />
                  <ItemKeys title="Days" value={duration_in_days} />
                  <ItemKeys title="Location" value={address} />
                </div>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};
