import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import {
  getBookedBookings,
  getBookingDetail,
} from "../../../../store/actions/account.action";
import { BookingListItem, MyAccountNavigation } from "./../../utils";

export const Bookings = () => {
  const Dispatch = useDispatch();
  const { push } = useHistory();
  const { user, token } = useSelector((state) => state.auth);
  const { bookedBookings, isLoading, isError, message } = useSelector(
    (state) => state.UserAccount
  );

  useEffect(() => {
    if (!token) {
      push("/");
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    Dispatch(getBookedBookings(user.id, token));
  }, []);

  return (
    <React.Fragment>
      <MyAccountNavigation />
      <Container>
        {bookedBookings &&
          bookedBookings.map((val, idx) => (
            <BookingListItem
              item={val}
              key={idx}
              handleItemClick={() =>
                Dispatch(getBookingDetail(val.id, token, push))
              }
            />
          ))}
        {!isLoading && bookedBookings.length < 1 && <p>{message}</p>}
      </Container>
    </React.Fragment>
  );
};

export default Bookings;
