import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  GET_ALL_COUNTRIES,
  GET_ALL_COUNTRIES_FAILED,
  GET_ALL_COUNTRIES_SUCCESS,
  FETCH_CAR_TYPES,
  STRIPE_KEY,
  STRIPE_KEY_FAILED,
  STRIPE_KEY_SUCCESS,
  FETCH_CAR_TYPES_FAILED,
  FETCH_CAR_TYPES_SUCCESS,
  GET_SEARCH_VEHICLE_FILTER,
  GET_SEARCH_VEHICLE_FILTER_SUCCESS,
  GET_SEARCH_VEHICLE_FILTER_FAILED,
  GET_BLOGS,
  GET_BLOGS_FAILED,
  GET_BLOGS_SUCCESS,
  GET_PRIVACY_PAGE_CONTENT,
  GET_PRIVACY_PAGE_CONTENT_FAILED,
  GET_PRIVACY_PAGE_CONTENT_SUCCESS,
  SET_LOCATION_INFORMATION,
  SET_LOCATION_DATA,
  GET_LOCATION_DATA_FAILED,
  GET_LOCATION_DATA_SUCCESS,
  SET_LOCATION_BLOGS
} from "./../actions/config.action";
import {
  BASE,
  GET_ALL_COUNTRIES_API,
  GET_SEARCH_VEHICLE_FILTERS_API,
  FETCH_ALL_CAR_TYPES,
  GET_STRIPE_KEY,
  WORDPRESS_BLOGS_API,
  WORDPRESS_PRIVACY_PAGE_API
} from "./../../config/api";

export class ConfigEpic {
  static getAllCountries = action$ =>
    action$.pipe(
      ofType(GET_ALL_COUNTRIES),
      switchMap(() => {
        return new Observable(async observer => {
          try {
            const result = await axios.get(`${BASE}${GET_ALL_COUNTRIES_API}`);
            const { data } = result;
            if (data.data) {
              observer.next({
                type: GET_ALL_COUNTRIES_SUCCESS,
                payload: data.data,
                message: "Get all countries success"
              });
            } else {
              observer.next({
                type: GET_ALL_COUNTRIES_FAILED,
                message: "Get all countries failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_ALL_COUNTRIES_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in getting countries"
            });
          }
        });
      })
    );
  static stripeKey = action$ =>
    action$.pipe(
      ofType(STRIPE_KEY),
      switchMap(() => {
        return new Observable(async observer => {
          try {
            const result = await axios.get(
              `${BASE}${GET_STRIPE_KEY}`
            );
            const { data } = result;
            if (data.data) {
              //console.log("there");
              observer.next({
                type: STRIPE_KEY_SUCCESS,
                payload: data.data,
                message: "Get key success"
              });
            } else {
              observer.next({
                type: STRIPE_KEY_FAILED,
                message: "Get key failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: STRIPE_KEY_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in getting key"
            });
          }
        });
      })
    );
  static getSearchVehicleFilter = action$ =>
    action$.pipe(
      ofType(GET_SEARCH_VEHICLE_FILTER),
      switchMap(() => {
        return new Observable(async observer => {
          try {
            const result = await axios.get(
              `${BASE}${GET_SEARCH_VEHICLE_FILTERS_API}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_SEARCH_VEHICLE_FILTER_SUCCESS,
                payload: data.data,
                message: "Get all countries success"
              });
            } else {
              observer.next({
                type: GET_SEARCH_VEHICLE_FILTER_FAILED,
                message: "Get all countries failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_SEARCH_VEHICLE_FILTER_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in getting countries"
            });
          }
        });
      })
    );
  static fetchCarTypes = action$ =>
    action$.pipe(
      ofType(FETCH_CAR_TYPES),
      switchMap(() => {
        return new Observable(async observer => {
          try {
            const result = await axios.get(`${BASE}${FETCH_ALL_CAR_TYPES}`);
            const { data } = result;
            if (data.data) {
              observer.next({
                type: FETCH_CAR_TYPES_SUCCESS,
                payload: data.data,
                message: "Get all car types success"
              });
            } else {
              observer.next({
                type: FETCH_CAR_TYPES_FAILED,
                message: "Get all car types failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: FETCH_CAR_TYPES_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in getting car types"
            });
          }
        });
      })
    );
  static getBlogs = action$ =>
    action$.pipe(
      ofType(GET_BLOGS),
      switchMap(() => {
        return new Observable(async observer => {
          try {
            const { data: data_1 } = await axios.get(
              `${WORDPRESS_BLOGS_API}?category=1`
            );
            const { data: data_2 } = await axios.get(
              `${WORDPRESS_BLOGS_API}?category=2`
            );
            const { data: data_3 } = await axios.get(
              `${WORDPRESS_BLOGS_API}?category=3`
            );
            if (data_1.length > 0 || data_2.length > 0 || data_3.length > 0) {
              observer.next({
                type: GET_BLOGS_SUCCESS,
                category_1: data_1,
                category_2: data_2,
                category_3: data_3,
                message: "Blogs Fetched"
              });
            } else {
              observer.next({
                type: GET_BLOGS_FAILED,
                message: "Blogs fetch failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_BLOGS_FAILED,
              message: errorMessage ? errorMessage : "Error in fetching blogs"
            });
          }
        });
      })
    );
  static getPrivacyPageContent = action$ =>
    action$.pipe(
      ofType(GET_PRIVACY_PAGE_CONTENT),
      switchMap(() => {
        return new Observable(async observer => {
          try {
            const result = await axios.get(WORDPRESS_PRIVACY_PAGE_API);
            const { data } = result;
            if (data) {
              observer.next({
                type: GET_PRIVACY_PAGE_CONTENT_SUCCESS,
                payload: data.content.rendered,
                message: "Privacy Page Content Fetched"
              });
            } else {
              observer.next({
                type: GET_PRIVACY_PAGE_CONTENT_FAILED,
                message: "Privacy Page Content fetch failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response
              ? err.response.data.message
              : "Error in fetching Privacy Page Content";
            observer.next({
              type: GET_PRIVACY_PAGE_CONTENT_FAILED,
              message: errorMessage
            });
          }
        });
      })
    );
  
    static getLocationData = action$ =>
    action$.pipe(
      ofType(SET_LOCATION_DATA),
      switchMap((payload) => {
        return new Observable(async observer => {
          try {
            const result = await axios.get(`${BASE}/location/${payload.payload}`);
            const { data } = result;
            if (data.data) {
              observer.next({
                type: SET_LOCATION_INFORMATION,
                payload: data.data,
                message: "Get location success"
              });
            } else {
              observer.next({
                type: GET_LOCATION_DATA_FAILED,
                message: "Get location failed"
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_LOCATION_DATA_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in getting location"
            });
          }
        });
      })
    );
}
