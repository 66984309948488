import React from "react";
import {Price, Temp,Things, Blogs, LocationImage, NearestLocation} from "./locationUtils";

export const LocationPageInfo = () => { 
    return (
        <>
        <Price />
        <Temp />
        <Blogs />
        {/* <Things/> */}
        <LocationImage />
        <NearestLocation />
        </>
    );
}