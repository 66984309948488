import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Slider from "react-slick";

import { BlogCard, BlogCardSliderItem, BlogLocationCard } from "./blogCardUtils";
import MooseIcon from "../../assets/moose-big.png";
import styles from "./styles.module.scss";

import LazyLoad from 'react-lazyload';

export const BlogCardComponent = ({
	title,
	blogs_data: blogs,
	link,
	showMoose = false
}) => {
	let blogs_data = blogs && blogs.slice(0, 3);
	return (
		<section className={`${styles.blogCardSection} d-none d-md-flex`}>
			{showMoose && (
				<div className={styles.mooseImage}>
					<LazyLoad height={200}>
						<img src={MooseIcon} alt="Moose" className="img-fluid" />
					</LazyLoad>
				</div>
			)}
			<Container>
				<div className={styles.popularPlacesSection_head}>
					<h1 className={styles.title}>{title}</h1>
					<span>
						<a target="_blank" href={link}>
							Read all post
						</a>
					</span>
				</div>
				<div className={styles.popularPlacesBlogCards_container}>
					{blogs_data &&
						blogs_data.map((value, idx) => <BlogCard key={idx} {...value} />)}
				</div>
			</Container>
		</section>
	);
};

export const BlogLocationComponent = ({
	title,
	blogs_data: blogs,
	link,
	showMoose = false
}) => {
	let blogs_data = blogs;
	return (
		<section className={`${styles.blogCardSection} d-none d-md-flex`}>
			{/* {showMoose && (
				<div className={styles.mooseImage}>
					<LazyLoad height={200}>
						<img src={MooseIcon} alt="Moose" className="img-fluid" />
					</LazyLoad>
				</div>
			)} */}
			<Container>
				<div className={styles.popularPlacesSection_head}>
					<h1 className={styles.title}>{title}</h1>
					<span>
						<a target="_blank" href={link}>
							View all post
						</a>
					</span>
				</div>
				<div className={styles.popularPlacesBlogCards_container}>
					<Row>
					{blogs_data &&
						blogs_data.map((value, idx) => <BlogLocationCard key={idx} {...value} />)}
					</Row>
				</div>
			</Container>
		</section>
	);
};
export const BlogCardSlider = ({ blogs_data, title, showMoose = false }) => {
	let [nav, setNav] = useState();
	let [navIndex, setNavIndex] = useState(0);
	let selectedItem = blogs_data[navIndex];

	return (
		<section className='w-100'>
			{showMoose && (
				<div className={styles.mooseImage}>
					<LazyLoad height={200}>
						<img src={MooseIcon} alt="Moose" className="img-fluid" />
					</LazyLoad>
				</div>
			)}
			<div className={styles.blogCardSlider_container}>
				<h1 className={styles.blogCardSlider_title}>{title}</h1>
				<Slider
					asNavFor={nav}
					ref={slider => setNav(slider)}
					slidesToShow={1}
					swipeToSlide={true}
					afterChange={idx => setNavIndex(idx)}
					focusOnSelect={true}
					className="blogCardSlider"
					infinite
					variableWidth
					centerPadding={"60px"}
					centerMode
				>
					{blogs_data &&
						blogs_data.map((value, idx) => (
							<BlogCardSliderItem key={idx} {...value} />
						))}
				</Slider>
				<div className={styles.blogCardSliderDesc_container}>
					<p className={styles.item_title}>
						{selectedItem && selectedItem.title}
					</p>
					<p className={styles.item_desc}>
						{selectedItem && <span dangerouslySetInnerHTML={{ __html: selectedItem.description }}></span>}
					</p>
					<a
						href={selectedItem && selectedItem.readmore_link}
						className={styles.readmorelink}
					>
						Read more <span className="zmdi zmdi-arrow-right"></span>
					</a>
				</div>
			</div>
		</section>
	);
};

export default BlogCardComponent;
