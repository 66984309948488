export const BASE = "https://api.rentalmoose.com";
export const LOGIN_API = "/login"; // POST
export const SIGNUP_API = "/signup"; // POST
export const FORGOT_PASSWORD_API = "/forgot-password"; // POST
export const RESET_PASSWORD_API = "/reset-password"; // POST
export const GET_ALL_COUNTRIES_API = "/getCountries"; //GET
export const FETCH_ALL_CAR_TYPES = "/getCarTypes"; //GET
export const SEARCH_STATIONS = "/searchStations"; //POST
export const GET_AVAILABLE_CAR_DETAILS = "/getAvailableVehicles"; // POST
export const SEARCH_CARS = "/getVehicles"; //POST
export const GET_CAR_DETAILS = "/getVehicleDetails"; // POST
export const BOOKING_CAR = "/booking"; //POST
export const GET_SEARCH_VEHICLE_FILTERS_API = "/search-vehicle-filters"; //GET
export const GET_BOOKED_BOOKINGS_API = "/user/bookings"; //GET
export const FAIL_BOOKING = "/user/booking/fail/"; //POST
export const SUCCESS_BOOKING = "/user/booking/success/"; //POST
export const GET_EXPIRED_BOOKINGS_API = "/user/booking/history"; //GET
export const GET_BOOKING_DETAIL_API = "/user/booking/details?bookingID="; //GET
export const CANCEL_BOOKING_API = "/user/booking/cancel"; //POST
export const UPDATE_USER_PROFILE_API = "/user/profile";
export const UPLOAD_VEHICLE_IMAGES_API = "/upload-booking-photos"; //POST
export const DELETE_VEHICLE_IMAGES_API = "/delete-booking-photos"; //POST
export const WORDPRESS_BLOGS_API = `${BASE}/get-wp`; //GET
export const WORDPRESS_PRIVACY_PAGE_API =
  "https://blog.rentalmoose.com/wp-json/wp/v2/posts/81"; //GET
export const SUBSCRIBE = "/subscribe"; //POST
export const GET_LOCATION_NAME = "/get-location-name?code="; //GET
export const FEEDBACK_API = "/feedback"; //POST
export const GET_STRIPE_KEY = "/stripe"; //POST
export const REEDEM_COUPON_API = '/coupon/redeem';
export const GET_LOCATION_TOP_CARS = '/topLocationCars/';
export const GET_LOCATION_INFO = '/locationInfo/';
export const GET_LOCATION_TOP  = '/topLocations';
export const GET_LOCATION_ALL_CARS  = '/locationCars';
 
