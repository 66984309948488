import React from "react";
import ErrorPage from "./errorPage";

class ErrorBoundary extends React.Component {
  state = {
    error: null
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (<ErrorPage errorString={this.state.error.toString()} />);
    }
    return (this.props.children);
  }
}

export default ErrorBoundary;
