import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";

import {
  SearchPanelComponent,
  ResultPanelComponent,
  setTermsAndCondition,
  setSupplierTermsAndCondition,
} from "./../../components";

export const SearchResult = ({location}) => {
  const { carList ,isLoading} = useSelector((state) => state.searchResult);
  const Dispatch = useDispatch();

  const WIDTH = window.innerWidth;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    Dispatch(setTermsAndCondition(false));
    Dispatch(setSupplierTermsAndCondition(false));
    if (!location){
      try {
        window.scroll({
          top: 600,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(600, 0);
      }
    }
    
  }, []);
  return (
    <React.Fragment>
      <SearchPanelComponent filter responsive={WIDTH <= 767} />
      <ResultPanelComponent />
    </React.Fragment>
  );
};
