import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {Helmet} from "react-helmet";
import {
  DownloadVoucherContainer,
  bookingLocation,
  LocationCardsContainer,
  OrderedExtras,
} from "./confirmationUtils";
import { PriceBreakDownContainer } from "./../stepsUtils";
import { CarDetailedCard, HorizontalBorder, Button, CarCard } from "./../../../";
import { IncludedPriceRow } from "./../stepsUtils";
import { NavLink as Link, withRouter } from "react-router-dom";
import { TermsModal } from "../../../CarCard/cardCardUtils";

import styles from "./styles.module.scss";

export  const ConfirmationStep = ({
  searchResult: { selectedCar },
  bookingDetail: { bookingVoucher, driverDetails },
  auth: { isUserLoggedIn },
  searchParams, showModal
}) => {
  const { pay_now, pay_later, total_amount, extras } = selectedCar;
  const INNER_WIDTH = window.innerWidth;
  const [RentalmooseTermsModal, setRentalmooseTermsModal] = useState(false);

  const { terms } = useSelector((state) => state.config);
 useEffect(()=>{
    window.gtag("event", "Confirmation page is being shown", {
      event_category: "Confirmation page",
      event_label: "Confirmation page is being shown"
    })
 })

  useEffect(() => {
    try {
      window.scroll({
        top: 600,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(600, 0);
    }
  }, []);

  let extras_Total = 0;
  let external_Extras = 0;
  let internal_Extras = 0;
  let total_pay_now = 0;
  let car_charges = 0;
  let later_charges = 0;

  extras &&
    extras
      .filter((v) => v.selected && v.type === "internal")
      .map((v) => {
        internal_Extras += v.count * Number(v.price_per_day);
      });

  extras &&
    extras
      .filter((v) => v.selected && v.type === "external")
      .map((v) => {
        external_Extras += v.count * Number(v.price_per_day);
      });

  total_pay_now = pay_now + internal_Extras;
  extras_Total = internal_Extras + external_Extras;
  later_charges = pay_later + external_Extras;
  car_charges = pay_now + pay_later;

  let price_breakdown_data = {
    pay_now: total_pay_now.toFixed(2),
    pay_later: later_charges.toFixed(2),
    total_amount: car_charges.toFixed(2),
    extras_total: extras_Total.toFixed(2),
    service_charges: 0.99,
  };

  return (
    <div>
      <Helmet>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-619354864/JvNgCL3y1b0CEPC1qqcC','value':  `+pay_now+`,'currency': 'EUR','transaction_id': ''});`}</script>
      </Helmet>
      <DownloadVoucherContainer voucher={bookingVoucher} /> 
  
      {INNER_WIDTH < 768 ? (
        <CarCard
          item={selectedCar}
          handleBookCar={() => {}}
          searchParams={searchParams}
          isConfirmationBooking={true}
        />
      ) : (
        <CarDetailedCard
          searchParams={searchParams}
          item={selectedCar}
          driverDetails={driverDetails}
        />
      )}
      <LocationCardsContainer
        bookingLocation={bookingVoucher}
        searchParams={searchParams}
        checkoutStationDetails={selectedCar.checkout_station_details}
      />
      <IncludedPriceRow inclusives={selectedCar.inclusives} heading="Included in the price" />
      {selectedCar.extras.filter((i) => i.selected).length > 0 && (
        <OrderedExtras item={selectedCar} />
      )}
      <PriceBreakDownContainer {...price_breakdown_data} />
      <HorizontalBorder />
      <div className={styles.bottomButtons_container}>
        {isUserLoggedIn ? (
          <Link to="/account/bookings">
            <Button blueBG>Go To My Booking</Button>
          </Link>
        ) :
          <Button blueBG onClick={()=> showModal("signupModal")}>Go To My Booking</Button>
        }
        <Button>
          <a href="https://blog.rentalmoose.com/privacy-policy/">Open Privacy Policy</a>
        </Button>
        <Button onClick={() => setRentalmooseTermsModal(true)}>Terms & Conditions</Button>
        <TermsModal
          modal={RentalmooseTermsModal}
          toggle={() => setRentalmooseTermsModal((prev) => !prev)}
          Text={terms}
          title="Rentalmoose Terms"
        />
        
      </div>
    </div>
  );
};
