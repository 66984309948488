import { combineReducers } from "redux";

import { AccountReducer } from "./account.reducer";
import { AuthReducer } from "./auth.reducer";
import { AppReducer } from "./app.reducer";
import { ConfigReducer } from "./config.reducer";
import { ModalReducer } from "./modal.reducer";
import {
  SearchPanelReducer,
  SearchParamsReducer,
  BookingDetailReducer
} from "./../../components";
import { VehicleImagesReducer } from "./vehicleImages.reducer";

export const rootInitialState = {};

export let rootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  config: ConfigReducer,
  modalState: ModalReducer,
  searchResult: SearchPanelReducer,
  searchParams: SearchParamsReducer,
  bookingDetail: BookingDetailReducer,
  UserAccount: AccountReducer,
  vehicleImages: VehicleImagesReducer
});
