import React from "react";
import { Button as ReactstrapButton } from "reactstrap";
import _ from "lodash";
import styles from "./styles.module.scss";

export const Button = props => {
  return (
    <ReactstrapButton
      {..._.omit(props, ["blueBG","stepName"])}
      className={`${
        props.blueBG ? "button_container" : "button_container_custom"
      } ${props.stepName === "option" ? styles.openMapBtn : ""} `}
    >
      {props.children}
    </ReactstrapButton>
  );
};
